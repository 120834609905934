

@media screen and (max-width: 991px) {
    .dropdownSelectors {
        display: grid;
        grid-template-columns: 1fr ;
        grid-gap: 20px;
    }


  }

  
  @media screen and (min-width: 991px) {
    .dropdownSelectors {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
    }
  }


.buttonSecondary {
    border-radius: 4px;
    font-size: 14px;
    width: 84px;
    height: 32px;
}
.buttonPrimary{
    background-color: var(--indigo);
    border-radius: 4px;
    font-size: 14px;
    width: 84px;
    border-color: #fff;
    color: #fff !important;
}

  .modalTitle svg {
    margin-right: 10px;
  }
  
  .modalHeader {
    border-bottom: none;
  }

  .modalTitle{
      font-size: 24px;
      display: flex;
  }

  .modalFooter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 2rem 1rem 0;
    border-top: 0px solid #dee2e6;
    border-bottom-right-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}

.modal {
    margin: 0 auto; 
    top: 20% !important;
}