.font-size(@sizeValue) {
  @remValue: @sizeValue;
  @pxValue: (@sizeValue * 10);
  font-size: ~"@{pxValue}px";
  font-size: ~"@{remValue}rem";
}

.borderLeft {
  border-image: linear-gradient(to bottom, @color-brand-red, #8f191c) 1 100%;
  border-style: solid;
  border-width: 3px;
  border-right: 0;
  padding-left: 2rem;
}

.linear-gradient() {
  background: linear-gradient(to right, #cf2f44 0%,#8f191c 99%);
}

.vertical-gradient() {
  background: linear-gradient(to bottom, rgba(207,47,68,1) 0%,rgba(143,25,28,1) 100%);
}
