.AU-005 {
  h2 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
    text-align: center;
    @media(min-width: @bpMD) {
      margin-top: 30px;
      margin-bottom: 30px;
      font-size: 2.4rem;
    }
  }
  .leadership {
    margin-bottom: 20px;
  }
  p {
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 0;
    font-weight: bold;
  }
  span {
    font-size: 12px;
  }


  @media screen and (min-width: @bpMD) {
     p {
        font-size: inherit;
        margin-bottom: 10px;
      }
      span{
        font-size: 14px;
      }
    }
}
