// Material UI style form element
.group {
  position: relative;
  margin-bottom: 20px;

  label {
    color: #000;
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 30px;
    top: 10px;
    transition: 0.2s ease all;

  }

  input {
    font-size: 18px;
    padding: 10px 10px 10px 30px;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid #757575;

    &:focus {
      border-bottom: 2px solid #CF2F45;
      outline: 0;
      -webkit-transition: all ease-in-out .15s;
      -o-transition: all ease-in-out .15s;
      transition: all ease-in-out .15s;
    }

    &:focus {
        box-shadow: none;
        border-bottom-color: #CF2F45;
        // ~ .animated-label {
        //   top: 0;
        //   opacity: 1;
        //   color: #8e44ad;
        //   font-size: 12px;
        //   &:after {
        //     visibility: visible;
        //     width: 100%;
        //     left: 0;
        //   }
        // }
      }

    &:focus,
    &:valid {
      ~ label {
        top: -3px;
        font-size: 12px;
        color: #CF2F45;
      }
    }
  }
}

/* Custom Select */
.select {
  position: relative;
  display: flex;
  width: 100%;
  height: 3em;
  line-height: 3;
  background: #404040;
  overflow: hidden;

  &:hover {
    background: linear-gradient(to right, #cf2f44 1%,#8f191c 100%);
  }

  /* Reset Select */
  select {
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 0 !important;
    background: #404040;
    background-image: none;

    /* Remove IE arrow */
    &::-ms-expand {
      display: none;
    }

    option {
      &:checked {
        background: linear-gradient(to right, #cf2f44 1%,#8f191c 100%);
      }
    }
  }

  /* Custom Select */
  select {
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 0 !important;
    background: #404040;
    background-image: none;

    option:checked {
      background: linear-gradient(to right, #cf2f44 1%,#8f191c 100%);
    }

    /* Remove IE arrow */
    &::-ms-expand {
      display: none;
    }

    flex: 1;
    padding: 0 .5em;
    color: #fff;
    cursor: pointer;
  }

  /* Arrow */
  &::after {
    content: '\25BC';
    color: #FFF;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 1em;
    background: #8f191c;
    background: #404040;
    cursor: pointer;
    pointer-events: none;
    transition: .25s all ease;
  }
}

.form-check {
  &-label {
    margin-left: 10px;
  }
}
// Form fields with icons
.form-icon {
  height: 10px;
  width: 10px;
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  margin-top: -5px;
  margin-left: 10px;

  &.icon-user {
    background-image: url(../../assets/images/user/user.png);
  }

  &.icon-email {
    background-image: url(../../assets/images/user/mail.png);
  }

  &.icon-org {
    background-image: url(../../assets/images/user/org.png);
  }

  &.icon-password {
    background-image: url(../../assets/images/user/password.png);
  }
}

// Search field
.search-box {
  .form-control {
    .font-size(1.8);
    border-radius: 25px;
    height: auto;
    line-height: 24px;
    padding-left: 23px;
  }

  .btn {
    .font-size(1.8);
    background-color: #fff;
    border-radius: 25px;
    padding-right: 23px;

    .fa {
      color: @color-brand-red;
    }
  }
}

.book-your-place {
  input {
    background-color: #F4F4F4;
  }
  .custom-control-label {
    .font-size(1.4);
      font-weight: inherit;
    a {
      .font-size(1.4);
      font-weight: inherit;
    }
  }
  .custom-control-input {

  }
}
