.ReTD-002 {
  background-position: center;
  background-size: cover;

  .card {
    background: #fff;
    img{
      min-height: 460px;
    }
    .card-body {
      &.active,
      &:hover {
        .linear-gradient();

        a {
          color: #fff;
        }
      }
    }

    .stretched-link {
      .font-size(1.8);
      text-decoration: none;
    }
  }

  .search-box-group {
    display: none;
  }
}
