.ReCP-005 {
  h4 {
    .font-size(2.6);
    font-weight: bold;
    line-height: 33px;
  }

  img {

    @media screen and (max-width: @bpMD) {
      width: 100%;
    }
  }

  .feature-block {
  }

  .popular {

    @media screen and (max-width: @bpMD) {
      display: none;
    }


    h4 {
      margin-bottom: 30px;
    }

    .card {
      border-radius: 0;
      border: 1px solid grey;
      border-image: linear-gradient(to bottom, #CF2F44, #8f191c) 30 30 round;;
      border-style: solid;
      border-width: 5px;
      border-bottom:0;
      border-left:0;
      border-right:0;
      box-shadow: 0 3px 6px rgba(0, 0, 0, .1);



      &.premium {
        border-top-color: #00798C;
        border-image: none;
      }

      .card-body p {
        font-weight: bold;
      }

      &.premium {
        border-top-color: #00798C;

        &:before {
          display: none;
        }
      }
    }
  }
}

.feature-block-img{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 343px;

}
