.AU-009 {

  h1 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
    text-align: center;
    @media(min-width: @bpMD) {
      margin-top: 30px;
      margin-bottom: 30px;
      font-size: 2.6rem;
    }
  }

  h2 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
    @media(min-width: @bpMD) {
      margin-top: 30px;
      margin-bottom: 30px;
      font-size: 2.4rem;
    }
  }

  .awards {
    margin-bottom: 20px;
    width: 50%;
    float: left;
    padding: 0 5px;
    @media(min-width: @bpMD) {
        width: 25%;
    }
  }

  .post-article {
    margin-bottom: 20px;
    width: 100%;
    float: left;
    padding: 0 5px;
    p {
        font-size: 14px;
    }
    @media(min-width: @bpMD) {
        width: 50%;
    }
  }

  .awards__image-container {
    margin-bottom: 30px;
  }
  span {
    font-size: 12px;
    color: @color-brand-grey;
  }
}
