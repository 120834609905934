.PP-002 {
  margin-top: 50px;

  h1 {
    .font-size(3.6);
    line-height: inherit !important;
    font-family: @font-family-secondary;

    @media screen and (min-width: @bpMD) {
      .font-size(5.6);
      line-height: inherit;
    }
  }

  h2 {
    .font-size(1.8);
    line-height: 23px;
    margin-bottom: 20px;
    font-weight: bold;
    @media screen and (min-width: @bpMD) {
      .font-size(2.6);
      line-height: 33px;
    }
  }

  p {
    .font-size(1.4);
    line-height: 22px;
    margin-bottom: 20px;

    @media screen and (min-width: @bpMD) {
      .font-size(1.8);
      line-height: 30px;
    }
  }
}

.PP-002alt {
  padding-top: 40px;
  padding-bottom: 40px;
  background-size: cover;
  h1 {
    .font-size(3.6);
      line-height: 4.6rem !important;
      font-family: @font-family-secondary;
      margin-bottom: 20px;
      font-weight: 600;
    @media screen and (min-width: @bpMD) {
      .font-size(4.8);
      line-height: 5.8rem !important;
      margin-top: 20px;
    }
  }
  p {
    font-weight: 500;
  }
}

#gradient-horizontal {
  --color-stop-1: #a770ef;
  --color-stop-2: #cf8bf3;
  --color-stop-3: #fdb99b;
}

#BoneDisease {
  fill: url(#gradient-horizontal) gray;
}
