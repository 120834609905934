.HP-005 {
  background-repeat: no-repeat;
  background-position: center 300px;
  background-size: contain;

  padding-bottom: 90px;

  @media screen and (min-width: @bpMD) {
    background-image: none !important;
    padding-bottom: 0;
  }

  .break {
    height: 20px;

    @media screen and (min-width: @bpMD) {
      height: 30px;
    }
  }

  .content-background-img {
    background-size: 0;
    @media screen and (min-width: @bpMD) {
      background-position: 0% 100%;
      background-repeat: no-repeat;
      background-size: 78% 89%;
    }
  }

  article {
    &.margin-top-auto {
      margin-top: auto;
    }
    header {
      align-items: center;
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      height: 100%;
      padding: 32px 16px 32px 32px;

      @media screen and (min-width: @bpMD) {
        padding-top: 30px;
        padding-bottom: 30px;
      }

      // h2 {
      //   .font-size(2.6);
      //   border-left: 2px solid @white;
      //   line-height: 33px;
      //   padding-left: 2rem;
      //   width: 100%;

      //   @media screen and (min-width: @bpMD) {
      //     .font-size(4.8);
      //     line-height: 58px;
      //     width: 70%;
      //   }
      // }
       h2 {
        .font-size(3.6);
        .borderLeft();
        max-width: 400px;
        color: #000;
        margin: 50px 0;
        font-weight: 900;

        small {
          .font-size(1.4);
        }

        span {
          display: block;
          line-height: 1;
        }

        .dark & {
          border-image: linear-gradient(to bottom, #FFF, #FFF) 1 100%;
        }
      }
    }

    div {
      background: #fff;
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
      margin-bottom: 20px;
      padding: 20px;
    }

    h3 {
      .font-size(2.6);
      font-weight: bold;
      line-height: 33px;
    }

    p {
      display: none;
      .font-size(1.4);
      border-bottom: 2px solid #ececec;
      line-height: 18px;
      padding-bottom: 24px;

      @media screen and (min-width: @bpMD) {
        display: inline-block;
      }
    }
  }
}

.container + .HP-005 {
  @media screen and (min-width: @bpMD) {
    margin-top: -120px;
  }
}

.HP-015 {
  background-repeat: no-repeat;
  background-position: center 200px;
  background-size: contain;
  @media screen and (min-width: @bpLG) {
    background-image: none !important;
    padding-bottom: 0;
  }

  .break {
    height: 20px;

    @media screen and (min-width: @bpLG) {
      height: 30px;
    }
  }

  .content-background-img {
    background-size: 0;
    @media screen and (min-width: @bpLG) {
      background-position: 0% 100%;
      background-repeat: no-repeat;
      background-size: 78% 89%;
    }
  }

  article {
    &.margin-top-auto {
      margin-top: auto;
    }
    header {
      align-items: center;
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      height: 100%;
      padding: 32px 16px 32px 32px;

      @media screen and (min-width: @bpLG) {
        padding-top: 130px;
        padding-bottom: 130px;
      }

      h2 {
        .font-size(2.6);
        border-left: 2px solid @white;
        line-height: 33px;
        padding-left: 2rem;
        width: 100%;

        @media screen and (min-width: @bpLG) {
          .font-size(4.8);
          line-height: 58px;
          width: 70%;
        }
      }
    }

    div {
      background: #fff;
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
      margin-bottom: 20px;
      padding: 20px;
    }

    h3 {
      .font-size(2.6);
      font-weight: bold;
      line-height: 33px;
    }

    p {
      display: none;
      .font-size(1.4);
      border-bottom: 2px solid #ececec;
      line-height: 18px;
      padding-bottom: 24px;

      @media screen and (min-width: @bpLG) {
        display: inline-block;
      }
    }
  }

}

.container{
  &.HP-015 {
    @media screen and (min-width: @bpLG) {
      margin-top: -120px;
    }
  }
}
