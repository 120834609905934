.POS-001 {
  .breadcrumb {
    background: transparent;
    border-radius: 0;
    padding: 0;
    font-weight: bold;
    .breadcrumb-item {
      &.active {
        //color: #000;
      }
    }
    a {
      position: relative;
      &:after {
        content: '';
        border-color: @color-brand-red;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 1px;

        border-width: 0 0 1px;
        border-style: solid;
      }
    }
  }
}

.POS-002 {
 // max-width: 900px;
  margin-top: 20px;
  margin-bottom: 20px;
  h2 {
    .font-size(3);
    // border-width: 4px;
    margin-bottom: 20px;
    line-height: inherit;
    // border-style: solid;
    // border-image: linear-gradient(to bottom, @color-brand-red, #8f191c) 1 100%;
    // border-right: 0;
    // line-height: 33px;
    // padding-left: 20px;
    font-weight: bold;
    .title__location {
      .font-size(1.6);
      color: @color-brand-red;
      font-weight: 300;
    }
  }

  p {
    .font-size(1.6);
    font-weight: bold;
  }
}

