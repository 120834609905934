/* #### Assay page updated */
.assay-menu-components {
  background-color: #fff;
  padding-bottom: 50px;
}

#accordionAssay {
  min-width: 100%;
}
#accordionAssay  .col-lg-6  {
 float: left;
}
.img-border {
  border-bottom: 3px solid #EAEBEA;
  margin-bottom: 10px;
  height: 120px;
}
.img-border img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}
.img-border.active_nav:after, .img-border.active:after {
  content: "";
  height: 3px;
  width: 100px;
  display: block;
  position: absolute;
  left: 22%;
  top: 60.5%;
  -webkit-border-image: -webkit-gradient(linear, left top, left bottom, from(#CF2F44), to(#8F191C)) 30 30 stretch;
  -webkit-border-image: linear-gradient(to bottom, #CF2F44, #8F191C) 30 30 stretch;
  -o-border-image: -o-linear-gradient(top, #CF2F44, #8F191C) 30 30 stretch;
  border-image: -webkit-gradient(linear, left top, right top, from(#CF2F44), to(#8F191C)) 30 30 stretch;
  border-image: linear-gradient(to right, rgb(209, 92, 108), #8F191C) 30 30 stretch;
  border-style: solid;
  border-width: 3px;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
}
img.fadeIMG {
  opacity: 0.3;
}
.xtmenu-link:hover img.fadeIMG {
  opacity: 0.6;
}
.vitros-models {
  margin-bottom: 40px;
}
.xtmenu-title {
  font-size: 15px;
  color: #B3B3B3;
  opacity: 0.6;
  line-height: 22px;
  text-align: center;
  padding-top: 10px;
}
.xtmenu-link {
  cursor: pointer;
}
.xtmenu-link:hover .xtmenu-title{
  opacity: 1;
}
.xtmenu-title.active {
  color: #CF2F44;
  opacity: 1;
}
.accordion-assay, .accordion-assay.collapsed {
  cursor: pointer;
  border-left: 5px solid #BCBDBC;
  padding: 3px 10px;
  margin: 3px 0;
  font-weight: bold;
}
.top {
  border-top: 1px solid #BCBDBC;
  margin-bottom: 3px;
}
.top.last-child {
  border-bottom: 1px solid #BCBDBC;
}
.top.active {
  border-bottom: 0;
  margin-bottom: 0;
}
#accordionAssay ul {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  padding-left: 30px;
}
#accordionAssay ul li {
  padding: 0 5px;
  color: #8F191C;
  font-size: 14px;
  line-height: 25px;
}
.accordion-assay.active {
  background-image: linear-gradient(to right, #8F191C , #CF2F44);
  border-bottom: 0;
  border-left: 0;
  padding-left: 15px;
  color: #fff
}
#tab2, #tab3, #tab4, #tab5, #tab6  {
  display: none
}
.key {
  margin-top: 50px;
}
.key p {
  font-size: 13px
}
@media (max-width: 1200px) {
  .img-border.active_nav:after, .img-border.active:after {
    top: 54%;
    left: 17%;
}
}
@media (max-width: 991px) {
  .img-border.active_nav:after, .img-border.active:after {
    display: none;
}
.img-border {
  border-bottom: 0;
  height: auto;
margin-bottom: 0; }
.top.last-child {
  border-bottom: 0;
}
}
@media (max-width: 768px) {
  .img-border.active_nav:after, .img-border.active:after {
    display: none;
}
.xtmenu-title{
  font-size: 12px;
}
// .vitros-padding {
//   padding: 0 15px;
// }
.assay-menu-components {
  padding-bottom: 40px;
}
}

@media (max-width: 479px) {
  #accordionAssay ul {
    column-count: 2;
    padding-left: 20px;
  }
}
