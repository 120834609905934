.tech-doc {
  display: flex;
  margin-bottom: 30px;

  .title {
    .font-size(1.4);
    color: #BCBDBC;
    line-height: 18px;
  }

  .download {
    .font-size(1.4);
    .vertical-gradient();
    color: #fff;
    font-weight: bold;
    line-height: 18px;

    svg {
      fill: #fff;
      height: 24px;
      width: 24px;
      margin: 30px auto 0;
    }
  }

  .versions {
    p {
      .font-size(1.4);
      color: #76A95A;
      margin-bottom: 0;
    }

    a {
      .font-size(1.2);
      color: #CF2F44;
      line-height: 15px;


      &:after {
        width: 12px;
        height: 7px;
      }
    }
  }
}
