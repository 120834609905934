.POS-009 {
  p {
    .font-size(2.6);
    line-height: 36px;
    font-weight: bold;
    font-style: italic;
    border-image: linear-gradient(to bottom, #CF2F44, #8f191c) 1 100%;
    border-style: solid;
    border-width: 3px;
    border-right: 0;
    padding-left: 4.7rem;
    margin-left: 6rem;
  }
}
