.ReTD {
  display: none;
}

.ReTD-001 {
  p {
    color: #fff;
  }

  .modal-dialog {
    max-width: 1000px;
  }

  &.modal {
    .modal-content {
      background: linear-gradient(to right, #cf2f44 0%, #8f191c 100%);
      border: 0;

      h1 {
        .font-size(6.6);
        color: #fff;
        font-family: @font-family-secondary;
      }

      form {
        background: #fff;
        margin-bottom: 20px;

        .form-check-label {
          color: #000;
        }
      }

      .modal-footer {
        border-top: 0;
      }
    }
  }
}
