.POS-006, .POS-023 {
  img {
    border-bottom: 4px solid #CF2F44
  }

  .figure-caption {
    .font-size(1.4);
    border-bottom: 2px solid #F4F4F4;
    color: #747678;
    font-weight: 400;
    line-height: 20px;
    margin-top: 30px;
    padding-bottom: 30px;
    max-width: 80%;
    min-width: 260px;
    margin-left: auto;
    margin-right: auto;
  }
}
.POS-029 {
  i {
    height: 24px;
    width: 24px;
    color: #BCBDBC;
    position: relative;
    left: 5px;
    top: 2px;
  }
}
