.selectText {
    text-decoration: underline;
    font: normal normal normal 18px Rubik;
    letter-spacing: 0px;
    color: #747474;
    line-height: 27px;
    padding: 5px 0;
    cursor: pointer;
}
.selectText span {
    padding: 0 10px;
}