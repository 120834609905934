.title-h1 {
    font-family: 'Calluna', sans-serif;
    font-size: 34px;
    margin-bottom: 20px;
    @media screen and (min-width: @bpSM) {
        font-size: 54px;
    }
}

.mb-50px {
    margin-bottom: 50px;
}

.full-bio {
    // font-size: 16px;
    // font-size: 1.6rem;
    p {
        &:first-of-type {
            font-weight: bold;
            border-width: 3px;
            border-style: solid;
            -webkit-border-image: -webkit-gradient(linear, left top, left bottom, from(#CF2F44), to(#8f191c)) 1 100%;
            -webkit-border-image: linear-gradient(to bottom, #CF2F44, #8f191c) 1 100%;
            -o-border-image: -o-linear-gradient(top, #CF2F44, #8f191c) 1 100%;
            border-image: -webkit-gradient(linear, left top, left bottom, from(#CF2F44), to(#8f191c)) 1 100%;
            border-image: linear-gradient(to bottom, #CF2F44, #8f191c) 1 100%;
            border-right: 0;
            border-bottom: 0;
            border-top: 0;
            // line-height: 20px;
            padding-left: 15px;
            margin-bottom: 40px;
        }
    }
}

.leadership-full {
    .leadership__image-container {
        margin-bottom: 15px;
    }
    img {
        text-align:center;
        padding-bottom:5px;
        background:
        linear-gradient(
          to left,
          (#8f191c) 0%,
          (#CF2F44) 100%
        )
        left
        bottom
        #777
        no-repeat;
        background-size:100% 5px ;
    }
}

.leadership-full__content {
    font-size: 34px;
    font-weight: bold;
}

.leadership-full__content {
    display: none;
    @media screen and (min-width: @bpMD) {
        display: block;
        padding-bottom: 30px;
        border-bottom: 3px solid @op-color-extra-light-grey;
    }
    h2 {
        font-size: 32px;
        font-weight: bold;
    }
    h3 {
        font-size: 24px;
        color: @op-color-medium-grey;
    }
}

.quick-link-social {
    padding-top: 15px;
    margin-top: 15px;
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            margin-bottom: 15px;
            font-weight: bold;
            color: @op-color-medium-grey;
            a {
                transition: transform .3s ease-out;
                display: inline-block;
                color: @op-color-medium-grey;
                &:hover {
                    transform: translateY(-5px);
                }
                i {
                    margin-left: 10px;
                    font-weight: initial;
                }
            }
        }
    }
}
