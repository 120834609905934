.ReTD-014 {
  svg {
    height: 24px;
    width: 24px;
  }

  .smart-result {
    color: #8F191C;
  }
}
