.PP-010 {
  .item-block {
    background-position: center;
    background-size: cover;
    margin-bottom: 0;
    height: 360px;

    @media screen and (min-width: @bpMD) {
      height: 600px;
    }
  }

  &:nth-of-type(odd) {
    .container {
      justify-content: flex-end;
    }
  }

  &:nth-of-type(even) {
    .container {
      justify-content: flex-start;
    }
  }

  .article-content {
    background: transparent;
    margin: 0;
    max-width: 500px;

    h2 {
      .font-size(2.6);
      line-height: 33px;

      @media screen and (min-width: @bpMD) {
        .font-size(4.6);
        line-height: 58px;
      }
    }

    p {
      .font-size(1.4);
      line-height: 22px;

      @media screen and (min-width: @bpMD) {
        .font-size(1.8);
        line-height: 30px;
      }
    }

    a {
      .font-size(1.2);
      line-height: 15px;

      @media screen and (min-width: @bpMD) {
        .font-size(1.4);
        line-height: 18px;
      }
    }
  }
}
