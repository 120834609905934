.PP-013 {
  .article {
    justify-content: flex-start;

    img {
      left: auto;
      right: 0;
    }

    cite {
      display: block;
      margin-left: 0;
      margin-top: 10px;
    }
  }
}
