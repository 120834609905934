.PP-006 {
  h3 {
    font-weight: bold;
    @media screen and (max-width: @bpMD) {
      .font-size(2.6);
      text-align: center;
      margin: 0 auto 40px;
      line-height: 33px;
    }
    @media (min-width: @bpMD) {
      .font-size(3.2) !important;
      line-height: 33px;
      margin-top: 40px;
      margin-bottom: 80px;
    }
  }

  .blockquote {
    p {
      font-weight: bold;
      @media (min-width: @bpMD) {
        .font-size(2);
        padding-left: 15px;
        margin-top: 10px;
      }


      span {
        @media (min-width: @bpMD) {
          &:before {
            content: '“';
            color: #8F191C;
            position: absolute;
            left: 0px;
            .font-size(4.2);
            top: -10px;
          }

        }

      }
    }
    cite {
      color: #747678;
      margin-left: 10px;
      @media (min-width: @bpMD) {
        .font-size(1.4);
      }
    }
  }
  .slick-dots {
    bottom: -20px;
  }
}
