.POS-011 {
  background: #F4F4F4;
  display: flex;
  flex-flow: row wrap;

  h4 {
    width: 100%;
  }

  .addr {
    border-image: linear-gradient(to bottom, #CF2F44, #8f191c) 1 100%;
    border-style: solid;
    border-width: 3px;
    border-right: 0;
    padding-left: 1.8rem;

    span {
      display: block;
    }
  }

  strong {
    display: block;
  }
}
