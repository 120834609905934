.HP-006 {
  display: flex;
  flex-direction: column;
  margin: 50px 0;

  @media screen and (min-width: @bpMD) {
    height: 100vh;
    max-height: 900px;
  }

  .container {
    &,
    .row {
      height: 100%;
    }
  }

  @media screen and (max-width: @bpMD - 1px) {
    background-image: none !important;
  }

  @media screen and (min-width: @bpMD) {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  h1 {
    font-size: 36px;
    font-size: 3.6rem;
    line-height: 54px;
    @media screen and (min-width: @bpLG) {
      .font-size(4.6);
      font-weight: bold;
      margin-bottom: 40px;
    }
  }

  p {
    margin-bottom: 60px;
  }

  .button-well {
    height: auto;

    @media screen and (min-width: @bpMD) {
      margin: 0 0 150px;
    }
  }

  .about-block {
    margin: 0 auto;
    max-width: 800px;

    > div {
      display: block;
      width: 50%;

      @media screen and (min-width: @bpMD) {
        width: 25%;
      }
    }
  }

  h2 {
    .font-size(4.6);
    color: #8F191C;
    margin: 0;
    margin-right: 5px;

  }

  small {
    .font-size(1.4);
    color: #000;
    line-height: 1;
    width: 50px;
    display: inline-block;
    text-align: left;
  }
}
