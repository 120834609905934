.Re-004 {
  background-color: #f8f8f8;
  padding: 50px 0;

  .copy-block {
    color: #00798C;

    h3 {
      .font-size(3.6);
      line-height: 45px;

      @media (max-width: @bpMD) {
        .font-size(2.8);
        line-height: 35px;
      }
    }

    p {

      @media (max-width: @bpMD) {
        .font-size(1.6);
        line-height: 23px;
        margin-top: 40px;
        margin-bottom: 20px;
      }
    }

    ul {
      color: #000;

      @media (max-width: @bpMD) {
        margin-bottom: 40px;
      }
    }

    li {
      font-weight: bold;
      border-left: 2px solid #000;
      padding-left: 10px;

      &:first-child {
        border-left: 0;
        padding-left: 0;
      }
    }
  }

  .card {
    background: #fff;
    border-top: 5px solid #00798C;
    box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.1);
    margin-bottom: 0;
    padding: 0 40px;

    &.premium {

      p {
        margin-top: 10px;
      }

      &:before {
        .font-size(1.8);
        top: 0;
        padding: 10px 15px;
        font-weight: bold;
      }
    }
    ul {
      margin-top: 30px;
    }

    li {
      .font-size(1.8);
      line-height: 24px;
      padding-bottom: 20px;
      font-weight: bold;

      &:before {
        content: '';
        display: block;
        height: 22px;
        width: 22px;
        float: left;
        // background-image: url(http://placehold.it/32?text=fallback); // Fallback PNG
        background-image: url(../../assets/images/svg/PremiumTick.svg);
        background-size: contain;
        background-repeat: no-repeat;
        margin-right:0.5em;
        margin-bottom: 20px;
      }
    }

    svg {
      width: 22px;
      height: 22px;
    }

    .btn-well {
      text-align: center;
      padding-bottom: 30px;
    }
  }

  .social a {
      font-size: 2.3rem;
      color: #BCBDBC;
  }

}


@media (max-width: 991px) {
  .Re-002 .link-block{
    height: 280px;
  }
  .Re-002 .link-block a {
    font-size: 12px;
    line-height: 18px;
  }
  .Re-003{
    margin-top: 200px !important;
  }
  .card .card-bg-img{
    min-height: 320px;
    max-height: 320px;
  }
  .card .card-title{
    font-size: 24px;
  }
  .card-text{
    line-height:24px;
  }
  .card .card-bg-img.mini-cards{
    min-height: 130px;
    max-height: 130px;
  }
  .card .card-title.card-min-ititle{
    font-size: 13px;
  }
  .Re-001{
    height: 430px;
  }
  .Re-001 .container .content{
    justify-content: flex-start;
  }
  .Re-004 .copy-block h3{
    font-size: 24px ;
    line-height: 28px;
  }
  .copy-block p{
    font-size: 14px;
    line-height: 22px;
  }
  .list-inline-item{
    font-size: 14px;
  }
  .Re-004 .card li{
    font-size: 13px;
    padding-left: 35px;
    position: relative;
    line-height: 18px;
    &:before{
      position: absolute;
      left: 0;
    }
  }
  // .request-call__intro-text{
  // margin: 0 0 20px 0;
  // }
  .request-call__container{
    padding: 50px 20px;
  }
}


@media (max-width: 767px) {

  .Re-001 .container .content h1{
    font-size: 36px;
    margin: 0;
  }
  .Re-001{
    height: 250px;
  }
  .Re-001 .container .content p{
    font-size: 14px;
    line-height: 23px;
  }
  .Re-001 .Re-002{
    margin-top: -60px;
  }
  .Re-002 .link-block{
    height: 140px;
  }
  .Re-002 .link-block a{
    font-size: 22px;
  }
  .Re-003{
    margin-top: 600px !important;
    padding-bottom: 0;
  }

  .Re-003 .article-header > p:last-of-type{
    margin-bottom: 30px;
  }
  .card .card-title.card-min-ititle, .card .card-title{
    font-size: 18px;
  }
  .card .card-bg-img.mini-cards, .card .card-bg-img{
    min-height: 163px;
    max-height: 163px;
  }
  .Re-003 .card-read-more{
    margin-top: 0;
    margin-bottom: 20px;
  }
  .Re-004 .copy-block h3{
    font-size: 26px;
  }
  .Re-004{
    padding: 25px 0;
  }
  .Re-004 .copy-block p{
    font-size: 14px;
    margin-top: 20px;
  }
  .Re-004 .card li{
    font-size: 14px;
  }
  .Re-004 .card li:before{
    top: -5px;
  }
  .card-read-more{
    display: none;
  }
  .main{
    margin-bottom: 30px;
  }
  .Re-004{
    padding-bottom: 50px;
  }
}


@media (max-width: 576px) {
  .Re-001 .Re-002{
    margin-top: -40px;
  }
  .Re-002 .link-block a{
    line-height: 30px;
  }
  .Re-003 .article-header h2{
    font-size: 26px;
    line-height: 40px;
  }
  .Re-003 .latest-content{
    margin: 0 -15px;
  }
  .card .card-title{
    line-height: 24px;
  }
  .Re-004 .copy-block li{
    padding-left: 5px;
  }
  .Re-004 .card.premium:before{
    padding: 10px;
  }
  .Re-004 .card.premium p{
    font-size: 16px;
    margin: 10px auto;
    margin-right: 0;
  }
  .Re-004 .card{
    padding: 0 20px;
  }
  .call-back-block{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .request-call__input{
    margin-right: 0;
    height: 46px;
    padding: 0 23px;
  }
  .request-call__intro-text{
    font-size: 26px;
  }
  .call-back-block button{
    width: 251px;
    height: 46px;
    margin-top: 25px;
  }
  .request-call__container{
    padding: 33px 15px;
  }
}
