.AU-001 {
  background-image: none;
  margin-bottom: 50px;

  &.white {
    * {
      color: white;
    }
  }

  .link-block {

    svg {
      width: 20px;
      height: 25px;
      margin-left: 20px;
      fill: @color-brand-red;
    }
  }

  @media screen and (min-width: @bpMD) {
    background-size: cover;
    background-position: right center;
    width: 100%;
    display: block;
    height: 600px;
    max-height: 85vh;
  }

  > span {
    display: block;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 70vh;
    height: 600px;

    @media (min-width: @bpMD) {
      background-image: none !important;
    }
  }


  .col {
    @media screen and (min-width: @bpMD) {
      max-width: 55%;
    }
  }

  .container {
    &,
    .row {
      height: 100%;
    }
  }

  h1 {
    .font-size(3.6);
    font-family: @font-family-secondary;
    line-height: 36px;
    margin: 20px 0;

    @media screen and (min-width: @bpMD) {
      .font-size(7.6);
      line-height: 76px;
      margin: 48px 0;
    }
  }

  p {
    .font-size(1.4);
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to bottom, @color-brand-red, #8f191c) 1 100%;
    border-right: 0;
    line-height: 33px;
    margin-bottom: 40px;
    padding-left: 20px;
    font-weight: bold;
    @media screen and (min-width: @bpMD) {
      .font-size(1.8);
      line-height: 30px;
    }
  }

}

.responsibility-main.AU-001 {
  position: relative;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255) 0%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 50%);
  }

  .col {
    max-width: 60%;

    p {
      line-height: 33px;
    }
  }
}

.responsibility-message {
  &.AU-004 {
    margin: 73px auto 60px !important;
  }

  .cover {
    width: 300px !important;
    height: 300px !important;
    border: 5px solid #F4F4F4;
  }

  h1 {
    .font-size(3.6) !important;
    margin-bottom: 30px;
  }

  p {
    .font-size(1.8) !important;
  }
}

.responsibility-action.HP-001 {
  position: relative;

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.46) 0%, rgba(0, 0, 0, 0) 99%);
  }
}

.responsibility-action {
  p {
    .font-size(2.69) !important;
    line-height: 34px;

  }

  .HP-001 .col {
    max-width: 58%;
  }
}

.responsibility-count {
  margin: 60px auto;

  h3 {
    .font-size(4.6) !important;
  }

  p {
    .font-size(2.4) !important;
    line-height: 30px;

    span {
      .font-size(1.8) !important;
      line-height: 23px;
      display: block;
      margin-top: 15px;
    }
  }
}

#HP001.responsibility-action-1 {
  margin-top: 0 !important;

  .component {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.responsibility-action-1.HP-001 {

  position: relative;

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 100%)
  }

  p {
    .font-size(2.6) !important;
    line-height: 35px;
    padding-left: 25px !important;
    max-width: 580px !important;
  }
}

.responsibility-action-1 ~ .responsibility-action-1.HP-001 {
  &:before {
    background: linear-gradient(337.16deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 100%);
  }
}

.responsibility-count-last {
  margin: 60px auto;

  h3 {
    .font-size(3.2) !important;
  }

  p {
    .font-size(1.8) !important;
    line-height: 22px;

    span {
      .font-size(1.4) !important;
      line-height: 23px;
      display: block;
    }
  }
}

.responsibility-compliance {
  h1 {
    .font-size(4.6) !important;
  }

  .small-text {
    .font-size(1.4) !important;
    line-height: 22px;
  }

  .au-border {
    .clearfix {
      margin-top: 70px;
    }
  }

}


.about-us-innovations{
  .article-content {
    padding-left: 50px;
  }
  h2{
    font-weight: bold;
  }
  .button-link {
    padding: 16px 20px 14px 20px;
    border: 1px solid #CF2F44;
    cursor: pointer;
    transition: 0.3s;
    text-decoration: none;

    &:hover{
      background-color: #CF2F44;
      color: white;
    }
  }
  .component + .component {
    .article-content {
      padding-left: 0;
      padding-right: 50px;
    }
  }
}

.about-us-diagnostic {
  margin-bottom: 90px;
  .link-arrow-down {
    text-decoration: none;
  }
}

.about-us-awards {
  .AU-003 {
    border-bottom: 5px solid #F4F4F4;
  }
}

.about-us-questions {
  margin-top: 90px;
  h2{
    .font-size(4.6) !important;
    font-weight: bold;
    padding-bottom: 90px !important;
  }
}

.about-us-leadership {
  margin-bottom: 90px;
  h2{
    margin-top: 90px !important;
    margin-bottom: 77px !important;
    .font-size(4.6) !important;
    font-weight: bold ;
  }

  .AU-005 {
    .leadership{
      margin-bottom: 40px;
    }
  }
}




@media (max-width: 991px) {

  .responsibility-main.AU-001 .col {
    max-width: 80%;
  }

  .responsibility-message .cover {
    width: 250px !important;
    height: 250px !important;
  }

  .responsibility-message p {
    .font-size(1.6) !important;
    line-height: 22px;
  }

  .responsibility-message a {
    line-height: 22px;
    display: block;
  }

  .responsibility-action.HP-001 .col {
    max-width: 90%;
  }

  .responsibility-count h3 {
    .font-size(3.6) !important;
  }

  .responsibility-count p {
    .font-size(1.8) !important;
    line-height: 22px;
  }

  .responsibility-count p span {
    .font-size(1.4) !important;
    line-height: 17px;
    display: block;
    margin-top: 7px;
  }

  .responsibility-count .content-text-box {
    padding-bottom: 0 !important;
  }

  .responsibility-action-1 .col {
    max-width: 100% !important;
  }

  .responsibility-compliance p {
    .font-size(1.6);
    line-height: 22px;
  }

  .responsibility-compliance h2 {
    .font-size(2.2) !important;
    margin: 15px 0 !important;
  }

  .responsibility-compliance .awards img {
    // width: 90%;
    // height: 70px;
  }

  .responsibility-compliance {
    &.AU-010 {
      .awards {
        .awards__image-container {
          margin-bottom: 0;
        }
      }
    }
  }

  .responsibility-compliance .awards span {
    .font-size(1.2) !important;
    line-height: 18px;
    display: block;
    padding: 15px 10px;
  }

  .responsibility-compliance.AU-010 .au-border {
    margin-top: 25px !important;
    padding-bottom: 15px !important;
    padding-top: 15px !important;
  }

  .responsibility-related p {
    .font-size(1.4);
    line-height: 20px;
  }

  .about-us-innovations .article-content{
    padding-left: 0;
  }

}


@media (max-width: 767px) {
  .AU-002 h2{
    .font-size(2.4)!important;
  }
  .AU-002 p{
    .font-size(1.4);
    line-height: 22px;
  }
  .about-us-innovations h2{
    .font-size(2.6)!important;
    line-height: 40px;
    margin-top: 20px;
    margin-bottom: 13px;
  }
  .about-us-innovations  p span{
    .font-size(1.4)!important;
    line-height: 22px;
    display: block;
  }
  .about-us-innovations .button-link{
    display: inline-block;
  }
  .about-us-innovations .component + .component .article-content{
    padding-right: 0;
  }
  .about-us-diagnostic h2{
    max-width: 80% !important;
  }
  .about-us-diagnostic{
    margin-bottom: 50px;
  }
  .about-us-awards .col-md-10, .about-us-awards .container{
    padding: 0;

  }
  .about-us-questions h2{
    .font-size(2.6) !important;
  margin-top: 0 !important;
    margin-bottom: 60px !important;
    padding-bottom: 0!important;
  }
  .about-us-questions{
    margin-top: 50px;
  }
  .about-us-questions span {
    .font-size(1.4);
    line-height: 22px;
    display: block;
  }
  .about-us-questions .AU-004 .what-they-say-wrapper .blockquote cite{
    margin-left: 0;
    .font-size(1.2);
  }
  .about-us-questions .arrow-link{
    small{
      padding-right: 45px;
      display: block;
      position: relative;
    }
    background-image: none;
    position: relative;
    padding-right: 45px;
    display: block;
    small:before{
      position: absolute;
      content: '';
      color: #000;
      width: 14px;
      height: 14px;
      border:1px solid  #8F191C;
     border-left: none;
      border-bottom: none;
      top: 40%;
      transform: translateY(-50%) rotate(45deg) ;
      right: 10px;
    }
    small:after{
      position: absolute;
      content: '';
      width: 24px;
      height: 1px;
      background-color:#8F191C;
    right: 9px;
      top: 40%;
      transform: translateY(-50%)  ;
    }
  }
  .about-us-leadership h2 {
    .font-size(2.6)!important;
    line-height: 44px;
    margin-top: 60px !important;
    margin-bottom: 40px !important;
  }
  .leadership__content p {
    line-height: 12px;
  }
  .about-us-leadership.AU-005 .leadership{
    margin-bottom: 20px;
  }
  .about-us-leadership{
    margin-bottom: 50px;
  }
  .about-us-questions h2{
    margin-bottom: 35px !important;
  }
}

@media (max-width: 576px) {

  .responsibility-main.AU-001 .col {
    max-width: 92%;
  }

  .responsibility-action-1 a, .responsibility-action a {
    .font-size(1.2) !important;
  }

  .responsibility-action p {
    .font-size(1.7) !important;
    line-height: 22px;
  }

  .AU-004 .message-from .cover {
    width: 170px !important;
    height: 170px !important;
    margin-right: 0 !important;
    margin-bottom: 20px !important;
  }

  .responsibility-count {
    margin: 30px auto;
  }

  .responsibility-action-1.HP-001 p {
    font-size: 20px !important;
    line-height: 26px;
  }

  .clearfix {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .responsibility-compliance .awards img {
    width: auto;
    max-width: auto;
    height: auto;
  }

  .responsibility-compliance .awards {
    //width: 100%;
  }

  .responsibility-compliance {
    &.AU-010 {
      .awards {
        margin-bottom: 10px;
        max-width: 300px;
        .awards__image-container {
          height: auto;
          width: auto;
          min-height: auto;
          margin-bottom: 0;
        }
      }
    }
  }

  .responsibility-compliance h1 {
    .font-size(2.6) !important;
    margin: 30px 0 !important;
  }

  .responsibility-count-last {
    margin: 30px auto !important;
  }

  .responsibility-compliance .post-article {
    margin-left: 0 !important;
  }

  .responsibility-compliance .clearfix {
    margin-top: 0!important;
  }
}

