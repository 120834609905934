.Re-003--home {
  .card {
    .card-title {
      .font-size(2.6);
    line-height: 26px;
    }
  }
}
.card {
  background-color: transparent;
  margin-bottom: 20px;

  @media (max-width: @bpMD) {
    margin-bottom: 0;
  }

  .card-bg-img {
    background-size: cover;
    background-position: center;
    min-height: 460px;
    max-height: 460px;
    background-repeat: no-repeat;

    &.mini-cards{
      min-height: 223px;
      max-height: 223px;
    }
  }

  .card-meta {
    margin: 10px 0;

    .tag {
      color: @color-brand-red;
      .font-size(1.5)
    }

    .date {
      color: #BCBDBC;
      .font-size(1.5)
    }
  }

  .card-title {
    .font-size(3.6);
    font-weight: bold;
    line-height: 33px;
    &.card-min-ititle{
      .font-size(1.8);
      line-height: 24px;
    }
  }

  .card-text {
    + a {
      margin-bottom: -10px;
    }
  }

  &.premium {
    &:before {
      .font-size(1.4);
      background-color: #00798C;
      color: #fff;
      content: ' \2605 \0020  Premium';
      left: 10px;
      padding: 5px 10px;
      position: absolute;
      top: 10px;
      z-index: 1;
    }
  }

  &.suggested {
    &:before {
      .font-size(1.4);
      background-color: #8F191C;
      color: #fff;
      content: 'Suggested';
      left: 0;
      padding: 5px 10px;
      position: absolute;
      top: 10px;
      z-index: 1;
    }
  }

  .stretched-link {
    // font-size: 0;
    // height: 1px;
    // width: 1px;
    // line-height: 0;
    // padding: 0;
    // margin: -1px;
    // white-space: nowrap;
    // overflow: hidden;
    &.card-main-link{
      font-size: 18px;
      color: black;
    }
  }

  &.card-signup {
    .card-text {
      margin-bottom: 30px;

      + a {
        margin-bottom: 0;
      }
    }
  }
}

// Re-003 cusotm overrides
@media screen and (max-width: @bpMD - 1px) {
  .Re-003 {
    .col-md-3 {
      .card {
        flex-direction: row;

        .card-body,
        img {
          width: 50%;
        }

        img + .card-body {
          margin-left: 10px;
        }
      }
    }
  }
}
