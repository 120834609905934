.drop-down {
  background: linear-gradient(90deg, #404040 0, #000 100%);
  height: 0;
  display: none;
  transition: height 1s;

  .global-menu__item {}

  .block {
    > div {
      padding: 20px;
      background-color: rgba(216, 216, 216, 0.05);
    }
  }

  &.is-active {
    height: 500px;
    display: block;
  }
}
