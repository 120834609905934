.POS-013 {
  ul {
    list-style: none;

    li {
      margin-bottom: 10px;
      &::before {
        content: "›";
        color: #CF2F44;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }
}
