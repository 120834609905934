.HP-002 {
  .filter-block {
    background-color: #f8f8f8;
    margin-bottom: 60px;
    margin-top: 60px;
    padding: 20px 0 40px;
  }

  @media screen and (min-width: @bpMD) {
    .card-group {
      .card {
        border-left: 1px solid grey !important;

        &.active,
        &:first-of-type {
          border-left: 0 !important;
        }
      }
    }
  }

  h2 {

    @media screen and (min-width: @bpLG) {
      font-weight: bold;
      line-height: 54px;
      margin-top: 30px;
      margin-bottom: 40px;
      .font-size(4.6);
    }
  }

  p {
    max-width: 760px;
    margin: 10px auto;
  }


  .select {
    margin-bottom: 3px;
    select {
      background: -webkit-gradient(linear, left top, right top, color-stop(0, #CF2F44), to(#8F191C));
      background: linear-gradient(90deg, #CF2F44 0, #8F191C 100%);
      color: #fff;
      option {
        background-color: #747678;
        // &:selected {
        //     background: -webkit-linear-gradient(left, #CF2F44 0%, #8F191C 100%);
        //     background: -o-linear-gradient(left, #CF2F44 0%, #8F191C 100%);
        //     background: linear-gradient(to right, #CF2F44 0%, #8F191C 100%);
        // }
        // &:hover {
        //   background: -webkit-linear-gradient(left, #CF2F44 0%, #8F191C 100%);
        //   background: -o-linear-gradient(left, #CF2F44 0%, #8F191C 100%);
        //   background: linear-gradient(to right, #CF2F44 0%, #8F191C 100%);
        // }
      }
    }
    &:after {
      background: transparent;
    }
  }

  .card {
    padding: 50px 20px 10px;
    transition: all 0.2s;
    margin-bottom: 0;
    width: 100%;
    &:before {
      display: none;
      transition: all 0.2s;
    }

    svg {
      height: 24px;
      width: 24px;
      margin: 0 auto;
    }

    .svg-icon-link {
      fill: transparent;
    }

    .card-title {
      .font-size(1.8);
      line-height: 24px;
    }

    &.active{
      color: #fff;
      background-color: #cf2f44;
      background-image: linear-gradient(90deg,#cf2f44,#8f191c);
      z-index: 1;

      @media screen and (min-width: @bpMD) {
        transform: scale(1.1);
      }

      svg {
        fill: #fff;
      }

      &:before {
        display: block;
      }
    }
  }
  .card-group {
    .slick-dots {
      bottom: -35px;
    }
  }
}
