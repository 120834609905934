.article {
  position: relative;
  ~.SOL-004 {
    margin-top: 50px;
  }
  @media (min-width: @bpLG) {
    display: flex;
    overflow: hidden;

    img {
      position: absolute;
      z-index: 1;
    }

  }

  &:nth-of-type(even),
  &:nth-of-type(odd) {
    @media (min-width: @bpLG) {
      display: flex;
      justify-content: flex-end;

      img {
        left: 0;
      }
    }
  }

  + .article:nth-of-type(even) {
    @media (min-width: @bpLG) {
      justify-content: flex-start;

      img {
        left: auto;
        right: 0;
      }
    }
  }
  &.position-right {
    @media (min-width: @bpLG) {
      display: flex;
      justify-content: flex-end;

      img {
        left: 0;
      }
    }
  }

  &.position-left {
    @media (min-width: @bpLG) {
      justify-content: flex-start;

      img {
        left: auto;
        right: 0;
      }
    }
  }

  img {
    width: 100%;

    @media (min-width: @bpLG) {
      width: 75%;
      max-width: 660px;
      max-height: 550px;
    }
  }
}

.article-content {
  @media (min-width: @bpLG) {
    background: #fff;
    margin: 20px 0;
    display: flex;
    flex-flow: column;
    width: 50%;
    padding: 20px;
    z-index: 999;

    a {
      align-self: flex-start;

      &.btn {
        flex-wrap: wrap;

      }
    }
  }

  h2 {
    margin: 20px 0;
    @media screen and (min-width: @bpLG) {
      .font-size(4.6);
      .borderLeft();
      line-height: 54px;
      margin: 25px 0;
    }
    small {
      .font-size(1.4);
    }

    span {
      display: block;
      line-height: 1;
    }

    .dark & {
      border-image: linear-gradient(to bottom, #FFF, #FFF) 1 100%;
    }
  }

  > p {
    .font-size(1.6);
    margin-bottom: 30px;
  }

  .blockquote {
    span {
      &:before {
        content: "“";
      }

      &:after {
        content: "”"
      }
    }

    cite {
      .font-size(1.4);
      color: #747678;
      font-weight: 100;
      font-style: normal;
      margin-left: 10px;
    }
  }

    cite {
      .font-size(1.4);
      color: #747678;
      font-weight: 100;
      font-style: normal;
      margin-left: 10px;
    }


  .button-well {
    margin: 20px 0;

    .btn + a {
      margin-left: 10px;
    }
  }
}

.video-link {
  align-items: center;
  background: transparent;
  border: 0;
  display: flex;
  margin: 10px 0;

  &:focus {
    box-shadow: 0 0 0;
    outline: none;
  }

  &:before {
    background-image: url(../../assets/images/video-button.png);
    background-size: contain;
    content: '';
    display: inline-block;
    height: 46px;
    margin-right: 10px;
    width: 46px;
  }

  &:hover {
    text-decoration: none;
  }
  &.video-link-white {
    &:before {
      background-image: url(../../assets/images/video-button-white.png);
    }
  }
}

.img-link {
  align-items: center;
  background: transparent;
  border: 0;
  display: flex;
  margin: 10px 0;
  padding: 20px;
  .img-link-left{
    width: 52px;
    margin-right: 20px;
    @media screen and (min-width: @bpSM) {
      width: 72px;
    }
    @media screen and (min-width: @bpLG) {
      width: 52px;
    }
  }
  .img-content-right {
    color: #FFFFFF;
    font-family: Overpass;
    font-size: 26px;
    font-weight: bold;
    line-height: 40px;
    @media  (min-width: @bpLG) {
      font-size: 18px;
    }
    @media  (min-width: @bpXL) {
      font-size: 26px;
    }
  }
  &:hover {
    text-decoration: none;
  }
}

.download-link {
  svg {
    fill: @color-brand-red;
    height: 24px;
    width: 24px;
    margin-left: 10px;

    .dark & {
      fill: #fff;
    }
  }

  .dark & {
    color: #fff;
  }
}


#OD003, #OD003ALT, #OD003ALTb, {
  .component {
    &:nth-of-type(even),
    &:nth-of-type(odd) {
      .article {
        @media (min-width: @bpLG) {
          display: flex;
          justify-content: flex-end;

          img {
            left: 0;
          }
        }
      }
    }
  }

  .component:nth-of-type(even) {
    .article {
      @media (min-width: @bpLG) {
        justify-content: flex-start;

        img {
          left: auto;
          right: 0;
        }
      }
    }
  }
}

.OD003 {
  .component {
    &:nth-of-type(even),
    &:nth-of-type(odd) {
      .article {
        @media (min-width: @bpLG) {
          display: flex;
          justify-content: flex-end;

          img {
            left: 0;
          }
        }
      }
    }
  }

  .component:nth-of-type(even) {
    .article {
      @media (min-width: @bpLG) {
        justify-content: flex-start;

        img {
          left: auto;
          right: 0;
        }
      }
    }
  }
}



.PP003 {
  .component {
    &:nth-of-type(even),
    &:nth-of-type(odd) {
      .article {
        @media (min-width: @bpLG) {
          display: flex;
          justify-content: flex-end;

          img {
            left: 0;
          }
        }
      }
    }
  }

  .component:nth-of-type(odd) {
    .article {
      @media (min-width: @bpLG) {
        justify-content: flex-start;

        img {
          left: auto;
          right: 0;
        }
      }
    }
  }
}


