.POS-020 {
  .card-header {
    background-color: transparent;
    color: @color-brand-red;

    .btn {
      color: #000;
      font-weight: bold;
      text-transform: capitalize;

      span {
        text-decoration: underline;
      }

      &.stretched-link {
        .font-size(1.8);
        height: auto;
        line-height: 23px;
        width: auto;

        svg {
          height: 20px;
          width: 20px;
        }
      }
    }

    .collapsed {
      .active {
        display: inline-block;
      }

      .inactive {
        display: none;
      }
    }

    .active {
      display: none;
    }

    p {
      margin-bottom: 0;
    }
  }

  .card-body {
    padding: 0;
  }
}
