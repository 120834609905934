@keyframes shake {
 10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.SOL-005 {
  background-color: #eee;
  padding: 50px 0;

  h2 {
    .font-size(4.6);
    line-height: 54px;
    margin-bottom: 50px;
  }

  h3 {
    .font-size(2.6);
    line-height: 22px;
    margin-bottom: 20px;
  }

  p {
    .font-size(1.8);
    margin-bottom: 30px;
    > .btn {
      margin-left: 10px;
    }
  }

  a {
    &.arrow-link {
      display: inline-block;
      margin-top: 20px;
      margin-bottom: 50px;
    }

    &.btn {
      @media screen and (min-width: @bpMD) {
        margin-left: 10px;
      }
    }
  }

  .custom {
    .link-block-container {
        padding: 20px 10px 20px 20px;
        overflow: hidden;
        position: relative;
        display: inline-block;
        width: 100%;
        &:hover, &:focus {
          .link-block {
            transform: scale(1.1);
            &:before {
              display: block;
            }
          }
        }
      .link-block {
        position: relative;
        padding: 20px;
        min-height: 265px;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        transition: all .5s;
        //min-height: 255px;
        p {
          .font-size(2.6);
          line-height: 34px;
          margin-bottom: 40px;
        }
        .icon {
          height: 34px;
          width: 34px;
          margin-bottom: 30px;
        }
        &:before {
          content: "";
          display: none;
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: fade(#fff, 10%);
        }
        a {
          text-decoration: none;
          color: inherit;
          &:hover, &:focus {
            .arrow {
                animation: shake 2s cubic-bezier(.36,.07,.19,.67) both;
                transform: translate3d(0, 0, 0);
                backface-visibility: hidden;
                perspective: 1000px;
            }
          }
        }
      }

      @media screen and (min-width: @bpLG) {
        padding-top: 70px;
        padding-bottom: 20px;
      }
      }
      @media screen and (min-width: @bpLG) {
        .col-lg-6 {
          &:nth-of-type(odd) {
            margin-top: -50px;
          }
        }

      }

  }
}
