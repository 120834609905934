.AU-007 {

  .message-from {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    align-items: center;
    justify-content: center;
    text-align: center;
    h1 {
        font-weight: bold;
        font-size: 2.8rem;
    }
    .clearfix {
    }
    img {
      display: block;
      height: 150px;
      width: 150px;
      max-height: 250px;
      max-width: 250px;
      margin: 10px auto;
    }
  }

  @media (min-width: @bpMD) {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 2.8rem;

    .message-from {
      text-align: left;
      flex-direction: row;
      img {
        width: auto;
        height: auto;
        border: 3px solid @color-brand-grey;
        margin: 0;
        margin-right: 50px;
      }

      .blockquote {
        p {
          .font-size(1.6);
          margin-top: 10px;
        }
      }
    }

  }

}


#AU007{
  .innovation-title {
    margin: 70px 0;
    .font-size(3.6) !important;
  }
}
