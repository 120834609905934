.ReTD-005 {
  margin-bottom: 30px;

  .select {
    background-color: #f4f4f4;

    select {
      background-color: #f4f4f4;
      color: #000;
    }

    &::after {
      background-color: #f4f4f4;
      color: #000;
    }
  }
}
