.SOL-002 {
  a {
    background-color: #eee;
    background-image: url(../../assets/images/down-arrow-red.svg);
    background-position: right 10% center;
    background-size: 20px;
    background-repeat: no-repeat;
    border-bottom: 5px solid @color-brand-red;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,.13);
    color: @color-neutral-black;
    display: block;
    height: 80%;
    margin-bottom: 20px;
    padding: 20px;
    transition: .4s;

    &:hover {
      text-decoration: none;
      box-shadow: 0 6px 10px 0 rgba(0,0,0,.26);
    }

    span {
      display: block;
      width: 70%;
      font-size: 2rem;
      font-weight: bold;
    }
  }
}
