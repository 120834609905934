.ReCP-006 {
  .article-block {
    .article-item {
      display: none;

      img {
        width: 100%;
      }
    }
  }
  .article-block .card.premium .premium-text {
   display: none;
  }
}

.load-more-btn {
  width: 161px;
  cursor: pointer;
}


@media (max-width: 1200px) {

  .ReCP-001 h2{
    margin-top: 60px;
  }
  .ReCP-002 .hero-nav h3{
    font-size: 14px;
  }
  .ReCP-004 h2{
    font-size: 35px;
  }
  .ReCP-004{
    margin-bottom: 40px;
  }
  .ReCP-005 h4{
    font-size: 21px;
  }
  .ReCP-005 .popular .card .card-body p {
    font-size: 14px;
    line-height: 20px;
  }
  .feature-block-img{
    min-height: 300px;
  }
  .feature-block h4{
    font-size: 19px;
    line-height: 24px;
  }
  .article-block p{
    font-size: 14px;
    line-height: 22px
  }
  .ReCP-003 .card-body h3{
    font-size: 16px;
    margin-bottom: 10px;
  }
  .ReCP-003  .checkmark-wrap{
    font-size: 14px;
  }
}

.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show>.btn-outline-secondary.dropdown-toggle{
  background-color: transparent;
  border: none;

}
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-secondary.dropdown-toggle:focus{
  background-color: transparent;
  border: none;
  box-shadow: none;
}
.btn-outline-secondary:hover{
  background-color: transparent;
  border: none;
  box-shadow: none;
}
@media (max-width: 991px) {

 #RECP002 .ReCP-002 .hero-nav h3{
    font-size: 11px;
    line-height: 15px;
  }
  #RECP002 .ReCP-002 .hero-nav .meta .date,#RECP002  .ReCP-002 .hero-nav .meta .tag{
    font-size: 11px;
  }
  .RECP002 .ReCP-002 {
    margin-bottom: 20px;
  }

  .ReCP-004 h2 {
    font-size: 28px;
    line-height: 40px;
  }
  .ReCP-003 .checkmark-wrap{
    font-size: 12px;
  }
  .ReCP-005 .popular h4{
    margin-bottom: 15px;
    font-size: 17px;
    line-height: 20px;
  }
  .ReCP-005 .popular .meta .date,.ReCP-005 .popular  .meta .cat , #RECP006  .tag, #RECP006  .date  {
    font-size: 10px;
  }
  .ReCP-005 .popular .card .card-body p{
    font-size: 11px;
    line-height: 15px;
  }
  .article-block p{
    font-size: 12px;
    line-height: 24px;
  }
  .article-block .card.premium .premium-text {
    font-size: 11px;
    background-color: #00798C;
    color: #fff;
    left: 10px;
    padding: 2px 5px;
    display: none;
  }
}

@media (max-width: 991px) {

  .ReCP-001 h2{
    font-size: 36px;
    margin: 30px 0;
  }
}

@media (max-width: 767px) {
  .ReCP-003 .checkmark-wrap{
    font-size: 12px;
  }

  .article-block .card.premium .premium-text {
    .font-size(1.4);
    left: 10px;
    padding: 5px 10px;
  }

  #RECP002 .ReCP-002 .hero-nav .meta .date, #RECP002 .ReCP-002 .hero-nav .meta .tag{
    font-size: 14px;
  }
  #RECP002 .ReCP-002 .hero-nav h3{
    font-size: 18px;
    margin-top: 10px;
  }
  .nav-block{
    min-height:105px ;
  }
  .ReCP-005 .popular .meta .date, .ReCP-005 .popular .meta .cat, #RECP006 .tag, #RECP006 .date{
    font-size: 14px;
  }
  .article-block p{
    font-size: 18px;
  }
  .ReCP-002 .hero .content h3{
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 0;
  }
  .ReCP-002 .hero .content{
    padding: 20px 15px;
  }
  .ReCP-002 .hero-nav .slick-slide{
    padding-bottom: 15px;
  }
  #RECP002 .ReCP-002 .hero-nav h3{
    line-height: 22px;
  }
  .feature-block-img{
    min-height: 163px;
  }
}




