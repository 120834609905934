
// .HP-004 {

//   .component {
//     margin: 0 !important;

//     > img {
//       right: 0;
//       left: auto;
//       width: 75%;
//     }

//     &.article {
//       justify-content: flex-start;
//     }
//   }
// }


.HP-004 {
  .article-content{
    padding-left: 50px;
  }
  .article {
    position: relative;
    @media (min-width: @bpLG) {
      display: flex;
      overflow: hidden;

      img {
        position: absolute;
        z-index: 1;
      }

    }

    &:nth-of-type(even),
    &:nth-of-type(odd) {
      @media (min-width: @bpLG) {
        display: flex;
        justify-content: flex-start;

        img {
          right: 0;
          left: auto;
        }
      }
    }

    + .article:nth-of-type(even) {
      @media (min-width: @bpLG) {
        justify-content: flex-end;

        img {
          right: auto;
          left: 0;
        }
      }
    }

    img {
      width: 100%;

      @media (min-width: @bpLG) {
        width: 75%;
      }
      @media (min-width: @bpXL) {
        width: 65%;
      }
    }
  }
  .component {
    margin: 0 !important;

    > img {
      right: 0;
      left: auto;

      @media screen and (max-width: @bpMD) {
        width: 100%;
      }
    }

    &.article {
      justify-content: flex-start;
    }

    &.reverse {
      justify-content: flex-end;

      > img {
        right: auto;
        left: 0;
      }
    }

    .button-img {
      margin-bottom: 20px;

      img {
        position: relative;
        width: 30%;
      }
    }
  }
}
