.AU-002 {
  max-width: 824px;
  width: 100%;
  h2 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;

    @media(min-width: @bpMD) {
      margin-top: 30px;
      margin-bottom: 30px;
      font-size: 3.4rem;
    }
  }
  .innovation-title {
    margin: 0 0 40px 0;
    .font-size(3.6) !important;
  }

}
