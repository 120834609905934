.AU-004 {

  .message-from {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    align-items: center;
    justify-content: center;
    text-align: center;
    h1 {
        font-weight: bold;
        font-size: 2.8rem;
    }
    img {
      display: block;
      height: 150px;
      width: 150px;
      max-height: 250px;
      max-width: 250px;
      margin: 10px auto;
    }

    .cover {
      width: 250px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 250px;
      border-radius: 50%;
      margin-right: 60px;
    }
  }

  @media (min-width: @bpMD) {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 2.8rem;

    .message-from {
      text-align: left;
      flex-direction: row;
      img {
        width: auto;
        height: auto;
        border: 3px solid @color-brand-grey;
        margin: 0;
        margin-right: 50px;
      }

      .blockquote {
        p {
          .font-size(1.6);
          margin-top: 10px;
        }
      }
    }

  }

  h2 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold !important;
    font-size: 2.4rem;
    text-align: center;
  }

  .what-they-say-wrapper {
    position: relative;
    margin-bottom: 40px;
    img {
      display: none;
    }

    .blockquote {
      p {
        font-weight: bold;
      }
      cite {
        color: #747678;
        margin-left: 10px;
      }
    }
  }

  @media (min-width: @bpMD) {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 2.8rem;

    .what-they-say-wrapper {
      margin-left: 100px;
      max-width: 500px;
      img {
        display: block;
        width: 100px;
        height: 100px;
        position: absolute;
        left: -100px;
      }

      .blockquote {
          padding-left: 30px;
        p {
          .font-size(1.6);
          margin-top: 10px;
          span {
            &:before {
              content: '“';
              color: #8F191C;
              position: absolute;
              left: 0px;
              .font-size(4.2);
              top: -10px;
            }
          }
        }
        cite {
        .font-size(1.4);
        }
      }
    }

   .arrow-link {
        padding-left: 30px;
    }

  }

}
