.POS-007 {
  &.first-letter {
    p {
      &:first-child::first-letter {
        .font-size(5.4);
        color: #fff;
        background-color: #CF2F44;

        float: left;
        line-height: 84px;
        padding: 10px 30px;
        margin-top: 10px;
        margin-right: 10px;
        text-transform: uppercase;
      }
    }
  }
}

.POS-007ALT {
  p {
    font-size: 14px;
    line-height: 22px;
  }
}
