.ReCP-004 {

  @media screen and (max-width: @bpMD) {
    align-items: center;
  }

  h2 {
    .font-size(4.6);
    line-height: 54px;

    @media screen and (max-width: @bpMD) {
      .font-size(2.6);
      text-align: center;
      margin: 0 auto 40px;
      width: 230px;
      line-height: 33px;
    }
  }

  .search-wrap {

    @media screen and (max-width: @bpMD) {
      width: 65%;
    }
  }

  .filter-btn {
    margin-top: 15px;
    width: 100px;
    margin-left: 20px;
    display: none;
    cursor: pointer;
    font-weight: bold;

    i {
      color: @color-brand-red;
      margin-left: 10px;
    }

    @media screen and (max-width: @bpMD) {
      display: block;
    }
  }

  // .input-group {
  //   width: 70%;
  // }

  .form-control {
    .font-size(1.8);
    border-radius: 25px;
    height: auto;
    line-height: 24px;
    padding-left: 23px;
  }

  .btn {
    .font-size(1.8);
    border-radius: 25px;
    padding-right: 23px;

    .fa {
      color: @color-brand-red;
    }
  }
}

.close-modal-cust {

  position: absolute;
  right: 35px;
  top: 12px;
  font-size: 20px;
  z-index: 1000;
}

.modal-cust {
  min-height: 1100px;
}

.filter-modal {
  z-index: 10000;
  padding-top: 10px;
  background: white;

  .modal-content {
    border: 0;
  }

  .checkmark-wrap {
    width: 50%;
    @media(min-width: @bpMD) {
      width: 45%;
    }
  }

  .btn {
    width: 150px;
  }
}

.search-block-learning {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .search-wrap{
    position: relative;
    width: 85%;
  }
  input{
    border: 1px solid rgba(203,214,236,0.5) ;
    border-radius: 25px;
    background-color: white;
    font-size: 18px;
    padding: 0 55px 0 20px;
    height: 50px;
    width: 100%;
    outline: none;
    font-weight: 400;
    font-family: sans-serif;
  }
  span{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    border: none;
    button{
      height: 50px;
      padding: 0;
      border: none !important;
      border-radius: 0 !important;
    }
  }

}


@media screen and (max-width: @bpMD) {
  #HP002 .card-group>.card {
    flex: unset;
    margin-bottom: 0;
  }
}
@media screen and (max-width: @bpLG) {

  .Re-003 .col-lg-6 > .col-lg-6 {
    float: unset;
    padding: 0;
  }
  .HP-002 .card .card-title {
    font-size: 15px;
    font-size: 1.2rem;
    line-height: 18px;
  }
  .HP-002 .card {
    padding: 30px 20px 0px;

  }
}

@media screen and (min-width: @bpMD) {
  .AU-004 .what-they-say-wrapper:nth-of-type(even)
  {
    margin-left: auto!important;
    left: auto;
    right: 0;
  }
}

.HP-002 .card-group .card.active-1 {
  color: #fff;
  background-color: #cf2f44;
  background-image: -webkit-gradient(linear, left top, right top, from(#cf2f44), to(#8f191c));
  background-image: -o-linear-gradient(left, #cf2f44, #8f191c);
  background-image: linear-gradient(90deg, #cf2f44, #8f191c);
  z-index: 0;

}
.HP-002 .card-group .card.active-1 svg {
  fill: #fff;
}

#RECP002 a {
  color: #fff;
}
.ReCP-002 .hero .content {
  width:100%;
}

.ReCP-004 {
  .search-block-learning {
    .search-wrap {
      display: none !important;
    }
  }
}
