.PP-001 {
  max-width: 100%;

  @media screen and (max-width: @bpMD - 1px) {
    .row {
      overflow-x: auto;
      flex-wrap: nowrap !important;

      > .col-xs-4 {
        display: inline-block;
        float: none;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
      }
    }
  }

  .content-block {
    > div {
      border-bottom: 2px solid #BCBDBC;
      min-height: 130px;
      margin-bottom: 10px;
      transition: 0.5s;
    }
    img {
      margin-bottom: 5px;
    }

    a {
      .font-size(1.2);
      align-self: flex-end;
      color: #BCBDBC;
      text-decoration: none;
      transition: 0.5s;

      &:hover {
        color: #CF2F44;
      }
    }

    &.active,
    &:hover {
      > div {
        border-bottom-color: #CF2F44;
      }

      a {
        color: #CF2F44;
      }
    }
  }
}


.products-main {

  .col-12{
    overflow-y: auto;
    width: 100%
  }
  &.component {
    margin-bottom: 50px;
  }
  .img-fluid {
    width: 100px;
    height: 60px;
  }
  .product-menu {
  overflow-y: auto;
  display: inline-flex;

  }
  .active-product p{
    color: #8F191C !important;
    &::before{
      width: 80% !important;
    }
  }
  .product-components{
    width: 140px;
    position: relative;
    &:hover p{
      color: #8F191C !important;
      &::before{
        width: 80% !important;
      }
    }

    cursor: pointer;
    padding: 15px;
    p{
      .font-size(1.2);
      line-height: 18px;
      color: #BCBDBC;
      transition: 0.3s;
      margin-top: 25px;
      padding-top: 15px;
      text-indent: inherit;
      position: relative;
      &::before{
        position: absolute;
        content: '';
        width: 0;
        height: 2px;
        background: linear-gradient(308.7deg, #8F191C 0%, #CF2F44 100%);
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        transition: 0.3s;
      }
    }
  }
  .inner-line-product {
    width: 100%;
    height: 2px;
    position: absolute;
    background-color: #EAEBEA;
    top: 64%;
    transform: translateY(-50%)
  }
}

.products-for-main {
  .row{
    //this was when the navs updated the items
    //display: none ;
  }
  &.component{
    margin-top: 0;
  }
}
.active-product-block {
  display: flex !important;
}
.product-image {
  .card-bg-img {
    position: absolute;
    width: 60%;
    height: 540px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    right: 0;
  }
  .img-md {
    .card-bg-img {
      @media screen and (min-width: @bpXL) {
        height: 470px;
      }
    }
  }
}

.product-image {
  .article-content{
    width: 55%
  }
  .article{position: unset}

}

@media(max-width:992px){
  .product-image .card-bg-img{
    position: relative;
    width: 100%;
  }
  .products-for-main h1{
    .font-size(3.6);
    line-height: 38px;
  }
  .product-image .article-content{
    width: 100%;
  }
}

@media(max-width:767px){
 .product-image{
   .component{
     margin-bottom: 20px;
   }
 }
 .benefits {
  .PP-004 h2{
    .font-size(3.6)!important;
    margin-bottom: 30px!important;
  }
  .slick-dots{
    bottom: -10px;
  }
 }
 .products-main.component{
  margin-bottom: 20px;
 }

.slider-products {
  .image-hero {
    height: 420px !important;
    min-height: 420px !important;
  }
}
.slider-products.PP-005 .image-hero h2{
  .font-size(2.6)!important;
  line-height: 34px !important;
}
.slider-products.PP-005 .image-hero{
  position: relative;
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.46) 0%, rgba(0, 0, 0, 0) 99%);}
}
}

@media(max-width:576px){
  .product-image .card-bg-img{
    height: 190px;
  }
  .products-main .product-components{
    width: 100px;
    position: relative;
    cursor: pointer;
    padding: 8px;
  }
  .products-main .inner-line-product{
    top: 58.5%
  }
  .benefits .PP-004 h2{
    .font-size(2.6)!important;
    line-height: 34px !important
  }
 .slider-products{
  &.component {
    margin-bottom: 20px !important;
  }
 }
}
