.POS-012 {
  p {
    color: #CF2F44;
  }
  .card-premium {
    font-size: 1.4rem;
    background-color: #00798C;
    color: #fff;
    padding: 5px 10px;
    &:before {
      content: ' \2605 \0020';
    }
  }
}


.POS-012-ALT {
  h2 {
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to bottom, @color-brand-red, #8f191c) 1 100%;
    border-right: 0;
    padding-left: 20px;
    border-top: 0;
    border-bottom: 0;
    margin-bottom: 30px;
    margin-top: 30px;
    @media screen and (max-width: @bpSM - 1px) {
      line-height: 46px;
    }
  }
  p {
    font-weight: bold;
  }
  .card-premium {
    font-size: 1.4rem;
    background-color: #00798C;
    color: #fff;
    padding: 5px 10px;
    &:before {
      content: ' \2605 \0020';
    }
  }
  .meta {
    @media screen and (max-width: @bpSM - 1px) {
      display: grid;
    }
  }
}

