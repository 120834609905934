// #RECP003 {
//   position: sticky;
//   top: 0;
//   max-height: 100vh;
//   overflow-y: auto;
//   margin-bottom: 90px;

//   @media(max-width: @bpXL) {
//     max-height: calc(100vh - 110px);
//     top: 110px;
//   }
// }

.ReCP-003 {
  // margin-bottom: 0;

  .card-header {
    .font-size(1.8);
    border-bottom: 0;
    font-weight: bold;
    line-height: 23px;
    margin-bottom: 0;
    padding: 14px 10px;
  }
  .card-body {
    @media(max-width: @bpMD) {
      padding-bottom: 0;
    }
    h3 {
      .font-size(1.8);
      font-weight: bold;
      line-height: 23px;
      margin-bottom: 22px;
    }
  }

  .form-check {
    margin-bottom: 10px;

    .form-check-input {
      border-radius: 0;
    }

    .form-check-label {
      margin-left: 1rem;
      line-height: 21px;
    }
  }

}

.checkmark-wrap {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
  @media(min-width: @bpMD) {
    padding-left: 35px;
  }
}

.checkmark-wrap input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #000;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkmark-wrap input:checked ~ .checkmark:after {
  display: block;
}

.checkmark-wrap .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid @color-brand-red;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
