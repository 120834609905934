.POS-008 {
  .bullet {
    list-style: none;

    li {
      &::before {
        content: "\2022";
        color: #CF2F44;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }
}

.POS-008alt {
  h1 {
    font-weight: 600;
  }
  p {
    font-size: small;
    font-weight: 500;
  }
  .bullet {
    list-style: none;
    padding-left: 22px;
    li {
      font-size: 1.8rem;
      font-weight: 600;
      &::before {
        font-size: 4.2rem;
        content: "\2022";
        color: #CF2F44;
        font-weight: bold;
        display: inline-block;
        width: 20px;
        margin-left: -25px;
        position: relative;
        top: 9px;
      }
    }
  }
}
