.Re-002 {
  .link-block {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 350px;
    width: 100%;
    padding: 15px;

    @media (max-width: @bpMD) {
      height: 200px;
      margin-bottom: 20px;
    }

    a {
      color: #fff;
      text-decoration: none;
      align-items: center;
      width: 100%;
      font-size: 24px;
      line-height: 34px;
      @media (max-width: @bpMD) {
        font-size: 25px;
      }
    }
  }

  .Re-001 & {
    margin-top: -100px;

    @media (max-width: @bpMD) {
      margin-top: 50px;
    }
  }

  svg {
    width: 24px;
    min-width: 24px;
    height: 25px;
    fill: #8f191c;
  }
  .stretched-link::after{
    display: block;

  }
  .stretched-link {
    text-indent: inherit;
    position: inherit;
  }

}

@media (max-width: 1200px) {
  .Re-002 .link-block a{
    font-size: 18px;
    line-height: 24px;
  }
  .stretched-link::after{
    display: none;
  }

}


