.OD-003 {
  position: relative;

  @media (min-width: 768px) {
    display: flex;
    justify-content: flex-end;
    overflow: hidden;

    img {
      position: absolute;
      z-index: 1;
    }
  }

  img {
    width: 100%;

    @media (min-width: 768px) {
      width: 60%;
    }
  }


  .content {
    @media (min-width: 768px) {
      background: #fff;
      margin: 20px 0;
      display: flex;
      flex-flow: column;
      width: 50%;
      padding: 20px;
      z-index: 999;

      .btn {
        align-self: flex-start;
      }
    }

    h2 {
      .font-size(4.6);
      .borderLeft();
      line-height: 54px;
      margin: 50px 0;
    }

    p {
      .font-size(1.8);
      margin-bottom: 50px;
    }
  }

  &.right {
    img {
      left: 0;
    }
  }

  &.left {
    @media (min-width: 768px) {
      justify-content: flex-start;
      background-position: right;
      img {
        right: 0;
      }
    }
  }

  .blockquote {
    span {
      &:before {
        content: "“";
      }

      &:after {
        content: "”"
      }
    }

    cite {
      .font-size(1.4);
      color: #747678;
      font-weight: 100;
      font-style: normal;
      margin-left: 10px;
    }
  }
}


.support-innovations {
  .blockquote {
    > span {
      padding: 20px 0;
      display: block;
    }
  }

  padding-top: 150px;

  .button-link {
    padding: 16px 20px 14px 20px;
    border: 1px solid #CF2F44;
    cursor: pointer;
    transition: 0.3s;
    text-decoration: none;

    &:hover {
      background-color: #CF2F44;
      color: white;
    }
  }

  .component {
    &:first-child {
      .button-well {
        padding-left: 2rem;
      }
    }
  }

}

.support-innovations {
  .article-content {
    width: 55%;
  }

  .card-bg-img {
    width: 60%;
    height: 550px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
  }

  .component:first-child {
    margin-bottom: 40px;

    .support-title {
      margin-top: 0;
    }

    .card-bg-img {
      position: relative;
      background-size: contain;
      width: 45%;
    }
  }

  .component + .component {
    .article-content {
      padding-right: 70px;
    }

    .card-bg-img {
      left: auto;
      right: 0;
    }
  }

  .services-title {
    p {
      &:first-child {
        margin-bottom: 20px;
      }

      margin-bottom: 0;
      display: flex;
      align-items: flex-end;

      span:first-child {
        .font-size(4.6) !important;
        line-height: 30px;
        font-weight: bold;
        display: block;
      }

      span:last-child {
        .font-size(1.4) !important;
        line-height: 20px;
        margin-left: 10px;
        display: block;
      }
    }
  }
}

.services-title {
    p {
      &:first-child {
        margin-bottom: 20px;
      }

      margin-bottom: 0;
      display: flex;
      align-items: flex-end;

      span:first-child {
        .font-size(4.6) !important;
        line-height: 30px;
        font-weight: bold;
        display: block;
      }

      span:last-child {
        .font-size(1.4) !important;
        line-height: 20px;
        margin-left: 10px;
        display: block;
      }
    }
  }

.services-block {
  margin-bottom: 90px;

  h2 {
    text-align: center;
    .font-size(4.6);
    line-height: 54px;
    font-weight: bold;
    margin-bottom: 60px;
  }
}

.services-team-help .PP-014 {
  padding-top: 60px;
  padding-bottom: 120px;

  border-top: 5px solid #F4F4F4;
}

.support-title {
  .font-size(6.6) !important;
  line-height: 80px;
  font-style: normal;
  font-weight: 300;
  background: none !important;
  border: none !important;
  margin-bottom: 10px !important;
  font-family: 'Calluna', sans-serif;
}

.support-subtitle {
  .font-size(2.6) !important;
  line-height: 40px;
  font-weight: bold;
  padding-left: 2rem;
}

.support-text {
  .font-size(1.8) !important;
  line-height: 22px;
  margin-top: 33px;
  padding-left: 2rem;
}

.support-slider-block {
  h2 {
    margin-bottom: 50px !important;
  }

  p {
    margin-bottom: 30px !important
  }

  .button-well {
    margin: 0 !important
  }

  .item-block {
    &:nth-child(even) .container {
      justify-content: flex-start !important;
    }
  }
}

.support-comments {
  h3 {
    .font-size(4.6) !important;
    line-height: 34px;
    font-weight: bold;
    margin-bottom: 90px !important;
  }

  p {
    position: relative;
    max-width: 500px;
    width: 100%;

    span:before {
      content: '“';
      color: #8F191C;
      position: absolute;
      left: -15px !important;
      font-size: 42px;
      font-size: 4.2rem;
      top: 5px !important;
    }
  }
}

.support-download.container {
  padding-left: 100px !important;
  padding-right: 100px !important;
}


.assay-menu-main {
  h2 {
    .font-size(6.0) !important;

  }

  p {
    line-height: 30px;
  }
}

.assay-menu-components {
  background-color: #F8F8F8;
}

.assay-menu-components {
  .article-content {
    padding-left: 0 !important;
    // background-color: #F8F8F8;
  }

  .blockquote {
    p {
      padding: 50px 0 40px 0;
      .font-size(2.6) !important;
      line-height: 34px;
      font-weight: bold;
    }
  }

  .assay-menu-components-title {
    display: flex;
    align-items: center;

    p {
      color: #8F191C;

      span:first-child {
        .font-size(3.6);
        font-weight: bold;
      }

      span:last-child {
        .font-size(1.4)
      }
    }

    p + p {
      margin-left: 30px;
    }
  }

  #HP004 {
    min-height: 550px;
    display: flex;
    align-items: center;
    background: linear-gradient(to right, #F8F8F8 50%, rgba(0, 0, 0, 0));
  }

  position: relative;

  .article {
    position: unset;

  }

  .card-bg-img {
    width: 50%;
    height: 550px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    left: auto;
    top: 0;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: -10px;
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, #F8F8F8 7%, rgba(0, 0, 0, 0) 30%);

    }
  }
}

.product-image {
  .component {
    display: none;
  }

  .component:nth-child(odd) {
    .article {
      justify-content: flex-start !important;
    }
  }
}

.assay-menu-table {
  margin-top: 90px;
  .appendix {
    svg{
      margin-top: -5px;
    }
  }
  .PP-012 .assays p {
    line-height: 20px;
    transition: 0.3s;
  }

  .assay-block {
    margin-bottom: 20px;
    cursor: pointer;

    &:hover {
      p {
        color: #CF2F44;
      }
    }
  }

  .img-fluid {
    width: 100px;
    height: 60px;
  }


  .table-main {
    padding-bottom: 20px;
    p{
      .font-size(1.2) !important;
      line-height: 18px;
      font-weight: normal;
    }
  }
}
.assay-table-tr {
  display: flex;
  align-items: center;
  .text-left{
      width: 28%;
    p{
      margin-bottom: 0;
      .font-size(1.8)!important;
      line-height: 30px;
      width: 190px;
      font-weight: bold;
    }
  }
  .align-top{
    width: 12%;
    padding: 5px;
    text-align: center;
    p{
      .font-size(1.2) !important;
      line-height: 18px;
      font-weight: normal;
      text-align: center;
    }

  }

}
.assay-menu-table {
  .appendix{
    padding: 20px 30px !important;
  }
}
.assay-table-thead {
  padding: 20px 30px;
}

.assay-table-tbody{
  border-top: 2px solid rgba(0,0,0,0.1);
  .assay-table-tr{
    padding: 5px 30px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
  }
  .assay-table-td{
    display: flex;
      justify-content: center;
      text-align: center;
    &.text-left{
      text-align: left;
      justify-content: flex-start;

    }
      svg{
        width: 16px !important;
        height: 16px !important;
        justify-content: center!important;
      }


  }
}
.assay-menu-run{
  margin-top: 90px;
  position: relative;
  .article{
    position: unset;
  }
  .component{
    height: 550px;
    display: flex;
    align-items: center;
  }
  .card-bg-img {
    width: 60%;
    height: 550px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    left: auto;
    right: 0;
    top:0
  }
  .button-well {
    a{
      padding: 16px 20px 14px 20px;
      border: 1px solid #CF2F44;
      cursor: pointer;
      transition: 0.3s;
      text-decoration: none;
      &:hover {
        background-color: #CF2F44;
        color: white;
      }
    }
  }
}
.assay-table-block{
  display: none;
}
.active-assay{
  display: block;
}
.active-assay-link {
  p{
    color: #CF2F44 !important;
  }
}

@media (max-width: 1200px) {
  .support-innovations {
    padding-top: 60px;
  }

  .support-innovations .component {
    margin-bottom: 55px !important;
  }

  .support-download.container {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
// assay-menu media-start

  .assay-menu-components-title {
    p{
      line-height: 20px !important;
    }
  }
  // assay-menu media-end

}

@media (max-width: 991px) {
  .support-innovations .component + .component .article-content {
    padding-right: 0;
  }

  .services-team-help .PP-014 {
    padding-bottom: 60px;
  }

  .support-innovations .component:first-child .card-bg-img, .support-innovations .article-content {
    width: 100%;
  }

  .support-innovations .card-bg-img {
    position: relative;
    width: 100%;
    height: 420px;
  }

  .support-innovations .article img {
    height: 400px;
  }

  .support-title, .support-subtitle, .support-text, .support-innovations .component:first-child .button-well {
    padding-left: 0 !important;
  }

  .support-download .download-link__text {
    width: 80%;
  }


  // assay-menu media-start
  .assay-menu-components{
    .card-bg-img{
      position: relative;
      width: 100%;
      &:before{
        background: linear-gradient(to right, #F8F8F8 7%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 70%,  #F8F8F8 93%);
        left: 0;
      }
    }
    .assay-menu-components-title{
      margin-top: 25px;
    }
    .blockquote p{
      padding: 30px 0 20px 0 ;
    }
  }
  .assay-menu-table{
    margin-top: 50px;
  }
  .PP-012 .assays .assay-block{
    width:20% !important;
  }
  .assay-menu-table{
    .table-responsive-md{
      width: 100%;
      overflow-x: auto !important;
      .assay-table-block{
        width: 1000px;
      }
    }
  }

  .assay-menu-run {
    .card-bg-img{
      width: 100%;
      position: relative;
    }
    .component{
      height: auto;
    }
  }
  // assay-menu media-end

}

@media (max-width: 767px) {
  .support-innovations .card-bg-img {
    height: 300px;
  }

  .support-comments p span {
    padding-left: 15px;
  }

  .support-comments p span:before {
    top: 15px !important;
    left: 4px !important;
  }

  .services-team-help .PP-014 {
    padding-bottom: 30px;
  }

  .services-title p span:first-child {
    .font-size(2.4) !important;
  }

  .services-title p:first-child {
    margin-bottom: 0;
  }

  .support-innovations .article img {
    height: 176px;
  }

  .support-innovations {
    padding-top: 30px;
  }

  .support-title, .services-block h2 {
    .font-size(3.5) !important;
    margin-top: 20px !important;
    line-height: 30px !important;
  }

  .support-subtitle {
    .font-size(1.8) !important;
    line-height: 22px !important;
  }

  .support-text {
    .font-size(1.4) !important;
    line-height: 22px !important;
    margin-top: 20px !important;
    margin-bottom: 30px !important;
  }

  .article-title {
    .font-size(2.6) !important;
    line-height: 30px !important;
    margin-top: 20px !important;
    margin-bottom: 25px !important;
  }

  .article-quote {
    .font-size(1.8) !important;
    line-height: 22px !important;
  }

  .item-block {
    position: relative;
  }

  .item-block::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.7) 100%);

  }

  .item-block .container {
    z-index: 99;
  }

  .support-slider-block .slick-dots {
    bottom: -45px;
  }

  .support-comments h3 {
    margin-top: 50px !important;
    margin-bottom: 30px !important;
    .font-size(2.4) !important

  }

  .support-comments p {
    .font-size(1.4);
    line-height: 22px;
    display: flex;
    flex-direction: column;

  }

  .support-comments .slick-dots {
    bottom: -35px;
  }

  .support-comments p cite {
    margin-left: 0 !important;
    margin-top: 10px !important;
  }

  .support-download {
    margin-top: 90px;
  }

  .support-download.container {
    padding-top: 20px !important;
  }

  .support-download .download-link__text {
    .font-size(1.4) !important;
    line-height: 22px;
  }

  .support-comments p {
    max-width: 100%;
  }

  .support-comments p span {
    padding-left: 35px;
  }

  .services-block {
    margin-bottom: 50px;
  }
  // assay-menu media-start
  .assay-menu-main {
    .support-title{
      line-height: 55px !important;
      .font-size(3.5) !important;
    }
  }
  .assay-menu-components .blockquote p{
    .font-size(2.0) !important;
    line-height: 25px;
    padding-top: 10px !important;
  }
  .assay-menu-table{
    margin-top: 20px;
  }
  .assay-menu-components .card-bg-img, .assay-menu-run .card-bg-img{
    height: 350px;
  }
  // assay-menu media-end
  .support-comments p cite{
    padding-left: 35px !important;
  }
  .PP-012 .appendix p svg{
    margin-right: 10px;
  }
  .PP-012 .appendix p{
    line-height: 20px;
  }
  .active-assay-link {

      color: black !important;

  }
}


@media (max-width: 576px) {

  .support-innovations .card-bg-img {
    height: 188px;
  }

  .services-block h2 {
    margin-bottom: 30px;
  }
  // assay-menu media-start
  .assay-menu-main .support-title, .assay-menu-run h2{
    .font-size(2.6)!important;
    line-height: 38px !important;
  }
  .assay-menu-components .card-bg-img, .assay-menu-run .card-bg-img{
    height: 180px;
  }
  .assay-menu-components .assay-menu-components-title{
    flex-direction: column;
    align-items: flex-start;
    p{
      display: flex;
      span +span{
        margin-left: 10px;
      }
    }

  }
  .assay-menu-components .assay-menu-components-title p + p{
    margin-left: 0;
  }
  .assay-menu-components .blockquote p{
    .font-size(1.6)!important;
    line-height: 20px;
  }
  .assay-menu-table .PP-012 .assays{
    margin: 20px 0;
  }
  .assay-menu-run{
    margin-top: 0;
  }

  .assay-menu-run h2{
    margin: 20px 0 20px 0;
    line-height: 30px !important;
  }
  .assay-menu-run p{
    .font-size(1.4) !important;
    line-height: 22px;
  }
  // assay-menu media-end

}
