.PP-014 {
  .content {
    @media screen and (max-width: @bpMD - 1px) {
      position: relative;

      // &:after {
      //   position: absolute;
      //   bottom: 0;
      //   height: 100%;
      //   width: 100%;
      //   content: "";
      //   background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
      // }

      &.active {
        &:after {
          background: none;
        }
      }
    }

    ul {
      padding-left: 20px;
    }

    p {
      margin-bottom: 0;
    }

    p,
    ul {
      .font-size(1.4);
      line-height: 22.5px;
    }
  }

  .intro {
    color: #8F191C;
    font-weight: bold;

    p {
      .font-size(1.8);
      line-height: 30px;
    }
  }

  .read-more {
    cursor: pointer;

    svg {
      fill: @color-brand-red;
      height: 24px;
      width: 24px;
    }

    &.active {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}
