.slideSlick {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 24px #0000001A;
    border-radius: 8px;
    padding: 14px;
    margin-bottom: 25px;
    padding-bottom: 5px;
    min-height: 101px;
    display: flex;
    justify-content: center;
    flex-direction: column;

  }