.ReCP-002 {

  .mobile-img {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -1;
  }

  .mobile-img-hide {
    @media screen and (max-width: @bpMD) {
      background: none !important;
    }
  }

  .hero {
    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 60%;
      max-width: 100%;
      padding-left: 20px;
      padding-bottom: 50px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 400px;

      .tag {
        .font-size(1.4);
        line-height: 18px;
        color: #fff;
        font-weight: normal;
        margin-bottom: 15px;
      }

      h3 {
        .font-size(3.6);
        line-height: 44px;
        margin-bottom: 20px;
      }

      p {
        margin-bottom: 0;
        max-width: 600px;
      }
    }
  }

  .hero-nav {
    padding: 30px 0 0;

    .meta {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;

      .date,
      .tag {
        .font-size(1.4);
        line-height: 18px;
      }

      .tag {
        color: #8F191C;
      }

      .date {
        color: #BCBDBC
      }
    }


    h3 {
      .font-size(1.8);
      line-height: 24px;
      font-weight: bold;
    }

    .nav-block {
      padding: 10px;
      border: 1px solid grey;
      // border-top: 3px solid #CF2F44;
      border-image: linear-gradient(to bottom, #CF2F44, #8f191c) 30 30 round;;
      border-style: solid;
      border-width: 5px;
      border-bottom:0;
      border-left:0;
      border-right:0;
      box-shadow: 0 3px 6px rgba(0, 0, 0, .1);

      @media screen and (min-width: @bpMD) {
        height: 100%;
      }

      &.premium {
        border-image: linear-gradient(to bottom, #00798C, #00798C) 30 30 round;
      }
    }

    .slick-slide {
      cursor: pointer;
      width: 25% !important;
      padding-bottom: 20px;

      @media screen and (max-width: @bpMD) {
        width: 100% !important;
      }

      @media screen and (min-width: @bpMD) {
        float: none;
        height: auto;
      }

    }

    .slick-track {
      width: 100% !important;
      transform: none !important;

      @media screen and (min-width: @bpMD) {
        display: flex;
      }
    }
  }
}

.hero-nav {
  .slick-current {
    .nav-block {
      box-shadow: 7px 11px 7px rgba(0, 0, 0, 0.1);

    }
      //border-top-color: #00798C;



  }
}
