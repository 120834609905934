.PP-012 {
  h2 {
    .font-size(2.6);
    font-weight: bold;
    line-height: 33px;

    @media screen and (min-width: @bpMD) {
      .font-size(4.6);
      line-height: 54px;
    }
  }

  .assays {
    margin-top: 50px;
    margin-bottom: 30px;

    .assay-block {
      width: 16.666%;

      @media screen and (min-width: @bpMD) {
        width: 12.5%;
      }

      &.active {
        color: @color-brand-red;
      }
    }

    svg {
      fill: @color-brand-red;
      height: 36px;
      margin-bottom: 10px;
      width: 100%;
    }

    p {
      .font-size(1.4);
    }
  }

  .assay-table {
    svg {
      align-self: flex-start;
      display: block;
      height: 48px;
      text-align: left;
      width: 48px;
    }

    .table {
      th {
        border-top: 0;
      }

      td {
        svg {
          height: 16px;
          width: 16px;
          margin: 0 auto;
        }
      }
    }
  }

  .appendix {
    background-color: #f8f8f8;

    p {
      .font-size(1.2);
      margin-right: 1rem;

      svg {
        display: inline-block;
        height: 16px;
        width: 16px;
      }
    }
  }

  .fade {
    opacity: 0.3;
  }

  // Select box__icon
  .select-box {
    .font-size(1.6);
    position: relative;
    display: block;
    width: 100%;
    margin: 0 auto;
    color: #fff;

    &__current {
      position: relative;
      box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);
      cursor: pointer;
      outline: none;

      &:focus {
        & + .select-box__list {
          opacity: 1;
          animation-name: none;

          .select-box__option {
            cursor: pointer;
          }
        }

        .select-box__icon {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }

    &__icon {
      fill: #fff;
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      width: 20px;
      transition: 0.2s ease;
    }

    &__value {
      display: flex;
    }

    &__input {
      display: none;

      &:checked + .select-box__input-text {
        display: block;
      }
    }

    &__input-text {
      display: none;
      width: 100%;
      margin: 0;
      padding: 15px;
      background-color: #cf2f44;
      background-image: linear-gradient(90deg,#cf2f44,#8f191c);
    }

    &__list {
      background-color: #cf2f44;
      background-image: linear-gradient(90deg,#cf2f44,#8f191c);
      position: absolute;
      width: 100%;
      padding: 0;
      list-style: none;
      opacity: 0;
      z-index: 999;
      animation-name: HideList;
      animation-duration: 0.5s;
      animation-delay: 0.5s;
      animation-fill-mode: forwards;
      animation-timing-function: step-start;
      box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);
    }

    &__option {
      display: block;
      padding: 15px;
      background-color: #cf2f44;
      background-image: linear-gradient(90deg,#cf2f44,#8f191c);

      &:hover,
      &:focus {
        color: #fff;
        background-color: #cf2f44;
      }
    }
  }
}
