@content-text-box-color: #00798C;
@content-text-box-primary-color: #CF2F44;
@content-text-box-secondary-color: #8F191C;

.AU-008 {
  text-align: center;

  @media(min-width: @bpMD) {
    text-align: left;
  }

  &.content-text-box--color {
    color: @content-text-box-color;
  }
  &.content-text-box--color-primary {
    color: @content-text-box-primary-color;
  }
  &.content-text-box--color-secondary {
    color: @content-text-box-secondary-color;
  }

  .content-text-box {
    padding: 20px 5px;
  }


  .content-text-box__title {
    font-size: 2.4rem;
    font-weight: bold;
    @media(min-width: @bpMD) {
      font-size: 2.8rem;
      margin-bottom: 15px;
    }
  }

  .content-text-box__main-content {
    color: #000;
  }

}

