.P-contact-us {
  padding-top: 150px;
  margin-bottom: 150px;
    .select-box__list{
      li{
        margin-left: 0 !important;
        padding: 5px !important;
        label{
          padding: 5px 15px;
        }
      }
    }
  .P-contact-us-title {
    h1 {
      .font-size(6.6);
      line-height: 80px;
      font-family: 'Calluna', sans-serif;
    }
  }

  .P-contact-us-menu {
    margin-top: 50px;
    margin-bottom: 60px;
    ul{
      width: 90%;
      margin: 0;
      padding: 0;
      border-bottom: 2px solid #EAEBEA ;
      li{
        list-style: none;
        padding: 15px 0;
        .font-size(1.8);
        line-height: 18px;
        font-weight: bold;
        cursor: pointer;
        position: relative;
        @media screen and(min-width: 768px) {
          &:before{
            position: absolute;
            content: '';
            width: 0;
            bottom: -2px;
            left: 50%;
            transform: translateX(-50%);
            transition: 0.3s;
            height: 4px;
            background: linear-gradient(308.7deg, #8F191C 0%, #CF2F44 100%);
          }
          &:hover:before, &.active-contact:before{
            width: 100% !important;
          }
        }
      }
      li+li{
        margin-left: 30px;
      }
    }
  }
  .P-contact-us-box{
    >p{
      .font-size(1.8);
      line-height: 28px;
      color: #BCBDBC;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 50px;
    }
    .P-contact-us-box-title {
      h3{
        .font-size(2.6);
        line-height: 34px;
        font-weight: bold;
        margin-bottom: 35px;
      }

    }
    .P-contact-phone-block{
      >span{
        .font-size(1.40);
        color: #BCBDBC;
        font-weight: bold;
        line-height: 22px;
        padding-left: 30px;
        margin-bottom: 30px;
        display: block;
      }
    }
    .P-contact-phone{
      .font-size(1.8);
      line-height: 28px;
      font-weight: bold;
      position: relative;
      padding-left: 30px;
      text-decoration: none;
      color: black;
      display: block;
    }
    .P-support-info {
     p{
       .font-size(1.8);
       font-weight: bold;
       margin-bottom: 0;
       line-height: 24px;
     }
      svg{
        position: absolute;
        width: 20px ;
        height: 20px ;
        fill:#8F191C ;
        left: 0;
        top: 50%;
        transform: translateY(-50%)
      }

    .P-contact-email{
      .font-size(1.4);
      line-height: 22px;
      font-weight: bold;
      position: relative;
      padding-left: 30px;
      text-decoration: none;
      color: black;
      display: block;
      span{
        line-height: 10px;
        display: block;
      }
    }
    }
    .P-support-info +.P-support-info{
      margin-top: 70px;
    }
  }
}
.P-contact-us-js{
  display: none;
}
.active-contact-block{
  display: block;
}

.P-contact-form-bg{
  background: linear-gradient(308.7deg, #8F191C 0%, #CF2F44 100%);
  padding: 17px 30px;
  >p{
    color: white;
    .font-size(2.6);
    font-weight: bold;
    margin-bottom: 35px;
  }
  .P-contact-us-form{
    background-color: white;
    width: 100%;
    padding: 30px;
    box-shadow: inset 0 0 5px 0 rgba(203,214,236,0.5), 0 2px 14px 0 rgba(0,0,0,0.1);
    margin-bottom: -50px;
    border-radius: 0;
   h3{
     .font-size(1.8);
     color: #BCBDBC;
     max-width: 325px;
     width: 100%;
     font-weight: bold;
     margin-bottom: 40px;
   }
   p{
    .font-size(1.8);
    color: black;
    max-width: 325px;
    width: 100%;
    font-weight: bold;
    margin-bottom: 30px;
   }
  }
  .message-box__label {
    margin-top:45px;
  }
  .form-group {
    p{
      margin-bottom: 0;
      font-weight: normal;
    }
    cursor: pointer;
    position: relative;
    padding-left: 20px;
    input{
      display: none;
      &:checked + span::before{
        display: block;
      }
    }
    label{
      display: block;
      cursor: pointer;
      span{
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        border: 1px solid black;
        &::before{
          position: absolute;
          content: '';
          border: 1px solid black;
          border-left: none;
          border-bottom: none;
          width: 10px;
          height: 6px;
          transform: rotate(130deg);
          left: 3px;
          top: 2px;
          display: none;
        }
      }
    }
  }
}

form {
.form-check  {
  margin-bottom: 60px;
  cursor: pointer;
    position: relative;
    padding-left: 20px;
    input{
      display: none;
      &:checked + span::before{
        display: block;
      }
    }
    p{
      .font-size(1.4) !important;
      margin-bottom: 0 !important;

    }
    label{
      display: block;
      cursor: pointer;
      span{
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        border: 1px solid black;
        &::before{
          position: absolute;
          content: '';
          border: 1px solid black;
          border-left: none;
          border-bottom: none;
          width: 10px;
          height: 6px;
          transform: rotate(130deg);
          left: 3px;
          top: 2px;
          display: none;
        }
      }
    }
}
}
.send-form {
  button{
    width: 110px;
  }
}

.P-contact-phone, .P-contact-email{
  span:last-child{
    display: none !important;
  }
}

.login-modal, .signup {
  //background: linear-gradient(308.7deg, #8F191C 0%, #CF2F44 100%) !important;
  .form-icon {
    width: 15px;
    height: 15px;
  }
  .modal-dialog{
    max-width: none !important;
  }
  .rounded{
    background-color: transparent !important;
    padding: 0;
  }
  .login-form, .signup-form{
    background-color: white;
    margin-bottom: 20px;
    position: relative;
    padding: 60px 30px;
    max-width: 450px;
    width: 100%;
    &::before{
      content: '';
      position: absolute;
      width: 80%;
      background-color: rgba(255,255,255,0.2);
      height: 50px;
      bottom: -25px;
      left: 50%;
      transform: translateX(-50%)
    }
  }
  .group input{
    padding-left: 35px;
  }
  .group label{
    left: 35px
  }
  .fogotten {
    margin-top: 10px;
    text-align: right;
     a {
    .font-size(1.4);
    color: #BCBDBC;
    text-decoration: none;
    text-align: right;
  }
  }

  .group{
    margin-bottom: 0;
  }
  .close{
    width: 24px;
    height: 24px;
    right: 60px !important;
    top: 60px;
    z-index: 999
  }
  .group + .group{
    margin-top: 20px;
  }
  .modal-footer {
    margin-top: 20px;
    button{
      background-color: transparent;
      border: 1px solid #BCBDBC;
      color:#BCBDBC;
      width: 110px;
    }
  }
  .form-check {
    margin-bottom: 0;
    p{
      color: black;
      .font-size(1.4);
      line-height: 10px;
    }
  }
}

.signup {
   .form-check {
    margin-bottom: 0;
    p{
      color: black;
      .font-size(1.4);
      line-height: 20px;
    }
  }
}

@media (max-width: 1200px){
  .P-contact-form-bg .form-group p{
    .font-size(1.4);

  }
  .form-check p{
    .font-size(1.3)!important;
  }
  .form-check{
    margin-bottom: 35px;
  }
}


@media (max-width: 991px){
  .P-contact-us-form{
    margin-top: 60px;
  }
  .P-contact-form-bg{
    background: none;
    padding: 30px 20px;
    -webkit-box-shadow: inset 0 0 5px 0 rgba(203, 214, 236, 0.5), 0 2px 14px 0 rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 5px 0 rgba(203, 214, 236, 0.5), 0 2px 14px 0 rgba(0, 0, 0, 0.1);
    border-radius: 0;
  }
  .P-contact-form-bg .P-contact-us-form {
    box-shadow: none ;
    padding: 0 ;
    margin-bottom: 0;
    margin-top: 30px;
  }
  .P-contact-form-bg > p{
    color: black;
    margin-bottom: 0;
  }
  .login-form{
    margin:  0 auto;
  }
  .login-modal, .signup h1{
    text-align: center;
    margin-bottom: 45px;
  }
}


@media (max-width: 767px) {
  .P-contact-us{
    padding-top: 60px;
  }
  .P-contact-us .P-contact-us-menu{
    margin: 30px 0 40px 0;
  }
  .P-contact-us-desktop{
    display: none;
  }
  .P-contact-us .P-contact-us-menu ul{
    width: 100%;
  }
  .active-contact {
    label{
      color: black;
    }
  }

  .signup {
   .form-check {
    margin-bottom: 0;
    p{
      color: black;
      .font-size(1.4);
      line-height: 20px;
    }
  }
  .signup-form {
    margin: auto;
  }
}

}

@media (max-width: 576px) {
  .P-contact-info{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .P-contact-phone, .P-contact-email{
    svg{
      left: 15px!important;
    }
    width: 120px;
    height: 46px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border: 1px solid #CF2F44;
    span{
      display: none !important;
    }
    span:last-child{
      display: block !important;
      color: #CF2F44;
      line-height: 1;
      margin-top: 5px;
    }

  }
  .P-contact-us .P-contact-us-box .P-contact-phone-block > span{
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 10px;
    width: 115px;
    .font-size(1.2)
  }
  .P-contact-us .P-contact-us-box .P-contact-us-box-title h3{
    .font-size(1.8);
    line-height: 24px;
    margin-bottom: 14px;
  }
  .P-contact-us .P-contact-us-box .P-support-info + .P-support-info{
    margin-top: 40px;
  }
  .P-contact-us .P-contact-us-title h1{
    .font-size(3.6);
    line-height: 40px;
  }
  .P-contact-us{
    padding-top: 30px;
    margin-bottom: 60px;
  }
  .P-contact-us .P-contact-us-box > p{
    margin-bottom: 20px;
  }
  .P-contact-form-bg > p {
    .font-size(1.8);
  }
  .P-contact-form-bg .P-contact-us-form h3{
    .font-size(1.4);
    margin-bottom: 0;
  }
  .P-contact-form-bg .P-contact-us-form p{
    margin: 20px 0;
  }
  .form-row{
    flex-direction: column;
     p{
       margin: 0 !important;
     }
  }
  .group label{
    font-size: 14px;
    margin-bottom: 0;
  }
  .form-icon{
    margin-top: -8px;
  }
  .form-check{
    p{
      line-height: 18px;
    }
  }
  .login-modal h1{
   .font-size(3.6) !important;
  }
  .login-modal, .signup .close {

    right: 10px !important;
    top: 20px;
  }
  .login-modal, .signup .modal-footer{
    flex-direction: column;
    align-items: flex-start !important;
  }
  .login-modal, .signup .form-check{
    margin-bottom: 30px;
  }
  .login-modal, .signup .login-form{
    padding: 30px 15px;
  }
  .login-modal, .signup .modal-footer button{
    width: 100%;
  }
}

.form-icon.icon-facility {
 background-image: url(../../assets/images/user/facility.png);
}
.form-icon.icon-tel {
 background-image: url(../../assets/images/user/tel.png);
}
.form-control {
 font-size: 18px;
}

#RE033 {
height: 100%;
  .order-wrapper{
    +.text-center {
      .btn {
        //margin-bottom: 20px;
        @media screen and (min-width: 1200px) {
          //margin-top: 40px;
        }
      }
    }
  }
}

#HP032 {
  height: 100%;
  .box-padding {
    height: 100%;
  }
}
.tab-wrapper-primary {
  height: 100%;


  .nav {
    border-bottom: 2px solid #EAEBEA;
    white-space: nowrap;
    overflow-y: hidden;
    flex-wrap: nowrap;
    li {

      a {
        border: 0 !important;
        position: relative;
        padding-left: 5px;
        padding-right: 5px;
        margin-right: 5px;
        font-size: 1.8rem;
        margin-right: 20px;
        color: #212529;
        font-weight: bold;
        &:before {
          position: absolute;
          content: '';
          width: 0;
          bottom: 0px;
          left: 50%;
          transform: translateX(-50%);
          transition: 0.3s;
          height: 4px;
          background: linear-gradient(308.7deg, #8F191C 0%, #CF2F44 100%);
        }
        &:hover {
          &:before {
            width: 100%;
            background: linear-gradient(308.7deg, #8F191C 0%, #CF2F44 100%);
          }
        }
        &.active {
          color: #212529;
          &:before {
          background: linear-gradient(308.7deg, #8F191C 0%, #CF2F44 100%);
          width: 100%;
          }
        }
      }
      &:last-child {
        a {
          margin-right: 0;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    padding: 15px;
    border: 1px solid #dee2e6;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    .nav {

      li {

        a {
          margin-right: 40px;
          &:hover {
            &:before {

            }
          }
          &.active {
            &:before {

            }
          }
        }
      }
    }
  }

}

.list-group {
  .list-group-item {
    background-color: transparent;
    padding-left: 0;
    a {
      font-weight: bold;
      font-size: 80%;
    }
  }
}

.dot {
  height: 8px;
  width: 8px;
  align-self: center;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-right: 15px;
}

.order__img {
  max-width: 250px;
  margin: auto;
  margin-right: 10px;
  @media screen and (min-width: 768px) {
    max-width: 140px;
  }
}

.order-options {
  height: 100%;
  justify-content: center;
  .list-group {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}

.order-wrapper {
  .list-group {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}

.order-wrapper {
  @media screen and (min-width: 768px) {
    padding: 1.5rem;
  }
}

.order-wrapper {
  .order-item {
    &.card {
      padding: 1.5rem;
      @media screen and (min-width: 768px) {
        background-color: #fff;
        margin-bottom: 10px;
        margin-top: 10px;
      }
      @media screen and (max-width: 767px) {
        border: 0;
        padding: 0;
      }
    }
  }
  &.dispatched {
    .dot {
      background-color: #76A95A;
    }
    .order-date, .order-date-short, .order-status-short, .order-status-full, .order-date-full {
      color: #76A95A;
    }
  }
  &.delivered {
    .dot {
      background-color: #BCBDBC;
    }
    .order-date, .order-date-short, .order-status-short, .order-status-full, .order-date-full {
      color: #BCBDBC;
    }
  }
}

.box-padding {
  padding: 10px;
  @media screen and (min-width: 768px) {
    padding: 15px;
  }
}
.box-padding-lg {
  padding: 15px;
  @media screen and (min-width: 768px) {
    padding: 30px;
  }
}

.background-primary-gradient {
  background-color: #cf2f44;
  background-image: linear-gradient(90deg, #CF2F44, #8F191C);
}

.order-wrapper {
  .order-item {
    .left-container-mx-w-40pc {
      max-width: 35%;
    }
    .left-container-mx-w-40pc {
      max-width: 35%;
    }
    .right-container-mn-w-60pc {
      min-width: 60%;
      @media screen and (max-width: 767px) {
        justify-content: center;
        align-items: center;
        margin-right: auto;
      }
    }
    .right-container-mn-w-5pc {
      min-width: 5%;
      justify-content: center;
      align-items: center;
      color: #CF2F44;
      @media screen and (min-width: 768px) {
        display: none !important;
      }
    }
    .order-num, .order-desc, .order-date-full {
      display: none;
    }
    @media screen and (min-width: 768px) {
      .order-status-short, .order-date-short {
        display: none;
      }
      .order-num, .order-desc, .order-date-full {
        display: block;
      }
    }
  }
}

.box-padding {

  .card {
    &:last-child {
      margin-bottom: 0 !important;
      color: red;
    }

    @media screen and (min-width: 479px) and (max-width: 991px) {
      &:first-of-type {
        margin-right:2%;
        float: left;
      }
    }
  }
  .card {
    @media screen and (min-width: 479px) and (max-width: 991px) {
      width: 49%;
      margin-bottom: 0 !important;
      padding-bottom: 20px;
    }
  }
}

.coming-soon {
   background-size: 150% 300%;
   background-position: center;
   .coming-soon-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      ul {
        margin: 10px 0;
        @media screen and (min-width: 768px) {
          max-width: 45%;

        }
      }
      .coming-soon__right {
        margin: auto;
        img {
          @media screen and (min-width: 768px) {
            max-width: 300px;
          }
        }
      }
   }
}


.list-bullet-img {
  padding-right: 8px;
  position: relative;
  top: -5px;
}

.secondary-color {
  color: #8F191C;
}

