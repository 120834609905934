@charset "UTF-8";
@color-body-text : #131313 !default;
@color-body-bg : #fff !default;
@color-links : #444 !default;
@color-links-hover : #808080!default;
@color-links-active : #131313 !default;
@color-links-visited : #131313 !default;
@color-btn-primary : #fff !default;
@color-btn-primary-bg : #131313 !default;
@color-btn-primary-bg-hover : #808080 !default;
@color-btn-primary-border : #131313 !default;
@color-btn-secondary : #131313 !default;
@color-btn-secondary-bg : #fff !default;
@color-btn-secondary-bg-hover : #eee !default;
@color-btn-secondary-border : #131313 !default;
@color-btn-disabled : #808080 !default;
@color-btn-disabled-bg : #eee !default;
@color-form : #131313 !default;
@color-form-bg : #fff !default;
@color-form-border : #444 !default;
@color-form-border-focus : #131313 !default;
@color-form-border-error : #b12a0b !default;
@color-form-label : #131313 !default;
.borderLeft {
  border-image: linear-gradient(to bottom, #CF2F44, #8f191c) 1 100%;
  border-style: solid;
  border-width: 3px;
  border-right: 0;
  padding-left: 2rem;
}
html {
  font-size: 62.5%;
}
body {
  font-size: 16px;
  font-size: 1.6rem;
  font-family: 'Overpass', sans-serif;
}
p {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 30px;
}
h2,
.h2 {
  font-size: 36px;
  font-size: 3.6rem;
  line-height: 54px;
}
h4,
.h4 {
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 33px;
}
h5,
.h5 {
  font-size: 25px;
  font-size: 2.5rem;
}
.lead {
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 33px;
}
.post-tag {
  font-size: 14px;
  font-size: 1.4rem;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  line-height: 18px;
  padding: 8px 7px;
}
a {
  font-size: 16px;
  font-size: 1.6rem;
  color: #CF2F44;
  font-family: 'Overpass', sans-serif;
  line-height: 20px;
}
a:hover {
  color: #a52636;
  text-decoration: none;
}
a.link-arrow {
  color: #000;
  vertical-align: middle;
}
a.link-arrow:after {
  content: '';
  background-image: url(../../assets/images/right-arrow-red.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 24px;
  line-height: 20px;
  margin-left: 5px;
}
a.link-arrow-down svg {
  fill: #CF2F44;
  width: 24px;
  height: 24px;
}
button.arrow-link {
  background-color: transparent;
  border: 0;
}
h2.component-title {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
}
@media (min-width: 768px) {
  h2.component-title {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 2.4rem;
  }
}
.h3-component-title {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .h3-component-title {
    margin-bottom: 40px;
  }
}
.h4-component-title.with-arrow {
  color: inherit;
}
.h4-component-title.with-arrow:hover,
.h4-component-title.with-arrow:focus,
.h4-component-title.with-arrow:active {
  color: #a52636;
}
.icon-right-title {
  margin-left: 5px;
  vertical-align: middle;
}
.btn,
button {
  cursor: pointer;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
  border: 1px solid #CF2F44 !important;
  background-color: #CF2F44 !important;
}
.btn {
  border-radius: 0;
  font-size: 14px;
  font-size: 1.4rem;
  background: transparent;
  line-height: 22px;
  padding: 10px 20px;
}
.btn.btn-primary {
  border: 1px solid #CF2F44;
  color: #CF2F44;
}
.btn.btn-primary:hover {
  background: #CF2F44;
  color: #fff;
}
.btn.btn-secondary {
  border: 1px solid #000;
  color: #000;
}
.btn.btn-secondary:hover {
  color: #fff;
}
.arrow-link {
  background-image: url(../../assets/images/right-arrow-red.svg);
  color: #000;
  background-position: right center;
  background-size: 20px;
  background-repeat: no-repeat;
  padding-right: 30px;
}
.dark-bg .arrow-link,
.dark .arrow-link,
.arrow-link.dark {
  background-image: url(../../assets/images/right-arrow-white.svg);
  color: #FFF;
}
.dark-bg .btn-primary {
  color: #FFF;
  border-color: #FFF;
}
.dark-bg .btn-primary:hover {
  background: transparent;
}
.btn-min-width {
  min-width: 160px;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.a-reset {
  text-decoration: none;
  color: inherit;
}
.a-reset:hover,
.a-reset:focus,
.a-reset:active {
  color: inherit;
  text-decoration: none;
}
.dark .arrow,
.dark .icon {
  fill: #fff;
}
.dark-bg,
.dark {
  color: #fff;
}
.arrow {
  fill: #CF2F44;
  width: 24px;
  height: 14px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.slick-slider .slick-dots li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #bcbdbc;
}
.slick-slider .slick-dots li.slick-active {
  background-color: #a52636;
}
@media (min-width: 991px) {
  .col-lg-3--fixed {
    position: fixed;
  }
  .col-lg-3--fixed + .col-lg-9 {
    margin-left: 25%;
  }
}
.meta {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.meta .date,
.meta .tag,
.meta .cat {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 18px;
}
.meta .tag,
.meta .cat {
  color: #8F191C;
  font-weight: bold;
}
.meta .date {
  color: #BCBDBC;
}
.no-hero {
  padding-top: 120px;
}
.no-hero.orthoplus {
  padding-top: 115px;
}
ul.slick-dots {
  bottom: 0px;
}
@media screen and (max-width: 767px) {
  .SOL-007 ul.slick-dots {
    bottom: -20px;
  }
}
.video-post {
  background-color: #000;
  border-bottom: 3px solid #CF2F44;
}
.bg-none {
  background-color: transparent !important;
}
.color-premium {
  color: #00798C;
}
.color-red {
  color: #CF2F44;
}
.Re-004 .card p strong {
  text-decoration: none !important;
}
.Re-004 .card .color-premium {
  text-transform: uppercase;
  position: relative;
}
.Re-004 .card .color-premium:after {
  content: '';
  border-color: #00798C;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -4px;
  border-width: 0 0 2px;
  border-style: solid;
}
.fade-out {
  position: relative;
  max-height: 100px;
  overflow: hidden;
}
.fade-out:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0;
  padding: 30px 0;
  background-image: linear-gradient(to bottom, transparent, white);
}
div#fade-container {
  max-width: 900px;
  margin: auto;
  position: relative;
  line-height: 1.3;
}
div#fade-content {
  max-height: 230px;
  overflow: hidden;
}
div#fade-content.full {
  max-height: none;
}
.fade-anchor {
  background-image: linear-gradient(to bottom, transparent, white);
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.7) 20%, white 50%);
  height: 130px;
  padding-top: 65px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  text-align: center;
  -webkit-transition: background 150ms ease-in;
  transition: background 150ms ease-in;
}
.fade-anchor-text {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 9pt 9pt 0 0;
  color: #000;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  padding: 10px;
  text-decoration: underline;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.color-grey {
  color: #8D8D8D;
}
main > .row.justify-content-md-center {
  margin-left: 0;
  margin-right: 0;
}
.text-fadeout {
  position: relative;
}
.text-fadeout:before {
  bottom: 0;
  content: '';
  display: block;
  height: 15rem;
  position: absolute;
  width: 100%;
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.7) 31%, white 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.7) 31%, white 100%);
}
.row-inner-fix {
  margin-left: 15px;
  margin-right: 15px;
}
.stretched-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
  text-indent: -9999px;
}
.stretched-link:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}
@media screen and (max-width: 1199px) {
  .stretched-link--xl-max {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0);
  }
}
@media screen and (max-width: 767px) {
  .stretched-link--md-max {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0);
  }
}
.ReCP-002 a {
  color: #fff;
}
.modal.show .modal-dialog.modal-center {
  display: flex;
  height: 100%;
  max-width: 800px;
  margin: 30px auto;
}
.modal.show .modal-dialog.modal-center .modal-content {
  align-self: center;
}
.modal.show .modal-dialog.modal-center .modal-content .modal-body {
  position: relative;
  padding: 0px;
}
.modal.show .modal-dialog.modal-center .modal-content .modal-body .close {
  position: absolute;
  right: -15px;
  top: -15px;
  z-index: 999;
  font-size: 2rem;
  font-weight: normal;
  color: #fff;
  opacity: 1;
}
.orthoplus .footer-list > li {
  margin-bottom: 10px;
}
.orthoplus .footer-list > li.underline > a {
  text-decoration: underline;
}
.orthoplus .footer-list > li > a,
.orthoplus .footer-menus > li .title {
  color: #fff;
}
.orthoplus .footer-list > li > a:hover {
  opacity: 0.6;
}
.orthoplus li.inline {
  display: inline-block;
  margin: 0 13px;
  list-style: none;
}
.orthoplus li.inline a,
.orthoplus .footer__copyright {
  color: #BCBDBC;
  font-size: 1.2rem;
}
.orthoplus .footer__copyright {
  margin-right: 10px;
}
.orthoplus li.inline a:hover {
  opacity: 0.6;
}
.orthoplus .col-md-5ths--2x {
  width: 40%;
}
.orthoplus .col-md-5ths.last-2 {
  width: 19%;
}
@media (max-width: 1200px) {
  .orthoplus .col-md-5ths--2x {
    width: 50%;
  }
  .orthoplus .col-md-5ths.last-2 {
    width: 25%;
  }
}
@media (max-width: 768px) {
  .orthoplus li.inline {
    display: none;
  }
  .orthoplus .col-md-5ths--2x,
  .orthoplus .col-md-5ths.last-2 {
    width: 100%;
  }
}
.orthoplus .footer-menus > li h4.title .footer-drop-down:before {
  color: #fff;
}
a.link-unstyled {
  color: #000;
}
a.link-unstyled:hover {
  color: #000;
}
.tag-type {
  background: #404040;
  padding: 4px 8px 2px 8px;
  color: white;
  line-height: 24px;
  font-size: 14px;
  text-transform: uppercase;
}
.credit-fee {
  color: #BCBDBC;
  line-height: 24px;
  font-size: 14px;
  text-transform: uppercase;
}
.video-length {
  font-size: 14px;
  line-height: 22px;
  color: #404040;
  margin-top: 5px;
}
.account-red-text {
  padding: 250px 0;
}
@media (max-width: 992px) {
  .account-red-text {
    padding: unset;
  }
}
.account-red-text p {
  color: white;
}
.account-red-text img {
  width: 220px;
  display: block;
  margin: 0 auto;
}
.account-red-text p:first-child {
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
  padding-top: 15px;
}
.account-red-text p:last-child {
  font-size: 20px;
  font-weight: normal;
  text-align: center;
  margin-top: 18px;
  padding-bottom: 15px;
}
.POS-051 {
  min-height: 210px;
}
.hero-container {
  color: #fff;
  padding: 20px;
  padding-bottom: 50px;
}
.hero-container .button-well {
  margin-bottom: 0;
}
.hero-container h1 {
  margin-top: 40px;
}
@media screen and (min-width: 992px) {
  .hero-container h1 {
    font-size: 3.6rem;
    line-height: 4.4rem;
  }
}
.hero-container .video-link {
  color: #fff;
}
.background-secondary {
  background-image: none;
  background-color: #cf2f44;
  background: linear-gradient(to right, #ba2a3d, #8f191c);
  color: #ffffff;
}
.POS-025 {
  position: relative;
  top: -40px;
}
.rightCaption {
  float: right;
}
.background-dark-grey {
  background-color: #404040;
}
.OD-001 .container .content p {
  max-width: 770px !important;
}
.HP-001 a.btn {
  background: transparent !important;
}
.HP-001 a.btn:hover {
  background: #CF2F44 !important;
}
.orthoplus-beneath-video h4 {
  margin-bottom: 20px;
}
.orthoplus-beneath-video ul {
  margin: 0;
  padding: 0 0 0 15px;
}
.orthoplus-beneath-video ul li {
  color: #8F191C;
  margin: 10px 0;
  font-weight: 500;
}
.orthoplus-beneath-video ul li span {
  color: black;
  font-size: 14px;
}
.orthoplus-beneath-video #POS007b h4 {
  font-weight: bold;
}
@media (max-width: 767px) {
  .orthoplus-beneath-video #POS007b {
    padding: 20px;
  }
}
.orthoplus .POS-015 {
  background: none !important;
}
@media (max-width: 479px) {
  .orthoplus .POS-015 .user-profile {
    display: block;
  }
  .orthoplus .POS-015 h4 {
    font-size: 2.2rem;
  }
  .orthoplus .POS-015 .user-profile p {
    -webkit-border-image: -webkit-gradient(linear, left top, right top, from(#CF2F44), to(#8F191C)) 100% 1;
    -webkit-border-image: linear-gradient(to right, #CF2F44, #8F191C) 100% 1;
    -o-border-image: -o-linear-gradient(top, #CF2F44, #8F191C) 100% 1;
    border-image: -webkit-gradient(linear, right top, left top, from(#CF2F44), to(#8F191C)) 100% 1;
    border-image: linear-gradient(to right, #CF2F44, #8F191C) 100% 1;
    margin: 20px 0;
    padding: 20px 0 10px;
  }
  .orthoplus .POS-015 .user-profile img {
    width: 100%;
    height: 100%;
  }
}
.orthoplus-beneath-video {
  padding: 20px;
  background-color: #F8F8F8;
}
@media screen and (min-width: 992px) {
  .orthoplus-beneath-video {
    padding: 30px 45px;
  }
}
.orthoplus-beneath-video .POS-007ALT p {
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: bold;
}
.ie .img-fluid {
  height: 100%;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .ie .request-call__container > div {
    margin-top: 40px;
  }
}
@media screen and (min-width: 992px) {
  .ie .request-call__container > div {
    margin-top: 50px;
  }
}
.login p,
.signup p {
  color: #fff;
}
.login .modal-dialog,
.signup .modal-dialog {
  max-width: 1000px;
}
.login.modal,
.signup.modal {
  background: #CF2F44;
}
.login.modal .close,
.signup.modal .close {
  position: absolute;
  right: -10px;
  color: white;
}
.login.modal .close svg,
.signup.modal .close svg {
  fill: #fff;
  width: 24px;
  height: 24px;
}
.login.modal .modal-content,
.signup.modal .modal-content {
  background: transparent;
  border: 0;
}
.login.modal .modal-content h1,
.signup.modal .modal-content h1 {
  font-size: 66px;
  font-size: 6.6rem;
  color: #fff;
  font-family: 'Calluna', sans-serif;
}
.login.modal .modal-content form,
.signup.modal .modal-content form {
  background: white;
  margin-bottom: 20px;
}
.login.modal .modal-content form .form-check-label,
.signup.modal .modal-content form .form-check-label {
  color: #000;
}
.login.modal .modal-content .modal-footer,
.signup.modal .modal-content .modal-footer {
  border-top: 0;
}
.group {
  position: relative;
  margin-bottom: 20px;
}
.group label {
  color: #000;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 30px;
  top: 10px;
  transition: 0.2s ease all;
}
.group input {
  font-size: 18px;
  padding: 10px 10px 10px 30px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
}
.group input:focus {
  border-bottom: 2px solid #CF2F45;
  outline: 0;
  -webkit-transition: all ease-in-out 0.15s;
  -o-transition: all ease-in-out 0.15s;
  transition: all ease-in-out 0.15s;
}
.group input:focus {
  box-shadow: none;
  border-bottom-color: #CF2F45;
}
.group input:focus ~ label,
.group input:valid ~ label {
  top: -3px;
  font-size: 12px;
  color: #CF2F45;
}
/* Custom Select */
.select {
  position: relative;
  display: flex;
  width: 100%;
  height: 3em;
  line-height: 3;
  background: #404040;
  overflow: hidden;
  /* Reset Select */
  /* Custom Select */
  /* Arrow */
}
.select:hover {
  background: linear-gradient(to right, #cf2f44 1%, #8f191c 100%);
}
.select select {
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #404040;
  background-image: none;
  /* Remove IE arrow */
}
.select select::-ms-expand {
  display: none;
}
.select select option:checked {
  background: linear-gradient(to right, #cf2f44 1%, #8f191c 100%);
}
.select select {
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #404040;
  background-image: none;
  /* Remove IE arrow */
  flex: 1;
  padding: 0 0.5em;
  color: #fff;
  cursor: pointer;
}
.select select option:checked {
  background: linear-gradient(to right, #cf2f44 1%, #8f191c 100%);
}
.select select::-ms-expand {
  display: none;
}
.select::after {
  content: '\25BC';
  color: #FFF;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 1em;
  background: #8f191c;
  background: #404040;
  cursor: pointer;
  pointer-events: none;
  transition: 0.25s all ease;
}
.form-check-label {
  margin-left: 10px;
}
.form-icon {
  height: 10px;
  width: 10px;
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  margin-top: -5px;
  margin-left: 10px;
}
.form-icon.icon-user {
  background-image: url(../../assets/images/user/user.png);
}
.form-icon.icon-email {
  background-image: url(../../assets/images/user/mail.png);
}
.form-icon.icon-org {
  background-image: url(../../assets/images/user/org.png);
}
.form-icon.icon-password {
  background-image: url(../../assets/images/user/password.png);
}
.search-box .form-control {
  font-size: 18px;
  font-size: 1.8rem;
  border-radius: 25px;
  height: auto;
  line-height: 24px;
  padding-left: 23px;
}
.search-box .btn {
  font-size: 18px;
  font-size: 1.8rem;
  background-color: #fff;
  border-radius: 25px;
  padding-right: 23px;
}
.search-box .btn .fa {
  color: #CF2F44;
}
.book-your-place input {
  background-color: #F4F4F4;
}
.book-your-place .custom-control-label {
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: inherit;
}
.book-your-place .custom-control-label a {
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: inherit;
}
.drop-down {
  background: linear-gradient(90deg, #404040 0, #000 100%);
  height: 0;
  display: none;
  transition: height 1s;
}
.drop-down .block > div {
  padding: 20px;
  background-color: rgba(216, 216, 216, 0.05);
}
.drop-down.is-active {
  height: 500px;
  display: block;
}
.navbar-hamburger {
  background: none;
  border: none;
  font-size: 30px;
  margin-left: auto;
}
.navbar-header-mobile {
  display: flex;
  align-items: center;
  align-content: center;
  -ms-flex-pack: space-between;
  justify-content: space-between;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}
@media screen and (min-width: 1200px) {
  .navbar-header-mobile {
    display: none;
  }
}
.navbar-header-mobile .ortho-logo {
  background-image: url(../../assets/images/ortho-logo-mob.png);
  height: 44px;
  width: 120px;
  margin-left: 15px;
  margin-bottom: 15px;
}
.top-panel {
  background-color: #404040;
  background: linear-gradient(90deg, #404040 0, #000 100%);
  background-size: cover;
  height: 100%;
  left: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  top: 0;
  transition: transform 0.3s ease;
  width: 100%;
  z-index: 1031;
}
@media (min-width: 1200px) {
  .top-panel {
    display: none;
  }
}
.top-panel--top {
  transform: translate3d(0, -100%, 0);
}
.top-panel--right {
  transform: translate3d(100%, 0, 0);
}
.top-panel.is-open {
  transform: translate3d(0, 0, 0);
}
@media (max-width: 1200px -1px) {
  .top-panel-open {
    overflow: hidden;
  }
}
.global-menu {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 50px;
  width: 100%;
}
.global-menu__stage {
  overflow: hidden;
  position: relative;
}
.global-menu__content {
  animation-timing-function: ease-in-out;
  float: left;
  transform: translate3d(0, 0, 0);
  transition-duration: 0.3s;
  width: 100%;
}
.global-menu__content--left {
  position: absolute;
  transform: translate3d(-100%, 0, 0);
}
.global-menu__content--right {
  position: absolute;
  transform: translate3d(100%, 0, 0);
}
.top-panel__header {
  align-items: center;
  background-color: #f2f2f2;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  display: flex;
  height: 50px;
  justify-content: space-between;
}
.top-panel__main-actions {
  align-items: stretch;
  display: flex;
  height: 100%;
  position: relative;
}
.top-panel__action {
  align-items: center;
  display: flex;
  justify-content: space-around;
  position: relative;
}
.top-panel__btn-action {
  align-items: center;
  background-color: #f2f2f2;
  border-right: 1px solid #bfbfbf;
  display: flex;
  font-size: 20px;
  font-weight: 700;
  height: 100%;
  line-height: 50px;
  padding: 0 14px;
  text-transform: uppercase;
  user-select: none;
}
.top-panel__btn-action,
.top-panel__btn-action:active,
.top-panel__btn-action:focus,
.top-panel__btn-action:hover {
  color: #8f191c;
  text-decoration: none;
}
.top-panel__btn-action:hover {
  background-color: #ffffff;
}
.top-panel__btn-action:active,
.top-panel__btn-action:focus {
  background-color: #e5e5e5;
}
.top-panel__btn-action--home {
  font-size: 24px;
}
.top-panel__close-action {
  background-color: transparent;
  border: 0;
  color: #8f191c;
  outline: 0;
  padding: 0 12px;
  user-select: none;
  font-size: 28px;
  line-height: 28px;
}
.dropdown-menu--top-panel-lang,
.dropdown-menu--top-panel-search {
  margin-top: 12px;
  padding: 2px;
}
.dropdown-menu--top-panel-lang:after,
.dropdown-menu--top-panel-search:after,
.dropdown-menu--top-panel-lang:before,
.dropdown-menu--top-panel-search:before {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 0;
  content: '';
  font-size: 0;
  height: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: -10px;
  width: 0;
}
.dropdown-menu--top-panel-lang:before,
.dropdown-menu--top-panel-search:before {
  border-bottom: 10px solid linear-gradient(90deg, #CF2F44 0, #8F191C 100%);
}
.dropdown-menu--top-panel-lang:after,
.dropdown-menu--top-panel-search:after {
  border-bottom: 10px solid linear-gradient(90deg, #CF2F44 0, #8F191C 100%);
}
.dropdown-menu--top-panel-search.dropdown-menu {
  left: 10px !important;
  position: fixed !important;
  right: 10px !important;
  top: 50px !important;
  transform: translate3d(0px, 0px, 0px) !important;
}
.dropdown-menu--top-panel-search.dropdown-menu:after,
.dropdown-menu--top-panel-search.dropdown-menu:before {
  left: 58px;
  right: auto;
}
.dropdown-menu--top-panel-lang {
  height: auto;
  max-height: 50vh;
  overflow-y: auto;
  margin-top: 10px;
}
.dropdown-menu--top-panel-lang.dropdown-menu {
  text-align: center;
}
.dropdown-menu--top-panel-lang.dropdown-menu a {
  display: block;
  padding: 8px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.4;
  white-space: nowrap;
}
.dropdown-menu--top-panel-lang.dropdown-menu a.active {
  background-color: #8f191c;
  color: #fff;
}
.dropdown-menu--top-panel-lang.dropdown-menu:after,
.dropdown-menu--top-panel-lang.dropdown-menu:before {
  left: auto;
  right: 18px;
}
@media (max-width: 1199px) {
  .global-menu__nav {
    height: calc(100vh - 92px);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 15px 10px;
  }
  .global-menu__nav--secondary {
    padding-top: 15px;
  }
  .global-menu__list {
    padding-left: 0;
    list-style: none;
  }
  .global-menu__item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    clear: both;
    position: relative;
  }
  .global-menu__item:last-child {
    border-bottom: 0;
  }
  .global-menu__item a,
  .global-menu__item button {
    align-items: center;
    background-color: rgba(255, 255, 255, 0);
    border: 0;
    color: rgba(255, 255, 255, 0.9);
    display: flex;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    justify-content: space-between;
    outline: 0;
    padding: 10px 5px 10px 15px;
    text-decoration: none;
    width: 100%;
  }
  .global-menu__item a:hover,
  .global-menu__item button:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }
  .global-menu__item a:active,
  .global-menu__item button:active {
    background-color: rgba(255, 255, 255, 0.35);
  }
  .global-menu__item a + a,
  .global-menu__item button + a,
  .global-menu__item a + button,
  .global-menu__item button + button {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    background: none;
  }
  .global-menu__item a + a:hover,
  .global-menu__item button + a:hover,
  .global-menu__item a + button:hover,
  .global-menu__item button + button:hover {
    background: none;
  }
  .global-menu__item a + a:active,
  .global-menu__item button + a:active,
  .global-menu__item a + button:active,
  .global-menu__item button + button:active {
    background: none;
  }
  .global-menu__item p {
    color: #fff;
    margin: 0;
    font-size: 1.8rem;
    padding: 10px 5px 5px 0;
  }
  .global-menu__item.global-menu__item--svg a,
  .global-menu__item.global-menu__item--svg button {
    padding-left: 60px;
  }
  .global-menu__item.global-menu__item--svg svg {
    position: absolute;
    fill: white;
    width: 28px;
    height: 28px;
    left: 15px;
    top: 8px;
  }
  .global-menu__item.global-menu__item--svg svg.svg--wide {
    width: 40px;
  }
  .global-menu__item--back {
    background-color: rgba(255, 255, 255, 0);
    border-bottom: none !important;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0;
  }
  .global-menu__item--back a {
    padding-left: 20px;
    padding-right: 20px;
  }
  .global-menu__back-btn {
    text-transform: uppercase;
  }
}
.top-panel__footer {
  bottom: 0;
  left: 0;
  position: absolute;
  align-items: center;
  border: 0;
  color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: space-between;
  outline: 0;
  text-decoration: none;
  width: 100%;
  background-color: #404040;
}
.top-panel__btn-footer {
  background-color: #404040;
  display: block;
  font-size: 14px;
  font-weight: 700;
  padding: 12px 10px;
  text-align: center;
  width: 50%;
}
.top-panel__btn-footer:first-child {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}
.top-panel__btn-footer,
.top-panel__btn-footer:focus,
.top-panel__btn-footer:hover {
  color: #fff;
  text-decoration: none;
}
.top-panel__btn-footer:focus,
.top-panel__btn-footer:hover {
  background-color: #484848;
}
.gs-form {
  padding: 3px 15px 10px 5px;
  margin: 5px;
  overflow: hidden;
}
.gs-form .form-group {
  margin: 0;
}
.gs-form label.error {
  clear: both;
  color: #b12a0b;
  display: block;
  text-align: center;
  padding: 5px 5px 0;
}
.gs-form__btn {
  float: left;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 7px 14px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 0;
  user-select: none;
  color: #8f191c;
}
.gs-form__input {
  background-color: transparent;
  border-radius: 0;
  border-width: 0 0 1px;
  color: #8f191c;
  width: calc(100% - 46px);
  height: 36px;
  padding: 7px 14px;
  font-size: 15px;
  line-height: 1.5;
  outline: none;
}
.gs-form__input:focus {
  padding-top: 8px;
  border-bottom: 2px solid #8f191c;
}
.gs-form__input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
@media screen and (min-width: 1200px) {
  .global-menu__list {
    display: flex;
    list-style: none;
    padding-left: 0;
    max-width: 1200px;
    margin: auto;
    position: relative;
    -webkit-justify-content: space-evenly;
    -moz-justify-content: space-evenly;
    justify-content: space-between;
  }
  .global-menu__list:before,
  .global-menu__list:after {
    content: '';
    display: block;
  }
  .global-menu__list > li {
    margin-top: 50px;
  }
  .global-menu__list > li a:hover {
    text-decoration: none;
  }
  .global-menu__list > li a:hover .global-menu__item {
    color: black;
    background-color: white;
  }
  .global-menu__list > li a:hover .global-menu__item svg {
    fill: #CF2F44;
  }
  .global-menu__list > li a:hover .global-menu__item .sub-mega-menu__title h4 {
    color: #000;
  }
  .global-menu__list.global-menu__list--sm {
    max-width: 735px;
  }
  .global-menu__list.global-menu__list--md {
    max-width: 950px;
  }
  .global-menu__list.global-menu__list--lg {
    max-width: 1400px;
  }
  .global-menu__list.global-menu__list--lg .global-menu__item--product-container-1 {
    width: 30.2%;
    left: 2.4%;
    margin-top: 0 !important;
  }
  .global-menu__list.global-menu__list--lg .global-menu__item--product-container-2 {
    left: 35%;
    width: 30.2%;
    margin-top: 0 !important;
  }
  .global-menu__list.global-menu__list--lg .global-menu__item--product-container-3 {
    left: 67.5%;
    width: 30.2%;
    margin-top: 0 !important;
  }
  .global-menu__list .global-menu__item {
    border-bottom: none;
    background-color: rgba(216, 216, 216, 0.05);
    color: #fff;
    padding: 20px;
    width: 195px;
    height: 100%;
  }
  .global-menu__list .global-menu__item h4 {
    font-size: 1.8rem;
    color: #fff;
  }
  .global-menu__list .global-menu__item.global-menu__item--svg a {
    text-decoration: none;
  }
  .global-menu__list .global-menu__item.global-menu__item--svg svg {
    width: 30px;
    height: 30px;
    fill: #fff;
  }
  .global-menu__list .global-menu__item.global-menu__item--svg svg.svg--wide {
    width: 105px;
  }
  .global-menu__item.global-menu__item--browse {
    text-align: center;
    margin: 30px;
  }
  .global-menu__item.global-menu__item--browse a {
    text-align: center;
    color: #fff;
    text-decoration: none;
  }
  .global-menu__item--back {
    background-color: rgba(255, 255, 255, 0);
    border-bottom: none !important;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0;
  }
  .global-menu__item--back a {
    padding-left: 20px;
    padding-right: 20px;
  }
  .global-menu__item--back .global-menu__back-btn {
    align-items: center;
    background-color: rgba(255, 255, 255, 0);
    border: 0;
    color: rgba(255, 255, 255, 0.9);
    display: flex;
    font-size: 16px;
    font-weight: 700;
    justify-content: space-between;
    outline: 0;
    padding: 10px 5px 10px 15px;
    text-decoration: none;
  }
  .global-menu__back-btn {
    text-transform: uppercase;
  }
  .global-menu__item--product-container-1 {
    position: absolute;
    left: 3.33%;
    color: white;
    text-align: center;
    width: 35.5%;
    margin-top: 0 !important;
  }
  .global-menu__item--product-container-2 {
    position: absolute;
    left: 42%;
    color: white;
    text-align: center;
    background: none;
    width: 35.5%;
    padding: 0;
    margin-top: 0 !important;
  }
  .global-menu__item--product-container-3 {
    position: absolute;
    left: 80.66%;
    color: white;
    text-align: center;
    background: none;
    width: 16.33%;
    padding: 0;
    margin-top: 0 !important;
  }
  .global-menu__item--product-container-sm {
    position: absolute;
    left: 4.33%;
    color: white;
    text-align: center;
    width: 59.5%;
    margin-top: 0 !important;
  }
  .global-menu__item--product-container-sm-1 {
    position: absolute;
    left: 68.5%;
    color: white;
    text-align: center;
    background: none;
    width: 27.33%;
    padding: 0;
    margin-top: 0 !important;
  }
  .global-menu__item--product {
    color: #747678;
    border-bottom: 1px solid #747678;
    text-transform: uppercase;
    font-weight: bold;
  }
  .img-max-height-100 {
    max-height: 100px;
  }
  .fix-menu-desktop .menu-desktop {
    background: linear-gradient(90deg, #404040 0, #000 100%);
    top: -1px;
  }
  .fix-menu-desktop .menu-desktop .main-menu {
    width: calc(100% - 30px);
  }
  .fix-menu-desktop .menu-desktop .main-menu li a {
    color: #fff;
  }
  .fix-menu-desktop .menu-desktop .main-menu li a:after {
    color: #fff;
    margin-top: -2px;
  }
}
.ortho-logo {
  align-self: center;
  background-size: contain;
  background-image: url(../../assets/images/ortho-logo-black.png);
  background-repeat: no-repeat;
  content: '';
  display: inline-block;
  width: 290px;
  height: 26px;
  margin-bottom: 0;
}
.fix-menu-desktop .ortho-logo {
  background-image: url(../../assets/images/ortho-logo-white.png);
}
.container-menu-desktop {
  display: none;
}
@media screen and (min-width: 1200px) {
  .container-menu-desktop {
    display: block;
  }
}
.wrap-main-nav {
  width: 100%;
  height: 55px;
  z-index: 1000;
  position: relative;
}
.main-nav {
  width: 100%;
  height: 55px;
}
.main-nav:hover {
  background: transparent;
}
.main-nav.is-active {
  background: linear-gradient(90deg, #404040 0, #000 100%);
}
.main-nav.is-active .ortho-logo {
  background-image: url(../../assets/images/ortho-logo-white.png);
}
.menu-desktop {
  margin: 0 auto;
  height: 55px;
  position: relative;
}
.main-menu {
  display: flex;
  max-width: 1140px;
  list-style-type: none;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;
  align-items: center;
}
.main-menu > li {
  height: 100%;
  position: relative;
}
/*---------------------------------------------*/
.sub-menu {
  list-style-type: none;
  position: absolute;
  top: 0;
  left: 100%;
  width: 225px;
  background-color: #fff;
  padding: 15px 0px 15px 0px;
  transition: all 0.3s;
  visibility: hidden;
  opacity: 0;
  border: 1px solid #f2f2f2;
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.2);
}
.sub-menu li {
  position: relative;
  background-color: transparent;
}
.main-menu > li > .sub-menu {
  top: 100%;
  left: 0px;
}
.main-menu > li.respon-sub-menu > .sub-menu {
  top: 100%;
  left: auto;
  right: 0;
}
li .respon-sub-menu > .sub-menu .sub-menu {
  top: 0;
  left: auto;
  right: 100%;
}
.sub-menu a {
  font-size: 14px;
  line-height: 1.7857;
  color: #222;
  display: block;
  padding: 8px 30px;
  width: 100%;
  transition: all 0.3s;
}
.main-menu > li > a {
  color: #222;
  display: flex;
  align-items: center;
  height: 100%;
  font-weight: 500;
  padding: 5px 0px;
  margin: 0 18px;
  transition: all 0.3s;
}
.is-active .main-menu > li > a {
  color: #fff;
}
.main-menu > li:first-child a {
  margin-left: 0;
}
.main-menu > li:last-child a {
  margin-right: 0;
}
.light-nav .main-menu .ortho-logo {
  background-image: url(../../assets/images/ortho-logo-white.png);
}
.light-nav .main-menu > li > a {
  color: #fff;
}
.is-active .light-nav .main-menu > li > a {
  color: #fff;
}
.main-menu > li.mega-menu-item > a .fas {
  margin-left: 6px;
  transition: all 0.3s;
}
.main-menu > li.mega-menu-item > a .fa-caret-up {
  display: none;
}
.main-menu > li.mega-menu-item.is-active > a .fa-caret-down {
  display: none;
}
.main-menu > li.mega-menu-item.is-active > a .fa-caret-up {
  display: inline-block;
}
li.main-menu-active > a:before {
  transform: scaleX(1);
}
.main-menu > li:hover > a {
  text-decoration: none;
}
.main-menu > li:hover > .sub-menu {
  visibility: visible;
  opacity: 1;
}
.main-menu > li.mega-menu-item {
  position: static;
}
.sub-menu > li:hover {
  background-color: transparent;
}
.sub-menu > li:hover > .sub-menu {
  visibility: visible;
  opacity: 1;
}
.sub-menu > li:hover > a {
  background-color: #17b978;
  color: #fff;
  text-decoration: none;
}
.sub-mega-menu {
  flex-wrap: wrap;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  width: 100%;
  display: none;
}
.main-menu > li:hover > .sub-mega-menu {
  display: flex;
}
.sub-mega-menu .nav {
  width: 240px;
  border-right: 1px solid #f2f2f2;
  padding: 30px 0;
}
.sub-mega-menu .tab-content {
  min-height: 540px;
}
.fix-menu-desktop .main-nav {
  position: fixed;
  top: -55px;
  left: 0;
  transition: transform 0.3s;
}
.fix-menu-desktop .show-main-nav {
  transform: translateY(100%);
}
.fix-menu-desktop .menu-desktop {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.fix-menu-desktop .menu-desktop .logo-stick {
  display: block;
}
.p-relative {
  position: relative;
}
.sub-mega-menu {
  background: linear-gradient(90deg, #404040 0, #000 100%);
  padding: 20px;
}
.sub-mega-menu-link-container {
  background-color: rgba(216, 216, 216, 0.05);
  color: #fff;
  padding: 20px;
}
.sub-mega-menu-link-container a {
  color: #fff;
}
.sub-mega-menu-link-container a:hover {
  text-decoration: none;
}
.sub-mega-menu__icon {
  min-height: 50px;
}
.sub-mega-menu__icon .icon {
  width: 30px;
  height: 30px;
  fill: #fff;
}
.sub-mega-menu__title {
  min-height: 60px;
}
.sub-mega-menu__title h4 {
  font-size: 2.4rem;
}
.sub-mega-menu__text {
  min-height: 80px;
  color: #747678;
}
@media screen and (max-width: 1199px) {
  main {
    padding-top: 95px;
  }
}
@media screen and (max-width: 1199px) {
  .global-header {
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 10000;
  }
  .global-header .navbar-header-mobile .ortho-logo {
    height: 40px;
    width: 120px;
    margin-left: 15px;
    margin-bottom: 10px;
  }
  .global-header .navbar-header-mobile .navbar-hamburger {
    margin-top: 10px;
  }
}
@media screen and (min-width: 1200px) {
  .global-header {
    position: absolute;
    width: 100%;
  }
}
.global-header .top-nav {
  background-color: #cf2f44;
  background-image: linear-gradient(90deg, #CF2F44, #8F191C);
  position: sticky;
  z-index: 10;
}
.global-header .top-nav a {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  color: #fff;
  display: inline-block;
  margin-left: 6px;
  padding: 10px;
}
@media screen and (max-width: 575px) {
  .global-header .top-nav a {
    margin-left: 5px;
  }
}
.global-header .top-nav a:hover {
  text-decoration: none;
}
.container-menu-desktop .search-form .form-group input.form-control::-webkit-input-placeholder {
  display: none;
}
.container-menu-desktop .search-form .form-group input.form-control::-moz-placeholder {
  display: none;
}
.container-menu-desktop .navbar-right.adjust-width {
  min-width: 195px;
  /*or I can probably just use columns*/
}
.container-menu-desktop .navbar-right.adjust-width .form-group input.form-control {
  z-index: 10;
  background: none;
}
.container-menu-desktop .navbar-right .form-group {
  margin-bottom: 0;
}
.container-menu-desktop .navbar-right form {
  width: 100%;
}
.container-menu-desktop .form-group {
  position: relative;
  width: 0%;
  min-width: 60px;
  height: 55px;
  overflow: hidden;
  transition: width 1s;
  backface-visibility: hidden;
}
.container-menu-desktop .form-group input.form-control {
  position: absolute;
  background: none;
  top: 0;
  right: 0;
  outline: none;
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  height: 55px;
  margin: 0;
  z-index: -10;
  border: 0;
  background-clip: inherit;
}
.container-menu-desktop input[type="text"].form-control {
  -webkit-appearence: none;
  -webkit-border-radius: 0;
}
.container-menu-desktop .form-control-submit,
.container-menu-desktop .search-label {
  width: 60px;
  height: 55px;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  line-height: 55px;
  background: transparent;
}
.container-menu-desktop .form-control-submit {
  background: #fff;
  /*stupid IE*/
  opacity: 0;
  color: transparent;
  border: none;
  outline: none;
  z-index: -1;
}
.container-menu-desktop .search-label {
  z-index: 90;
}
.container-menu-desktop .form-group.sb-search-open,
.container-menu-desktop .no-js .sb-search-open {
  width: 100%;
}
.container-menu-desktop .form-group.sb-search-open .search-label,
.container-menu-desktop .no-js .sb-search .search-label {
  z-index: 11;
}
.container-menu-desktop .form-group.sb-search-open .form-control-submit,
.container-menu-desktop .no-js .form-control .form-control-submit {
  z-index: 90;
}
.container-menu-desktop .main-nav.is-active .form-control {
  color: white;
  background: none;
}
.container-menu-desktop .main-nav.is-active .search-label {
  background: none;
  color: #fff;
}
.container-menu-desktop.fix-menu-desktop .search-label {
  background: none;
  color: #fff;
}
.container-menu-desktop.fix-menu-desktop .form-control {
  color: white;
  background: none;
}
.light-nav .search-form .form-control {
  color: white;
  background: none;
}
.light-nav .search-form .search-label {
  background: none;
  color: #fff;
}
/* #### ORTHO + NAV */
.orthoplus.navbar-header-mobile {
  padding: 10px;
}
.orthoplus .btn {
  font-size: unset;
  font-weight: bold;
}
.orthoplus .main-menu > li > a {
  font-weight: bold;
}
.orthoplus .main-menu > li > .dropdown {
  color: #222;
  padding: 7px 0px;
  margin: 0;
}
.orthoplus .dropdown-menu {
  padding: 20px 10px;
  width: 300px;
  position: relative;
  right: auto  !important;
  left: -75px !important;
  -webkit-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  border-width: 0;
  border: 0;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.3);
  line-height: 2em;
}
.orthoplus .dropdown-menu .row {
  margin: 0 0 10px;
}
.orthoplus .dropdown-toggle.active {
  color: #CF2F44 !important;
}
.orthoplus .dropdown-toggle.active::after {
  border-top: 0;
  border-color: #CF2F44;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.orthoplus .dropdown.show .dropdown-toggle {
  color: #CF2F44 !important;
}
.orthoplus .dropdown.show .dropdown-toggle::after {
  border-top: 0;
  border-color: #CF2F44;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.orthoplus .dropdown-item {
  line-height: 2em;
  font-weight: bold;
}
.orthoplus .dropdown-item:active {
  background-color: #cf2f44;
  background-image: -webkit-gradient(linear, left top, right top, from(#CF2F44), to(#8F191C));
  background-image: -o-linear-gradient(left, #CF2F44, #8F191C);
  background-image: linear-gradient(90deg, #CF2F44, #8F191C);
}
.orthoplus .dropdown-menu .user {
  font-size: 17px;
}
.orthoplus .dropdown-menu .user span a {
  font-size: 12px;
}
.orthoplus .ortho-logo {
  background-image: url(../../assets/images/ortho+-logo-black.png);
  width: 160px !important;
}
.orthoplus .navbar-header-mobile .ortho-logo {
  background-image: url(../../assets/images/ortho+-logo-black.png);
  margin-top: 25px;
  width: 160px;
}
.orthoplus .main-menu > li > a:hover,
.dropdown-toggle:hover {
  color: #CF2F44 !important;
}
.orthoplus .ortho-plus-notification:after {
  content: "";
  display: inline-block;
  width: 5px;
  height: 5px;
  position: absolute;
  top: 15px;
  right: 10px;
  background-size: contain;
  background-image: url(../../assets/icons/NEW.png);
}
.orthoplus .ortho-plus-notification a:hover {
  opacity: 0.6;
}
@media (max-width: 1199px) {
  .orthoplus.navbar-header-mobile .ortho-logo {
    height: 40px;
    width: 120px;
    margin-left: 15px;
    margin-bottom: 0px;
    margin-top: 15px;
  }
  .orthoplus .ortho-plus-notification a:hover {
    opacity: 0.8;
  }
  .settings:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  .orthoplus .top-panel__header {
    height: 70px;
    background-color: #fff;
  }
  .orthoplus .fa {
    color: #404040;
  }
  .orthoplus .fas {
    color: #404040;
  }
  .main-menu > li > a .global-menu {
    top: 70px;
    padding-bottom: 80px;
  }
  .orthoplus .global-menu__item {
    border-bottom: 0;
  }
  .orthoplus .dropdown-toggle.active {
    color: #fff !important;
  }
  .orthoplus .dropdown {
    clear: both;
    position: relative;
    color: #fff;
  }
  .orthoplus .dropdown-toggle {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0);
    border: 0;
    color: rgba(255, 255, 255, 0.9);
    display: flex;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    outline: 0;
    padding: 10px 5px 10px 15px;
    text-decoration: none;
    width: 100%;
  }
  .dropdown-toggle:hover {
    color: #fff !important;
    opacity: 0.8;
  }
  .orthoplus .dropdown.show {
    border-bottom: none;
    clear: both;
    position: relative;
  }
  .orthoplus .dropdown-menu {
    position: relative !important;
    padding: 10px;
    width: 110%;
    height: auto;
    left: -10px !important;
    background-color: #404040;
    transform: translate3d(0px, 0px, 0px) !important;
    clear: both;
    position: relative;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.3);
  }
  .orthoplus a.dropdown-item {
    color: #fff;
    line-height: 38px;
    font-weight: 400;
    font-size: 14px;
  }
  .orthoplus a.dropdown-item:hover,
  .orthoplus .global-menu__item a:hover {
    background-color: none !important;
    background: transparent;
    opacity: 0.8;
  }
  .orthoplus .top-panel__footer {
    background-image: linear-gradient(to right, #CF2F44, #8F191C);
    height: 88px;
    padding: 10px;
  }
  .orthoplus .top-panel__footer .profile {
    width: 310px;
  }
  .orthoplus .top-panel__footer .profile .image {
    width: 50px;
    margin-right: 10px;
    float: left;
  }
  .orthoplus .top-panel__footer .profile .user {
    width: 210px;
    float: left;
  }
  .orthoplus .top-panel__footer .profile .user a {
    color: #fff;
  }
  .orthoplus .top-panel__footer .toggle {
    width: 30px;
  }
  .orthoplus .top-panel__btn-footer {
    background: none;
  }
  .orthoplus .ortho-plus-notification {
    line-height: 30px;
    padding: 10px 5px 10px 15px;
  }
  .orthoplus .notfications {
    padding-bottom: 20px;
    margin-top: 20px;
    border-bottom: 1px solid #fff;
  }
  .orthoplus .main-items {
    padding-top: 20px;
  }
  .orthoplus .ortho-plus-notification .row {
    margin: 0;
  }
  .orthoplus .global-menu__item a:hover,
  .global-menu__item button:hover {
    background-color: transparent;
  }
  .orthoplus .ortho-plus-notification a {
    color: #fff;
  }
  .orthoplus .ortho-plus-notification .img-after {
    width: 40px;
    float: left;
  }
  .orthoplus .ortho-plus-notification .text {
    /*width: 200px;*/
    float: left;
  }
  .orthoplus .ortho-plus-notification .img-after:after {
    content: "";
    display: inline-block;
    width: 5px;
    height: 5px;
    position: relative;
    top: -10px;
    right: -5px;
    background-size: contain;
    background-image: url(../../assets/icons/NEW.png);
  }
  .orthoplus .ortho-plus-notification:after {
    display: none;
  }
}
@media (min-width: 1200px) {
  .orthoplus.fix-menu-desktop .menu-desktop {
    background: #fff;
    top: -1px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .orthoplus.fix-menu-desktop .menu-desktop .main-menu li a,
  .orthoplus.fix-menu-desktop .menu-desktop .main-menu li a:after {
    color: #000;
  }
}
.orthoplus.fix-menu-desktop .main-menu {
  margin-top: 0;
}
.orthoplus .main-menu {
  margin-top: 15px;
}
footer {
  background-color: #404040;
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 0;
  color: #fff;
  position: relative;
}
footer p {
  color: #fff;
}
footer ul {
  list-style: none;
  margin-bottom: 0;
}
footer a {
  color: #fff;
  text-decoration: none;
  transition: 400ms ease-in-out;
  display: block;
}
footer a:hover {
  text-decoration: none;
  color: #fff;
}
footer .select select,
footer .select {
  background: rgba(25, 25, 25, 0.3);
}
footer .select:hover {
  background: rgba(25, 25, 25, 0.3);
}
footer .select:after {
  background: rgba(25, 25, 25, 0.3);
}
footer .dropdown--custom-footer {
  position: absolute;
  bottom: 0;
  right: 0;
}
footer .dropdown--custom-footer select {
  padding: 10px;
  background: none;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  margin-left: -30px;
  padding-left: 30px;
  border: 0;
}
footer .dropdown--custom-footer option {
  line-height: 24px;
}
footer .dropdown--custom-footer option:selected {
  padding: 10px;
  color: #fff;
  background: none;
}
footer .dropdown--custom-footer .dropup-options {
  background: rgba(0, 0, 0, 0.8);
}
footer .dropdown--custom-footer .dropup-options:hover {
  background: red;
}
footer .footer__copyright {
  color: #747679;
  font-size: 1.2rem;
}
.footer__quick-links {
  margin: 20px 0;
}
.footer__quick-links li {
  margin-bottom: 15px;
}
.footer__quick-links li:last-child {
  margin-bottom: 0;
}
.footer__quick-links li a {
  font-size: 14px;
  line-height: 17px;
  margin-right: 25px;
  text-transform: uppercase;
  font-weight: 500;
}
@media screen and (min-width: 768px) {
  .footer__quick-links li a {
    text-transform: initial;
    font-weight: 500;
  }
  .footer__quick-links li a.text-inherit {
    color: #bcbdbc;
    text-transform: inherit;
    font-size: 1.2rem;
    font-weight: 500;
  }
}
.footer__quick-links li a.text-inherit {
  text-transform: inherit;
}
.footer--services ul li a {
  color: #bcbdbc;
  font-size: 12px;
  line-height: 24px;
}
.footer--services ul li a:active,
.footer--services ul li a:focus,
.footer--services ul li a:hover {
  color: #fff;
}
@media screen and (max-width: 767px) {
  .footer .active {
    -webkit-transition: background-color 0.5s ease-in-out;
    transition: background-color 0.5s ease-in-out;
  }
  .footer .active .footer-list {
    max-height: 500px;
    -webkit-transition: max-height 0.5s;
    transition: max-height 0.5s;
  }
  .footer-menus > li {
    border-bottom: 2px solid #747678;
  }
  .footer-menus > li:first-child {
    border-top: 2px solid #747678;
  }
  .footer-menus > li h4.title {
    line-height: 1.4rem;
    font-size: 1.4rem;
    text-transform: inherit;
    font-weight: 500;
  }
  .footer-menus > li h4.title .footer-drop-down {
    right: 0;
    position: absolute;
    top: -webkit-calc(42%);
    top: calc(50% - 8px);
    width: 14px;
    height: 8px;
    background-position: 0 -930px;
    transform: scale(0.8);
    -ms-transform: scale(0.8);
    -webkit-transform: scale(0.8);
  }
  .footer-menus > li h4.title .footer-drop-down:before {
    font-size: 2rem;
  }
  .footer .active .footer-drop-down {
    transform: rotate(180deg) scale(0.8) !important;
    -ms-transform: rotate(180deg) scale(0.8) !important;
    -webkit-transform: rotate(180deg) scale(0.8) !important;
  }
  .footer-list > li {
    padding: 0 0 5px 15px;
  }
}
@media (min-width: 768px) {
  .footer__quick-links ul {
    display: flex;
    padding: 45px 0;
    margin-bottom: 0;
  }
  .footer__quick-links a:hover {
    transform: translateY(-5px);
  }
  .footer__quick-links.footer__quick-links--primary {
    padding: 20px 0 40px;
    margin-bottom: 0;
  }
}
.footer {
  -webkit-transition: background-color 0.5s ease-in-out;
  transition: background-color 0.5s ease-in-out;
}
.footer-content {
  margin: 20px auto;
}
.footer ul {
  margin: 0;
  padding: 0;
}
.footer-menus {
  width: 100%;
}
.col-lg-5ths,
.col-md-5ths,
.col-sm-5ths,
.col-xs-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.footer-menus > li .title {
  margin: 0;
  width: 100%;
  cursor: pointer;
  position: relative;
  display: inline-block;
  font-size: 1.06666667rem;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  padding: 20px 0;
}
.footer-list {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.5s;
  transition: max-height 0.5s;
}
.footer-list > li > a {
  line-height: 2.3;
  font-size: 1.2rem;
  color: #bcbdbc;
}
.footer-list > li > a:active,
.footer-list > li > a:focus,
.footer-list > li > a:hover {
  color: #fff;
}
@media screen and (min-width: 768px) {
  .col-md-5ths {
    width: 25%;
    float: left;
  }
  .col-md-5ths--2x {
    width: 50%;
  }
  .col-md-5ths--2x ul {
    column-count: 2;
  }
  .footer-menus > li .title {
    width: auto;
    cursor: default;
    color: #bcbdbc;
    text-transform: inherit;
    font-size: 1.2rem;
    font-weight: 500;
  }
  .footer-list {
    max-height: none;
  }
}
.footer__quick-links.footer__quick-links--social li:last-child a {
  margin-right: 0;
}
.footer__quick-links.footer__quick-links--social li a {
  font-weight: normal;
}
@media (min-width: 768px) {
  .divider-md {
    height: 1px;
    background-color: #fff;
  }
}
.footer .fab {
  font-size: 18px;
}
.back-to-top {
  bottom: 30px;
  height: 45px;
  position: fixed;
  right: 30px;
  width: 45px;
  z-index: 1060;
}
.back-to-top a {
  align-items: center;
  border: 1px solid #CF2F44;
  background-color: #fff;
  color: #CF2F44;
  display: flex;
  height: 45px;
  justify-content: center;
  left: 0;
  position: absolute;
  text-align: center;
  text-decoration: none;
  top: 0;
  width: 45px;
}
.back-to-top svg.icon {
  transform: rotate(180deg) translateX(-5px) translateY(3px);
  fill: #CF2F44;
  width: 20px;
  height: 20px;
}
@media (max-width: 576px) {
  .back-to-top {
    bottom: 15px;
    height: 40px;
    right: 10px;
    width: 40px;
  }
  .back-to-top a {
    height: 40px;
    width: 40px;
  }
}
.article {
  position: relative;
}
.article ~ .SOL-004 {
  margin-top: 50px;
}
@media (min-width: 992px) {
  .article {
    display: flex;
    overflow: hidden;
  }
  .article img {
    position: absolute;
    z-index: 1;
  }
}
@media (min-width: 992px) {
  .article:nth-of-type(even),
  .article:nth-of-type(odd) {
    display: flex;
    justify-content: flex-end;
  }
  .article:nth-of-type(even) img,
  .article:nth-of-type(odd) img {
    left: 0;
  }
}
@media (min-width: 992px) {
  .article + .article:nth-of-type(even) {
    justify-content: flex-start;
  }
  .article + .article:nth-of-type(even) img {
    left: auto;
    right: 0;
  }
}
@media (min-width: 992px) {
  .article.position-right {
    display: flex;
    justify-content: flex-end;
  }
  .article.position-right img {
    left: 0;
  }
}
@media (min-width: 992px) {
  .article.position-left {
    justify-content: flex-start;
  }
  .article.position-left img {
    left: auto;
    right: 0;
  }
}
.article img {
  width: 100%;
}
@media (min-width: 992px) {
  .article img {
    width: 75%;
    max-width: 660px;
    max-height: 550px;
  }
}
@media (min-width: 992px) {
  .article-content {
    background: #fff;
    margin: 20px 0;
    display: flex;
    flex-flow: column;
    width: 50%;
    padding: 20px;
    z-index: 999;
  }
  .article-content a {
    align-self: flex-start;
  }
  .article-content a.btn {
    flex-wrap: wrap;
  }
}
.article-content h2 {
  margin: 20px 0;
}
@media screen and (min-width: 992px) {
  .article-content h2 {
    font-size: 46px;
    font-size: 4.6rem;
    border-image: linear-gradient(to bottom, #CF2F44, #8f191c) 1 100%;
    border-style: solid;
    border-width: 3px;
    border-right: 0;
    padding-left: 2rem;
    line-height: 54px;
    margin: 25px 0;
  }
}
.article-content h2 small {
  font-size: 14px;
  font-size: 1.4rem;
}
.article-content h2 span {
  display: block;
  line-height: 1;
}
.dark .article-content h2 {
  border-image: linear-gradient(to bottom, #FFF, #FFF) 1 100%;
}
.article-content > p {
  font-size: 16px;
  font-size: 1.6rem;
  margin-bottom: 30px;
}
.article-content .blockquote span:before {
  content: "“";
}
.article-content .blockquote span:after {
  content: "”";
}
.article-content .blockquote cite {
  font-size: 14px;
  font-size: 1.4rem;
  color: #747678;
  font-weight: 100;
  font-style: normal;
  margin-left: 10px;
}
.article-content cite {
  font-size: 14px;
  font-size: 1.4rem;
  color: #747678;
  font-weight: 100;
  font-style: normal;
  margin-left: 10px;
}
.article-content .button-well {
  margin: 20px 0;
}
.article-content .button-well .btn + a {
  margin-left: 10px;
}
.video-link {
  align-items: center;
  background: transparent;
  border: 0;
  display: flex;
  margin: 10px 0;
}
.video-link:focus {
  box-shadow: 0 0 0;
  outline: none;
}
.video-link:before {
  background-image: url(../../assets/images/video-button.png);
  background-size: contain;
  content: '';
  display: inline-block;
  height: 46px;
  margin-right: 10px;
  width: 46px;
}
.video-link:hover {
  text-decoration: none;
}
.video-link.video-link-white:before {
  background-image: url(../../assets/images/video-button-white.png);
}
.img-link {
  align-items: center;
  background: transparent;
  border: 0;
  display: flex;
  margin: 10px 0;
  padding: 20px;
}
.img-link .img-link-left {
  width: 52px;
  margin-right: 20px;
}
@media screen and (min-width: 576px) {
  .img-link .img-link-left {
    width: 72px;
  }
}
@media screen and (min-width: 992px) {
  .img-link .img-link-left {
    width: 52px;
  }
}
.img-link .img-content-right {
  color: #FFFFFF;
  font-family: Overpass;
  font-size: 26px;
  font-weight: bold;
  line-height: 40px;
}
@media (min-width: 992px) {
  .img-link .img-content-right {
    font-size: 18px;
  }
}
@media (min-width: 1200px) {
  .img-link .img-content-right {
    font-size: 26px;
  }
}
.img-link:hover {
  text-decoration: none;
}
.download-link svg {
  fill: #CF2F44;
  height: 24px;
  width: 24px;
  margin-left: 10px;
}
.dark .download-link svg {
  fill: #fff;
}
.dark .download-link {
  color: #fff;
}
@media (min-width: 992px) {
  #OD003 .component:nth-of-type(even) .article,
  #OD003ALT .component:nth-of-type(even) .article,
  #OD003ALTb .component:nth-of-type(even) .article,
  #OD003 .component:nth-of-type(odd) .article,
  #OD003ALT .component:nth-of-type(odd) .article,
  #OD003ALTb .component:nth-of-type(odd) .article {
    display: flex;
    justify-content: flex-end;
  }
  #OD003 .component:nth-of-type(even) .article img,
  #OD003ALT .component:nth-of-type(even) .article img,
  #OD003ALTb .component:nth-of-type(even) .article img,
  #OD003 .component:nth-of-type(odd) .article img,
  #OD003ALT .component:nth-of-type(odd) .article img,
  #OD003ALTb .component:nth-of-type(odd) .article img {
    left: 0;
  }
}
@media (min-width: 992px) {
  #OD003 .component:nth-of-type(even) .article,
  #OD003ALT .component:nth-of-type(even) .article,
  #OD003ALTb .component:nth-of-type(even) .article {
    justify-content: flex-start;
  }
  #OD003 .component:nth-of-type(even) .article img,
  #OD003ALT .component:nth-of-type(even) .article img,
  #OD003ALTb .component:nth-of-type(even) .article img {
    left: auto;
    right: 0;
  }
}
@media (min-width: 992px) {
  .OD003 .component:nth-of-type(even) .article,
  .OD003 .component:nth-of-type(odd) .article {
    display: flex;
    justify-content: flex-end;
  }
  .OD003 .component:nth-of-type(even) .article img,
  .OD003 .component:nth-of-type(odd) .article img {
    left: 0;
  }
}
@media (min-width: 992px) {
  .OD003 .component:nth-of-type(even) .article {
    justify-content: flex-start;
  }
  .OD003 .component:nth-of-type(even) .article img {
    left: auto;
    right: 0;
  }
}
@media (min-width: 992px) {
  .PP003 .component:nth-of-type(even) .article,
  .PP003 .component:nth-of-type(odd) .article {
    display: flex;
    justify-content: flex-end;
  }
  .PP003 .component:nth-of-type(even) .article img,
  .PP003 .component:nth-of-type(odd) .article img {
    left: 0;
  }
}
@media (min-width: 992px) {
  .PP003 .component:nth-of-type(odd) .article {
    justify-content: flex-start;
  }
  .PP003 .component:nth-of-type(odd) .article img {
    left: auto;
    right: 0;
  }
}
.Re-003--home .card .card-title {
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 26px;
}
.card {
  background-color: transparent;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .card {
    margin-bottom: 0;
  }
}
.card .card-bg-img {
  background-size: cover;
  background-position: center;
  min-height: 460px;
  max-height: 460px;
  background-repeat: no-repeat;
}
.card .card-bg-img.mini-cards {
  min-height: 223px;
  max-height: 223px;
}
.card .card-meta {
  margin: 10px 0;
}
.card .card-meta .tag {
  color: #CF2F44;
  font-size: 15px;
  font-size: 1.5rem;
}
.card .card-meta .date {
  color: #BCBDBC;
  font-size: 15px;
  font-size: 1.5rem;
}
.card .card-title {
  font-size: 36px;
  font-size: 3.6rem;
  font-weight: bold;
  line-height: 33px;
}
.card .card-title.card-min-ititle {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 24px;
}
.card .card-text + a {
  margin-bottom: -10px;
}
.card.premium:before {
  font-size: 14px;
  font-size: 1.4rem;
  background-color: #00798C;
  color: #fff;
  content: ' \2605 \0020  Premium';
  left: 10px;
  padding: 5px 10px;
  position: absolute;
  top: 10px;
  z-index: 1;
}
.card.suggested:before {
  font-size: 14px;
  font-size: 1.4rem;
  background-color: #8F191C;
  color: #fff;
  content: 'Suggested';
  left: 0;
  padding: 5px 10px;
  position: absolute;
  top: 10px;
  z-index: 1;
}
.card .stretched-link.card-main-link {
  font-size: 18px;
  color: black;
}
.card.card-signup .card-text {
  margin-bottom: 30px;
}
.card.card-signup .card-text + a {
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .Re-003 .col-md-3 .card {
    flex-direction: row;
  }
  .Re-003 .col-md-3 .card .card-body,
  .Re-003 .col-md-3 .card img {
    width: 50%;
  }
  .Re-003 .col-md-3 .card img + .card-body {
    margin-left: 10px;
  }
}
.tech-doc {
  display: flex;
  margin-bottom: 30px;
}
.tech-doc .title {
  font-size: 14px;
  font-size: 1.4rem;
  color: #BCBDBC;
  line-height: 18px;
}
.tech-doc .download {
  font-size: 14px;
  font-size: 1.4rem;
  background: linear-gradient(to bottom, #cf2f44 0%, #8f191c 100%);
  color: #fff;
  font-weight: bold;
  line-height: 18px;
}
.tech-doc .download svg {
  fill: #fff;
  height: 24px;
  width: 24px;
  margin: 30px auto 0;
}
.tech-doc .versions p {
  font-size: 14px;
  font-size: 1.4rem;
  color: #76A95A;
  margin-bottom: 0;
}
.tech-doc .versions a {
  font-size: 12px;
  font-size: 1.2rem;
  color: #CF2F44;
  line-height: 15px;
}
.tech-doc .versions a:after {
  width: 12px;
  height: 7px;
}
.component {
  margin-bottom: 50px;
}
@media screen and (min-width: 992px) {
  .component {
    margin-bottom: 90px;
  }
  .solutions .component {
    margin-bottom: 50px;
  }
}
.component.HP-001,
.component.SOL-002,
.component.ReCP-005 {
  margin-bottom: 35px;
}
.component.OD-001 {
  margin-bottom: 60px;
}
@media screen and (min-width: 992px) {
  .component.OD-001 {
    margin-bottom: 10px;
  }
}
.component.OD-002 {
  margin-bottom: 0;
}
.component.with-quote {
  margin-bottom: 45px;
}
.btn-primary:focus {
  box-shadow: none;
}
.custom-radio .custom-control-indicator {
  border-radius: 50%;
  border-width: 4px;
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}
.custom-control {
  cursor: pointer;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 16px;
  min-height: 12px;
  padding-left: 24px;
  position: relative;
  margin-bottom: 0;
  font-size: 1.8rem;
  color: #000;
  line-height: 2rem;
  font-weight: bold;
}
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
}
.custom-control-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
}
.custom-control-indicator {
  position: absolute;
  background-color: #f2f2f2;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: block;
  height: 16px;
  left: 0;
  pointer-events: none;
  top: 1px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 16px;
  border-color: #666;
  border-style: solid;
  border-width: 2px;
  transition: all 0.3s ease;
  transform: translateZ(0);
}
.custom-radio .custom-control-indicator {
  border-radius: 50%;
  border-width: 2px;
}
.custom-control-input:checked ~ .custom-control-indicator {
  background: #CF2F44;
  border-color: #CF2F44;
  box-shadow: 0px 0px 5px 2px #CF2F45;
  color: #fff;
}
.custom-control-input:checked ~ .custom-control-indicator:after {
  display: block;
  clear: both;
  content: "";
  top: 3px;
  left: 3px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: white;
  position: absolute;
}
.container .component.article-hide {
  display: none;
}
@media (min-width: 992px) {
  .container .component.article-hide {
    display: none;
  }
}
.container .component.article-display {
  display: block;
}
.container .component.article-display .article {
  display: block;
}
@media (min-width: 992px) {
  .container .component.article-display .article {
    display: flex;
  }
}
.contact-modal {
  margin-top: 95px;
}
@media (min-width: 1200px) {
  .contact-modal {
    margin-top: 0;
  }
}
.contact-modal h2,
.contact-modal h5 {
  color: #fff;
}
.contact-modal .form-group {
  justify-content: flex-start;
  flex-direction: row;
  display: flex;
}
.contact-modal .shadow-custom {
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.15), 3px 28px 0px -9px rgba(255, 255, 255, 0.2);
}
.CONT-001 {
  padding: 20px;
}
.CONT-001 .nav.nav-tabs {
  border-bottom: 3px solid #bcbdbc;
}
.CONT-001 .nav.nav-tabs .nav-item {
  margin-bottom: -3px;
  padding: 0 20px;
}
.CONT-001 .nav.nav-tabs .nav-link {
  color: #000;
  font-weight: bold;
}
.CONT-001 .nav.nav-tabs .nav-link:hover {
  border-right: 0;
  border-left: 0;
  border-top: 0;
  border-bottom: 3px;
}
.CONT-001 .nav.nav-tabs .nav-link.active {
  border-bottom: 3px solid #CF2F44;
  color: #000;
  border-right: 0;
  border-left: 0;
  border-top: 0;
}
.CONT-001 .country-title {
  color: #bcbdbc;
  margin-top: 30px;
  margin-bottom: 10px;
}
.CONT-001 .contact__country {
  color: #bcbdbc;
  font-size: 1.6rem;
  font-weight: 500;
  margin: 30px;
}
.CONT-001 .contact {
  padding: 20px 0;
}
.CONT-001 .contact__times {
  color: #bcbdbc;
  font-size: 1.2rem;
}
.CONT-001 .contact__title {
  font-weight: bold;
  margin-top: 10px;
  font-size: 2rem;
}
.CONT-001 .contact__number {
  margin-top: 10px;
  font-weight: bold;
  margin-bottom: 0;
  font-size: 1.6rem;
  padding-left: 25px;
}
.CONT-001 .contact__number:before {
  content: "\2713";
  color: #CF2F44;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1.2em;
  font-size: 1.6rem;
}
.CONT-001 .contact__email {
  margin-top: 10px;
  font-weight: bold;
  overflow-wrap: break-word;
  word-wrap: break-word;
  font-size: 1.4rem;
  padding-left: 25px;
}
.CONT-001 .contact__email:before {
  content: "\2713";
  color: #CF2F44;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1.2em;
  font-size: 1.6rem;
}
.CONT-001 .contact__address {
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: bold;
  margin-top: 20px;
}
.CONT-001 .contact__mobile {
  display: none;
}
.CONT-001 .message-box {
  padding: 20px;
}
.CONT-001 .message-box__input-container {
  margin-bottom: 20px;
}
.CONT-001 .message-box__title {
  font-size: 2rem;
  font-weight: bold;
}
.CONT-001 .message-box__label {
  font-weight: bold;
  margin: 10px 0;
  font-size: 1.6rem;
  color: #000;
}
.CONT-001 .message-box__input-label {
  font-weight: bold;
  font-size: 1.4rem;
}
.CONT-001 .nav-tabs-dropdown {
  display: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.CONT-001 .nav-tabs-dropdown:before {
  content: "\f0d7";
  font-family: 'fontAwesome';
  position: absolute;
  right: 30px;
}
@media screen and (min-width: 1200px) {
  .CONT-001 #nav-tabs-wrapper {
    display: flex!important;
  }
}
@media screen and (max-width: 1199px) {
  .CONT-001 .nav-tabs-dropdown {
    display: flex;
    background: linear-gradient(90deg, #CF2F44 0, #8F191C 100%);
    color: #fff;
  }
  .CONT-001 .nav-tabs-dropdown:hover {
    background: linear-gradient(90deg, #CF2F44 0, #8F191C 100%);
    color: #fff;
  }
  .CONT-001 #nav-tabs-wrapper {
    display: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    text-align: center;
  }
  .CONT-001 #nav-tabs-wrapper .nav-link.active {
    background: none;
    border: 0;
  }
  .CONT-001 .nav-tabs-horizontal {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  }
  .CONT-001 .nav-tabs-horizontal > li {
    float: none;
  }
  .CONT-001 .nav-tabs-horizontal > li + li {
    margin-left: 2px;
  }
  .CONT-001 .nav-tabs-horizontal > li,
  .CONT-001 .nav-tabs-horizontal > li > a {
    background: transparent;
    width: 100%;
  }
  .CONT-001 .nav-tabs-horizontal > li > a {
    border-radius: 4px;
  }
  .CONT-001 .nav-tabs-horizontal > li.active > a,
  .CONT-001 .nav-tabs-horizontal > li.active > a:hover,
  .CONT-001 .nav-tabs-horizontal > li.active > a:focus {
    color: #ffffff;
    background-color: #428bca;
  }
}
@media screen and (max-width: 599px) {
  .CONT-001 .form-row {
    flex-direction: column;
  }
  .CONT-001 .contact__mobile {
    display: block;
  }
  .CONT-001 .contact__mobile a {
    position: relative;
    padding-left: 35px;
    margin-right: 10px;
  }
  .CONT-001 .contact__mobile a i {
    margin-left: -15px;
  }
  .CONT-001 .contact__email {
    display: none;
  }
  .CONT-001 .contact__number {
    display: none;
  }
}
/*
.custom-form {

  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  max-width: 360px;
  margin: 40px auto 40px;
  background: #fff;
  padding: 40px;
  border-radius: 4px;
  .btn-primary {
    background-color: #8e44ad;
    border-color: #8e44ad;
  }
  .form-group {
    position: relative;
    padding-top: 16px;
    margin-bottom: 16px;
    .animated-label {
      position: absolute;
      top: 20px;
      left: 0;
      bottom: 0;
      z-index: 2;
      width: 100%;
      font-weight: 300;
      opacity: 0.5;
      cursor: text;
      transition: 0.2s ease all;
      margin: 0;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 45%;
        height: 2px;
        width: 10px;
        visibility: hidden;
        background-color:#8e44ad;
        transition: 0.2s ease all;
      }
    }
    &.not-empty {
      .animated-label {
        top: 0;
        font-size: 12px;
      }
    }
    .form-control {
      position: relative;
      z-index: 1;
      border-radius: 0;
      border-width: 0 0 1px;
      border-bottom-color: rgba(0,0,0,0.25);
      height: auto;
      padding: 3px 0 5px;
      &:focus {
        box-shadow: none;
        border-bottom-color: rgba(0,0,0,0.12);
        ~ .animated-label {
          top: 0;
          opacity: 1;
          color: #8e44ad;
          font-size: 12px;
          &:after {
            visibility: visible;
            width: 100%;
            left: 0;
          }
        }
      }
    }
  }
}



.custom-form {

  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  max-width: 360px;
  margin: 40px auto 40px;
  background: #fff;
  padding: 40px;
  border-radius: 4px;
  .btn-primary {
    background-color: #8e44ad;
    border-color: #8e44ad;
  }
  .form-group {
    position: relative;
    padding-top: 16px;
    margin-bottom: 16px;
    .animated-label {
      position: absolute;
      top: 20px;
      left: 0;
      bottom: 0;
      z-index: 2;
      width: 100%;
      font-weight: 300;
      opacity: 0.5;
      cursor: text;
      transition: 0.2s ease all;
      margin: 0;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 45%;
        height: 2px;
        width: 10px;
        visibility: hidden;
        background-color:#8e44ad;
        transition: 0.2s ease all;
      }
    }
    &.not-empty {
      .animated-label {
        top: 0;
        font-size: 12px;
      }
    }
    .form-control {
      position: relative;
      z-index: 1;
      border-radius: 0;
      border-width: 0 0 1px;
      border-bottom-color: rgba(0,0,0,0.25);
      height: auto;
      padding: 3px 0 5px;
      &:focus {
        box-shadow: none;
        border-bottom-color: rgba(0,0,0,0.12);
        ~ .animated-label {
          top: 0;
          opacity: 1;
          color: #8e44ad;
          font-size: 12px;
          &:after {
            visibility: visible;
            width: 100%;
            left: 0;
          }
        }
      }
    }
  }
}
 */
.btn-outline {
  color: #fff;
  border: 1px solid #bcbdbc;
}
.btn-outline:disabled {
  background: transparent;
  color: #bcbdbc;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.btn-outline:disabled:hover,
.btn-outline:disabled:active,
.btn-outline:disabled.active,
.btn-outline:disabled:focus {
  color: #bcbdbc;
}
.btn-outline:hover,
.btn-outline:active,
.btn-outline.active,
.btn-outline:focus {
  color: #eee;
}
.btn-outline {
  background: #bcbdbc;
}
.CONT-001 {
  margin-bottom: 80px;
}
@media screen and (min-width: 1200px) {
  .CONT-001 {
    margin-bottom: 0px;
  }
}
.OD-001 {
  background-size: cover;
  background-position: center;
  width: 100%;
  display: block;
}
@media (min-width: 768px) {
  .OD-001 {
    height: 600px;
  }
}
.OD-001.darkBG {
  color: #fff;
}
.OD-001.darkBG .container .content p {
  border-image: linear-gradient(to bottom, #fff, #fff) 1 100%;
}
.OD-001 .container {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  height: 100%;
}
.OD-001 .container .content {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  justify-content: center;
}
.OD-001 .container .content h1 {
  font-size: 76px;
  font-size: 7.6rem;
  font-family: 'Calluna', sans-serif;
  line-height: 76px;
  margin: 48px 0 48px;
}
.OD-001 .container .content p {
  font-size: 18px;
  font-size: 1.8rem;
  border-width: 4px;
  border-style: solid;
  border-image: linear-gradient(to bottom, #CF2F44, #8f191c) 1 100%;
  border-right: 0;
  line-height: 33px;
  padding-left: 20px;
  max-width: 550px;
}
@media (min-width: 768px) {
  .OD-001 .OD-002 {
    display: flex;
    flex-flow: wrap;
    margin-top: -108px;
    padding: 20px;
    width: 70%;
    min-width: 640px;
  }
}
.OD-002 {
  display: block;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .OD-002 {
    background: #fff;
    color: #8F191C;
  }
}
.OD-002.hero-toggle .content {
  display: block;
}
.OD-002.hero-toggle h3 {
  font-size: 14px;
  font-size: 1.4rem;
  color: #CF2F44;
  font-weight: bold;
  padding: 10px 0;
  width: 100%;
  text-align: center;
  background: #fff;
}
@media (min-width: 768px) {
  .OD-002.hero-toggle h3 {
    text-align: left;
  }
}
.OD-002.hero-toggle .form-check {
  margin-bottom: 20px;
}
.OD-002.hero-toggle .form-check .form-check-label {
  font-size: 18px;
  font-size: 1.8rem;
  color: #000;
}
.OD-002 .select-box {
  font-size: 16px;
  font-size: 1.6rem;
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;
  color: #fff;
}
.OD-002 .select-box__current {
  position: relative;
  box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);
  cursor: pointer;
  outline: none;
}
.OD-002 .select-box__current:focus + .select-box__list {
  opacity: 1;
  animation-name: none;
}
.OD-002 .select-box__current:focus + .select-box__list .select-box__option {
  cursor: pointer;
}
.OD-002 .select-box__current:focus .select-box__icon {
  transform: translateY(-50%) rotate(180deg);
}
.OD-002 .select-box__icon {
  fill: #fff;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 20px;
  transition: 0.2s ease;
}
.OD-002 .select-box__value {
  display: flex;
}
.OD-002 .select-box__input {
  display: none;
}
.OD-002 .select-box__input.active + .select-box__input-text {
  display: block;
}
.OD-002 .select-box__input:checked + .select-box__input-text {
  display: block;
}
.OD-002 .select-box__input-text {
  display: none;
  width: 100%;
  margin: 0;
  padding: 15px;
  background-color: #cf2f44;
  background-image: linear-gradient(90deg, #cf2f44, #8f191c);
}
.OD-002 .select-box__list {
  background-color: #cf2f44;
  background-image: linear-gradient(90deg, #cf2f44, #8f191c);
  position: absolute;
  width: 100%;
  padding: 0;
  list-style: none;
  opacity: 0;
  z-index: 999;
  animation-name: HideList;
  animation-duration: 0.5s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: step-start;
  box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);
}
.OD-002 .select-box__option {
  display: block;
  padding: 15px;
  background-color: #cf2f44;
  background-image: linear-gradient(90deg, #cf2f44, #8f191c);
}
.OD-002 .select-box__option:hover,
.OD-002 .select-box__option:focus {
  color: #fff;
  background-color: #cf2f44;
}
@keyframes HideList {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(0);
  }
}
.OD-003 {
  position: relative;
}
@media (min-width: 768px) {
  .OD-003 {
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
  }
  .OD-003 img {
    position: absolute;
    z-index: 1;
  }
}
.OD-003 img {
  width: 100%;
}
@media (min-width: 768px) {
  .OD-003 img {
    width: 60%;
  }
}
@media (min-width: 768px) {
  .OD-003 .content {
    background: #fff;
    margin: 20px 0;
    display: flex;
    flex-flow: column;
    width: 50%;
    padding: 20px;
    z-index: 999;
  }
  .OD-003 .content .btn {
    align-self: flex-start;
  }
}
.OD-003 .content h2 {
  font-size: 46px;
  font-size: 4.6rem;
  border-image: linear-gradient(to bottom, #CF2F44, #8f191c) 1 100%;
  border-style: solid;
  border-width: 3px;
  border-right: 0;
  padding-left: 2rem;
  line-height: 54px;
  margin: 50px 0;
}
.OD-003 .content p {
  font-size: 18px;
  font-size: 1.8rem;
  margin-bottom: 50px;
}
.OD-003.right img {
  left: 0;
}
@media (min-width: 768px) {
  .OD-003.left {
    justify-content: flex-start;
    background-position: right;
  }
  .OD-003.left img {
    right: 0;
  }
}
.OD-003 .blockquote span:before {
  content: "“";
}
.OD-003 .blockquote span:after {
  content: "”";
}
.OD-003 .blockquote cite {
  font-size: 14px;
  font-size: 1.4rem;
  color: #747678;
  font-weight: 100;
  font-style: normal;
  margin-left: 10px;
}
.support-innovations {
  padding-top: 150px;
}
.support-innovations .blockquote > span {
  padding: 20px 0;
  display: block;
}
.support-innovations .button-link {
  padding: 16px 20px 14px 20px;
  border: 1px solid #CF2F44;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
}
.support-innovations .button-link:hover {
  background-color: #CF2F44;
  color: white;
}
.support-innovations .component:first-child .button-well {
  padding-left: 2rem;
}
.support-innovations .article-content {
  width: 55%;
}
.support-innovations .card-bg-img {
  width: 60%;
  height: 550px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
}
.support-innovations .component:first-child {
  margin-bottom: 40px;
}
.support-innovations .component:first-child .support-title {
  margin-top: 0;
}
.support-innovations .component:first-child .card-bg-img {
  position: relative;
  background-size: contain;
  width: 45%;
}
.support-innovations .component + .component .article-content {
  padding-right: 70px;
}
.support-innovations .component + .component .card-bg-img {
  left: auto;
  right: 0;
}
.support-innovations .services-title p {
  margin-bottom: 0;
  display: flex;
  align-items: flex-end;
}
.support-innovations .services-title p:first-child {
  margin-bottom: 20px;
}
.support-innovations .services-title p span:first-child {
  font-size: 46px !important;
  font-size: 4.6rem !important;
  line-height: 30px;
  font-weight: bold;
  display: block;
}
.support-innovations .services-title p span:last-child {
  font-size: 14px !important;
  font-size: 1.4rem !important;
  line-height: 20px;
  margin-left: 10px;
  display: block;
}
.services-title p {
  margin-bottom: 0;
  display: flex;
  align-items: flex-end;
}
.services-title p:first-child {
  margin-bottom: 20px;
}
.services-title p span:first-child {
  font-size: 46px !important;
  font-size: 4.6rem !important;
  line-height: 30px;
  font-weight: bold;
  display: block;
}
.services-title p span:last-child {
  font-size: 14px !important;
  font-size: 1.4rem !important;
  line-height: 20px;
  margin-left: 10px;
  display: block;
}
.services-block {
  margin-bottom: 90px;
}
.services-block h2 {
  text-align: center;
  font-size: 46px;
  font-size: 4.6rem;
  line-height: 54px;
  font-weight: bold;
  margin-bottom: 60px;
}
.services-team-help .PP-014 {
  padding-top: 60px;
  padding-bottom: 120px;
  border-top: 5px solid #F4F4F4;
}
.support-title {
  font-size: 66px !important;
  font-size: 6.6rem !important;
  line-height: 80px;
  font-style: normal;
  font-weight: 300;
  background: none !important;
  border: none !important;
  margin-bottom: 10px !important;
  font-family: 'Calluna', sans-serif;
}
.support-subtitle {
  font-size: 26px !important;
  font-size: 2.6rem !important;
  line-height: 40px;
  font-weight: bold;
  padding-left: 2rem;
}
.support-text {
  font-size: 18px !important;
  font-size: 1.8rem !important;
  line-height: 22px;
  margin-top: 33px;
  padding-left: 2rem;
}
.support-slider-block h2 {
  margin-bottom: 50px !important;
}
.support-slider-block p {
  margin-bottom: 30px !important;
}
.support-slider-block .button-well {
  margin: 0 !important;
}
.support-slider-block .item-block:nth-child(even) .container {
  justify-content: flex-start !important;
}
.support-comments h3 {
  font-size: 46px !important;
  font-size: 4.6rem !important;
  line-height: 34px;
  font-weight: bold;
  margin-bottom: 90px !important;
}
.support-comments p {
  position: relative;
  max-width: 500px;
  width: 100%;
}
.support-comments p span:before {
  content: '“';
  color: #8F191C;
  position: absolute;
  left: -15px !important;
  font-size: 42px;
  font-size: 4.2rem;
  top: 5px !important;
}
.support-download.container {
  padding-left: 100px !important;
  padding-right: 100px !important;
}
.assay-menu-main h2 {
  font-size: 60px !important;
  font-size: 6rem !important;
}
.assay-menu-main p {
  line-height: 30px;
}
.assay-menu-components {
  background-color: #F8F8F8;
}
.assay-menu-components {
  position: relative;
}
.assay-menu-components .article-content {
  padding-left: 0 !important;
}
.assay-menu-components .blockquote p {
  padding: 50px 0 40px 0;
  font-size: 26px !important;
  font-size: 2.6rem !important;
  line-height: 34px;
  font-weight: bold;
}
.assay-menu-components .assay-menu-components-title {
  display: flex;
  align-items: center;
}
.assay-menu-components .assay-menu-components-title p {
  color: #8F191C;
}
.assay-menu-components .assay-menu-components-title p span:first-child {
  font-size: 36px;
  font-size: 3.6rem;
  font-weight: bold;
}
.assay-menu-components .assay-menu-components-title p span:last-child {
  font-size: 14px;
  font-size: 1.4rem;
}
.assay-menu-components .assay-menu-components-title p + p {
  margin-left: 30px;
}
.assay-menu-components #HP004 {
  min-height: 550px;
  display: flex;
  align-items: center;
  background: linear-gradient(to right, #F8F8F8 50%, rgba(0, 0, 0, 0));
}
.assay-menu-components .article {
  position: unset;
}
.assay-menu-components .card-bg-img {
  width: 50%;
  height: 550px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  left: auto;
  top: 0;
}
.assay-menu-components .card-bg-img:before {
  position: absolute;
  content: '';
  top: 0;
  left: -10px;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #F8F8F8 7%, rgba(0, 0, 0, 0) 30%);
}
.product-image .component {
  display: none;
}
.product-image .component:nth-child(odd) .article {
  justify-content: flex-start !important;
}
.assay-menu-table {
  margin-top: 90px;
}
.assay-menu-table .appendix svg {
  margin-top: -5px;
}
.assay-menu-table .PP-012 .assays p {
  line-height: 20px;
  transition: 0.3s;
}
.assay-menu-table .assay-block {
  margin-bottom: 20px;
  cursor: pointer;
}
.assay-menu-table .assay-block:hover p {
  color: #CF2F44;
}
.assay-menu-table .img-fluid {
  width: 100px;
  height: 60px;
}
.assay-menu-table .table-main {
  padding-bottom: 20px;
}
.assay-menu-table .table-main p {
  font-size: 12px !important;
  font-size: 1.2rem !important;
  line-height: 18px;
  font-weight: normal;
}
.assay-table-tr {
  display: flex;
  align-items: center;
}
.assay-table-tr .text-left {
  width: 28%;
}
.assay-table-tr .text-left p {
  margin-bottom: 0;
  font-size: 18px !important;
  font-size: 1.8rem !important;
  line-height: 30px;
  width: 190px;
  font-weight: bold;
}
.assay-table-tr .align-top {
  width: 12%;
  padding: 5px;
  text-align: center;
}
.assay-table-tr .align-top p {
  font-size: 12px !important;
  font-size: 1.2rem !important;
  line-height: 18px;
  font-weight: normal;
  text-align: center;
}
.assay-menu-table .appendix {
  padding: 20px 30px !important;
}
.assay-table-thead {
  padding: 20px 30px;
}
.assay-table-tbody {
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}
.assay-table-tbody .assay-table-tr {
  padding: 5px 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.assay-table-tbody .assay-table-td {
  display: flex;
  justify-content: center;
  text-align: center;
}
.assay-table-tbody .assay-table-td.text-left {
  text-align: left;
  justify-content: flex-start;
}
.assay-table-tbody .assay-table-td svg {
  width: 16px !important;
  height: 16px !important;
  justify-content: center!important;
}
.assay-menu-run {
  margin-top: 90px;
  position: relative;
}
.assay-menu-run .article {
  position: unset;
}
.assay-menu-run .component {
  height: 550px;
  display: flex;
  align-items: center;
}
.assay-menu-run .card-bg-img {
  width: 60%;
  height: 550px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  left: auto;
  right: 0;
  top: 0;
}
.assay-menu-run .button-well a {
  padding: 16px 20px 14px 20px;
  border: 1px solid #CF2F44;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
}
.assay-menu-run .button-well a:hover {
  background-color: #CF2F44;
  color: white;
}
.assay-table-block {
  display: none;
}
.active-assay {
  display: block;
}
.active-assay-link p {
  color: #CF2F44 !important;
}
@media (max-width: 1200px) {
  .support-innovations {
    padding-top: 60px;
  }
  .support-innovations .component {
    margin-bottom: 55px !important;
  }
  .support-download.container {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .assay-menu-components-title p {
    line-height: 20px !important;
  }
}
@media (max-width: 991px) {
  .support-innovations .component + .component .article-content {
    padding-right: 0;
  }
  .services-team-help .PP-014 {
    padding-bottom: 60px;
  }
  .support-innovations .component:first-child .card-bg-img,
  .support-innovations .article-content {
    width: 100%;
  }
  .support-innovations .card-bg-img {
    position: relative;
    width: 100%;
    height: 420px;
  }
  .support-innovations .article img {
    height: 400px;
  }
  .support-title,
  .support-subtitle,
  .support-text,
  .support-innovations .component:first-child .button-well {
    padding-left: 0 !important;
  }
  .support-download .download-link__text {
    width: 80%;
  }
  .assay-menu-components .card-bg-img {
    position: relative;
    width: 100%;
  }
  .assay-menu-components .card-bg-img:before {
    background: linear-gradient(to right, #F8F8F8 7%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 70%, #F8F8F8 93%);
    left: 0;
  }
  .assay-menu-components .assay-menu-components-title {
    margin-top: 25px;
  }
  .assay-menu-components .blockquote p {
    padding: 30px 0 20px 0 ;
  }
  .assay-menu-table {
    margin-top: 50px;
  }
  .PP-012 .assays .assay-block {
    width: 20% !important;
  }
  .assay-menu-table .table-responsive-md {
    width: 100%;
    overflow-x: auto !important;
  }
  .assay-menu-table .table-responsive-md .assay-table-block {
    width: 1000px;
  }
  .assay-menu-run .card-bg-img {
    width: 100%;
    position: relative;
  }
  .assay-menu-run .component {
    height: auto;
  }
}
@media (max-width: 767px) {
  .support-innovations .card-bg-img {
    height: 300px;
  }
  .support-comments p span {
    padding-left: 15px;
  }
  .support-comments p span:before {
    top: 15px !important;
    left: 4px !important;
  }
  .services-team-help .PP-014 {
    padding-bottom: 30px;
  }
  .services-title p span:first-child {
    font-size: 24px !important;
    font-size: 2.4rem !important;
  }
  .services-title p:first-child {
    margin-bottom: 0;
  }
  .support-innovations .article img {
    height: 176px;
  }
  .support-innovations {
    padding-top: 30px;
  }
  .support-title,
  .services-block h2 {
    font-size: 35px !important;
    font-size: 3.5rem !important;
    margin-top: 20px !important;
    line-height: 30px !important;
  }
  .support-subtitle {
    font-size: 18px !important;
    font-size: 1.8rem !important;
    line-height: 22px !important;
  }
  .support-text {
    font-size: 14px !important;
    font-size: 1.4rem !important;
    line-height: 22px !important;
    margin-top: 20px !important;
    margin-bottom: 30px !important;
  }
  .article-title {
    font-size: 26px !important;
    font-size: 2.6rem !important;
    line-height: 30px !important;
    margin-top: 20px !important;
    margin-bottom: 25px !important;
  }
  .article-quote {
    font-size: 18px !important;
    font-size: 1.8rem !important;
    line-height: 22px !important;
  }
  .item-block {
    position: relative;
  }
  .item-block::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
  }
  .item-block .container {
    z-index: 99;
  }
  .support-slider-block .slick-dots {
    bottom: -45px;
  }
  .support-comments h3 {
    margin-top: 50px !important;
    margin-bottom: 30px !important;
    font-size: 24px !important;
    font-size: 2.4rem !important;
  }
  .support-comments p {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 22px;
    display: flex;
    flex-direction: column;
  }
  .support-comments .slick-dots {
    bottom: -35px;
  }
  .support-comments p cite {
    margin-left: 0 !important;
    margin-top: 10px !important;
  }
  .support-download {
    margin-top: 90px;
  }
  .support-download.container {
    padding-top: 20px !important;
  }
  .support-download .download-link__text {
    font-size: 14px !important;
    font-size: 1.4rem !important;
    line-height: 22px;
  }
  .support-comments p {
    max-width: 100%;
  }
  .support-comments p span {
    padding-left: 35px;
  }
  .services-block {
    margin-bottom: 50px;
  }
  .assay-menu-main .support-title {
    line-height: 55px !important;
    font-size: 35px !important;
    font-size: 3.5rem !important;
  }
  .assay-menu-components .blockquote p {
    font-size: 20px !important;
    font-size: 2rem !important;
    line-height: 25px;
    padding-top: 10px !important;
  }
  .assay-menu-table {
    margin-top: 20px;
  }
  .assay-menu-components .card-bg-img,
  .assay-menu-run .card-bg-img {
    height: 350px;
  }
  .support-comments p cite {
    padding-left: 35px !important;
  }
  .PP-012 .appendix p svg {
    margin-right: 10px;
  }
  .PP-012 .appendix p {
    line-height: 20px;
  }
  .active-assay-link {
    color: black !important;
  }
}
@media (max-width: 576px) {
  .support-innovations .card-bg-img {
    height: 188px;
  }
  .services-block h2 {
    margin-bottom: 30px;
  }
  .assay-menu-main .support-title,
  .assay-menu-run h2 {
    font-size: 26px !important;
    font-size: 2.6rem !important;
    line-height: 38px !important;
  }
  .assay-menu-components .card-bg-img,
  .assay-menu-run .card-bg-img {
    height: 180px;
  }
  .assay-menu-components .assay-menu-components-title {
    flex-direction: column;
    align-items: flex-start;
  }
  .assay-menu-components .assay-menu-components-title p {
    display: flex;
  }
  .assay-menu-components .assay-menu-components-title p span + span {
    margin-left: 10px;
  }
  .assay-menu-components .assay-menu-components-title p + p {
    margin-left: 0;
  }
  .assay-menu-components .blockquote p {
    font-size: 16px !important;
    font-size: 1.6rem !important;
    line-height: 20px;
  }
  .assay-menu-table .PP-012 .assays {
    margin: 20px 0;
  }
  .assay-menu-run {
    margin-top: 0;
  }
  .assay-menu-run h2 {
    margin: 20px 0 20px 0;
    line-height: 30px !important;
  }
  .assay-menu-run p {
    font-size: 14px !important;
    font-size: 1.4rem !important;
    line-height: 22px;
  }
}
.request-call {
  background-image: none;
  background-color: #cf2f44;
  background: linear-gradient(to right, #ba2a3d, #8f191c);
  color: #ffffff;
}
.request-call__container {
  padding: 30px 20px;
  min-height: 200px;
  display: flex;
  justify-content: center;
}
.request-call__container .square-link-white {
  padding: 1.4rem;
}
.request-call__intro-text {
  margin-bottom: 20px;
  margin-top: 20px;
  margin-right: 20px;
  font-size: 3.2rem;
  text-align: center;
}
.request-call-text {
  margin-bottom: 20px;
  margin-top: 20px;
  margin-right: 20px;
  font-size: 1.8rem;
  line-height: 28px;
}
.request-call-number {
  margin-bottom: 20px;
  margin-top: 20px;
  margin-right: 20px;
  font-size: 3.6rem;
  line-height: 34px;
}
.request-call__btn {
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
}
@media (min-width: 400px) {
  .request-call__btn {
    width: auto;
  }
}
.square-link-red {
  color: #cf2f44;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  border: 1px solid #cf2f44;
  padding: 0.8rem 1.4rem;
  text-decoration: none;
  background-color: transparent;
  @include transition(0.4s ease-in-out);
}
.square-link-red:hover {
  background-color: #cf2f44;
  color: #ffffff;
}
p a.square-link-red {
  margin-left: 1rem;
}
.square-link-white {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  border: 1px solid white;
  padding: 1rem 1.4rem;
  text-decoration: none;
  background-color: transparent;
}
.square-link-white:hover {
  background-color: white;
  color: #cf2f44 !important;
  text-decoration: none;
}
.square-input-white {
  color: #ffffff;
  font-size: 14px;
  font-weight: 200;
  line-height: 22px;
  border: 1px solid transparent;
  padding: 0.8rem 1.4rem;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.15);
}
.square-input-white::placeholder {
  color: #ffffff;
}
.square-input-white:hover {
  background-color: #ffffff;
  color: #cf2f44;
}
@media screen and (min-width: 1200px) {
  .request-call__containerALT .col-auto-container {
    height: 100%;
  }
}
.request-call__containerALT .col-auto-container a {
  color: white;
}
.request-call__containerALT .col-auto-container a.text-nowrap svg {
  fill: white;
}
.HP-001 {
  background-image: none;
}
.HP-001.white * {
  color: white !important;
  fill: #fff !important;
}
.HP-001.white-color {
  background-repeat: no-repeat;
}
.HP-001.white-color * {
  color: white;
}
.HP-001.white-color svg {
  fill: white;
}
.HP-001.white-color h2 {
  padding-left: 15px;
  border-left: 4px solid white;
}
.HP-001.white-color p {
  border: none !important;
}
.HP-001.white-color .btn {
  background: transparent;
  border: 1px solid white;
}
.HP-001.white-color .link-arrow-down {
  color: white;
}
@media screen and (min-width: 768px) {
  .HP-001 {
    background-size: cover;
    background-position: right center;
    width: 100%;
    display: block;
    height: 600px;
  }
}
.HP-001 > span {
  display: block;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
}
@media (min-width: 768px) {
  .HP-001 > span {
    background-image: none !important;
  }
}
@media screen and (min-width: 768px) {
  .HP-001 .col {
    max-width: 55%;
  }
}
.HP-001 .container,
.HP-001 .container .row {
  height: 100%;
}
.HP-001 h1 {
  font-size: 36px;
  font-size: 3.6rem;
  font-family: 'Calluna', sans-serif;
  line-height: 36px;
  margin: 20px 0;
}
@media screen and (min-width: 768px) {
  .HP-001 h1 {
    font-size: 76px;
    font-size: 7.6rem;
    line-height: 76px;
    margin: 48px 0;
  }
}
.HP-001 p {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 22px;
  border-width: 4px;
  border-style: solid;
  border-image: linear-gradient(to bottom, #CF2F44, #8f191c) 1 100%;
  border-right: 0;
  line-height: 33px;
  margin-bottom: 40px;
  padding-left: 20px;
}
@media screen and (min-width: 768px) {
  .HP-001 p {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 30px;
  }
}
.HP-001 a.btn {
  background: #fff;
  margin-bottom: 20px;
  margin-right: 20px;
}
.HP-001 a.link-arrow-down {
  color: #000;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .HP-001 a.link-arrow-down {
    display: inline-block;
    transform: translateY(-10px);
  }
}
.HP-001.dark-bg {
  color: #fff;
}
.HP-001.dark-bg p {
  border-image: linear-gradient(to bottom, #fff, #fff) 1 100%;
}
.HP-001.overlay-bg {
  position: relative;
  background-size: cover;
  background-position: center;
}
.HP-001.overlay-bg:before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.HP-001.variation-2 h2 {
  border-left: 5px solid white;
  padding-left: 20px;
  max-width: 380px;
}
@media screen and (min-width: 768px) {
}
.HP-001.variation-2 p {
  border-left: 0;
  padding-left: 0;
  max-width: 400px;
}
.HP-001.variation-2 p b {
  font-weight: inherit;
}
.HP-001.variation-2 .btn {
  background-color: transparent;
  border: 1px solid #fff;
}
.HP-001.variation-2 .link-arrow-down {
  font-weight: inherit;
}
.HP-002 .filter-block {
  background-color: #f8f8f8;
  margin-bottom: 60px;
  margin-top: 60px;
  padding: 20px 0 40px;
}
@media screen and (min-width: 768px) {
  .HP-002 .card-group .card {
    border-left: 1px solid grey !important;
  }
  .HP-002 .card-group .card.active,
  .HP-002 .card-group .card:first-of-type {
    border-left: 0 !important;
  }
}
@media screen and (min-width: 992px) {
  .HP-002 h2 {
    font-weight: bold;
    line-height: 54px;
    margin-top: 30px;
    margin-bottom: 40px;
    font-size: 46px;
    font-size: 4.6rem;
  }
}
.HP-002 p {
  max-width: 760px;
  margin: 10px auto;
}
.HP-002 .select {
  margin-bottom: 3px;
}
.HP-002 .select select {
  background: -webkit-gradient(linear, left top, right top, color-stop(0, #CF2F44), to(#8F191C));
  background: linear-gradient(90deg, #CF2F44 0, #8F191C 100%);
  color: #fff;
}
.HP-002 .select select option {
  background-color: #747678;
}
.HP-002 .select:after {
  background: transparent;
}
.HP-002 .card {
  padding: 50px 20px 10px;
  transition: all 0.2s;
  margin-bottom: 0;
  width: 100%;
}
.HP-002 .card:before {
  display: none;
  transition: all 0.2s;
}
.HP-002 .card svg {
  height: 24px;
  width: 24px;
  margin: 0 auto;
}
.HP-002 .card .svg-icon-link {
  fill: transparent;
}
.HP-002 .card .card-title {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 24px;
}
.HP-002 .card.active {
  color: #fff;
  background-color: #cf2f44;
  background-image: linear-gradient(90deg, #cf2f44, #8f191c);
  z-index: 1;
}
@media screen and (min-width: 768px) {
  .HP-002 .card.active {
    transform: scale(1.1);
  }
}
.HP-002 .card.active svg {
  fill: #fff;
}
.HP-002 .card.active:before {
  display: block;
}
.HP-002 .card-group .slick-dots {
  bottom: -35px;
}
.HP-004 .article-content {
  padding-left: 50px;
}
.HP-004 .article {
  position: relative;
}
@media (min-width: 992px) {
  .HP-004 .article {
    display: flex;
    overflow: hidden;
  }
  .HP-004 .article img {
    position: absolute;
    z-index: 1;
  }
}
@media (min-width: 992px) {
  .HP-004 .article:nth-of-type(even),
  .HP-004 .article:nth-of-type(odd) {
    display: flex;
    justify-content: flex-start;
  }
  .HP-004 .article:nth-of-type(even) img,
  .HP-004 .article:nth-of-type(odd) img {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .HP-004 .article + .article:nth-of-type(even) {
    justify-content: flex-end;
  }
  .HP-004 .article + .article:nth-of-type(even) img {
    right: auto;
    left: 0;
  }
}
.HP-004 .article img {
  width: 100%;
}
@media (min-width: 992px) {
  .HP-004 .article img {
    width: 75%;
  }
}
@media (min-width: 1200px) {
  .HP-004 .article img {
    width: 65%;
  }
}
.HP-004 .component {
  margin: 0 !important;
}
.HP-004 .component > img {
  right: 0;
  left: auto;
}
@media screen and (max-width: 768px) {
  .HP-004 .component > img {
    width: 100%;
  }
}
.HP-004 .component.article {
  justify-content: flex-start;
}
.HP-004 .component.reverse {
  justify-content: flex-end;
}
.HP-004 .component.reverse > img {
  right: auto;
  left: 0;
}
.HP-004 .component .button-img {
  margin-bottom: 20px;
}
.HP-004 .component .button-img img {
  position: relative;
  width: 30%;
}
.HP-005 {
  background-repeat: no-repeat;
  background-position: center 300px;
  background-size: contain;
  padding-bottom: 90px;
}
@media screen and (min-width: 768px) {
  .HP-005 {
    background-image: none !important;
    padding-bottom: 0;
  }
}
.HP-005 .break {
  height: 20px;
}
@media screen and (min-width: 768px) {
  .HP-005 .break {
    height: 30px;
  }
}
.HP-005 .content-background-img {
  background-size: 0;
}
@media screen and (min-width: 768px) {
  .HP-005 .content-background-img {
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 78% 89%;
  }
}
.HP-005 article.margin-top-auto {
  margin-top: auto;
}
.HP-005 article header {
  align-items: center;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 100%;
  padding: 32px 16px 32px 32px;
}
@media screen and (min-width: 768px) {
  .HP-005 article header {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.HP-005 article header h2 {
  font-size: 36px;
  font-size: 3.6rem;
  border-image: linear-gradient(to bottom, #CF2F44, #8f191c) 1 100%;
  border-style: solid;
  border-width: 3px;
  border-right: 0;
  padding-left: 2rem;
  max-width: 400px;
  color: #000;
  margin: 50px 0;
  font-weight: 900;
}
.HP-005 article header h2 small {
  font-size: 14px;
  font-size: 1.4rem;
}
.HP-005 article header h2 span {
  display: block;
  line-height: 1;
}
.dark .HP-005 article header h2 {
  border-image: linear-gradient(to bottom, #FFF, #FFF) 1 100%;
}
.HP-005 article div {
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
  padding: 20px;
}
.HP-005 article h3 {
  font-size: 26px;
  font-size: 2.6rem;
  font-weight: bold;
  line-height: 33px;
}
.HP-005 article p {
  display: none;
  font-size: 14px;
  font-size: 1.4rem;
  border-bottom: 2px solid #ececec;
  line-height: 18px;
  padding-bottom: 24px;
}
@media screen and (min-width: 768px) {
  .HP-005 article p {
    display: inline-block;
  }
}
@media screen and (min-width: 768px) {
  .container + .HP-005 {
    margin-top: -120px;
  }
}
.HP-015 {
  background-repeat: no-repeat;
  background-position: center 200px;
  background-size: contain;
}
@media screen and (min-width: 992px) {
  .HP-015 {
    background-image: none !important;
    padding-bottom: 0;
  }
}
.HP-015 .break {
  height: 20px;
}
@media screen and (min-width: 992px) {
  .HP-015 .break {
    height: 30px;
  }
}
.HP-015 .content-background-img {
  background-size: 0;
}
@media screen and (min-width: 992px) {
  .HP-015 .content-background-img {
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 78% 89%;
  }
}
.HP-015 article.margin-top-auto {
  margin-top: auto;
}
.HP-015 article header {
  align-items: center;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 100%;
  padding: 32px 16px 32px 32px;
}
@media screen and (min-width: 992px) {
  .HP-015 article header {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}
.HP-015 article header h2 {
  font-size: 26px;
  font-size: 2.6rem;
  border-left: 2px solid #ffffff;
  line-height: 33px;
  padding-left: 2rem;
  width: 100%;
}
@media screen and (min-width: 992px) {
  .HP-015 article header h2 {
    font-size: 48px;
    font-size: 4.8rem;
    line-height: 58px;
    width: 70%;
  }
}
.HP-015 article div {
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
  padding: 20px;
}
.HP-015 article h3 {
  font-size: 26px;
  font-size: 2.6rem;
  font-weight: bold;
  line-height: 33px;
}
.HP-015 article p {
  display: none;
  font-size: 14px;
  font-size: 1.4rem;
  border-bottom: 2px solid #ececec;
  line-height: 18px;
  padding-bottom: 24px;
}
@media screen and (min-width: 992px) {
  .HP-015 article p {
    display: inline-block;
  }
}
@media screen and (min-width: 992px) {
  .container.HP-015 {
    margin-top: -120px;
  }
}
.HP-006 {
  display: flex;
  flex-direction: column;
  margin: 50px 0;
}
@media screen and (min-width: 768px) {
  .HP-006 {
    height: 100vh;
    max-height: 900px;
  }
}
.HP-006 .container,
.HP-006 .container .row {
  height: 100%;
}
@media screen and (max-width: 767px) {
  .HP-006 {
    background-image: none !important;
  }
}
@media screen and (min-width: 768px) {
  .HP-006 {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
.HP-006 h1 {
  font-size: 36px;
  font-size: 3.6rem;
  line-height: 54px;
}
@media screen and (min-width: 992px) {
  .HP-006 h1 {
    font-size: 46px;
    font-size: 4.6rem;
    font-weight: bold;
    margin-bottom: 40px;
  }
}
.HP-006 p {
  margin-bottom: 60px;
}
.HP-006 .button-well {
  height: auto;
}
@media screen and (min-width: 768px) {
  .HP-006 .button-well {
    margin: 0 0 150px;
  }
}
.HP-006 .about-block {
  margin: 0 auto;
  max-width: 800px;
}
.HP-006 .about-block > div {
  display: block;
  width: 50%;
}
@media screen and (min-width: 768px) {
  .HP-006 .about-block > div {
    width: 25%;
  }
}
.HP-006 h2 {
  font-size: 46px;
  font-size: 4.6rem;
  color: #8F191C;
  margin: 0;
  margin-right: 5px;
}
.HP-006 small {
  font-size: 14px;
  font-size: 1.4rem;
  color: #000;
  line-height: 1;
  width: 50px;
  display: inline-block;
  text-align: left;
}
.PP-001 {
  max-width: 100%;
}
@media screen and (max-width: 767px) {
  .PP-001 .row {
    overflow-x: auto;
    flex-wrap: nowrap !important;
  }
  .PP-001 .row > .col-xs-4 {
    display: inline-block;
    float: none;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
.PP-001 .content-block > div {
  border-bottom: 2px solid #BCBDBC;
  min-height: 130px;
  margin-bottom: 10px;
  transition: 0.5s;
}
.PP-001 .content-block img {
  margin-bottom: 5px;
}
.PP-001 .content-block a {
  font-size: 12px;
  font-size: 1.2rem;
  align-self: flex-end;
  color: #BCBDBC;
  text-decoration: none;
  transition: 0.5s;
}
.PP-001 .content-block a:hover {
  color: #CF2F44;
}
.PP-001 .content-block.active > div,
.PP-001 .content-block:hover > div {
  border-bottom-color: #CF2F44;
}
.PP-001 .content-block.active a,
.PP-001 .content-block:hover a {
  color: #CF2F44;
}
.products-main .col-12 {
  overflow-y: auto;
  width: 100%;
}
.products-main.component {
  margin-bottom: 50px;
}
.products-main .img-fluid {
  width: 100px;
  height: 60px;
}
.products-main .product-menu {
  overflow-y: auto;
  display: inline-flex;
}
.products-main .active-product p {
  color: #8F191C !important;
}
.products-main .active-product p::before {
  width: 80% !important;
}
.products-main .product-components {
  width: 140px;
  position: relative;
  cursor: pointer;
  padding: 15px;
}
.products-main .product-components:hover p {
  color: #8F191C !important;
}
.products-main .product-components:hover p::before {
  width: 80% !important;
}
.products-main .product-components p {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 18px;
  color: #BCBDBC;
  transition: 0.3s;
  margin-top: 25px;
  padding-top: 15px;
  text-indent: inherit;
  position: relative;
}
.products-main .product-components p::before {
  position: absolute;
  content: '';
  width: 0;
  height: 2px;
  background: linear-gradient(308.7deg, #8F191C 0%, #CF2F44 100%);
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  transition: 0.3s;
}
.products-main .inner-line-product {
  width: 100%;
  height: 2px;
  position: absolute;
  background-color: #EAEBEA;
  top: 64%;
  transform: translateY(-50%);
}
.products-for-main.component {
  margin-top: 0;
}
.active-product-block {
  display: flex !important;
}
.product-image .card-bg-img {
  position: absolute;
  width: 60%;
  height: 540px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  right: 0;
}
@media screen and (min-width: 1200px) {
  .product-image .img-md .card-bg-img {
    height: 470px;
  }
}
.product-image .article-content {
  width: 55%;
}
.product-image .article {
  position: unset;
}
@media (max-width: 992px) {
  .product-image .card-bg-img {
    position: relative;
    width: 100%;
  }
  .products-for-main h1 {
    font-size: 36px;
    font-size: 3.6rem;
    line-height: 38px;
  }
  .product-image .article-content {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .product-image .component {
    margin-bottom: 20px;
  }
  .benefits .PP-004 h2 {
    font-size: 36px !important;
    font-size: 3.6rem !important;
    margin-bottom: 30px!important;
  }
  .benefits .slick-dots {
    bottom: -10px;
  }
  .products-main.component {
    margin-bottom: 20px;
  }
  .slider-products .image-hero {
    height: 420px !important;
    min-height: 420px !important;
  }
  .slider-products.PP-005 .image-hero h2 {
    font-size: 26px !important;
    font-size: 2.6rem !important;
    line-height: 34px !important;
  }
  .slider-products.PP-005 .image-hero {
    position: relative;
  }
  .slider-products.PP-005 .image-hero:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.46) 0%, rgba(0, 0, 0, 0) 99%);
  }
}
@media (max-width: 576px) {
  .product-image .card-bg-img {
    height: 190px;
  }
  .products-main .product-components {
    width: 100px;
    position: relative;
    cursor: pointer;
    padding: 8px;
  }
  .products-main .inner-line-product {
    top: 58.5%;
  }
  .benefits .PP-004 h2 {
    font-size: 26px !important;
    font-size: 2.6rem !important;
    line-height: 34px !important;
  }
  .slider-products.component {
    margin-bottom: 20px !important;
  }
}
.PP-002 {
  margin-top: 50px;
}
.PP-002 h1 {
  font-size: 36px;
  font-size: 3.6rem;
  line-height: inherit !important;
  font-family: 'Calluna', sans-serif;
}
@media screen and (min-width: 768px) {
  .PP-002 h1 {
    font-size: 56px;
    font-size: 5.6rem;
    line-height: inherit;
  }
}
.PP-002 h2 {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 23px;
  margin-bottom: 20px;
  font-weight: bold;
}
@media screen and (min-width: 768px) {
  .PP-002 h2 {
    font-size: 26px;
    font-size: 2.6rem;
    line-height: 33px;
  }
}
.PP-002 p {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 22px;
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .PP-002 p {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 30px;
  }
}
.PP-002alt {
  padding-top: 40px;
  padding-bottom: 40px;
  background-size: cover;
}
.PP-002alt h1 {
  font-size: 36px;
  font-size: 3.6rem;
  line-height: 4.6rem !important;
  font-family: 'Calluna', sans-serif;
  margin-bottom: 20px;
  font-weight: 600;
}
@media screen and (min-width: 768px) {
  .PP-002alt h1 {
    font-size: 48px;
    font-size: 4.8rem;
    line-height: 5.8rem !important;
    margin-top: 20px;
  }
}
.PP-002alt p {
  font-weight: 500;
}
#gradient-horizontal {
  --color-stop-1: #a770ef;
  --color-stop-2: #cf8bf3;
  --color-stop-3: #fdb99b;
}
#BoneDisease {
  fill: url(#gradient-horizontal) gray;
}
.PP-003-modal .modal-dialog {
  max-width: 800px;
  margin: 30px auto;
}
.PP-003-modal .modal-body {
  position: relative;
  padding: 0px;
}
.PP-003-modal .close {
  position: absolute;
  right: -30px;
  top: 0;
  z-index: 999;
  font-size: 2rem;
  font-weight: normal;
  color: #fff;
  opacity: 1;
}
.PP-004 h2 {
  margin-bottom: 80px;
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .PP-004 h2 {
    font-size: 26px;
    font-size: 2.6rem;
    text-align: center;
    margin: 0 auto 40px;
    line-height: 33px;
  }
}
.PP-004 h3 {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 23px;
}
.PP-004 p {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 18px;
}
.PP-004 p:last-child {
  margin-bottom: 20px;
}
.PP-004 .icon-box img {
  width: 96px;
  height: 96px;
  margin: 0 auto 20px;
}
.PP-004 .slick-dots {
  bottom: -20px;
}
.PP-005 .image-hero {
  display: flex;
  height: 50vh;
  background-position: center;
  background-size: cover;
  min-height: 600px;
  overflow: hidden;
}
.PP-005 .image-hero h2 {
  font-size: 46px;
  font-size: 4.6rem;
  border-left: 3px solid #fff;
  line-height: 58px;
  margin-bottom: 20px;
  padding-left: 20px;
}
.PP-005 .image-hero p {
  font-size: 18px;
  font-size: 1.8rem;
}
.PP-005 .image-hero a {
  font-size: 14px;
  font-size: 1.4rem;
}
.PP-005 .image-hero .container .row,
.PP-005 .image-hero .container {
  height: 100%;
}
.PP-005 .image-hero .container .row .dark {
  color: #fff;
}
.PP-005 .slider-controls .slide-dots .slick-dots {
  position: relative;
  list-style: none;
  bottom: 0;
  margin: 20px 30px;
  width: auto;
}
.PP-005 .slider-controls .slide-dots .slick-dots li {
  background-color: gray;
}
.PP-005 .slider-controls .slide-dots .slick-dots li.slick-active {
  background-color: #CF2F44;
}
.PP-005 .slider-controls .slide-next,
.PP-005 .slider-controls .slide-prev {
  display: block;
  height: 14px;
  width: 24px;
  cursor: pointer;
  background: transparent;
  fill: #CF2F44;
  padding: 0;
}
.PP-005 .slider-controls .slide-next:hover,
.PP-005 .slider-controls .slide-prev:hover,
.PP-005 .slider-controls .slide-next:focus,
.PP-005 .slider-controls .slide-prev:focus {
  fill: #e3818e;
}
.PP-006 h3 {
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .PP-006 h3 {
    font-size: 26px;
    font-size: 2.6rem;
    text-align: center;
    margin: 0 auto 40px;
    line-height: 33px;
  }
}
@media (min-width: 768px) {
  .PP-006 h3 {
    font-size: 32px !important;
    font-size: 3.2rem !important;
    line-height: 33px;
    margin-top: 40px;
    margin-bottom: 80px;
  }
}
.PP-006 .blockquote p {
  font-weight: bold;
}
@media (min-width: 768px) {
  .PP-006 .blockquote p {
    font-size: 20px;
    font-size: 2rem;
    padding-left: 15px;
    margin-top: 10px;
  }
}
@media (min-width: 768px) {
  .PP-006 .blockquote p span:before {
    content: '“';
    color: #8F191C;
    position: absolute;
    left: 0px;
    font-size: 42px;
    font-size: 4.2rem;
    top: -10px;
  }
}
.PP-006 .blockquote cite {
  color: #747678;
  margin-left: 10px;
}
@media (min-width: 768px) {
  .PP-006 .blockquote cite {
    font-size: 14px;
    font-size: 1.4rem;
  }
}
.PP-006 .slick-dots {
  bottom: -20px;
}
.PP-007 {
  background-color: #f8f8f8;
  padding-top: 60px;
  padding-bottom: 60px;
}
@media screen and (min-width: 768px) {
  .PP-007 {
    margin-bottom: 90px;
  }
}
.PP-007 h3 {
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 34px;
  font-weight: bold;
}
.PP-007 p {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 30px;
}
.PP-007 .download-link {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid #b3b3b3;
}
.PP-007 .download-link__text {
  margin-bottom: 0;
}
.PP-007 svg {
  fill: #CF2F44;
  height: 20px;
  width: 20px;
}
.PP-007 .PP-008 {
  margin-top: 60px;
}
.PP-009 .article-content .blockquote cite {
  display: block;
  margin-top: 30px;
}
.PP-010 .item-block {
  background-position: center;
  background-size: cover;
  margin-bottom: 0;
  height: 360px;
}
@media screen and (min-width: 768px) {
  .PP-010 .item-block {
    height: 600px;
  }
}
.PP-010:nth-of-type(odd) .container {
  justify-content: flex-end;
}
.PP-010:nth-of-type(even) .container {
  justify-content: flex-start;
}
.PP-010 .article-content {
  background: transparent;
  margin: 0;
  max-width: 500px;
}
.PP-010 .article-content h2 {
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 33px;
}
@media screen and (min-width: 768px) {
  .PP-010 .article-content h2 {
    font-size: 46px;
    font-size: 4.6rem;
    line-height: 58px;
  }
}
.PP-010 .article-content p {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 22px;
}
@media screen and (min-width: 768px) {
  .PP-010 .article-content p {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 30px;
  }
}
.PP-010 .article-content a {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 15px;
}
@media screen and (min-width: 768px) {
  .PP-010 .article-content a {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 18px;
  }
}
.PP-012 h2 {
  font-size: 26px;
  font-size: 2.6rem;
  font-weight: bold;
  line-height: 33px;
}
@media screen and (min-width: 768px) {
  .PP-012 h2 {
    font-size: 46px;
    font-size: 4.6rem;
    line-height: 54px;
  }
}
.PP-012 .assays {
  margin-top: 50px;
  margin-bottom: 30px;
}
.PP-012 .assays .assay-block {
  width: 16.666%;
}
@media screen and (min-width: 768px) {
  .PP-012 .assays .assay-block {
    width: 12.5%;
  }
}
.PP-012 .assays .assay-block.active {
  color: #CF2F44;
}
.PP-012 .assays svg {
  fill: #CF2F44;
  height: 36px;
  margin-bottom: 10px;
  width: 100%;
}
.PP-012 .assays p {
  font-size: 14px;
  font-size: 1.4rem;
}
.PP-012 .assay-table svg {
  align-self: flex-start;
  display: block;
  height: 48px;
  text-align: left;
  width: 48px;
}
.PP-012 .assay-table .table th {
  border-top: 0;
}
.PP-012 .assay-table .table td svg {
  height: 16px;
  width: 16px;
  margin: 0 auto;
}
.PP-012 .appendix {
  background-color: #f8f8f8;
}
.PP-012 .appendix p {
  font-size: 12px;
  font-size: 1.2rem;
  margin-right: 1rem;
}
.PP-012 .appendix p svg {
  display: inline-block;
  height: 16px;
  width: 16px;
}
.PP-012 .fade {
  opacity: 0.3;
}
.PP-012 .select-box {
  font-size: 16px;
  font-size: 1.6rem;
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;
  color: #fff;
}
.PP-012 .select-box__current {
  position: relative;
  box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);
  cursor: pointer;
  outline: none;
}
.PP-012 .select-box__current:focus + .select-box__list {
  opacity: 1;
  animation-name: none;
}
.PP-012 .select-box__current:focus + .select-box__list .select-box__option {
  cursor: pointer;
}
.PP-012 .select-box__current:focus .select-box__icon {
  transform: translateY(-50%) rotate(180deg);
}
.PP-012 .select-box__icon {
  fill: #fff;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 20px;
  transition: 0.2s ease;
}
.PP-012 .select-box__value {
  display: flex;
}
.PP-012 .select-box__input {
  display: none;
}
.PP-012 .select-box__input:checked + .select-box__input-text {
  display: block;
}
.PP-012 .select-box__input-text {
  display: none;
  width: 100%;
  margin: 0;
  padding: 15px;
  background-color: #cf2f44;
  background-image: linear-gradient(90deg, #cf2f44, #8f191c);
}
.PP-012 .select-box__list {
  background-color: #cf2f44;
  background-image: linear-gradient(90deg, #cf2f44, #8f191c);
  position: absolute;
  width: 100%;
  padding: 0;
  list-style: none;
  opacity: 0;
  z-index: 999;
  animation-name: HideList;
  animation-duration: 0.5s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: step-start;
  box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);
}
.PP-012 .select-box__option {
  display: block;
  padding: 15px;
  background-color: #cf2f44;
  background-image: linear-gradient(90deg, #cf2f44, #8f191c);
}
.PP-012 .select-box__option:hover,
.PP-012 .select-box__option:focus {
  color: #fff;
  background-color: #cf2f44;
}
.PP-013 .article {
  justify-content: flex-start;
}
.PP-013 .article img {
  left: auto;
  right: 0;
}
.PP-013 .article cite {
  display: block;
  margin-left: 0;
  margin-top: 10px;
}
@media screen and (max-width: 767px) {
  .PP-014 .content {
    position: relative;
  }
  .PP-014 .content.active:after {
    background: none;
  }
}
.PP-014 .content ul {
  padding-left: 20px;
}
.PP-014 .content p {
  margin-bottom: 0;
}
.PP-014 .content p,
.PP-014 .content ul {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 22.5px;
}
.PP-014 .intro {
  color: #8F191C;
  font-weight: bold;
}
.PP-014 .intro p {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 30px;
}
.PP-014 .read-more {
  cursor: pointer;
}
.PP-014 .read-more svg {
  fill: #CF2F44;
  height: 24px;
  width: 24px;
}
.PP-014 .read-more.active svg {
  transform: rotate(180deg);
}
/* #### Search page updates #### */
.search.ReCP-001 h2 span {
  font-size: 18px;
  margin-left: 30px;
  color: #BCBDBC;
  font-family: 'Overpass', sans-serif;
}
.search.ReCP-001 h2 span b {
  color: #000;
}
.search.ReCP-003,
.search.ReCP-006 {
  padding: 10px;
  -webkit-border-image: -webkit-gradient(linear, left top, left bottom, from(#CF2F44), to(#8F191C)) 30 30 stretch;
  -webkit-border-image: linear-gradient(to bottom, #CF2F44, #8F191C) 30 30 stretch;
  -o-border-image: -o-linear-gradient(top, #CF2F44, #8F191C) 30 30 stretch;
  border-image: -webkit-gradient(linear, left top, right top, from(#CF2F44), to(#8F191C)) 30 30 stretch;
  border-image: linear-gradient(to right, #CF2F44, #8F191C) 30 30 stretch;
  border-style: solid;
  border-width: 5px;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
}
.search.ReCP-006 {
  padding-top: 0;
}
.search.ReCP-001 h2 {
  margin-bottom: 80px;
}
.search.ReCP-003 h3 {
  color: #000;
  margin-bottom: 0px;
}
.search.ReCP-003 h3.active {
  color: #CF2F44;
  padding-left: 20px;
}
.search.ReCP-003 p {
  font-size: 14px;
  color: #BCBDBC;
  font-weight: bold;
}
.search.ReCP-006 .article-item {
  padding: 1.25rem 5px 0;
}
.search .card {
  margin-bottom: 0;
}
.search .card .card-title {
  font-size: 36px;
  font-size: 2rem;
  line-height: 30px;
}
.search .card-meta .tag {
  font-size: 14px;
  color: #BCBDBC;
}
.search .card-text {
  font-size: 14px;
  color: #000;
  line-height: 28px;
}
.search a:hover h3 {
  color: #CF2F44;
}
.search .card-text span,
.search .card .card-title span {
  color: #CF2F44;
}
.search .pagination a {
  color: #000;
  padding: 5px;
  font-weight: bold;
}
.search .pagination a.active,
.search .pagination a:hover {
  color: #CF2F44;
}
.search .card-body:hover .card-title {
  color: #CF2F44;
}
@media (max-width: 768px) {
  .search.ReCP-001 h2 span {
    margin-left: 0;
  }
  .search.ReCP-001 h2 {
    line-height: 35px;
  }
  .search.ReCP-003,
  .search.ReCP-006 {
    border-image: none;
    border: 0;
  }
  .search.ReCP-006 .article-item {
    padding: 1rem 5px 0;
  }
}
.search-block.OD-002 .select-box__input-text {
  display: block !important;
}
.search-block .select-box__option a {
  color: #fff;
}
.search-block .select-box__option a:hover {
  opacity: 0.7;
}
/* #### Assay page updated */
.assay-menu-components {
  background-color: #fff;
  padding-bottom: 50px;
}
#accordionAssay {
  min-width: 100%;
}
#accordionAssay .col-lg-6 {
  float: left;
}
.img-border {
  border-bottom: 3px solid #EAEBEA;
  margin-bottom: 10px;
  height: 120px;
}
.img-border img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}
.img-border.active_nav:after,
.img-border.active:after {
  content: "";
  height: 3px;
  width: 100px;
  display: block;
  position: absolute;
  left: 22%;
  top: 60.5%;
  -webkit-border-image: -webkit-gradient(linear, left top, left bottom, from(#CF2F44), to(#8F191C)) 30 30 stretch;
  -webkit-border-image: linear-gradient(to bottom, #CF2F44, #8F191C) 30 30 stretch;
  -o-border-image: -o-linear-gradient(top, #CF2F44, #8F191C) 30 30 stretch;
  border-image: -webkit-gradient(linear, left top, right top, from(#CF2F44), to(#8F191C)) 30 30 stretch;
  border-image: linear-gradient(to right, #d15c6c, #8F191C) 30 30 stretch;
  border-style: solid;
  border-width: 3px;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
}
img.fadeIMG {
  opacity: 0.3;
}
.xtmenu-link:hover img.fadeIMG {
  opacity: 0.6;
}
.vitros-models {
  margin-bottom: 40px;
}
.xtmenu-title {
  font-size: 15px;
  color: #B3B3B3;
  opacity: 0.6;
  line-height: 22px;
  text-align: center;
  padding-top: 10px;
}
.xtmenu-link {
  cursor: pointer;
}
.xtmenu-link:hover .xtmenu-title {
  opacity: 1;
}
.xtmenu-title.active {
  color: #CF2F44;
  opacity: 1;
}
.accordion-assay,
.accordion-assay.collapsed {
  cursor: pointer;
  border-left: 5px solid #BCBDBC;
  padding: 3px 10px;
  margin: 3px 0;
  font-weight: bold;
}
.top {
  border-top: 1px solid #BCBDBC;
  margin-bottom: 3px;
}
.top.last-child {
  border-bottom: 1px solid #BCBDBC;
}
.top.active {
  border-bottom: 0;
  margin-bottom: 0;
}
#accordionAssay ul {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  padding-left: 30px;
}
#accordionAssay ul li {
  padding: 0 5px;
  color: #8F191C;
  font-size: 14px;
  line-height: 25px;
}
.accordion-assay.active {
  background-image: linear-gradient(to right, #8F191C, #CF2F44);
  border-bottom: 0;
  border-left: 0;
  padding-left: 15px;
  color: #fff;
}
#tab2,
#tab3,
#tab4,
#tab5,
#tab6 {
  display: none;
}
.key {
  margin-top: 50px;
}
.key p {
  font-size: 13px;
}
@media (max-width: 1200px) {
  .img-border.active_nav:after,
  .img-border.active:after {
    top: 54%;
    left: 17%;
  }
}
@media (max-width: 991px) {
  .img-border.active_nav:after,
  .img-border.active:after {
    display: none;
  }
  .img-border {
    border-bottom: 0;
    height: auto;
    margin-bottom: 0;
  }
  .top.last-child {
    border-bottom: 0;
  }
}
@media (max-width: 768px) {
  .img-border.active_nav:after,
  .img-border.active:after {
    display: none;
  }
  .xtmenu-title {
    font-size: 12px;
  }
  .assay-menu-components {
    padding-bottom: 40px;
  }
}
@media (max-width: 479px) {
  #accordionAssay ul {
    column-count: 2;
    padding-left: 20px;
  }
}
.POS-001 .breadcrumb {
  background: transparent;
  border-radius: 0;
  padding: 0;
  font-weight: bold;
}
.POS-001 .breadcrumb a {
  position: relative;
}
.POS-001 .breadcrumb a:after {
  content: '';
  border-color: #CF2F44;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 1px;
  border-width: 0 0 1px;
  border-style: solid;
}
.POS-002 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.POS-002 h2 {
  font-size: 30px;
  font-size: 3rem;
  margin-bottom: 20px;
  line-height: inherit;
  font-weight: bold;
}
.POS-002 h2 .title__location {
  font-size: 16px;
  font-size: 1.6rem;
  color: #CF2F44;
  font-weight: 300;
}
.POS-002 p {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
}
.POS-003 {
  background: #000;
}
.POS-004 .post-back-to-link {
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.POS-004 .post-back-to-link a {
  color: #CF2F44;
  font-size: 2rem;
}
.POS-005 a {
  color: #BCBDBC;
}
.POS-005 .flex {
  display: flex;
}
.POS-005 .flex i:before {
  font-size: 21px;
  margin: 0 8px;
}
.POS-005.absolute {
  position: absolute;
  left: 0px;
  top: 100px;
}
@media screen and (min-width: 768px) {
  .POS-005.absolute {
    left: -15px;
  }
}
.POS-006 img,
.POS-023 img {
  border-bottom: 4px solid #CF2F44;
}
.POS-006 .figure-caption,
.POS-023 .figure-caption {
  font-size: 14px;
  font-size: 1.4rem;
  border-bottom: 2px solid #F4F4F4;
  color: #747678;
  font-weight: 400;
  line-height: 20px;
  margin-top: 30px;
  padding-bottom: 30px;
  max-width: 80%;
  min-width: 260px;
  margin-left: auto;
  margin-right: auto;
}
.POS-029 i {
  height: 24px;
  width: 24px;
  color: #BCBDBC;
  position: relative;
  left: 5px;
  top: 2px;
}
.POS-007.first-letter p:first-child::first-letter {
  font-size: 54px;
  font-size: 5.4rem;
  color: #fff;
  background-color: #CF2F44;
  float: left;
  line-height: 84px;
  padding: 10px 30px;
  margin-top: 10px;
  margin-right: 10px;
  text-transform: uppercase;
}
.POS-007ALT p {
  font-size: 14px;
  line-height: 22px;
}
.POS-008 .bullet {
  list-style: none;
}
.POS-008 .bullet li::before {
  content: "\2022";
  color: #CF2F44;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.POS-008alt h1 {
  font-weight: 600;
}
.POS-008alt p {
  font-size: small;
  font-weight: 500;
}
.POS-008alt .bullet {
  list-style: none;
  padding-left: 22px;
}
.POS-008alt .bullet li {
  font-size: 1.8rem;
  font-weight: 600;
}
.POS-008alt .bullet li::before {
  font-size: 4.2rem;
  content: "\2022";
  color: #CF2F44;
  font-weight: bold;
  display: inline-block;
  width: 20px;
  margin-left: -25px;
  position: relative;
  top: 9px;
}
.POS-009 p {
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 36px;
  font-weight: bold;
  font-style: italic;
  border-image: linear-gradient(to bottom, #CF2F44, #8f191c) 1 100%;
  border-style: solid;
  border-width: 3px;
  border-right: 0;
  padding-left: 4.7rem;
  margin-left: 6rem;
}
.POS-011 {
  background: #F4F4F4;
  display: flex;
  flex-flow: row wrap;
}
.POS-011 h4 {
  width: 100%;
}
.POS-011 .addr {
  border-image: linear-gradient(to bottom, #CF2F44, #8f191c) 1 100%;
  border-style: solid;
  border-width: 3px;
  border-right: 0;
  padding-left: 1.8rem;
}
.POS-011 .addr span {
  display: block;
}
.POS-011 strong {
  display: block;
}
.POS-012 p {
  color: #CF2F44;
}
.POS-012 .card-premium {
  font-size: 1.4rem;
  background-color: #00798C;
  color: #fff;
  padding: 5px 10px;
}
.POS-012 .card-premium:before {
  content: ' \2605 \0020';
}
.POS-012-ALT h2 {
  border-width: 4px;
  border-style: solid;
  border-image: linear-gradient(to bottom, #CF2F44, #8f191c) 1 100%;
  border-right: 0;
  padding-left: 20px;
  border-top: 0;
  border-bottom: 0;
  margin-bottom: 30px;
  margin-top: 30px;
}
@media screen and (max-width: 575px) {
  .POS-012-ALT h2 {
    line-height: 46px;
  }
}
.POS-012-ALT p {
  font-weight: bold;
}
.POS-012-ALT .card-premium {
  font-size: 1.4rem;
  background-color: #00798C;
  color: #fff;
  padding: 5px 10px;
}
.POS-012-ALT .card-premium:before {
  content: ' \2605 \0020';
}
@media screen and (max-width: 575px) {
  .POS-012-ALT .meta {
    display: grid;
  }
}
.POS-013 ul {
  list-style: none;
}
.POS-013 ul li {
  margin-bottom: 10px;
}
.POS-013 ul li::before {
  content: "›";
  color: #CF2F44;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.POS-015 {
  background: #F4F4F4;
}
.POS-015 h4 {
  margin-bottom: 20px;
}
.POS-015 .user-profile {
  display: flex;
  margin-bottom: 32px;
}
.POS-015 .user-profile img {
  width: 78px;
  height: 78px;
}
.POS-015 .user-profile p {
  border-image: linear-gradient(to bottom, #CF2F44, #8f191c) 1 100%;
  border-width: 2px;
  border-right-width: 0;
  border-style: solid;
  line-height: 23px;
  margin-left: 2rem;
  padding-left: 20px;
}
.POS-015 .user-profile p strong,
.POS-015 .user-profile p span {
  margin-bottom: 10px;
}
.POS-015 p span,
.POS-015 p strong {
  display: block;
  margin-bottom: 20px;
}
.POS-016 p span,
.POS-016 p strong {
  display: block;
}
.POS-018 .btn {
  padding: 0.375rem 0.75rem;
}
.POS-018 .modal {
  top: 100px;
}
.POS-019 .card-header {
  background-color: transparent;
}
.POS-019 .card-header .btn {
  color: #000;
  font-weight: bold;
  text-transform: capitalize;
}
.POS-019 .card-header .btn span {
  text-decoration: underline;
}
.POS-019 .card-header .btn.stretched-link {
  font-size: 18px;
  font-size: 1.8rem;
  height: auto;
  line-height: 23px;
  width: auto;
}
.POS-019 .card-header .collapsed .active {
  display: inline-block;
}
.POS-019 .card-header .collapsed .inactive {
  display: none;
}
.POS-019 .card-header .active {
  display: none;
}
.POS-019 .card-header p {
  margin-bottom: 0;
}
.POS-019 .card-body {
  padding: 0;
}
.POS-020 .card-header {
  background-color: transparent;
  color: #CF2F44;
}
.POS-020 .card-header .btn {
  color: #000;
  font-weight: bold;
  text-transform: capitalize;
}
.POS-020 .card-header .btn span {
  text-decoration: underline;
}
.POS-020 .card-header .btn.stretched-link {
  font-size: 18px;
  font-size: 1.8rem;
  height: auto;
  line-height: 23px;
  width: auto;
}
.POS-020 .card-header .btn.stretched-link svg {
  height: 20px;
  width: 20px;
}
.POS-020 .card-header .collapsed .active {
  display: inline-block;
}
.POS-020 .card-header .collapsed .inactive {
  display: none;
}
.POS-020 .card-header .active {
  display: none;
}
.POS-020 .card-header p {
  margin-bottom: 0;
}
.POS-020 .card-body {
  padding: 0;
}
.POS-021 p {
  margin-bottom: 20px;
}
.POS-021 ul {
  list-style: none;
}
.POS-021 ul li {
  margin-bottom: 10px;
}
.POS-021 ul li::before {
  content: "\2713";
  color: #CF2F44;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1.2em;
}
.settings-side-block {
  background-color: #F8F8F8;
  padding: 20px;
  border-radius: 5px;
}
.settings-side-block span {
  font-weight: bold;
  font-size: 80%;
}
.settings-side-block__title {
  color: #8F191C;
}
.settings-nav .settings-nav-pill {
  margin-bottom: 2px;
}
.settings-nav .settings-nav-pill .settings-nav-pill__link {
  color: #FFFFFF;
  padding: 20px;
  display: block;
  background-color: #404040;
}
.settings-nav .settings-nav-pill .settings-nav-pill__link div {
  font-weight: bold;
}
.settings-nav .settings-nav-pill .settings-nav-pill__link span {
  font-size: 80%;
}
.settings-nav .settings-nav-pill .settings-nav-pill__link.active {
  background-color: #CF2F44;
  background-image: linear-gradient(90deg, #CF2F44, #8F191C);
}
.settings-nav .settings-nav-pill:first-child a {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.settings-nav .settings-nav-pill:last-child {
  margin-bottom: 0px;
}
.settings-nav .settings-nav-pill:last-child a {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
.settings-nav .settings-nav-pill:last-child {
  margin-bottom: 0px;
}
.tab-content-settings {
  height: 100%;
}
.tab-content-settings .tab-pane {
  padding: 30px;
  background-color: #EAEBEA;
  border-radius: 2px;
  height: 100%;
}
.tab-content-settings .tab-pane h2 {
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 2.4rem;
  font-weight: bold;
}
.tab-content-settings .tab-pane .form-row {
  margin-bottom: 10px;
}
.tab-content-settings .tab-pane .form-row .form-control {
  border: 0;
  border-radius: 0;
}
.tab-content-settings .tab-pane .reset-password {
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 10px;
}
.notification-settings .notifications-text {
  margin-bottom: 0;
  font-weight: bold;
}
.notification-settings .notifications-example {
  position: relative;
  top: -2px;
  font-size: 90%;
}
.notification-settings .switch-container {
  margin-bottom: 20px;
}
@media screen and (min-width: 577px) {
  .notification-settings .switch-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #404040;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #CF2F44;
  background-image: linear-gradient(90deg, #CF2F44, #8F191C);
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
@media screen and (min-width: 768px) {
  .form-my-profile .form-group {
    margin-bottom: 20px;
  }
}
.message-container {
  border-top: 1px solid #EAEBEA;
}
.message-container.unread .message-row {
  background-color: #F8F8F8;
  font-weight: bold;
}
.message-container.unread .message-row .message-icon-col {
  color: #CF2F44;
}
@media screen and (min-width: 576px) {
  .message-container.unread .message-row .message-time-date-col span:after {
    padding-left: 10px;
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #CF2F44;
    margin-left: 20px;
  }
}
.message-row {
  display: flex;
  margin-top: 3px;
  margin-bottom: 3px;
  padding: 10px 0;
  flex-direction: column;
}
@media screen and (min-width: 576px) {
  .message-row {
    flex-direction: row;
  }
}
.message-icon-col {
  display: none;
}
@media screen and (min-width: 576px) {
  .message-icon-col {
    display: block;
    width: 7%;
    align-self: center;
    justify-content: center;
  }
  .message-icon-col i {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 20px;
  }
}
.message-name-col {
  align-self: flex-start;
  width: 80%;
  padding: 5px;
}
@media screen and (min-width: 576px) {
  .message-name-col {
    width: 18%;
    min-width: 120px;
    justify-content: center;
    align-items: center;
    align-self: center;
  }
}
.message-name-col p {
  margin-bottom: 0;
}
.message-desc-col {
  align-self: flex-start;
  padding: 5px;
}
@media screen and (min-width: 576px) {
  .message-desc-col {
    width: 70%;
    align-self: center;
    justify-content: center;
  }
}
.message-desc-col p {
  margin-bottom: 0;
}
.message-time-date-col {
  width: 10%;
  min-width: 90px;
  display: flex;
  padding: 5px 10px;
}
@media screen and (max-width: 575px) {
  .message-time-date-col {
    position: absolute;
    right: 0;
    padding-right: 0;
    align-self: center;
    justify-content: center;
  }
}
@media screen and (min-width: 576px) {
  .message-time-date-col {
    justify-content: flex-start;
    align-items: center;
    align-self: center;
  }
}
.notifications-block {
  margin-bottom: 50px;
}
@media screen and (min-width: 576px) {
  .notifications-block {
    margin-bottom: 100px;
  }
}
.SOL-002 a {
  background-color: #eee;
  background-image: url(../../assets/images/down-arrow-red.svg);
  background-position: right 10% center;
  background-size: 20px;
  background-repeat: no-repeat;
  border-bottom: 5px solid #CF2F44;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.13);
  color: #000;
  display: block;
  height: 80%;
  margin-bottom: 20px;
  padding: 20px;
  transition: 0.4s;
}
.SOL-002 a:hover {
  text-decoration: none;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.26);
}
.SOL-002 a span {
  display: block;
  width: 70%;
  font-size: 2rem;
  font-weight: bold;
}
.SOL-003 {
  position: relative;
}
.SOL-003:nth-of-type(even) .article {
  justify-content: flex-start;
}
.SOL-003:nth-of-type(even) .article img {
  left: auto;
  right: 0;
}
@media (min-width: 768px) {
  .SOL-003 {
    overflow: hidden;
  }
}
.SOL-003 img {
  width: 100%;
}
@media (min-width: 768px) {
  .SOL-003 img {
    width: 60%;
  }
}
@media (min-width: 768px) {
  .SOL-003 .content {
    background: #fff;
    margin: 20px 0;
    display: flex;
    flex-flow: wrap;
    width: 50%;
    padding: 20px;
    z-index: 999;
  }
  .SOL-003 .content .btn {
    align-self: flex-start;
    margin-right: 10px;
  }
}
.SOL-003 .content h2 {
  font-size: 46px;
  font-size: 4.6rem;
  border-image: linear-gradient(to bottom, #CF2F44, #8f191c) 1 100%;
  border-style: solid;
  border-width: 3px;
  border-right: 0;
  padding-left: 2rem;
  line-height: 54px;
  margin: 50px 0;
}
.SOL-003 .content p {
  font-size: 18px;
  font-size: 1.8rem;
  margin-bottom: 50px;
}
.SOL-003 .content .arrow-link {
  align-self: center;
}
@media (max-width: 767px) {
  .SOL-003 .content .arrow-link {
    display: block;
    margin-top: 10px;
    align-self: flex-start;
  }
}
.SOL-004 h3 {
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 33px;
}
.SOL-004 .blockquote {
  color: #8F191C;
}
.SOL-004 .blockquote p {
  font-size: 26px;
  font-size: 2.6rem;
}
.SOL-004 .blockquote p span:before {
  content: '“';
}
.SOL-004 .blockquote p span:after {
  content: '”';
}
.SOL-004 .blockquote cite {
  font-size: 14px;
  font-size: 1.4rem;
  color: #747678;
  display: block;
  margin-left: 10px;
}
@media screen and (min-width: 768px) {
  .SOL-004 .blockquote cite {
    display: inline-block;
  }
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.SOL-005 {
  background-color: #eee;
  padding: 50px 0;
}
.SOL-005 h2 {
  font-size: 46px;
  font-size: 4.6rem;
  line-height: 54px;
  margin-bottom: 50px;
}
.SOL-005 h3 {
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 22px;
  margin-bottom: 20px;
}
.SOL-005 p {
  font-size: 18px;
  font-size: 1.8rem;
  margin-bottom: 30px;
}
.SOL-005 p > .btn {
  margin-left: 10px;
}
.SOL-005 a.arrow-link {
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 50px;
}
@media screen and (min-width: 768px) {
  .SOL-005 a.btn {
    margin-left: 10px;
  }
}
.SOL-005 .custom .link-block-container {
  padding: 20px 10px 20px 20px;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 100%;
}
.SOL-005 .custom .link-block-container:hover .link-block,
.SOL-005 .custom .link-block-container:focus .link-block {
  transform: scale(1.1);
}
.SOL-005 .custom .link-block-container:hover .link-block:before,
.SOL-005 .custom .link-block-container:focus .link-block:before {
  display: block;
}
.SOL-005 .custom .link-block-container .link-block {
  position: relative;
  padding: 20px;
  min-height: 265px;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.5s;
}
.SOL-005 .custom .link-block-container .link-block p {
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 34px;
  margin-bottom: 40px;
}
.SOL-005 .custom .link-block-container .link-block .icon {
  height: 34px;
  width: 34px;
  margin-bottom: 30px;
}
.SOL-005 .custom .link-block-container .link-block:before {
  content: "";
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.1);
}
.SOL-005 .custom .link-block-container .link-block a {
  text-decoration: none;
  color: inherit;
}
.SOL-005 .custom .link-block-container .link-block a:hover .arrow,
.SOL-005 .custom .link-block-container .link-block a:focus .arrow {
  animation: shake 2s cubic-bezier(0.36, 0.07, 0.19, 0.67) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
@media screen and (min-width: 992px) {
  .SOL-005 .custom .link-block-container {
    padding-top: 70px;
    padding-bottom: 20px;
  }
}
@media screen and (min-width: 992px) {
  .SOL-005 .custom .col-lg-6:nth-of-type(odd) {
    margin-top: -50px;
  }
}
.SOL-007 {
  border-top: 5px solid #F4F4F4;
  padding-top: 60px;
}
.SOL-007 h2 {
  font-size: 46px;
  font-size: 4.6rem;
  line-height: 54px;
  margin-bottom: 9rem;
}
.SOL-007.innovation-SQL {
  padding-top: 105px !important;
}
.SOL-007.innovation-SQL h2 {
  font-weight: bold;
}
.SOL-007.innovation-SQL .card-text {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 24px;
}
.button-well {
  margin: 50px auto;
}
.orthoplus .SOL-007 {
  border-top: 0;
}
.orthoplus .SOL-007 h2 {
  text-align: left !important;
  margin-bottom: 1.5rem;
  font-size: 3.6rem;
  line-height: 4.4rem;
}
@media screen and (max-width: 767px) {
  .orthoplus .SOL-007 h2 {
    font-size: 2.4rem;
    line-height: 3.2rem;
    margin-bottom: 1.5rem !important;
  }
}
#POS025 + #SOL007 .SOL-007 {
  padding-top: 0;
}
#POS025 + #SOL007 .SOL-007 .tag,
#POS025 + #SOL007 .SOL-007 .date,
#POS025 + #SOL007 .SOL-007 .card-title {
  display: none;
}
#SOL007 + #SOL007ALT .SOL-007 {
  padding-top: 0;
  padding-top: 30px;
}
@media screen and (min-width: 768px) {
  #SOL007 + #SOL007ALT .SOL-007 {
    position: relative;
    top: -50px;
  }
}
@media screen and (min-width: 992px) {
  #SOL007 + #SOL007ALT .SOL-007 {
    padding-top: 0;
  }
}
.SOL-007ALT .background-dark-grey {
  padding-top: 20px;
}
.SOL-007ALT .background-dark-grey .card-text {
  color: #fff;
}
.SOL-007ALT .background-dark-grey .tag,
.SOL-007ALT .background-dark-grey .date,
.SOL-007ALT .background-dark-grey .card-title {
  display: none;
}
@media screen and (max-width: 767px) {
  .SOL-007ALT ul.slick-dots {
    bottom: -35px;
  }
}
.AU-001 {
  background-image: none;
  margin-bottom: 50px;
}
.AU-001.white * {
  color: white;
}
.AU-001 .link-block svg {
  width: 20px;
  height: 25px;
  margin-left: 20px;
  fill: #CF2F44;
}
@media screen and (min-width: 768px) {
  .AU-001 {
    background-size: cover;
    background-position: right center;
    width: 100%;
    display: block;
    height: 600px;
    max-height: 85vh;
  }
}
.AU-001 > span {
  display: block;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  max-height: 70vh;
  height: 600px;
}
@media (min-width: 768px) {
  .AU-001 > span {
    background-image: none !important;
  }
}
@media screen and (min-width: 768px) {
  .AU-001 .col {
    max-width: 55%;
  }
}
.AU-001 .container,
.AU-001 .container .row {
  height: 100%;
}
.AU-001 h1 {
  font-size: 36px;
  font-size: 3.6rem;
  font-family: 'Calluna', sans-serif;
  line-height: 36px;
  margin: 20px 0;
}
@media screen and (min-width: 768px) {
  .AU-001 h1 {
    font-size: 76px;
    font-size: 7.6rem;
    line-height: 76px;
    margin: 48px 0;
  }
}
.AU-001 p {
  font-size: 14px;
  font-size: 1.4rem;
  border-width: 4px;
  border-style: solid;
  border-image: linear-gradient(to bottom, #CF2F44, #8f191c) 1 100%;
  border-right: 0;
  line-height: 33px;
  margin-bottom: 40px;
  padding-left: 20px;
  font-weight: bold;
}
@media screen and (min-width: 768px) {
  .AU-001 p {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 30px;
  }
}
.responsibility-main.AU-001 {
  position: relative;
}
.responsibility-main.AU-001:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255) 0%, #ffffff 0%, rgba(255, 255, 255, 0) 50%);
}
.responsibility-main.AU-001 .col {
  max-width: 60%;
}
.responsibility-main.AU-001 .col p {
  line-height: 33px;
}
.responsibility-message.AU-004 {
  margin: 73px auto 60px !important;
}
.responsibility-message .cover {
  width: 300px !important;
  height: 300px !important;
  border: 5px solid #F4F4F4;
}
.responsibility-message h1 {
  font-size: 36px !important;
  font-size: 3.6rem !important;
  margin-bottom: 30px;
}
.responsibility-message p {
  font-size: 18px !important;
  font-size: 1.8rem !important;
}
.responsibility-action.HP-001 {
  position: relative;
}
.responsibility-action.HP-001:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.46) 0%, rgba(0, 0, 0, 0) 99%);
}
.responsibility-action p {
  font-size: 26.9px !important;
  font-size: 2.69rem !important;
  line-height: 34px;
}
.responsibility-action .HP-001 .col {
  max-width: 58%;
}
.responsibility-count {
  margin: 60px auto;
}
.responsibility-count h3 {
  font-size: 46px !important;
  font-size: 4.6rem !important;
}
.responsibility-count p {
  font-size: 24px !important;
  font-size: 2.4rem !important;
  line-height: 30px;
}
.responsibility-count p span {
  font-size: 18px !important;
  font-size: 1.8rem !important;
  line-height: 23px;
  display: block;
  margin-top: 15px;
}
#HP001.responsibility-action-1 {
  margin-top: 0 !important;
}
#HP001.responsibility-action-1 .component {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.responsibility-action-1.HP-001 {
  position: relative;
}
.responsibility-action-1.HP-001:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 100%);
}
.responsibility-action-1.HP-001 p {
  font-size: 26px !important;
  font-size: 2.6rem !important;
  line-height: 35px;
  padding-left: 25px !important;
  max-width: 580px !important;
}
.responsibility-action-1 ~ .responsibility-action-1.HP-001:before {
  background: linear-gradient(337.16deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 100%);
}
.responsibility-count-last {
  margin: 60px auto;
}
.responsibility-count-last h3 {
  font-size: 32px !important;
  font-size: 3.2rem !important;
}
.responsibility-count-last p {
  font-size: 18px !important;
  font-size: 1.8rem !important;
  line-height: 22px;
}
.responsibility-count-last p span {
  font-size: 14px !important;
  font-size: 1.4rem !important;
  line-height: 23px;
  display: block;
}
.responsibility-compliance h1 {
  font-size: 46px !important;
  font-size: 4.6rem !important;
}
.responsibility-compliance .small-text {
  font-size: 14px !important;
  font-size: 1.4rem !important;
  line-height: 22px;
}
.responsibility-compliance .au-border .clearfix {
  margin-top: 70px;
}
.about-us-innovations .article-content {
  padding-left: 50px;
}
.about-us-innovations h2 {
  font-weight: bold;
}
.about-us-innovations .button-link {
  padding: 16px 20px 14px 20px;
  border: 1px solid #CF2F44;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
}
.about-us-innovations .button-link:hover {
  background-color: #CF2F44;
  color: white;
}
.about-us-innovations .component + .component .article-content {
  padding-left: 0;
  padding-right: 50px;
}
.about-us-diagnostic {
  margin-bottom: 90px;
}
.about-us-diagnostic .link-arrow-down {
  text-decoration: none;
}
.about-us-awards .AU-003 {
  border-bottom: 5px solid #F4F4F4;
}
.about-us-questions {
  margin-top: 90px;
}
.about-us-questions h2 {
  font-size: 46px !important;
  font-size: 4.6rem !important;
  font-weight: bold;
  padding-bottom: 90px !important;
}
.about-us-leadership {
  margin-bottom: 90px;
}
.about-us-leadership h2 {
  margin-top: 90px !important;
  margin-bottom: 77px !important;
  font-size: 46px !important;
  font-size: 4.6rem !important;
  font-weight: bold ;
}
.about-us-leadership .AU-005 .leadership {
  margin-bottom: 40px;
}
@media (max-width: 991px) {
  .responsibility-main.AU-001 .col {
    max-width: 80%;
  }
  .responsibility-message .cover {
    width: 250px !important;
    height: 250px !important;
  }
  .responsibility-message p {
    font-size: 16px !important;
    font-size: 1.6rem !important;
    line-height: 22px;
  }
  .responsibility-message a {
    line-height: 22px;
    display: block;
  }
  .responsibility-action.HP-001 .col {
    max-width: 90%;
  }
  .responsibility-count h3 {
    font-size: 36px !important;
    font-size: 3.6rem !important;
  }
  .responsibility-count p {
    font-size: 18px !important;
    font-size: 1.8rem !important;
    line-height: 22px;
  }
  .responsibility-count p span {
    font-size: 14px !important;
    font-size: 1.4rem !important;
    line-height: 17px;
    display: block;
    margin-top: 7px;
  }
  .responsibility-count .content-text-box {
    padding-bottom: 0 !important;
  }
  .responsibility-action-1 .col {
    max-width: 100% !important;
  }
  .responsibility-compliance p {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 22px;
  }
  .responsibility-compliance h2 {
    font-size: 22px !important;
    font-size: 2.2rem !important;
    margin: 15px 0 !important;
  }
  .responsibility-compliance.AU-010 .awards .awards__image-container {
    margin-bottom: 0;
  }
  .responsibility-compliance .awards span {
    font-size: 12px !important;
    font-size: 1.2rem !important;
    line-height: 18px;
    display: block;
    padding: 15px 10px;
  }
  .responsibility-compliance.AU-010 .au-border {
    margin-top: 25px !important;
    padding-bottom: 15px !important;
    padding-top: 15px !important;
  }
  .responsibility-related p {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 20px;
  }
  .about-us-innovations .article-content {
    padding-left: 0;
  }
}
@media (max-width: 767px) {
  .AU-002 h2 {
    font-size: 24px !important;
    font-size: 2.4rem !important;
  }
  .AU-002 p {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 22px;
  }
  .about-us-innovations h2 {
    font-size: 26px !important;
    font-size: 2.6rem !important;
    line-height: 40px;
    margin-top: 20px;
    margin-bottom: 13px;
  }
  .about-us-innovations p span {
    font-size: 14px !important;
    font-size: 1.4rem !important;
    line-height: 22px;
    display: block;
  }
  .about-us-innovations .button-link {
    display: inline-block;
  }
  .about-us-innovations .component + .component .article-content {
    padding-right: 0;
  }
  .about-us-diagnostic h2 {
    max-width: 80% !important;
  }
  .about-us-diagnostic {
    margin-bottom: 50px;
  }
  .about-us-awards .col-md-10,
  .about-us-awards .container {
    padding: 0;
  }
  .about-us-questions h2 {
    font-size: 26px !important;
    font-size: 2.6rem !important;
    margin-top: 0 !important;
    margin-bottom: 60px !important;
    padding-bottom: 0!important;
  }
  .about-us-questions {
    margin-top: 50px;
  }
  .about-us-questions span {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 22px;
    display: block;
  }
  .about-us-questions .AU-004 .what-they-say-wrapper .blockquote cite {
    margin-left: 0;
    font-size: 12px;
    font-size: 1.2rem;
  }
  .about-us-questions .arrow-link {
    background-image: none;
    position: relative;
    padding-right: 45px;
    display: block;
  }
  .about-us-questions .arrow-link small {
    padding-right: 45px;
    display: block;
    position: relative;
  }
  .about-us-questions .arrow-link small:before {
    position: absolute;
    content: '';
    color: #000;
    width: 14px;
    height: 14px;
    border: 1px solid #8F191C;
    border-left: none;
    border-bottom: none;
    top: 40%;
    transform: translateY(-50%) rotate(45deg);
    right: 10px;
  }
  .about-us-questions .arrow-link small:after {
    position: absolute;
    content: '';
    width: 24px;
    height: 1px;
    background-color: #8F191C;
    right: 9px;
    top: 40%;
    transform: translateY(-50%);
  }
  .about-us-leadership h2 {
    font-size: 26px !important;
    font-size: 2.6rem !important;
    line-height: 44px;
    margin-top: 60px !important;
    margin-bottom: 40px !important;
  }
  .leadership__content p {
    line-height: 12px;
  }
  .about-us-leadership.AU-005 .leadership {
    margin-bottom: 20px;
  }
  .about-us-leadership {
    margin-bottom: 50px;
  }
  .about-us-questions h2 {
    margin-bottom: 35px !important;
  }
}
@media (max-width: 576px) {
  .responsibility-main.AU-001 .col {
    max-width: 92%;
  }
  .responsibility-action-1 a,
  .responsibility-action a {
    font-size: 12px !important;
    font-size: 1.2rem !important;
  }
  .responsibility-action p {
    font-size: 17px !important;
    font-size: 1.7rem !important;
    line-height: 22px;
  }
  .AU-004 .message-from .cover {
    width: 170px !important;
    height: 170px !important;
    margin-right: 0 !important;
    margin-bottom: 20px !important;
  }
  .responsibility-count {
    margin: 30px auto;
  }
  .responsibility-action-1.HP-001 p {
    font-size: 20px !important;
    line-height: 26px;
  }
  .clearfix {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .responsibility-compliance .awards img {
    width: auto;
    max-width: auto;
    height: auto;
  }
  .responsibility-compliance.AU-010 .awards {
    margin-bottom: 10px;
    max-width: 300px;
  }
  .responsibility-compliance.AU-010 .awards .awards__image-container {
    height: auto;
    width: auto;
    min-height: auto;
    margin-bottom: 0;
  }
  .responsibility-compliance h1 {
    font-size: 26px !important;
    font-size: 2.6rem !important;
    margin: 30px 0 !important;
  }
  .responsibility-count-last {
    margin: 30px auto !important;
  }
  .responsibility-compliance .post-article {
    margin-left: 0 !important;
  }
  .responsibility-compliance .clearfix {
    margin-top: 0!important;
  }
}
.AU-002 {
  max-width: 824px;
  width: 100%;
}
.AU-002 h2 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}
@media (min-width: 768px) {
  .AU-002 h2 {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 3.4rem;
  }
}
.AU-002 .innovation-title {
  margin: 0 0 40px 0;
  font-size: 36px !important;
  font-size: 3.6rem !important;
}
.AU-003 {
  padding-bottom: 75px;
}
.AU-003 h2 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
}
@media (min-width: 768px) {
  .AU-003 h2 {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 2.4rem;
  }
}
.AU-003 .awards {
  margin-bottom: 20px;
}
.AU-003 .awards span {
  line-height: 18px !important;
}
.AU-003 .awards__image-container {
  margin-bottom: 30px;
  height: 120px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.AU-003 span {
  font-size: 12px;
  color: #bcbdbc;
}
#AU003 .innovation-main-title {
  font-size: 46px;
  font-size: 4.6rem;
  margin: 90px ;
}
.AU-004 .message-from {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.AU-004 .message-from h1 {
  font-weight: bold;
  font-size: 2.8rem;
}
.AU-004 .message-from img {
  display: block;
  height: 150px;
  width: 150px;
  max-height: 250px;
  max-width: 250px;
  margin: 10px auto;
}
.AU-004 .message-from .cover {
  width: 250px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 250px;
  border-radius: 50%;
  margin-right: 60px;
}
@media (min-width: 768px) {
  .AU-004 {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 2.8rem;
  }
  .AU-004 .message-from {
    text-align: left;
    flex-direction: row;
  }
  .AU-004 .message-from img {
    width: auto;
    height: auto;
    border: 3px solid #bcbdbc;
    margin: 0;
    margin-right: 50px;
  }
  .AU-004 .message-from .blockquote p {
    font-size: 16px;
    font-size: 1.6rem;
    margin-top: 10px;
  }
}
.AU-004 h2 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold !important;
  font-size: 2.4rem;
  text-align: center;
}
.AU-004 .what-they-say-wrapper {
  position: relative;
  margin-bottom: 40px;
}
.AU-004 .what-they-say-wrapper img {
  display: none;
}
.AU-004 .what-they-say-wrapper .blockquote p {
  font-weight: bold;
}
.AU-004 .what-they-say-wrapper .blockquote cite {
  color: #747678;
  margin-left: 10px;
}
@media (min-width: 768px) {
  .AU-004 {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 2.8rem;
  }
  .AU-004 .what-they-say-wrapper {
    margin-left: 100px;
    max-width: 500px;
  }
  .AU-004 .what-they-say-wrapper img {
    display: block;
    width: 100px;
    height: 100px;
    position: absolute;
    left: -100px;
  }
  .AU-004 .what-they-say-wrapper .blockquote {
    padding-left: 30px;
  }
  .AU-004 .what-they-say-wrapper .blockquote p {
    font-size: 16px;
    font-size: 1.6rem;
    margin-top: 10px;
  }
  .AU-004 .what-they-say-wrapper .blockquote p span:before {
    content: '“';
    color: #8F191C;
    position: absolute;
    left: 0px;
    font-size: 42px;
    font-size: 4.2rem;
    top: -10px;
  }
  .AU-004 .what-they-say-wrapper .blockquote cite {
    font-size: 14px;
    font-size: 1.4rem;
  }
  .AU-004 .arrow-link {
    padding-left: 30px;
  }
}
.AU-005 h2 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
}
@media (min-width: 768px) {
  .AU-005 h2 {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 2.4rem;
  }
}
.AU-005 .leadership {
  margin-bottom: 20px;
}
.AU-005 p {
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 0;
  font-weight: bold;
}
.AU-005 span {
  font-size: 12px;
}
@media screen and (min-width: 768px) {
  .AU-005 p {
    font-size: inherit;
    margin-bottom: 10px;
  }
  .AU-005 span {
    font-size: 14px;
  }
}
.AU-006 h2 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
}
@media (min-width: 768px) {
  .AU-006 h2 {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 2.4rem;
  }
}
@media (min-width: 768px) {
  .AU-006 .content-box__main-content.collapse:not(.show) {
    display: block;
  }
}
.AU-006 .content-box.active .fa-caret-down {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}
.AU-006 button {
  width: 100%;
  margin-bottom: 5px;
  border: 0;
  text-align: left;
}
.AU-006 .title {
  font-size: 26px;
  font-weight: bold;
  background-color: #EAEBEA;
  padding: 35px 20px;
}
.AU-006 .title.title--red-background {
  background: linear-gradient(90deg, #CF2F44 0, #8F191C 100%);
  color: #fff;
}
.AU-006 .list-group {
  border: 1px solid #bcbdbc;
  padding: 0 10px;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .AU-006 .list-group {
    margin-bottom: 60px;
  }
}
.AU-006 .list-group li {
  border-color: #bcbdbc;
  display: block;
  padding: 15px 0;
}
.AU-006 .list-group li a {
  display: block;
  font-size: 18px !important;
  font-size: 1.8rem !important;
}
.innovation-main-title {
  font-size: 46px !important;
  font-size: 4.6rem !important;
  margin: 90px 0 56px !important;
}
.AU-007 .message-from {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.AU-007 .message-from h1 {
  font-weight: bold;
  font-size: 2.8rem;
}
.AU-007 .message-from img {
  display: block;
  height: 150px;
  width: 150px;
  max-height: 250px;
  max-width: 250px;
  margin: 10px auto;
}
@media (min-width: 768px) {
  .AU-007 {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 2.8rem;
  }
  .AU-007 .message-from {
    text-align: left;
    flex-direction: row;
  }
  .AU-007 .message-from img {
    width: auto;
    height: auto;
    border: 3px solid #bcbdbc;
    margin: 0;
    margin-right: 50px;
  }
  .AU-007 .message-from .blockquote p {
    font-size: 16px;
    font-size: 1.6rem;
    margin-top: 10px;
  }
}
#AU007 .innovation-title {
  margin: 70px 0;
  font-size: 36px !important;
  font-size: 3.6rem !important;
}
.AU-008 {
  text-align: center;
}
@media (min-width: 768px) {
  .AU-008 {
    text-align: left;
  }
}
.AU-008.content-text-box--color {
  color: #00798C;
}
.AU-008.content-text-box--color-primary {
  color: #CF2F44;
}
.AU-008.content-text-box--color-secondary {
  color: #8F191C;
}
.AU-008 .content-text-box {
  padding: 20px 5px;
}
.AU-008 .content-text-box__title {
  font-size: 2.4rem;
  font-weight: bold;
}
@media (min-width: 768px) {
  .AU-008 .content-text-box__title {
    font-size: 2.8rem;
    margin-bottom: 15px;
  }
}
.AU-008 .content-text-box__main-content {
  color: #000;
}
.AU-009 h1 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
}
@media (min-width: 768px) {
  .AU-009 h1 {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 2.6rem;
  }
}
.AU-009 h2 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}
@media (min-width: 768px) {
  .AU-009 h2 {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 2.4rem;
  }
}
.AU-009 .awards {
  margin-bottom: 20px;
  width: 50%;
  float: left;
  padding: 0 5px;
}
@media (min-width: 768px) {
  .AU-009 .awards {
    width: 25%;
  }
}
.AU-009 .post-article {
  margin-bottom: 20px;
  width: 100%;
  float: left;
  padding: 0 5px;
}
.AU-009 .post-article p {
  font-size: 14px;
}
@media (min-width: 768px) {
  .AU-009 .post-article {
    width: 50%;
  }
}
.AU-009 .awards__image-container {
  margin-bottom: 30px;
}
.AU-009 span {
  font-size: 12px;
  color: #bcbdbc;
}
.AU-010 .au-title {
  font-size: 36px;
  font-size: 3.6rem;
  margin-bottom: 60px;
  margin-top: 60px;
}
.AU-010 .au-border {
  border-top: 5px solid #F4F4F4;
  margin-top: 50px !important;
  padding-bottom: 50px !important;
  padding-top: 50px !important;
}
.AU-010 .small-text {
  font-size: 15px;
  font-size: 1.5rem;
}
.AU-010 .year {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .AU-010 .year {
    margin-top: 30px;
  }
}
.AU-010 h2 {
  margin-top: 37px;
  margin-bottom: 20px;
  font-weight: bold;
}
@media (min-width: 768px) {
  .AU-010 h2 {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 36px;
    font-size: 3.6rem;
  }
}
.AU-010 h4 {
  font-size: 18px;
  font-size: 1.8rem;
}
.AU-010 .sub-title {
  text-align: center;
  margin-bottom: 20px;
}
.AU-010 .timeline {
  position: relative;
}
.AU-010 .timeline::before {
  content: '';
  background: #D8D8D8;
  width: 2px;
  height: 100%;
  position: absolute;
  left: 50px;
  transform: translateX(-50%);
}
.AU-010 .timeline-item {
  width: 100%;
  margin-bottom: 70px;
  position: relative;
}
.AU-010 .timeline-item::after {
  content: '';
  display: block;
  clear: both;
}
.AU-010 .timeline-content {
  position: relative;
  max-width: 100%;
  width: auto;
  margin-left: 70px;
}
@media screen and (max-width: 767px) {
  .AU-010 .timeline-content {
    max-width: 100%;
    width: auto;
    margin-left: 70px;
  }
}
.AU-010 .timeline-img {
  width: 14px;
  height: 14px;
  background: #D8D8D8;
  border-radius: 50%;
  position: absolute;
  left: 28px;
  top: 50%;
  transform: translateY(-50%);
}
.AU-010 .timeline-p-title {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #a9a9a9;
  text-transform: uppercase;
  font-weight: bold;
}
@media (min-width: 992px) {
  .AU-010 .timeline-p-title {
    font-size: inherit;
  }
}
.AU-010 .timeline-menu li {
  margin-bottom: 10px;
  color: #bcbdbc;
}
.AU-010 .timeline-menu__link {
  color: #bcbdbc;
  position: relative;
}
.AU-010 .timeline-menu__link:hover {
  color: #CF2F44;
  text-decoration: none;
}
@media (min-width: 992px) {
  .AU-010 .timeline-menu__link:hover:before {
    width: 25px;
    height: 1px;
    background: #CF2F44;
    margin: 0 1rem;
    left: -50px;
    top: 5px;
    content: '';
    position: absolute;
  }
}
.AU-010 .timeline-content--date .timeline-img {
  background: #CF2F44;
}
.AU-010 .timeline-date {
  color: #CF2F44;
}
@media screen and (max-width: 991px) {
  .AU-010 .timeline-menu {
    display: flex;
    justify-content: space-between;
  }
}
.AU-010 .message-from {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.AU-010 .message-from img {
  display: block;
  height: 150px;
  width: 235px;
  max-height: 250px;
  max-width: 250px;
  margin: 10px auto;
}
.AU-010 .message-from .timeline-tag {
  color: #CF2F44;
  font-size: 1rem;
}
.AU-010 .message-from .timeline-title {
  font-weight: bold;
  font-size: 26px;
  font-size: 2.6rem;
}
.AU-010 .message-from .timeline-text {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 24px;
}
@media (min-width: 768px) {
  .AU-010 .clearfix {
    order: 2;
  }
  .AU-010 .message-from {
    text-align: left;
    flex-direction: row;
  }
  .AU-010 .message-from .clearfix {
    width: 400px;
  }
  .AU-010 .message-from img {
    width: 100%;
    height: 250px;
    margin: 0;
    margin-left: 50px;
    max-width: 100%;
  }
}
.message-components {
  max-width: 400px;
  width: 100%;
}
.timeline {
  overflow: hidden;
}
@media (max-width: 991px) {
  .AU-001 {
    height: 420px;
  }
  .AU-001 span {
    height: 420px;
  }
  #HP004 .component > img {
    width: 100%;
  }
  .HP-004 .article-content {
    padding-left: 0;
  }
  .innovation-main-title {
    margin: 40px 0 !important;
  }
  .AU-006 .title {
    font-size: 18px;
    padding: 15px 20px;
  }
  .AU-006 .list-group li a {
    font-size: 14px !important;
    font-size: 1.4rem !important;
  }
  #AU007 .innovation-title {
    margin: 30px 0;
  }
  .AU-006 .list-group {
    margin-bottom: 30px;
  }
  #HP001 .col {
    max-width: 100%;
  }
  .HP-001 {
    height: 450px;
  }
  .HP-001 span {
    height: 450px;
  }
  .AU-003 .awards__image-container {
    height: 80px;
    margin-bottom: 10px;
  }
  .AU-003 {
    padding-bottom: 15px;
  }
  .AU-003 .awards span {
    font-size: 10px;
  }
  .SOL-007.innovation-SQL {
    padding-top: 40px !important;
  }
  #SOL007 .SOL-007 h2 {
    margin-bottom: 4rem;
  }
  .SOL-007.innovation-SQL .card-text {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 18px;
  }
  #SOL007 .SOL-007 .card.premium:not(.with-text):before {
    font-size: 1rem;
  }
  #SOL007 .SOL-007 .card {
    margin-bottom: 0;
  }
  #SOL007 .button-well {
    margin: 30px 0;
  }
  .AU-010 .timeline::before {
    left: 8px;
  }
  .AU-010 .timeline-img {
    left: -14px;
  }
  .AU-010 .timeline-content {
    margin-left: 30px;
  }
  .message-components {
    max-width: 290px;
  }
  .AU-010 .message-from .timeline-title {
    font-size: 20px;
    font-size: 2rem;
  }
  .AU-010 .message-from .timeline-text {
    font-size: 16px;
    font-size: 1.6rem;
  }
  .AU-010 .message-from img {
    margin-left: 5px;
  }
}
@media (max-width: 767px) {
  .AU-001 {
    height: 450px;
  }
  .AU-001 span {
    height: 450px;
  }
  #AU001 p {
    line-height: 24px;
  }
  .AU-002 .innovation-title {
    font-size: 24px !important;
    font-size: 2.4rem !important;
    margin: 20px 0;
  }
  .AU-001 {
    margin-bottom: 0;
  }
  .innovation-text {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 22px;
  }
  #AU002 .AU-002 {
    margin-bottom: 30px;
  }
  #HP004 h2 {
    font-size: 24px;
    font-size: 2.4rem;
    margin: 20px 0;
    line-height: 30px;
    padding-left: 15px;
  }
  #HP004 p span {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 22px;
    display: block;
  }
  .HP-004 .component .button-img img {
    width: 50%;
  }
  .innovation-main-title {
    font-size: 26px !important;
    font-size: 2.6rem !important;
    margin: 0 0 40px 0 !important;
  }
  #AU007 .innovation-title {
    font-size: 24px !important;
    font-size: 2.4rem !important;
    margin: 20px 0 20px;
  }
  .AU-006 button {
    margin-bottom: 15px;
  }
  #HP001 h2 {
    font-size: 26px;
    font-size: 2.6rem;
    line-height: 35px;
  }
  .HP-001 span {
    padding: 48px 0;
  }
  .HP-001,
  .HP-001 span {
    height: 100%;
    min-height: 360px;
  }
  #HP001 p {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 22px;
  }
  .innovation-bg b {
    font-size: 12px;
    font-size: 1.2rem;
    margin-top: 20px;
    display: inline-block;
  }
  #AU003 .innovation-main-title {
    font-size: 26px;
    font-size: 2.6rem;
    line-height: 30px;
    margin-bottom: 30px !important;
  }
  .AU-003 .awards span {
    display: block;
    line-height: 16px;
  }
  .SOL-007 h2 {
    font-size: 26px;
    font-size: 2.6rem;
    line-height: 30px;
  }
  .SOL-007.innovation-SQL .card-text {
    font-size: 18px;
    font-size: 1.8rem;
  }
  .SOL-007.innovation-SQL {
    margin-bottom: 40px !important;
  }
  .AU-010 .timeline-content {
    margin-left: 0;
  }
  .timeline-date {
    padding-left: 15px;
  }
  .AU-010 .timeline-item {
    margin-bottom: 30px;
  }
}
.responsibility-compliance .awards .awards__image-container {
  min-height: 145px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-bottom: 20px;
}
.responsibility-compliance .awards .awards__content span {
  font-size: 1.2rem;
  color: #bcbdbc;
}
.Re-001 {
  background-size: cover;
  background-position: center;
  width: 100%;
  display: block;
}
@media (min-width: 768px) {
  .Re-001 {
    height: 600px;
  }
}
.Re-001.darkBG {
  color: #fff;
}
.Re-001.darkBG .container .content p {
  border-image: linear-gradient(to bottom, #fff, #fff) 1 100%;
}
.Re-001 .container {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  height: 100%;
}
.Re-001 .container .content {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  justify-content: center;
}
@media (min-width: 768px) {
  .Re-001 .container .content {
    width: 60%;
  }
}
.Re-001 .container .content h1 {
  font-size: 76px;
  font-size: 7.6rem;
  font-family: 'Calluna', sans-serif;
  line-height: 76px;
  margin: 48px 0 48px;
}
.Re-001 .container .content p {
  font-size: 18px;
  font-size: 1.8rem;
  border-width: 4px;
  border-style: solid;
  border-image: linear-gradient(to bottom, #CF2F44, #8f191c) 1 100%;
  border-right: 0;
  line-height: 33px;
  padding-left: 20px;
}
.Re-002 .link-block {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 350px;
  width: 100%;
  padding: 15px;
}
@media (max-width: 768px) {
  .Re-002 .link-block {
    height: 200px;
    margin-bottom: 20px;
  }
}
.Re-002 .link-block a {
  color: #fff;
  text-decoration: none;
  align-items: center;
  width: 100%;
  font-size: 24px;
  line-height: 34px;
}
@media (max-width: 768px) {
  .Re-002 .link-block a {
    font-size: 25px;
  }
}
.Re-001 .Re-002 {
  margin-top: -100px;
}
@media (max-width: 768px) {
  .Re-001 .Re-002 {
    margin-top: 50px;
  }
}
.Re-002 svg {
  width: 24px;
  min-width: 24px;
  height: 25px;
  fill: #8f191c;
}
.Re-002 .stretched-link::after {
  display: block;
}
.Re-002 .stretched-link {
  text-indent: inherit;
  position: inherit;
}
@media (max-width: 1200px) {
  .Re-002 .link-block a {
    font-size: 18px;
    line-height: 24px;
  }
  .stretched-link::after {
    display: none;
  }
}
.Re-003 {
  padding: 90px 0;
  margin-top: 300px;
}
.Re-003.Re-003--home {
  margin-top: 0 !important;
  padding: 0;
}
@media (max-width: 768px) {
  .Re-003 {
    margin-top: 0;
    padding: 30px 0;
  }
}
.Re-003 .latest-content {
  margin: 0;
}
.Re-003 .article-header h2 {
  font-size: 46px;
  font-size: 4.6rem;
  font-weight: bold;
  letter-spacing: -0.3px;
  line-height: 58px;
}
@media (max-width: 768px) {
  .Re-003 .article-header h2 {
    font-size: 34px;
    font-size: 3.4rem;
  }
}
.Re-003 .article-header > p {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 30px;
}
.Re-003 .article-header > p:last-of-type {
  margin-bottom: 60px;
}
.Re-003.variant .latest-content:nth-of-type(odd) .main {
  order: 1;
}
.Re-003 .card-read-more {
  margin-top: 20px;
}
.Re-003 .card-read-more a {
  text-decoration: none;
  margin-right: 10px;
  font-size: 14px;
  font-weight: bold;
}
.Re-003 .card-read-more .svg-icon-link {
  width: 24px;
  min-width: 24px;
  height: 25px;
  fill: #8f191c;
}
.Re-004 {
  background-color: #f8f8f8;
  padding: 50px 0;
}
.Re-004 .copy-block {
  color: #00798C;
}
.Re-004 .copy-block h3 {
  font-size: 36px;
  font-size: 3.6rem;
  line-height: 45px;
}
@media (max-width: 768px) {
  .Re-004 .copy-block h3 {
    font-size: 28px;
    font-size: 2.8rem;
    line-height: 35px;
  }
}
@media (max-width: 768px) {
  .Re-004 .copy-block p {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 23px;
    margin-top: 40px;
    margin-bottom: 20px;
  }
}
.Re-004 .copy-block ul {
  color: #000;
}
@media (max-width: 768px) {
  .Re-004 .copy-block ul {
    margin-bottom: 40px;
  }
}
.Re-004 .copy-block li {
  font-weight: bold;
  border-left: 2px solid #000;
  padding-left: 10px;
}
.Re-004 .copy-block li:first-child {
  border-left: 0;
  padding-left: 0;
}
.Re-004 .card {
  background: #fff;
  border-top: 5px solid #00798C;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
  padding: 0 40px;
}
.Re-004 .card.premium p {
  margin-top: 10px;
}
.Re-004 .card.premium:before {
  font-size: 18px;
  font-size: 1.8rem;
  top: 0;
  padding: 10px 15px;
  font-weight: bold;
}
.Re-004 .card ul {
  margin-top: 30px;
}
.Re-004 .card li {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 24px;
  padding-bottom: 20px;
  font-weight: bold;
}
.Re-004 .card li:before {
  content: '';
  display: block;
  height: 22px;
  width: 22px;
  float: left;
  background-image: url(../../assets/images/svg/PremiumTick.svg);
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 0.5em;
  margin-bottom: 20px;
}
.Re-004 .card svg {
  width: 22px;
  height: 22px;
}
.Re-004 .card .btn-well {
  text-align: center;
  padding-bottom: 30px;
}
.Re-004 .social a {
  font-size: 2.3rem;
  color: #BCBDBC;
}
@media (max-width: 991px) {
  .Re-002 .link-block {
    height: 280px;
  }
  .Re-002 .link-block a {
    font-size: 12px;
    line-height: 18px;
  }
  .Re-003 {
    margin-top: 200px !important;
  }
  .card .card-bg-img {
    min-height: 320px;
    max-height: 320px;
  }
  .card .card-title {
    font-size: 24px;
  }
  .card-text {
    line-height: 24px;
  }
  .card .card-bg-img.mini-cards {
    min-height: 130px;
    max-height: 130px;
  }
  .card .card-title.card-min-ititle {
    font-size: 13px;
  }
  .Re-001 {
    height: 430px;
  }
  .Re-001 .container .content {
    justify-content: flex-start;
  }
  .Re-004 .copy-block h3 {
    font-size: 24px ;
    line-height: 28px;
  }
  .copy-block p {
    font-size: 14px;
    line-height: 22px;
  }
  .list-inline-item {
    font-size: 14px;
  }
  .Re-004 .card li {
    font-size: 13px;
    padding-left: 35px;
    position: relative;
    line-height: 18px;
  }
  .Re-004 .card li:before {
    position: absolute;
    left: 0;
  }
  .request-call__container {
    padding: 50px 20px;
  }
}
@media (max-width: 767px) {
  .Re-001 .container .content h1 {
    font-size: 36px;
    margin: 0;
  }
  .Re-001 {
    height: 250px;
  }
  .Re-001 .container .content p {
    font-size: 14px;
    line-height: 23px;
  }
  .Re-001 .Re-002 {
    margin-top: -60px;
  }
  .Re-002 .link-block {
    height: 140px;
  }
  .Re-002 .link-block a {
    font-size: 22px;
  }
  .Re-003 {
    margin-top: 600px !important;
    padding-bottom: 0;
  }
  .Re-003 .article-header > p:last-of-type {
    margin-bottom: 30px;
  }
  .card .card-title.card-min-ititle,
  .card .card-title {
    font-size: 18px;
  }
  .card .card-bg-img.mini-cards,
  .card .card-bg-img {
    min-height: 163px;
    max-height: 163px;
  }
  .Re-003 .card-read-more {
    margin-top: 0;
    margin-bottom: 20px;
  }
  .Re-004 .copy-block h3 {
    font-size: 26px;
  }
  .Re-004 {
    padding: 25px 0;
  }
  .Re-004 .copy-block p {
    font-size: 14px;
    margin-top: 20px;
  }
  .Re-004 .card li {
    font-size: 14px;
  }
  .Re-004 .card li:before {
    top: -5px;
  }
  .card-read-more {
    display: none;
  }
  .main {
    margin-bottom: 30px;
  }
  .Re-004 {
    padding-bottom: 50px;
  }
}
@media (max-width: 576px) {
  .Re-001 .Re-002 {
    margin-top: -40px;
  }
  .Re-002 .link-block a {
    line-height: 30px;
  }
  .Re-003 .article-header h2 {
    font-size: 26px;
    line-height: 40px;
  }
  .Re-003 .latest-content {
    margin: 0 -15px;
  }
  .card .card-title {
    line-height: 24px;
  }
  .Re-004 .copy-block li {
    padding-left: 5px;
  }
  .Re-004 .card.premium:before {
    padding: 10px;
  }
  .Re-004 .card.premium p {
    font-size: 16px;
    margin: 10px auto;
    margin-right: 0;
  }
  .Re-004 .card {
    padding: 0 20px;
  }
  .call-back-block {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .request-call__input {
    margin-right: 0;
    height: 46px;
    padding: 0 23px;
  }
  .request-call__intro-text {
    font-size: 26px;
  }
  .call-back-block button {
    width: 251px;
    height: 46px;
    margin-top: 25px;
  }
  .request-call__container {
    padding: 33px 15px;
  }
}
.ReCP-001 h2 {
  font-size: 76px;
  font-size: 7.6rem;
  font-family: 'Calluna', sans-serif;
  line-height: 76px;
  margin-bottom: 40px;
  margin-top: 160px;
}
.ReCP-002 .mobile-img {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}
@media screen and (max-width: 768px) {
  .ReCP-002 .mobile-img-hide {
    background: none !important;
  }
}
.ReCP-002 .hero .content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 60%;
  max-width: 100%;
  padding-left: 20px;
  padding-bottom: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 400px;
}
.ReCP-002 .hero .content .tag {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 18px;
  color: #fff;
  font-weight: normal;
  margin-bottom: 15px;
}
.ReCP-002 .hero .content h3 {
  font-size: 36px;
  font-size: 3.6rem;
  line-height: 44px;
  margin-bottom: 20px;
}
.ReCP-002 .hero .content p {
  margin-bottom: 0;
  max-width: 600px;
}
.ReCP-002 .hero-nav {
  padding: 30px 0 0;
}
.ReCP-002 .hero-nav .meta {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.ReCP-002 .hero-nav .meta .date,
.ReCP-002 .hero-nav .meta .tag {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 18px;
}
.ReCP-002 .hero-nav .meta .tag {
  color: #8F191C;
}
.ReCP-002 .hero-nav .meta .date {
  color: #BCBDBC;
}
.ReCP-002 .hero-nav h3 {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 24px;
  font-weight: bold;
}
.ReCP-002 .hero-nav .nav-block {
  padding: 10px;
  border: 1px solid grey;
  border-image: linear-gradient(to bottom, #CF2F44, #8f191c) 30 30 round;
  border-style: solid;
  border-width: 5px;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}
@media screen and (min-width: 768px) {
  .ReCP-002 .hero-nav .nav-block {
    height: 100%;
  }
}
.ReCP-002 .hero-nav .nav-block.premium {
  border-image: linear-gradient(to bottom, #00798C, #00798C) 30 30 round;
}
.ReCP-002 .hero-nav .slick-slide {
  cursor: pointer;
  width: 25% !important;
  padding-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .ReCP-002 .hero-nav .slick-slide {
    width: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .ReCP-002 .hero-nav .slick-slide {
    float: none;
    height: auto;
  }
}
.ReCP-002 .hero-nav .slick-track {
  width: 100% !important;
  transform: none !important;
}
@media screen and (min-width: 768px) {
  .ReCP-002 .hero-nav .slick-track {
    display: flex;
  }
}
.hero-nav .slick-current .nav-block {
  box-shadow: 7px 11px 7px rgba(0, 0, 0, 0.1);
}
.ReCP-003 .card-header {
  font-size: 18px;
  font-size: 1.8rem;
  border-bottom: 0;
  font-weight: bold;
  line-height: 23px;
  margin-bottom: 0;
  padding: 14px 10px;
}
@media (max-width: 768px) {
  .ReCP-003 .card-body {
    padding-bottom: 0;
  }
}
.ReCP-003 .card-body h3 {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 23px;
  margin-bottom: 22px;
}
.ReCP-003 .form-check {
  margin-bottom: 10px;
}
.ReCP-003 .form-check .form-check-input {
  border-radius: 0;
}
.ReCP-003 .form-check .form-check-label {
  margin-left: 1rem;
  line-height: 21px;
}
.checkmark-wrap {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
}
@media (min-width: 768px) {
  .checkmark-wrap {
    padding-left: 35px;
  }
}
.checkmark-wrap input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #000;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.checkmark-wrap input:checked ~ .checkmark:after {
  display: block;
}
.checkmark-wrap .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #CF2F44;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
@media screen and (max-width: 768px) {
  .ReCP-004 {
    align-items: center;
  }
}
.ReCP-004 h2 {
  font-size: 46px;
  font-size: 4.6rem;
  line-height: 54px;
}
@media screen and (max-width: 768px) {
  .ReCP-004 h2 {
    font-size: 26px;
    font-size: 2.6rem;
    text-align: center;
    margin: 0 auto 40px;
    width: 230px;
    line-height: 33px;
  }
}
@media screen and (max-width: 768px) {
  .ReCP-004 .search-wrap {
    width: 65%;
  }
}
.ReCP-004 .filter-btn {
  margin-top: 15px;
  width: 100px;
  margin-left: 20px;
  display: none;
  cursor: pointer;
  font-weight: bold;
}
.ReCP-004 .filter-btn i {
  color: #CF2F44;
  margin-left: 10px;
}
@media screen and (max-width: 768px) {
  .ReCP-004 .filter-btn {
    display: block;
  }
}
.ReCP-004 .form-control {
  font-size: 18px;
  font-size: 1.8rem;
  border-radius: 25px;
  height: auto;
  line-height: 24px;
  padding-left: 23px;
}
.ReCP-004 .btn {
  font-size: 18px;
  font-size: 1.8rem;
  border-radius: 25px;
  padding-right: 23px;
}
.ReCP-004 .btn .fa {
  color: #CF2F44;
}
.close-modal-cust {
  position: absolute;
  right: 35px;
  top: 12px;
  font-size: 20px;
  z-index: 1000;
}
.modal-cust {
  min-height: 1100px;
}
.filter-modal {
  z-index: 10000;
  padding-top: 10px;
  background: white;
}
.filter-modal .modal-content {
  border: 0;
}
.filter-modal .checkmark-wrap {
  width: 50%;
}
@media (min-width: 768px) {
  .filter-modal .checkmark-wrap {
    width: 45%;
  }
}
.filter-modal .btn {
  width: 150px;
}
.search-block-learning {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.search-block-learning .search-wrap {
  position: relative;
  width: 85%;
}
.search-block-learning input {
  border: 1px solid rgba(203, 214, 236, 0.5);
  border-radius: 25px;
  background-color: white;
  font-size: 18px;
  padding: 0 55px 0 20px;
  height: 50px;
  width: 100%;
  outline: none;
  font-weight: 400;
  font-family: sans-serif;
}
.search-block-learning span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  border: none;
}
.search-block-learning span button {
  height: 50px;
  padding: 0;
  border: none !important;
  border-radius: 0 !important;
}
@media screen and (max-width: 768px) {
  #HP002 .card-group > .card {
    flex: unset;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 992px) {
  .Re-003 .col-lg-6 > .col-lg-6 {
    float: unset;
    padding: 0;
  }
  .HP-002 .card .card-title {
    font-size: 15px;
    font-size: 1.2rem;
    line-height: 18px;
  }
  .HP-002 .card {
    padding: 30px 20px 0px;
  }
}
@media screen and (min-width: 768px) {
  .AU-004 .what-they-say-wrapper:nth-of-type(even) {
    margin-left: auto!important;
    left: auto;
    right: 0;
  }
}
.HP-002 .card-group .card.active-1 {
  color: #fff;
  background-color: #cf2f44;
  background-image: -webkit-gradient(linear, left top, right top, from(#cf2f44), to(#8f191c));
  background-image: -o-linear-gradient(left, #cf2f44, #8f191c);
  background-image: linear-gradient(90deg, #cf2f44, #8f191c);
  z-index: 0;
}
.HP-002 .card-group .card.active-1 svg {
  fill: #fff;
}
#RECP002 a {
  color: #fff;
}
.ReCP-002 .hero .content {
  width: 100%;
}
.ReCP-004 .search-block-learning .search-wrap {
  display: none !important;
}
.ReCP-005 h4 {
  font-size: 26px;
  font-size: 2.6rem;
  font-weight: bold;
  line-height: 33px;
}
@media screen and (max-width: 768px) {
  .ReCP-005 img {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .ReCP-005 .popular {
    display: none;
  }
}
.ReCP-005 .popular h4 {
  margin-bottom: 30px;
}
.ReCP-005 .popular .card {
  border-radius: 0;
  border: 1px solid grey;
  border-image: linear-gradient(to bottom, #CF2F44, #8f191c) 30 30 round;
  border-style: solid;
  border-width: 5px;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}
.ReCP-005 .popular .card.premium {
  border-top-color: #00798C;
  border-image: none;
}
.ReCP-005 .popular .card .card-body p {
  font-weight: bold;
}
.ReCP-005 .popular .card.premium {
  border-top-color: #00798C;
}
.ReCP-005 .popular .card.premium:before {
  display: none;
}
.feature-block-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 343px;
}
.ReCP-006 .article-block .article-item {
  display: none;
}
.ReCP-006 .article-block .article-item img {
  width: 100%;
}
.ReCP-006 .article-block .card.premium .premium-text {
  display: none;
}
.load-more-btn {
  width: 161px;
  cursor: pointer;
}
@media (max-width: 1200px) {
  .ReCP-001 h2 {
    margin-top: 60px;
  }
  .ReCP-002 .hero-nav h3 {
    font-size: 14px;
  }
  .ReCP-004 h2 {
    font-size: 35px;
  }
  .ReCP-004 {
    margin-bottom: 40px;
  }
  .ReCP-005 h4 {
    font-size: 21px;
  }
  .ReCP-005 .popular .card .card-body p {
    font-size: 14px;
    line-height: 20px;
  }
  .feature-block-img {
    min-height: 300px;
  }
  .feature-block h4 {
    font-size: 19px;
    line-height: 24px;
  }
  .article-block p {
    font-size: 14px;
    line-height: 22px;
  }
  .ReCP-003 .card-body h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .ReCP-003 .checkmark-wrap {
    font-size: 14px;
  }
}
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  background-color: transparent;
  border: none;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
.btn-outline-secondary:hover {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
@media (max-width: 991px) {
  #RECP002 .ReCP-002 .hero-nav h3 {
    font-size: 11px;
    line-height: 15px;
  }
  #RECP002 .ReCP-002 .hero-nav .meta .date,
  #RECP002 .ReCP-002 .hero-nav .meta .tag {
    font-size: 11px;
  }
  .RECP002 .ReCP-002 {
    margin-bottom: 20px;
  }
  .ReCP-004 h2 {
    font-size: 28px;
    line-height: 40px;
  }
  .ReCP-003 .checkmark-wrap {
    font-size: 12px;
  }
  .ReCP-005 .popular h4 {
    margin-bottom: 15px;
    font-size: 17px;
    line-height: 20px;
  }
  .ReCP-005 .popular .meta .date,
  .ReCP-005 .popular .meta .cat,
  #RECP006 .tag,
  #RECP006 .date {
    font-size: 10px;
  }
  .ReCP-005 .popular .card .card-body p {
    font-size: 11px;
    line-height: 15px;
  }
  .article-block p {
    font-size: 12px;
    line-height: 24px;
  }
  .article-block .card.premium .premium-text {
    font-size: 11px;
    background-color: #00798C;
    color: #fff;
    left: 10px;
    padding: 2px 5px;
    display: none;
  }
}
@media (max-width: 991px) {
  .ReCP-001 h2 {
    font-size: 36px;
    margin: 30px 0;
  }
}
@media (max-width: 767px) {
  .ReCP-003 .checkmark-wrap {
    font-size: 12px;
  }
  .article-block .card.premium .premium-text {
    font-size: 14px;
    font-size: 1.4rem;
    left: 10px;
    padding: 5px 10px;
  }
  #RECP002 .ReCP-002 .hero-nav .meta .date,
  #RECP002 .ReCP-002 .hero-nav .meta .tag {
    font-size: 14px;
  }
  #RECP002 .ReCP-002 .hero-nav h3 {
    font-size: 18px;
    margin-top: 10px;
  }
  .nav-block {
    min-height: 105px ;
  }
  .ReCP-005 .popular .meta .date,
  .ReCP-005 .popular .meta .cat,
  #RECP006 .tag,
  #RECP006 .date {
    font-size: 14px;
  }
  .article-block p {
    font-size: 18px;
  }
  .ReCP-002 .hero .content h3 {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 0;
  }
  .ReCP-002 .hero .content {
    padding: 20px 15px;
  }
  .ReCP-002 .hero-nav .slick-slide {
    padding-bottom: 15px;
  }
  #RECP002 .ReCP-002 .hero-nav h3 {
    line-height: 22px;
  }
  .feature-block-img {
    min-height: 163px;
  }
}
.ReTD {
  display: none;
}
.ReTD-001 p {
  color: #fff;
}
.ReTD-001 .modal-dialog {
  max-width: 1000px;
}
.ReTD-001.modal .modal-content {
  background: linear-gradient(to right, #cf2f44 0%, #8f191c 100%);
  border: 0;
}
.ReTD-001.modal .modal-content h1 {
  font-size: 66px;
  font-size: 6.6rem;
  color: #fff;
  font-family: 'Calluna', sans-serif;
}
.ReTD-001.modal .modal-content form {
  background: #fff;
  margin-bottom: 20px;
}
.ReTD-001.modal .modal-content form .form-check-label {
  color: #000;
}
.ReTD-001.modal .modal-content .modal-footer {
  border-top: 0;
}
.ReTD-002 {
  background-position: center;
  background-size: cover;
}
.ReTD-002 .card {
  background: #fff;
}
.ReTD-002 .card img {
  min-height: 460px;
}
.ReTD-002 .card .card-body.active,
.ReTD-002 .card .card-body:hover {
  background: linear-gradient(to right, #cf2f44 0%, #8f191c 99%);
}
.ReTD-002 .card .card-body.active a,
.ReTD-002 .card .card-body:hover a {
  color: #fff;
}
.ReTD-002 .card .stretched-link {
  font-size: 18px;
  font-size: 1.8rem;
  text-decoration: none;
}
.ReTD-002 .search-box-group {
  display: none;
}
.ReTD-005 {
  margin-bottom: 30px;
}
.ReTD-005 .select {
  background-color: #f4f4f4;
}
.ReTD-005 .select select {
  background-color: #f4f4f4;
  color: #000;
}
.ReTD-005 .select::after {
  background-color: #f4f4f4;
  color: #000;
}
.ReTD-007 svg {
  height: 24px;
  width: 24px;
}
.ReTD-010 {
  background: #8F191C;
  margin-top: 200px;
}
.ReTD-010 p {
  margin-bottom: 0;
}
.ReTD-010 .btn {
  border-color: #fff;
  color: #fff;
}
.ReTD-010 .btn svg {
  fill: #fff;
  height: 12px;
  width: 12px;
}
.ReTD-013 h4 {
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 34px;
  margin-bottom: 30px;
}
.ReTD-013 h4 span {
  color: #8F191C;
}
.ReTD-014 svg {
  height: 24px;
  width: 24px;
}
.ReTD-014 .smart-result {
  color: #8F191C;
}
.title-h1 {
  font-family: 'Calluna', sans-serif;
  font-size: 34px;
  margin-bottom: 20px;
}
@media screen and (min-width: 576px) {
  .title-h1 {
    font-size: 54px;
  }
}
.mb-50px {
  margin-bottom: 50px;
}
.full-bio p:first-of-type {
  font-weight: bold;
  border-width: 3px;
  border-style: solid;
  -webkit-border-image: -webkit-gradient(linear, left top, left bottom, from(#CF2F44), to(#8f191c)) 1 100%;
  -webkit-border-image: linear-gradient(to bottom, #CF2F44, #8f191c) 1 100%;
  -o-border-image: -o-linear-gradient(top, #CF2F44, #8f191c) 1 100%;
  border-image: -webkit-gradient(linear, left top, left bottom, from(#CF2F44), to(#8f191c)) 1 100%;
  border-image: linear-gradient(to bottom, #CF2F44, #8f191c) 1 100%;
  border-right: 0;
  border-bottom: 0;
  border-top: 0;
  padding-left: 15px;
  margin-bottom: 40px;
}
.leadership-full .leadership__image-container {
  margin-bottom: 15px;
}
.leadership-full img {
  text-align: center;
  padding-bottom: 5px;
  background: linear-gradient(to left, #8f191c 0%, #CF2F44 100%) left bottom #777 no-repeat;
  background-size: 100% 5px ;
}
.leadership-full__content {
  font-size: 34px;
  font-weight: bold;
}
.leadership-full__content {
  display: none;
}
@media screen and (min-width: 768px) {
  .leadership-full__content {
    display: block;
    padding-bottom: 30px;
    border-bottom: 3px solid #EAEBEA;
  }
}
.leadership-full__content h2 {
  font-size: 32px;
  font-weight: bold;
}
.leadership-full__content h3 {
  font-size: 24px;
  color: #747678;
}
.quick-link-social {
  padding-top: 15px;
  margin-top: 15px;
}
.quick-link-social ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.quick-link-social ul li {
  margin-bottom: 15px;
  font-weight: bold;
  color: #747678;
}
.quick-link-social ul li a {
  transition: transform 0.3s ease-out;
  display: inline-block;
  color: #747678;
}
.quick-link-social ul li a:hover {
  transform: translateY(-5px);
}
.quick-link-social ul li a i {
  margin-left: 10px;
  font-weight: initial;
}
.P-contact-us {
  padding-top: 150px;
  margin-bottom: 150px;
}
.P-contact-us .select-box__list li {
  margin-left: 0 !important;
  padding: 5px !important;
}
.P-contact-us .select-box__list li label {
  padding: 5px 15px;
}
.P-contact-us .P-contact-us-title h1 {
  font-size: 66px;
  font-size: 6.6rem;
  line-height: 80px;
  font-family: 'Calluna', sans-serif;
}
.P-contact-us .P-contact-us-menu {
  margin-top: 50px;
  margin-bottom: 60px;
}
.P-contact-us .P-contact-us-menu ul {
  width: 90%;
  margin: 0;
  padding: 0;
  border-bottom: 2px solid #EAEBEA;
}
.P-contact-us .P-contact-us-menu ul li {
  list-style: none;
  padding: 15px 0;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 18px;
  font-weight: bold;
  cursor: pointer;
  position: relative;
}
@media screen and (min-width: 768px) {
  .P-contact-us .P-contact-us-menu ul li:before {
    position: absolute;
    content: '';
    width: 0;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.3s;
    height: 4px;
    background: linear-gradient(308.7deg, #8F191C 0%, #CF2F44 100%);
  }
  .P-contact-us .P-contact-us-menu ul li:hover:before,
  .P-contact-us .P-contact-us-menu ul li.active-contact:before {
    width: 100% !important;
  }
}
.P-contact-us .P-contact-us-menu ul li + li {
  margin-left: 30px;
}
.P-contact-us .P-contact-us-box > p {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 28px;
  color: #BCBDBC;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 50px;
}
.P-contact-us .P-contact-us-box .P-contact-us-box-title h3 {
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 34px;
  font-weight: bold;
  margin-bottom: 35px;
}
.P-contact-us .P-contact-us-box .P-contact-phone-block > span {
  font-size: 14px;
  font-size: 1.4rem;
  color: #BCBDBC;
  font-weight: bold;
  line-height: 22px;
  padding-left: 30px;
  margin-bottom: 30px;
  display: block;
}
.P-contact-us .P-contact-us-box .P-contact-phone {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 28px;
  font-weight: bold;
  position: relative;
  padding-left: 30px;
  text-decoration: none;
  color: black;
  display: block;
}
.P-contact-us .P-contact-us-box .P-support-info p {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 0;
  line-height: 24px;
}
.P-contact-us .P-contact-us-box .P-support-info svg {
  position: absolute;
  width: 20px ;
  height: 20px ;
  fill: #8F191C;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.P-contact-us .P-contact-us-box .P-support-info .P-contact-email {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 22px;
  font-weight: bold;
  position: relative;
  padding-left: 30px;
  text-decoration: none;
  color: black;
  display: block;
}
.P-contact-us .P-contact-us-box .P-support-info .P-contact-email span {
  line-height: 10px;
  display: block;
}
.P-contact-us .P-contact-us-box .P-support-info + .P-support-info {
  margin-top: 70px;
}
.P-contact-us-js {
  display: none;
}
.active-contact-block {
  display: block;
}
.P-contact-form-bg {
  background: linear-gradient(308.7deg, #8F191C 0%, #CF2F44 100%);
  padding: 17px 30px;
}
.P-contact-form-bg > p {
  color: white;
  font-size: 26px;
  font-size: 2.6rem;
  font-weight: bold;
  margin-bottom: 35px;
}
.P-contact-form-bg .P-contact-us-form {
  background-color: white;
  width: 100%;
  padding: 30px;
  box-shadow: inset 0 0 5px 0 rgba(203, 214, 236, 0.5), 0 2px 14px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: -50px;
  border-radius: 0;
}
.P-contact-form-bg .P-contact-us-form h3 {
  font-size: 18px;
  font-size: 1.8rem;
  color: #BCBDBC;
  max-width: 325px;
  width: 100%;
  font-weight: bold;
  margin-bottom: 40px;
}
.P-contact-form-bg .P-contact-us-form p {
  font-size: 18px;
  font-size: 1.8rem;
  color: black;
  max-width: 325px;
  width: 100%;
  font-weight: bold;
  margin-bottom: 30px;
}
.P-contact-form-bg .message-box__label {
  margin-top: 45px;
}
.P-contact-form-bg .form-group {
  cursor: pointer;
  position: relative;
  padding-left: 20px;
}
.P-contact-form-bg .form-group p {
  margin-bottom: 0;
  font-weight: normal;
}
.P-contact-form-bg .form-group input {
  display: none;
}
.P-contact-form-bg .form-group input:checked + span::before {
  display: block;
}
.P-contact-form-bg .form-group label {
  display: block;
  cursor: pointer;
}
.P-contact-form-bg .form-group label span {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  border: 1px solid black;
}
.P-contact-form-bg .form-group label span::before {
  position: absolute;
  content: '';
  border: 1px solid black;
  border-left: none;
  border-bottom: none;
  width: 10px;
  height: 6px;
  transform: rotate(130deg);
  left: 3px;
  top: 2px;
  display: none;
}
form .form-check {
  margin-bottom: 60px;
  cursor: pointer;
  position: relative;
  padding-left: 20px;
}
form .form-check input {
  display: none;
}
form .form-check input:checked + span::before {
  display: block;
}
form .form-check p {
  font-size: 14px !important;
  font-size: 1.4rem !important;
  margin-bottom: 0 !important;
}
form .form-check label {
  display: block;
  cursor: pointer;
}
form .form-check label span {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  border: 1px solid black;
}
form .form-check label span::before {
  position: absolute;
  content: '';
  border: 1px solid black;
  border-left: none;
  border-bottom: none;
  width: 10px;
  height: 6px;
  transform: rotate(130deg);
  left: 3px;
  top: 2px;
  display: none;
}
.send-form button {
  width: 110px;
}
.P-contact-phone span:last-child,
.P-contact-email span:last-child {
  display: none !important;
}
.login-modal .form-icon,
.signup .form-icon {
  width: 15px;
  height: 15px;
}
.login-modal .modal-dialog,
.signup .modal-dialog {
  max-width: none !important;
}
.login-modal .rounded,
.signup .rounded {
  background-color: transparent !important;
  padding: 0;
}
.login-modal .login-form,
.signup .login-form,
.login-modal .signup-form,
.signup .signup-form {
  background-color: white;
  margin-bottom: 20px;
  position: relative;
  padding: 60px 30px;
  max-width: 450px;
  width: 100%;
}
.login-modal .login-form::before,
.signup .login-form::before,
.login-modal .signup-form::before,
.signup .signup-form::before {
  content: '';
  position: absolute;
  width: 80%;
  background-color: rgba(255, 255, 255, 0.2);
  height: 50px;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
}
.login-modal .group input,
.signup .group input {
  padding-left: 35px;
}
.login-modal .group label,
.signup .group label {
  left: 35px;
}
.login-modal .fogotten,
.signup .fogotten {
  margin-top: 10px;
  text-align: right;
}
.login-modal .fogotten a,
.signup .fogotten a {
  font-size: 14px;
  font-size: 1.4rem;
  color: #BCBDBC;
  text-decoration: none;
  text-align: right;
}
.login-modal .group,
.signup .group {
  margin-bottom: 0;
}
.login-modal .close,
.signup .close {
  width: 24px;
  height: 24px;
  right: 60px !important;
  top: 60px;
  z-index: 999;
}
.login-modal .group + .group,
.signup .group + .group {
  margin-top: 20px;
}
.login-modal .modal-footer,
.signup .modal-footer {
  margin-top: 20px;
}
.login-modal .modal-footer button,
.signup .modal-footer button {
  background-color: transparent;
  border: 1px solid #BCBDBC;
  color: #BCBDBC;
  width: 110px;
}
.login-modal .form-check,
.signup .form-check {
  margin-bottom: 0;
}
.login-modal .form-check p,
.signup .form-check p {
  color: black;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 10px;
}
.signup .form-check {
  margin-bottom: 0;
}
.signup .form-check p {
  color: black;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 20px;
}
@media (max-width: 1200px) {
  .P-contact-form-bg .form-group p {
    font-size: 14px;
    font-size: 1.4rem;
  }
  .form-check p {
    font-size: 13px !important;
    font-size: 1.3rem !important;
  }
  .form-check {
    margin-bottom: 35px;
  }
}
@media (max-width: 991px) {
  .P-contact-us-form {
    margin-top: 60px;
  }
  .P-contact-form-bg {
    background: none;
    padding: 30px 20px;
    -webkit-box-shadow: inset 0 0 5px 0 rgba(203, 214, 236, 0.5), 0 2px 14px 0 rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 5px 0 rgba(203, 214, 236, 0.5), 0 2px 14px 0 rgba(0, 0, 0, 0.1);
    border-radius: 0;
  }
  .P-contact-form-bg .P-contact-us-form {
    box-shadow: none ;
    padding: 0 ;
    margin-bottom: 0;
    margin-top: 30px;
  }
  .P-contact-form-bg > p {
    color: black;
    margin-bottom: 0;
  }
  .login-form {
    margin: 0 auto;
  }
  .login-modal,
  .signup h1 {
    text-align: center;
    margin-bottom: 45px;
  }
}
@media (max-width: 767px) {
  .P-contact-us {
    padding-top: 60px;
  }
  .P-contact-us .P-contact-us-menu {
    margin: 30px 0 40px 0;
  }
  .P-contact-us-desktop {
    display: none;
  }
  .P-contact-us .P-contact-us-menu ul {
    width: 100%;
  }
  .active-contact label {
    color: black;
  }
  .signup .form-check {
    margin-bottom: 0;
  }
  .signup .form-check p {
    color: black;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 20px;
  }
  .signup .signup-form {
    margin: auto;
  }
}
@media (max-width: 576px) {
  .P-contact-info {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .P-contact-phone,
  .P-contact-email {
    width: 120px;
    height: 46px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border: 1px solid #CF2F44;
  }
  .P-contact-phone svg,
  .P-contact-email svg {
    left: 15px!important;
  }
  .P-contact-phone span,
  .P-contact-email span {
    display: none !important;
  }
  .P-contact-phone span:last-child,
  .P-contact-email span:last-child {
    display: block !important;
    color: #CF2F44;
    line-height: 1;
    margin-top: 5px;
  }
  .P-contact-us .P-contact-us-box .P-contact-phone-block > span {
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 10px;
    width: 115px;
    font-size: 12px;
    font-size: 1.2rem;
  }
  .P-contact-us .P-contact-us-box .P-contact-us-box-title h3 {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 24px;
    margin-bottom: 14px;
  }
  .P-contact-us .P-contact-us-box .P-support-info + .P-support-info {
    margin-top: 40px;
  }
  .P-contact-us .P-contact-us-title h1 {
    font-size: 36px;
    font-size: 3.6rem;
    line-height: 40px;
  }
  .P-contact-us {
    padding-top: 30px;
    margin-bottom: 60px;
  }
  .P-contact-us .P-contact-us-box > p {
    margin-bottom: 20px;
  }
  .P-contact-form-bg > p {
    font-size: 18px;
    font-size: 1.8rem;
  }
  .P-contact-form-bg .P-contact-us-form h3 {
    font-size: 14px;
    font-size: 1.4rem;
    margin-bottom: 0;
  }
  .P-contact-form-bg .P-contact-us-form p {
    margin: 20px 0;
  }
  .form-row {
    flex-direction: column;
  }
  .form-row p {
    margin: 0 !important;
  }
  .group label {
    font-size: 14px;
    margin-bottom: 0;
  }
  .form-icon {
    margin-top: -8px;
  }
  .form-check p {
    line-height: 18px;
  }
  .login-modal h1 {
    font-size: 36px !important;
    font-size: 3.6rem !important;
  }
  .login-modal,
  .signup .close {
    right: 10px !important;
    top: 20px;
  }
  .login-modal,
  .signup .modal-footer {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .login-modal,
  .signup .form-check {
    margin-bottom: 30px;
  }
  .login-modal,
  .signup .login-form {
    padding: 30px 15px;
  }
  .login-modal,
  .signup .modal-footer button {
    width: 100%;
  }
}
.form-icon.icon-facility {
  background-image: url(../../assets/images/user/facility.png);
}
.form-icon.icon-tel {
  background-image: url(../../assets/images/user/tel.png);
}
.form-control {
  font-size: 18px;
}
#RE033 {
  height: 100%;
}
@media screen and (min-width: 1200px) {
}
#HP032 {
  height: 100%;
}
#HP032 .box-padding {
  height: 100%;
}
.tab-wrapper-primary {
  height: 100%;
}
.tab-wrapper-primary .nav {
  border-bottom: 2px solid #EAEBEA;
  white-space: nowrap;
  overflow-y: hidden;
  flex-wrap: nowrap;
}
.tab-wrapper-primary .nav li a {
  border: 0 !important;
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
  font-size: 1.8rem;
  margin-right: 20px;
  color: #212529;
  font-weight: bold;
}
.tab-wrapper-primary .nav li a:before {
  position: absolute;
  content: '';
  width: 0;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.3s;
  height: 4px;
  background: linear-gradient(308.7deg, #8F191C 0%, #CF2F44 100%);
}
.tab-wrapper-primary .nav li a:hover:before {
  width: 100%;
  background: linear-gradient(308.7deg, #8F191C 0%, #CF2F44 100%);
}
.tab-wrapper-primary .nav li a.active {
  color: #212529;
}
.tab-wrapper-primary .nav li a.active:before {
  background: linear-gradient(308.7deg, #8F191C 0%, #CF2F44 100%);
  width: 100%;
}
.tab-wrapper-primary .nav li:last-child a {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .tab-wrapper-primary {
    padding: 15px;
    border: 1px solid #dee2e6;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .tab-wrapper-primary .nav li a {
    margin-right: 40px;
  }
}
.list-group .list-group-item {
  background-color: transparent;
  padding-left: 0;
}
.list-group .list-group-item a {
  font-weight: bold;
  font-size: 80%;
}
.dot {
  height: 8px;
  width: 8px;
  align-self: center;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-right: 15px;
}
.order__img {
  max-width: 250px;
  margin: auto;
  margin-right: 10px;
}
@media screen and (min-width: 768px) {
  .order__img {
    max-width: 140px;
  }
}
.order-options {
  height: 100%;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .order-options .list-group {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .order-wrapper .list-group {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .order-wrapper {
    padding: 1.5rem;
  }
}
.order-wrapper .order-item.card {
  padding: 1.5rem;
}
@media screen and (min-width: 768px) {
  .order-wrapper .order-item.card {
    background-color: #fff;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 767px) {
  .order-wrapper .order-item.card {
    border: 0;
    padding: 0;
  }
}
.order-wrapper.dispatched .dot {
  background-color: #76A95A;
}
.order-wrapper.dispatched .order-date,
.order-wrapper.dispatched .order-date-short,
.order-wrapper.dispatched .order-status-short,
.order-wrapper.dispatched .order-status-full,
.order-wrapper.dispatched .order-date-full {
  color: #76A95A;
}
.order-wrapper.delivered .dot {
  background-color: #BCBDBC;
}
.order-wrapper.delivered .order-date,
.order-wrapper.delivered .order-date-short,
.order-wrapper.delivered .order-status-short,
.order-wrapper.delivered .order-status-full,
.order-wrapper.delivered .order-date-full {
  color: #BCBDBC;
}
.box-padding {
  padding: 10px;
}
@media screen and (min-width: 768px) {
  .box-padding {
    padding: 15px;
  }
}
.box-padding-lg {
  padding: 15px;
}
@media screen and (min-width: 768px) {
  .box-padding-lg {
    padding: 30px;
  }
}
.background-primary-gradient {
  background-color: #cf2f44;
  background-image: linear-gradient(90deg, #CF2F44, #8F191C);
}
.order-wrapper .order-item .left-container-mx-w-40pc {
  max-width: 35%;
}
.order-wrapper .order-item .left-container-mx-w-40pc {
  max-width: 35%;
}
.order-wrapper .order-item .right-container-mn-w-60pc {
  min-width: 60%;
}
@media screen and (max-width: 767px) {
  .order-wrapper .order-item .right-container-mn-w-60pc {
    justify-content: center;
    align-items: center;
    margin-right: auto;
  }
}
.order-wrapper .order-item .right-container-mn-w-5pc {
  min-width: 5%;
  justify-content: center;
  align-items: center;
  color: #CF2F44;
}
@media screen and (min-width: 768px) {
  .order-wrapper .order-item .right-container-mn-w-5pc {
    display: none !important;
  }
}
.order-wrapper .order-item .order-num,
.order-wrapper .order-item .order-desc,
.order-wrapper .order-item .order-date-full {
  display: none;
}
@media screen and (min-width: 768px) {
  .order-wrapper .order-item .order-status-short,
  .order-wrapper .order-item .order-date-short {
    display: none;
  }
  .order-wrapper .order-item .order-num,
  .order-wrapper .order-item .order-desc,
  .order-wrapper .order-item .order-date-full {
    display: block;
  }
}
.box-padding .card:last-child {
  margin-bottom: 0 !important;
  color: red;
}
@media screen and (min-width: 479px) and (max-width: 991px) {
  .box-padding .card:first-of-type {
    margin-right: 2%;
    float: left;
  }
}
@media screen and (min-width: 479px) and (max-width: 991px) {
  .box-padding .card {
    width: 49%;
    margin-bottom: 0 !important;
    padding-bottom: 20px;
  }
}
.coming-soon {
  background-size: 150% 300%;
  background-position: center;
}
.coming-soon .coming-soon-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.coming-soon .coming-soon-content ul {
  margin: 10px 0;
}
@media screen and (min-width: 768px) {
  .coming-soon .coming-soon-content ul {
    max-width: 45%;
  }
}
.coming-soon .coming-soon-content .coming-soon__right {
  margin: auto;
}
@media screen and (min-width: 768px) {
  .coming-soon .coming-soon-content .coming-soon__right img {
    max-width: 300px;
  }
}
.list-bullet-img {
  padding-right: 8px;
  position: relative;
  top: -5px;
}
.secondary-color {
  color: #8F191C;
}
.orthoplus .POS-001 {
  background: #F8F8F8;
  padding: 20px;
  display: none;
}
@media screen and (min-width: 1200px) {
  .orthoplus .POS-001 {
    display: block;
  }
  .orthoplus .POS-001 nav {
    display: inline-block;
  }
  .orthoplus .POS-001 > span {
    float: right;
    font-size: 12px;
    line-height: 30px;
  }
}
.orthoplus .POS-001 .breadcrumb a {
  text-decoration: none;
  color: #404040;
}
.orthoplus .POS-001 .breadcrumb a:after {
  display: none !important;
}
.orthoplus .POS-001 .breadcrumb a {
  border: 0 !important;
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
  font-size: 1.8rem;
  margin-right: 20px;
  color: #212529;
  font-weight: bold;
}
.orthoplus .POS-001 .breadcrumb a:before {
  position: absolute;
  content: '';
  width: 0;
  bottom: -23px;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.3s;
  height: 4px;
  background: linear-gradient(308.7deg, #8F191C 0%, #CF2F44 100%);
}
.orthoplus .POS-001 .breadcrumb a:hover:before {
  width: 100%;
  background: linear-gradient(308.7deg, #8F191C 0%, #CF2F44 100%);
}
.orthoplus .POS-001 .breadcrumb a.active {
  color: #212529;
}
.orthoplus .POS-001 .breadcrumb a.active:before {
  background: linear-gradient(308.7deg, #8F191C 0%, #CF2F44 100%);
  width: 100%;
}
.orthoplus .POS-001 .breadcrumb:last-child a {
  margin-right: 0;
}
.POS-001 .breadcrumb a.active {
  color: #CF2F44;
}
.orthoplus .POS-001 .breadcrumb a:hover {
  color: #CF2F44;
  border-image: linear-gradient(308.7deg, #8F191C 0%, #CF2F44 100%);
  border-style: solid;
  border-width: 3px;
}
.orthoplus .POS-001 .breadcrumb a.active:after,
.orthoplus .POS-001 .breadcrumb a:hover:after {
  display: block;
  position: relative;
  top: 20px;
}
.orthoplus .breadcrumb-item + .breadcrumb-item::before {
  display: none;
}
.orthoplus .POS-001 .breadcrumb {
  margin-bottom: 0;
}
.orthoplus .breadcrumb-item {
  padding: 0 20px 0 0;
}
.orthoplus .ReCP-001 h2 {
  font-size: 76px;
  font-size: 7.6rem;
  font-family: 'Calluna', sans-serif;
  line-height: 76px;
  margin-bottom: 40px;
  margin-top: 60px;
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider.slick-track,
.slick-slider.slick-list {
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
.slick-slide:focus {
  outline: 0;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
  width: 100%;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
/* Slider */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover,
.slick-next:hover,
.slick-prev:focus,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Icons */
}
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url('./fonts/slick.eot');
  src: url('./fonts/slick.eot?#iefix') format('embedded-opentype'), url('./fonts/slick.woff') format('woff'), url('./fonts/slick.ttf') format('truetype'), url('./fonts/slick.svg#slick') format('svg');
}
.slick-prev {
  left: -25px;
}
[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir="rtl"] .slick-prev:before {
  content: "→";
}
.slick-next {
  right: -25px;
}
[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir="rtl"] .slick-next:before {
  content: "←";
}
/* Dots */
.slick-dotted .slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .slick-dots {
    display: none;
  }
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}
.slick img {
  width: 100%;
}
