.ReTD-010 {
  background: #8F191C;
  margin-top: 200px;

  p {
    margin-bottom: 0;
  }

  .btn {
    border-color: #fff;
    color: #fff;

    svg {
      fill: #fff;
      height: 12px;
      width: 12px;
    }
  }
}
