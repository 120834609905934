.AU-003 {
  padding-bottom: 75px;
  h2 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
    text-align: center;
    @media(min-width: @bpMD) {
      margin-top: 30px;
      margin-bottom: 30px;
      font-size: 2.4rem;
    }
  }
  .awards {
    margin-bottom: 20px;
      span{
        line-height: 18px !important;
      }
  }
  .awards__image-container {
    margin-bottom: 30px;
    height: 120px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  span {
    font-size: 12px;
    color: @color-brand-grey;
  }
}

#AU003{
  .innovation-main-title{
    .font-size(4.6);
    margin: 90px ;
  }
}
