@red1: #cf2f44;
@red2: #8f191c;
@white: #ffffff;

.request-call {
  background-image: none;
  background-color: @red1;
  background: linear-gradient(to right, darken(@red1, 5%), @red2);
  color: @white;
}

.request-call__container {
  padding: 30px 20px;
  min-height: 200px;
  display: flex;
  justify-content: center;
  .square-link-white {
    padding: 1.4rem;
  }
}

.request-call__intro-text {
    margin-bottom: 20px;
    margin-top: 20px;
    margin-right: 20px;
    font-size: 3.2rem;
    text-align: center;

}

.request-call-text {
    margin-bottom: 20px;
    margin-top: 20px;
    margin-right: 20px;
    font-size: 1.8rem;
    line-height: 28px;
}

.request-call-number {
    margin-bottom: 20px;
    margin-top: 20px;
    margin-right: 20px;
    font-size: 3.6rem;
    line-height: 34px;
}

.request-call__btn {
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
  @media  (min-width: 400px)  {
    width: auto;
  }
}



// button helpers
// --------------------------------------------------



.square-link-red {
    color: @red1;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    border: 1px solid @red1;
    padding: 0.8rem 1.4rem;
    text-decoration: none;
    background-color: transparent;
    @include transition(.4s ease-in-out);
    &:hover {
      background-color: @red1;
      color: @white;
    }
  }
  p a.square-link-red {
    margin-left: 1rem;
  }

  .square-link-white {
    color: @white;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    border: 1px solid white;
    padding: 1rem 1.4rem;
    text-decoration: none;
    background-color: transparent;

    &:hover {
      background-color: white;
      color: @red1 !important;
      text-decoration: none;
    }
  }

  .square-input-white {
    color: @white;
    font-size: 14px;
    font-weight: 200;
    line-height: 22px;
    border: 1px solid transparent;
    padding: 0.8rem 1.4rem;
    text-decoration: none;
    background-color: rgba(255,255,255,0.15);
    &::placeholder {
        color: @white;
    }
    &:hover {
      background-color: @white;
      color: @red1;
    }
  }


.request-call__containerALT {
  .col-auto-container {
    .request-call-text {
      // @media screen and (max-width: 1199px) {
      //   min-width: 300px;
      // }
    }
    @media screen and (min-width: 1200px) {
      height: 100%;
    }
    a {
      color: white;
      &.text-nowrap {
        svg {
          fill: white;
        }
      }
    }
  }
}
