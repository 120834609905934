.login,
.signup {
  p {
    color: #fff;
  }

  .modal-dialog {
    max-width: 1000px;
  }

  &.modal {
    background: @color-brand-red;

    .close {
      position: absolute;
      right: -10px;
      color: white;

      svg {
        fill: #fff;
        width: 24px;
        height: 24px;
      }
    }

    .modal-content {
      background: transparent;
      border: 0;

      h1 {
        .font-size(6.6);
        color: #fff;
        font-family: @font-family-secondary;
      }

      form {
        background: white;
        margin-bottom: 20px;

        .form-check-label {
          color: #000;
        }
      }

      .modal-footer {
        border-top: 0;
      }
    }
  }
}
