@background-footer-color: #404040;
@border-white: #fff;

footer {
  background-color: @background-footer-color;
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 0;
  color: @color-neutral-white;
  position: relative;

  p {
    color: @color-neutral-white;
  }

  ul {
    list-style: none;
    margin-bottom: 0;
  }

  a {
    color: @color-neutral-white;
    text-decoration: none;
    transition: 400ms ease-in-out;
    display: block;

    &:hover {
      text-decoration: none;
      color: @color-neutral-white;
    }
  }

  .select {
    select,
    & {
      background: rgba(25, 25, 25, 0.3);
    }

    &:hover {
      background: rgba(25, 25, 25, 0.3);
    }

    &:after {
      background: rgba(25, 25, 25, 0.3);
    }
  }
  .dropdown--custom-footer {
    // margin-right: 10%;
    position: absolute;
    bottom: 0;
    right: 0;
    select {
      padding: 10px;
      background: none;
      background: fade(#000, 20%);
      color: #fff;
      margin-left: -30px;
      padding-left: 30px;
      border: 0;
    }
    option {
        line-height: 24px;
      &:selected{
        padding: 10px;
        color: #fff;
        background: none;
      }
    }
    .dropup-options {
      background: fade(#000, 80%);
      &:hover {
        background: red;
      }
    }
  }
  .footer__copyright {
    color: #747679;
    font-size: 1.2rem;
  }
}

.footer__quick-links {
  margin: 20px 0;

  li {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      font-size: 14px;
      line-height: 17px;
      margin-right: 25px;
      text-transform: uppercase;
      font-weight: 500;
      @media screen and (min-width: 768px) {
        text-transform: initial;
        font-weight: 500;
        &.text-inherit {
          color: #bcbdbc;
          text-transform: inherit;
          font-size: 1.2rem;
          font-weight: 500;        }
      }
      &.text-inherit {
        text-transform: inherit;

      }
    }
  }
}

.footer--services {
  ul {
    li {
      a {
        color: #bcbdbc;
        font-size: 12px;
        line-height: 24px;

        &:active,
        &:focus,
        &:hover {
          color: @color-neutral-white;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .footer {
    .active {
      -webkit-transition: background-color 0.5s ease-in-out;
      transition: background-color 0.5s ease-in-out;
    }
  }

  .footer {
    .active {
      .footer-list {
        max-height: 500px;
        -webkit-transition: max-height 0.5s;
        transition: max-height 0.5s;
      }
    }
  }

  .footer-menus {
    > li {
      border-bottom: 2px solid #747678;

      &:first-child {
        border-top: 2px solid #747678;
      }
    }
  }

  .footer-menus {
    > li {
      h4.title {
        line-height: 1.4rem;
        font-size: 1.4rem;
        text-transform: inherit;
        font-weight: 500;
        .footer-drop-down {
          right: 0;
          position: absolute;
          top: -webkit-calc(50% - 8px);
          top: calc(50% - 8px);
          width: 14px;
          height: 8px;
          background-position: 0 -930px;
          transform: scale(.8);
          -ms-transform: scale(.8);
          -webkit-transform: scale(.8);
          &:before {
            font-size: 2rem;
          }
        }
      }
    }
  }

  .footer {
    .active {
      .footer-drop-down {
        transform: rotate(180deg) scale(.8)!important;
        -ms-transform: rotate(180deg) scale(.8)!important;
        -webkit-transform: rotate(180deg) scale(.8)!important;
      }
    }
  }

  .footer-list {
    > li {
      padding: 0 0 5px 15px;
    }
  }
}
@media (min-width: 768px) {
  .footer__quick-links {
    ul {
      display: flex;
      padding: 45px 0;
      margin-bottom: 0;
    }
  }

  .footer__quick-links {
    a {
      &:hover {
        transform: translateY(-5px);
      }
    }
  }

  .footer__quick-links {
    &.footer__quick-links--primary {
      padding: 20px 0 40px;
      margin-bottom: 0;
    }
  }
}

.footer {
  -webkit-transition: background-color 0.5s ease-in-out;
  transition: background-color 0.5s ease-in-out;
}

.footer-content {
  margin: 20px auto;
  //max-width: 1280px;
}

.footer {
  ul {
    margin: 0;
    padding: 0;
  }
}

.footer-menus {
  width: 100%;
}

.col-lg-5ths,
.col-md-5ths,
.col-sm-5ths,
.col-xs-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.footer-menus {
  > li {
    .title {
      margin: 0;
      width: 100%;
      cursor: pointer;
      position: relative;
      display: inline-block;
      font-size: 1.0666666667rem;
      text-transform: uppercase;
      color: #fff;
      font-weight: 600;
      padding: 20px 0;
    }
  }
}

.footer-list {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.5s;
  transition: max-height 0.5s;

  > li {
    > a {
      line-height: 2.3;
      font-size: 1.2rem;
      color: @color-grey-footer;

      &:active,
      &:focus,
      &:hover {
        color: @color-neutral-white;
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .col-md-5ths {
    width: 25%;
    float: left;
  }

  .col-md-5ths--2x {
    width: 50%;

    ul {
      column-count: 2;
    }
  }

  .footer-menus {
    > li {
      .title {
        width: auto;
        cursor: default;
        color: #bcbdbc;
        text-transform: inherit;
        font-size: 1.2rem;
        font-weight: 500;
      }
    }
  }

  .footer-list {
    max-height: none;
  }
}

.footer__quick-links {
  &.footer__quick-links--social {
    li {
      &:last-child {
        a {
          margin-right: 0;
        }
      }

      a {
        font-weight: normal;
      }
    }
  }
}
// helper

.divider-md {
  @media (min-width: @bpMD) {
    height: 1px;
    background-color: @border-white;
  }
}

.footer {
  .fab {
    font-size: 18px;
  }
}

.back-to-top {
  bottom: 30px;
  height: 45px;
  position: fixed;
  right: 30px;
  width: 45px;
  z-index: 1060;

  a {
    align-items: center;
    border: 1px solid @color-brand-red;
    background-color: @color-neutral-white;
    color: @color-brand-red;
    display: flex;
    height: 45px;
    justify-content: center;
    left: 0;
    position: absolute;
    text-align: center;
    text-decoration: none;
    top: 0;
    width: 45px;
  }

  svg.icon {
    transform: rotate(180deg) translateX(-5px) translateY(3px);
    fill: #CF2F44;
    width: 20px;
    height: 20px;
  }
  @media(max-width: @bpSM) {
    bottom: 15px;
    height: 40px;
    right: 10px;
    width: 40px;

    a {
      height: 40px;
      width: 40px;
    }
  }
}
