.SOL-004 {
  h3 {
    .font-size(2.6);
    line-height: 33px;
  }

  .blockquote {
    color: #8F191C;
    p {
      .font-size(2.6);

      span {
        &:before {
          content: '“';
        }

        &:after {
          content: '”';
        }
      }
    }
    cite {
      .font-size(1.4);
      color: #747678;
      display: block;
      margin-left: 10px;

      @media screen and (min-width: @bpMD) {
        display: inline-block;
      }
    }
  }
}
