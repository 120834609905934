.POS-005 {
  a {
    color: #BCBDBC;
  }
  .flex {
    display: flex;
    i {
      &:before {
        font-size: 21px;
        margin: 0 8px;
      }
    }
  }
  &.absolute {
    position: absolute;
    left: 0px;
    top: 100px;
    @media screen and (min-width: @bpMD) {
      left: -15px;
    }
  }
}
