.PP-005 {
  .image-hero {
    display: flex;
    height: 50vh;
    background-position: center;
    background-size: cover;
    min-height: 600px;
    overflow: hidden;

    h2 {
      .font-size(4.6);
      border-left: 3px solid #fff;
      line-height: 58px;
      margin-bottom: 20px;
      padding-left: 20px;
    }

    p {
      .font-size(1.8);
    }

    a {
      .font-size(1.4);
    }

    .container {
      .row,
      & {
        height: 100%;
      }

      .row {
        .dark {
          color: #fff;
        }
      }
    }
  }

  .slider-controls {
    .slide-dots {
      .slick-dots {
          position: relative;
          list-style: none;
          bottom: 0;
          margin: 20px 30px;
          width: auto;

          li {
            background-color: gray;

            &.slick-active {
              background-color: @color-brand-red;
            }
          }
      }
    }

    .slide-next,
    .slide-prev {
      display: block;
      height: 14px;
      width: 24px;
      cursor: pointer;
      background: transparent;
      fill: @color-brand-red;
      padding: 0;
      &:hover,
      &:focus {
        fill: lighten(@color-brand-red, 20%);
      }
    }


  }
}
