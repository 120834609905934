// based of
.SOL-003 {
  position: relative;

  &:nth-of-type(even) {
    .article {
      justify-content: flex-start;

      img {
        left: auto;
        right: 0;
      }
    }
  }

  @media (min-width: 768px) {
    overflow: hidden;
  }

  img {
    width: 100%;

    @media (min-width: 768px) {
      width: 60%;
    }
  }

  .content {
    @media (min-width: 768px) {
      background: #fff;
      margin: 20px 0;
      display: flex;
      flex-flow: wrap;
      width: 50%;
      padding: 20px;
      z-index: 999;

      .btn {
        align-self: flex-start;
        margin-right: 10px;
      }
    }

    h2 {
      .font-size(4.6);
      .borderLeft();
      line-height: 54px;
      margin: 50px 0;
    }

    p {
      .font-size(1.8);
      margin-bottom: 50px;
    }

    .arrow-link {
      @media (max-width: @bpMD - 1px) {
        display: block;
        margin-top: 10px;
        align-self: flex-start;
      }
      align-self: center;
    }
  }
}
