/* #### Search page updates #### */
.search.ReCP-001 h2 span {
  font-size: 18px;
  margin-left: 30px;
  color: #BCBDBC;
  font-family: 'Overpass', sans-serif;
}
.search.ReCP-001 h2 span b {
  color:#000;
}
.search.ReCP-003, .search.ReCP-006 {
  padding: 10px;
-webkit-border-image: -webkit-gradient(linear, left top, left bottom, from(#CF2F44), to(#8F191C)) 30 30 stretch;
-webkit-border-image: linear-gradient(to bottom, #CF2F44, #8F191C) 30 30 stretch;
-o-border-image: -o-linear-gradient(top, #CF2F44, #8F191C) 30 30 stretch;
border-image: -webkit-gradient(linear, left top, right top, from(#CF2F44), to(#8F191C)) 30 30 stretch;
border-image: linear-gradient(to right, #CF2F44, #8F191C) 30 30 stretch;
border-style: solid;
border-width: 5px;
border-bottom: 0;
border-left: 0;
border-right: 0;
}
.search.ReCP-006 {
  padding-top: 0;
}
.search.ReCP-001 h2 {
  margin-bottom: 80px;
}
.search.ReCP-003 h3 {
  color: #000;
  margin-bottom: 0px;
}
.search.ReCP-003 h3.active {
  color: #CF2F44;
  padding-left: 20px
}
.search.ReCP-003 p {
  font-size:14px;
  color: #BCBDBC;
  font-weight: bold;
}
.search.ReCP-006 .article-item{
  padding: 1.25rem 5px 0 ;
}
.search .card {
  margin-bottom: 0;
}
.search .card .card-title {
  font-size: 36px;
  font-size: 2rem;
  line-height: 30px;
}
.search .card-meta .tag  {
  font-size:14px;
  color: #BCBDBC
}
.search .card-text  {
  font-size:14px;
  color: #000;
  line-height: 28px;
}
.search a:hover h3 {
  color: #CF2F44;
}
.search .card-text span, .search .card .card-title span {
  color: #CF2F44
}
.search .pagination a {
  color: #000;
  padding: 5px;
  font-weight: bold
}
.search .pagination a.active, .search .pagination a:hover  {
  color: #CF2F44;
}
.search .card-body:hover .card-title {
  color: #CF2F44;
}
@media (max-width: 768px) {
  .search.ReCP-001 h2 span {
    margin-left: 0;
  }
  .search.ReCP-001 h2 {
    line-height: 35px;
  }
  .search.ReCP-003, .search.ReCP-006 {
  border-image: none;
  border: 0;
  }
  .search.ReCP-006 .article-item {
    padding: 1rem 5px 0;
  }
}

.search-block.OD-002 .select-box__input-text {
  display: block !important;
}
 .search-block .select-box__option a {
  color: #fff
}
.search-block .select-box__option a:hover {
  opacity: 0.7
}
