.label span{
    text-align: left;
    font: normal normal 24px/29px Rubik;
    letter-spacing: 0.01px;
    color: #34383C;
    text-transform: capitalize;
}
.country {
    font-size: 14px;
    color: #34383C;
}
.details {
    font: normal normal normal 14px/21px Rubik;
    letter-spacing: 0px;
    color: var(--indigo);
    margin: 10px 35px;
    text-decoration: underline;
    cursor: pointer;
}
.sharedText{
    position: absolute;
    margin-top: -27px;
    background: var(--indigo);
    color: white;
    padding: 4px;
    border-radius: 3px;
}
.collapsedView {
    background: 0% 0% no-repeat padding-box padding-box rgb(243, 246, 247);
    border-radius: 8px;
    display: grid;
    grid-template-columns: 4fr 4fr;
    padding: 10px 0;
}

.testingthis svg {
    max-width: 16px;
  }
  @media (min-width: 360px) and (max-width:740px){
    .blockMainTitle{
        padding: 0px 10px !important;
    }
  }
  /* Responsive layout - makes a one column layout instead of a two-column layout */
  @media (max-width: 576px) {
    .collapsedView {
        grid-template-columns: 4fr;
    }
  }
  @media screen and (min-width: 576px) and (max-width: 992px) {
    .collapsedView {
        grid-template-columns: 3fr 7fr;
    }
  }
  @media (min-width: 820px) and (max-width:900px){
    .blockMainTitle{
        white-space: unset !important;
        padding-right: 12px;
    }}
  .blockMainTitle{
    color: #222 !important;
    font-size: 14px;
    font-weight: normal;
    max-width: 450px;
    display: inline-block;
    width: 100%;
    line-height: normal;
    vertical-align: middle;
    margin-left: 0px;
    margin-top: 2px ;
    padding: 0px 43px;
    text-align: right;
  }

  .blockMainTitle span {
      margin: 0 5px;
  }

  .expandedView {
      padding: 0 10px;
  }
  .expandedView input {
      margin-right: 10px;
  }

  .formHeader {
    padding: 0 0 0 35px;
    color: #34383c;
    font-size: 15px;
    font-weight: 500;
    justify-content: space-between;
    align-items: center;
    opacity: .64;
  }