.PP-004 {
  h2 {
     @media screen and (max-width: @bpMD) {
      .font-size(2.6);
      text-align: center;
      margin: 0 auto 40px;
      line-height: 33px;
    }
    margin-bottom: 80px;
    margin-top: 40px;
  }

  h3 {
    .font-size(1.8);
    line-height: 23px
  }

  p {
    .font-size(1.4);
    line-height: 18px;

    &:last-child {
      margin-bottom: 20px;
    }
  }

  .icon-box {
    img {
      width: 96px;
      height: 96px;
      margin: 0 auto 20px;
    }
  }
  .slick-dots {
    bottom: -20px;
  }
}
