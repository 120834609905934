.POS-015 {
  background: #F4F4F4;

  h4 {
    margin-bottom: 20px;
  }

  .user-profile {
    display: flex;
    margin-bottom: 32px;

    img {
      width: 78px;
      height: 78px;
    }

    p {
      border-image: linear-gradient(to bottom, #CF2F44, #8f191c) 1 100%;
      border-width: 2px;
      border-right-width: 0;
      border-style: solid;
      line-height: 23px;
      margin-left: 2rem;
      padding-left: 20px;
      strong, span {
      margin-bottom: 10px;
      }
    }
  }

  p {
    span,
    strong {
      display: block;
      margin-bottom: 20px;
    }
  }
}
