@top-panel-btn-footer-bg: #404040;
@color4: #f0f0f0;
@color7: #04274d;
@zindex-navbar-fixed: 1030;
@dropdown-bg: @color-neutral-white; // Dropdown menu `border-color`.
@dropdown-border: @dropdown-bg; // Sidepanel
@sidepanel-bg: linear-gradient(90deg,@top-panel-btn-footer-bg 0,#000 100%); // Header
@sidepanel-header-bg: darken(@color-neutral-white, 5%);
@sidepanel-header-height: 50px;
@sidepanel-footer-height: 92px;
@sidepanel-header-action-color: #8f191c;
@sidepanel-header-action-bg: @sidepanel-header-bg;
@sidepanel-header-action-border: 1px solid darken(@sidepanel-header-bg, 20%);
@sidepanel-header-action-font-size: 20px;
@sidepanel-header-action-font-weight: 700;
@sidepanel-header-action-padding: 0 14px;
@sidepanel-header-action-close-color: @sidepanel-header-action-color; // Navigation
@mobile-main-nav-item-border: fade(@color-neutral-white, 30%);
@mobile-main-nav-link-font-size: 16px;
@mobile-main-nav-link-font-weight: 700;
@mobile-main-nav-link-padding: 10px 5px 10px 15px;
@mobile-main-nav-main-link-padding: 5px;
@mobile-main-nav-link-color: fade(@color-neutral-white, 90%);
@mobile-main-nav-spacing-v: 15px;
@mobile-main-nav-spacing-h: 10px;
@mobile-main-nav-sec-spacing-top: @mobile-main-nav-spacing-v;
@global-menu-item-game-tile-border: rgba(255,255,255,0.3); //// Back
@mobile-main-nav-link-bg: @color-neutral-white;
@mobile-main-nav-link-back-bg: @mobile-main-nav-link-bg;
@mobile-main-nav-link-back-margin-h: 0;
@mobile-main-nav-link-back-margin-bottom: 10px;

.navbar-hamburger {
  background: none;
  border: none;
  font-size: 30px;
  margin-left: auto;
}

.navbar-header-mobile {
  display: flex;
  align-items: center;
  align-content: center;
  -ms-flex-pack: space-between;
  justify-content: space-between;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  @media screen and (min-width: @bpXL) {
    display: none;
  }

  .ortho-logo {
    background-image: url(../../assets/images/ortho-logo-mob.png);
    height: 44px;
    width: 120px;
    margin-left: 15px;
    margin-bottom: 15px;
  }

}

.top-panel {
  background-color: @top-panel-btn-footer-bg;
  background: @sidepanel-bg;
  background-size: cover;
  height: 100%;
  left: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  top: 0;
  transition: transform 0.3s ease;
  width: 100%;
  z-index: @zindex-navbar-fixed + 1;
  // ONLY show on mobile

  @media (min-width: @bpXL) {
    display: none;
  }

  &--top {
    transform: translate3d(0,-100%,0);
  }

  &--right {
    transform: translate3d(100%,0,0);
  }

  &.is-open {
    transform: translate3d(0,0,0);
  }

  //add overflow hidden to body to stop scrolling.
  &-open {
    @media (max-width: @bpXL -1px) {
      overflow: hidden;
    }
  }
}

// Navigation
// --------------------------------------------------

.global-menu {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: @sidepanel-header-height;
  width: 100%;

  &__stage {
    overflow: hidden;
    position: relative;
  }

  &__content {
    animation-timing-function: ease-in-out;
    float: left;
    transform: translate3d(0, 0, 0);
    transition-duration: 0.3s;
    width: 100%;

    &--left {
      position: absolute;
      transform: translate3d(-100%, 0, 0);
    }

    &--right {
      position: absolute;
      transform: translate3d(100%, 0, 0);
    }
  }
}

// Header
// --------------------------------------------------

.top-panel__header {
  align-items: center;
  background-color: @sidepanel-header-bg;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .3);
  display: flex;
  height: @sidepanel-header-height;
  justify-content: space-between;
}

.top-panel__main-actions {
  align-items: stretch;
  display: flex;
  height: 100%;
  position: relative;
}

.top-panel__action {
  align-items: center;
  display: flex;
  justify-content: space-around;
  position: relative;
}

.top-panel__btn-action {
  align-items: center;
  background-color: @sidepanel-header-action-bg;
  border-right: @sidepanel-header-action-border;
  display: flex;
  font-size: @sidepanel-header-action-font-size;
  font-weight: @sidepanel-header-action-font-weight;
  height: 100%;
  line-height: @sidepanel-header-height;
  padding: @sidepanel-header-action-padding;
  text-transform: uppercase;
  user-select: none;

  &,
  &:active,
  &:focus,
  &:hover {
    color: @sidepanel-header-action-color;
    text-decoration: none;
  }

  &:hover {
    background-color: lighten(@sidepanel-header-action-bg, 10%);
  }

  &:active,
  &:focus {
    background-color: darken(@sidepanel-header-action-bg, 5%);
  }
}

.top-panel__btn-action--home {
  font-size: 24px;
}

.top-panel__close-action {
  background-color: transparent;
  border: 0;
  color: @sidepanel-header-action-close-color;
  outline: 0;
  padding: 0 12px;
  user-select: none;
  font-size: 28px;
  line-height: 28px;
}
// Modifier of btsp .dropdown-menu

.dropdown-menu--top-panel-lang,
.dropdown-menu--top-panel-search {
  margin-top: 12px;
  padding: 2px;

  &:after,
  &:before {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 0;
    content: '';
    font-size: 0;
    height: 0;
    line-height: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    top: -10px;
    width: 0;
  }

  &:before {
    border-bottom: 10px solid @dropdown-border;
  }

  &:after {
    border-bottom: 10px solid @dropdown-bg;
  }
}

.dropdown-menu--top-panel-search {
  &.dropdown-menu {
    left: 10px !important;
    position: fixed !important;
    right: 10px !important;
    top: 50px !important;
    transform: translate3d(0px, 0px, 0px) !important;

    &:after,
    &:before {
      left: 58px;
      right: auto;
    }
  }
}

.dropdown-menu--top-panel-lang {
  height: auto;
  max-height: 50vh;
  overflow-y: auto;
  margin-top: 10px;
  &.dropdown-menu {
    text-align: center;

    a {
      display: block;
      padding: 8px 20px;
      clear: both;
      font-weight: normal;
      line-height: 1.4;
      white-space: nowrap;

      &.active {
        background-color: @color-primary-red;
        color: @color-neutral-white;
      }
    }

    &:after,
    &:before {
      left: auto;
      right: 18px;
    }
  }
}

// Menu
// --------------------------------------------------
@media(max-width: @bpXL - 1px) {
  .global-menu__nav {
    height: calc(~'100vh - @{sidepanel-footer-height}');
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: @mobile-main-nav-spacing-v @mobile-main-nav-spacing-h;
  }

  .global-menu__nav--secondary {
    padding-top: @mobile-main-nav-sec-spacing-top;
  }

  .global-menu__list {
    padding-left: 0;
    list-style: none;
  }

  .global-menu__item {
    border-bottom: 1px solid @mobile-main-nav-item-border;
    clear: both;
    position: relative;

    &:last-child {
      border-bottom: 0;
    }

    a,
    button {
      align-items: center;
      background-color: fade(@mobile-main-nav-link-bg, 0%);
      border: 0;
      color: @mobile-main-nav-link-color;
      display: flex;
      font-size: 16px;
      line-height: 24px;
      font-weight: @mobile-main-nav-link-font-weight;
      justify-content: space-between;
      outline: 0;
      padding: @mobile-main-nav-link-padding;
      text-decoration: none;
      width: 100%;

      &:hover {
        background-color: fade(@mobile-main-nav-link-bg, 15%);
      }

      &:active {
        background-color: fade(@mobile-main-nav-link-bg, 35%);
      }

      + a,
      + button {
        position: absolute;
        top: 0;
        right: 0;
        width: auto;
        background: none;

        &:hover {
          background: none;
        }

        &:active {
          background: none;
        }
      }
    }

    p {
      color: @color-neutral-white;
      margin: 0;
      font-size: 1.8rem;
      padding: 10px 5px 5px 0;
    }

    &.global-menu__item--svg {
      a,
      button {
        padding-left: 60px;
      }

      svg {
        position: absolute;
        fill: white;
        width: 28px;
        height: 28px;
        left: 15px;
        top: 8px;
        &.svg--wide {
          width: 40px;
        }
      }
    }
  }

  .global-menu__item--back {
    background-color: fade(@mobile-main-nav-link-bg, 0%);
    border-bottom: none !important;
    margin-bottom: @mobile-main-nav-link-back-margin-bottom;
    margin-left: @mobile-main-nav-link-back-margin-h;
    margin-right: @mobile-main-nav-link-back-margin-h;

    a {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .global-menu__back-btn {
    text-transform: uppercase;
  }
}
// Footer
// --------------------------------------------------

.top-panel__footer {
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  align-items: center;
  border: 0;
  color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: space-between;
  outline: 0;
  text-decoration: none;
  width: 100%;
  background-color: @top-panel-btn-footer-bg;
}

.top-panel__btn-footer {
  background-color: @top-panel-btn-footer-bg;
  display: block;
  font-size: 14px;
  font-weight: 700;
  padding: 12px 10px;
  text-align: center;
  width: 50%;

  &:first-child {
    border-right: 1px solid rgba(255, 255, 255, 0.3);
  }

  &,
  &:focus,
  &:hover {
    color: @color-neutral-white;
    text-decoration: none;
  }

  &:focus,
  &:hover {
    background-color: lighten(@top-panel-btn-footer-bg, 3%);
  }
}
// gs form

.gs-form {
  padding: 3px 15px 10px 5px;
  margin: 5px;
  overflow: hidden;

  .form-group {
    margin: 0;
  }

  label.error {
    clear: both;
    color: @color-utility-error;
    display: block;
    text-align: center;
    padding: 5px 5px 0;
  }
}

.gs-form__btn {
  float: left;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 7px 14px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 0;
  user-select: none;
  color: @color-primary-red;
}

.gs-form__input {
  background-color: transparent;
  border-radius: 0;
  border-width: 0 0 1px;
  color: @color-primary-red;
  width: calc(100% - 46px);
  height: 36px;
  padding: 7px 14px;
  font-size: 15px;
  line-height: 1.5;
  outline: none;

  &:focus {
    padding-top: 8px;
    border-bottom: 2px solid @color-primary-red;
  }

  &::placeholder {
    color: fade(@color-neutral-white, 50%);
  }
}
@media screen and (min-width: @bpXL) {
  .global-menu__list {
    display: flex;
    list-style: none;
    padding-left: 0;
    max-width: 1200px;
    margin: auto;
    position: relative;
    justify-content: space-between;
    -webkit-justify-content: space-evenly;
    -moz-justify-content: space-evenly;
    justify-content: space-between;


  &:before,
  &:after {
    content: '';
    display: block;
  }
    > li {
      margin-top: 50px;

      a {
        &:hover {
          text-decoration: none;

          .global-menu__item {
            color: black;
            background-color: white;

            svg {
              fill: #CF2F44;
            }

            .sub-mega-menu__title {
              h4 {
                color: #000;
              }
            }
          }
        }
      }
    }

    &.global-menu__list--sm {
      max-width: 735px;
    }

    &.global-menu__list--md {
      max-width: 950px;
    }

    &.global-menu__list--lg {
      max-width: 1400px;

      .global-menu__item--product-container-1 {
        width: 30.2%;
        left: 2.4%;
        margin-top: 0 !important;
      }

      .global-menu__item--product-container-2 {
        left: 35%;
        width: 30.2%;
        margin-top: 0 !important;
      }

      .global-menu__item--product-container-3 {
        left: 67.5%;
        width: 30.2%;
        margin-top: 0 !important;
      }
    }

    .global-menu__item {
      border-bottom: none;
      background-color: rgba(216, 216, 216, 0.05);
      color: #fff;
      padding: 20px;
      width: 195px;
      height: 100%;

      h4 {
        font-size: 1.8rem;
        color: #fff;
      }
    }

    .global-menu__item {
      &.global-menu__item--svg {
        a {
          text-decoration: none;
        }

        svg {
          width: 30px;
          height: 30px;
          fill: #fff;
          &.svg--wide {
            width: 105px;
          }
        }
      }
    }
  }

  .global-menu__item {
    &.global-menu__item--browse {
      text-align: center;
      margin: 30px;

      a {
        text-align: center;
        color: #fff;
        text-decoration: none;
      }
    }
  }

  .global-menu__item--back {
    background-color: fade(@mobile-main-nav-link-bg, 0%);
    border-bottom: none !important;
    margin-bottom: @mobile-main-nav-link-back-margin-bottom;
    margin-left: @mobile-main-nav-link-back-margin-h;
    margin-right: @mobile-main-nav-link-back-margin-h;

    a {
      padding-left: 20px;
      padding-right: 20px;
    }

    .global-menu__back-btn {
      align-items: center;
      background-color: rgba(255, 255, 255, 0);
      border: 0;
      color: rgba(255, 255, 255, 0.9);
      display: flex;
      font-size: 16px;
      font-weight: 700;
      justify-content: space-between;
      outline: 0;
      padding: 10px 5px 10px 15px;
      text-decoration: none;
    }
  }

  .global-menu__back-btn {
    text-transform: uppercase;
  }

  .global-menu__item--product-container-1 {
    position: absolute;
    left: 3.33%;
    color: white;
    text-align: center;
    width: 35.5%;
    margin-top: 0 !important;
  }

  .global-menu__item--product-container-2 {
    position: absolute;
    left: 42%;
    color: white;
    text-align: center;
    background: none;
    width: 35.5%;
    padding: 0;
    margin-top: 0 !important;
  }

  .global-menu__item--product-container-3 {
    position: absolute;
    left: 80.66%;
    color: white;
    text-align: center;
    background: none;
    width: 16.33%;
    padding: 0;
    margin-top: 0 !important;
  }

  .global-menu__item--product-container-sm {
    position: absolute;
    left: 4.33%;
    color: white;
    text-align: center;
    width: 59.5%;
    margin-top: 0 !important;
  }

  .global-menu__item--product-container-sm-1 {
    position: absolute;
    left: 68.5%;
    color: white;
    text-align: center;
    background: none;
    width: 27.33%;
    padding: 0;
    margin-top: 0 !important;
  }

  .global-menu__item--product {
    color: #747678;
    border-bottom: 1px solid #747678;
    text-transform: uppercase;
    font-weight: bold;
  }

  .img-max-height-100 {
    max-height: 100px;
  }

  .fix-menu-desktop {
    .menu-desktop {
      background: linear-gradient(90deg,#404040 0,#000 100%);
      top: -1px;
      .main-menu {
        width: calc(100% - 30px);

        li {
          a {
            color: #fff;

            &:after {
              color: #fff;
              margin-top: -2px;
            }
          }
        }
      }
    }
  }
}

@submenuText: #747678;

.ortho-logo {
  align-self: center;
  background-size: contain;
  background-image: url(../../assets/images/ortho-logo-black.png);
  background-repeat: no-repeat;
  content: '';
  display: inline-block;
  width: 290px;
  height: 26px;
  margin-bottom: 0;

  .fix-menu-desktop & {
    background-image: url(../../assets/images/ortho-logo-white.png);
  }
}

.container-menu-desktop {
  display: none;

  @media screen and (min-width: @bpXL) {
    display: block;
  }

}

.wrap-main-nav {
  width: 100%;
  height: 55px;
  z-index: 1000;
  position: relative;
}

.main-nav {
  width: 100%;
  height: 55px;
  // transition: 1s;

  &:hover {
    background: transparent;
  }

  &.is-active {
    background: linear-gradient(90deg,#404040 0,#000 100%);

    .ortho-logo {
      background-image: url(../../assets/images/ortho-logo-white.png);
    }
  }
}

.menu-desktop {
  margin: 0 auto;
  height: 55px;
  position: relative;
}

.main-menu {
  display: flex;
  max-width: 1140px;
  list-style-type: none;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;
  align-items: center;

  > li {
    height: 100%;
    position: relative;
  }
}

/*---------------------------------------------*/
.sub-menu {
  list-style-type: none;
  position: absolute;
  top:0;
  left:100%;
  width: 225px;
  background-color: #fff;
  padding: 15px 0px 15px 0px;
  transition: all 0.3s;
  visibility: hidden;
  opacity: 0;
  border: 1px solid #f2f2f2;
  box-shadow: 0 5px 10px 0px rgba(0,0,0,0.2);

  li {
    position: relative;
    background-color: transparent;
  }
}

.main-menu {
  > li {
    > .sub-menu {
      top:100%;
      left: 0px;
    }

    &.respon-sub-menu {
      > .sub-menu {
        top:100%;
        left: auto;
        right: 0;
      }
    }
  }
}

li {
  .respon-sub-menu {
    > .sub-menu {
      .sub-menu {
        top:0;
        left: auto;
        right: 100%;
      }
    }
  }
}

.sub-menu {
  a {
    font-size: 14px;
    line-height: 1.7857;
    color: #222;
    display: block;
    padding: 8px 30px;
    width: 100%;
    transition: all 0.3s;
  }
}


.main-menu {
  > li {
    > a {
      color: #222;
      display: flex;
      align-items: center;
      height: 100%;
      font-weight: 500;
      padding: 5px 0px;
      margin: 0 18px;
      transition: all 0.3s;

      .is-active & {
        color: #fff;
      }
    }

    &:first-child {
      a {
        margin-left: 0;
      }
    }

    &:last-child {
      a {
        margin-right: 0;
      }
    }
  }
}

.light-nav {
  .main-menu {
    .ortho-logo {
      background-image: url(../../assets/images/ortho-logo-white.png);
    }
    > li {
      > a {
        color: #fff;
        .is-active & {
          color: #fff;
        }
      }
    }
  }
}



.main-menu {
  > li {
    &.mega-menu-item {
      > a {
        .fas {
          margin-left: 6px;
          transition: all 0.3s;
        }

        .fa-caret-up {
          display: none;
        }
      }

      &.is-active {
        > a {
          .fa-caret-down {
            display: none;
          }

          .fa-caret-up {
            display: inline-block;
          }
        }
      }
    }
  }
}

li.main-menu-active {
  > a {
    &:before {
      transform: scaleX(1);
    }
  }
}


.main-menu {
  > li {
    &:hover {
      > a {
        text-decoration: none;
      }
    }
  }
}

.main-menu {
  > li {
    &:hover {
      > .sub-menu {
        visibility: visible;
        opacity: 1;
      }
    }
    &.mega-menu-item {
      position: static;
    }
  }
}

.sub-menu {
  > li {
    &:hover {
      background-color: transparent;
      > .sub-menu {
        visibility: visible;
        opacity: 1;
      }
      > a {
        background-color: #17b978;
        color: #fff;
        text-decoration: none;
      }
    }
  }
}

.sub-mega-menu {
  flex-wrap: wrap;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  width: 100%;
  display: none;
}


.main-menu {
  > li {
    &:hover {
      > .sub-mega-menu {
        display: flex;
      }
    }
  }
}

.sub-mega-menu {
  .nav {
    width: 240px;
    border-right: 1px solid #f2f2f2;
    padding: 30px 0;
  }
}

.sub-mega-menu {
  .tab-content {
    min-height: 540px;
  }
}


.fix-menu-desktop {
  .main-nav {
    position: fixed;
    top: -55px;
    left: 0;
    transition: transform 0.3s;
  }

  .show-main-nav {
    transform: translateY(100%);
  }

  .menu-desktop {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .logo-stick {
      display: block;
    }
  }
}

.p-relative {
  position: relative;
}

.sub-mega-menu {
    background: linear-gradient(90deg,#404040 0,#000 100%);
    padding: 20px;
}

.sub-mega-menu-link-container {
    background-color: rgba(216,216,216,.05);
    color: #fff;
    padding: 20px;
    a {
        color:#fff;
        &:hover {
            text-decoration: none;
        }
    }
}

.sub-mega-menu__icon {
    min-height: 50px;
    .icon {
      width: 30px;
      height: 30px;
      fill: @color-neutral-white;
    }
}

.sub-mega-menu__title {
    min-height: 60px;
    h4 {
      font-size: 2.4rem;
    }
}

.sub-mega-menu__text {
    min-height: 80px;
    color: @submenuText;
}

main {
  @media screen and (max-width: @bpXL - 1px) {
    padding-top: 95px;
  }
}

.global-header {
  @media screen and (max-width: @bpXL - 1px) {
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 10000;
    .navbar-header-mobile {
      .ortho-logo {
        height: 40px;
        width: 120px;
        margin-left: 15px;
        margin-bottom: 10px;
      }
      .navbar-hamburger {
        margin-top: 10px;
      }
    }

  }
  @media screen and (min-width: @bpXL) {
    position: absolute;
    width: 100%;
  }

  .top-nav {
    background-color: #cf2f44;
    background-image: linear-gradient(90deg, #CF2F44, #8F191C);
    position: sticky;
    z-index: 10;
    a {
      .font-size(1.2);
      line-height: 1.2;
      color: #fff;
      display: inline-block;
      margin-left: 6px;
      padding: 10px;
      @media screen and (max-width: @bpSM - 1px) {
      margin-left: 5px
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.container-menu-desktop {
.search-form .form-group input.form-control::-webkit-input-placeholder{
  display:none;
}
.search-form .form-group input.form-control::-moz-placeholder{
  display:none;
}
.navbar-right{
 // min-width:450px; /*or I can probably just use columns*/
}
.navbar-right{
  &.adjust-width {
    min-width:195px; /*or I can probably just use columns*/
    .form-group input.form-control{
      z-index:10;
      background: none;
    }
  }
  .form-group {
    margin-bottom: 0;
  }
}
.navbar-right form{
  width:100%;
}
.form-group{
  position:relative;
  width:0%;
  min-width:60px;
  height:55px;
  overflow:hidden;
  transition: width 1s;
  backface-visibility:hidden;
}
.form-group input.form-control{
  position:absolute;
  background: none;
  top:0;
  right:0;
  outline:none;
  width:100%;
  font-size: 1.6rem;
  font-weight: 500;
  height:55px;
  margin:0;
  z-index:-10;
  border:0;
  background-clip: inherit;
}

input[type="text"].form-control{
  -webkit-appearence:none;
  -webkit-border-radius:0;
}
.form-control-submit,
.search-label{
  width:60px;
  height:55px;
  position:absolute;
  right:0;
  top:0;
  padding:0;
  margin:0;
  text-align:center;
  cursor:pointer;
  line-height:55px;
  background: transparent;
}
.form-control-submit{
  background:#fff; /*stupid IE*/
  opacity: 0;
  color:transparent;
  border:none;
  outline:none;
  z-index:-1;
}
.search-label{
  z-index:90;
}
.form-group.sb-search-open,
.no-js .sb-search-open{
  width:100%;
}
.form-group.sb-search-open .search-label,
.no-js .sb-search .search-label {
  z-index: 11;
}
.form-group.sb-search-open .form-control-submit,
.no-js .form-control .form-control-submit {
  z-index: 90;
}


.main-nav {
  &.is-active {
    .form-control {
      color: white;
      background: none;
    }
    .search-label {
      background:none;
      color: #fff;
    }
  }
}

&.fix-menu-desktop {
  .search-label {
    background:none;
    color: #fff;
  }
  .form-control {
      color: white;
      background: none;
    }
}

}


.light-nav {
  .search-form {

      .form-control {
        color: white;
        background: none;
      }
      .search-label {
        background:none;
        color: #fff;
      }

  }
}



// orthoplus

/* #### ORTHO + NAV */
.orthoplus.navbar-header-mobile {
  padding: 10px
}
.orthoplus .btn {
  font-size: unset;
  font-weight: bold;
}
.orthoplus .main-menu > li > a {
  font-weight: bold;
}
.orthoplus .main-menu > li > .dropdown {
  color: #222;
    padding: 7px 0px;
    margin: 0;
}
.orthoplus .dropdown-menu {
  padding: 20px 10px;
  width: 300px;
  position: relative;
  right: auto  !important;
  left: -75px !important;
  -webkit-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  border-width: 0;
  border: 0;
  box-shadow: 0 0 24px 0 rgba(0,0,0,0.3);
  line-height: 2em;
}
.orthoplus .dropdown-menu .row {
  margin: 0 0 10px;
}
.orthoplus .dropdown-toggle.active {
  color: #CF2F44 !important;
}
.orthoplus .dropdown-toggle.active::after {
  border-top: 0;
  border-color: #CF2F44 ;
  border-right: .3em solid transparent;
  border-bottom: .3em solid;
  border-left: .3em solid transparent;
}.orthoplus .dropdown.show .dropdown-toggle {
  color: #CF2F44 !important;
}
.orthoplus .dropdown.show .dropdown-toggle::after {
  border-top: 0;
  border-color: #CF2F44 ;
  border-right: .3em solid transparent;
  border-bottom: .3em solid;
  border-left: .3em solid transparent;
}
.orthoplus .dropdown-item {
  line-height: 2em;
  font-weight: bold;
}
.orthoplus .dropdown-item:active {
  background-color: #cf2f44;
  background-image: -webkit-gradient(linear, left top, right top, from(#CF2F44), to(#8F191C));
  background-image: -o-linear-gradient(left, #CF2F44, #8F191C);
  background-image: linear-gradient(90deg, #CF2F44, #8F191C);
}
.orthoplus .dropdown-menu .user {
  font-size: 17px;
}
.orthoplus .dropdown-menu .user span a {
  font-size: 12px;
}
.orthoplus .ortho-logo {
  background-image: url(../../assets/images/ortho+-logo-black.png);
  width: 160px !important
}
.orthoplus .navbar-header-mobile .ortho-logo {
  background-image: url(../../assets/images/ortho+-logo-black.png);
  margin-top: 25px;
  width: 160px;
}
.orthoplus .main-menu > li > a:hover, .dropdown-toggle:hover {
  color: #CF2F44 !important
}
.orthoplus .ortho-plus-notification:after {
  content: "";
  display: inline-block;
  width: 5px;
  height: 5px;
  position: absolute;
  top: 15px;
  right: 10px;
  background-size: contain;
  background-image: url(../../assets/icons/NEW.png);
}
.orthoplus .ortho-plus-notification a:hover {
  opacity: 0.6;
}
@media (max-width: 1199px) {
  .orthoplus.navbar-header-mobile .ortho-logo {
    height: 40px;
    width: 120px;
    margin-left: 15px;
    margin-bottom: 0px;
    margin-top: 15px;
  }
  .orthoplus .ortho-plus-notification a:hover {
    opacity: 0.8;
  }
  .settings:hover {
    cursor: pointer;
    opacity: 0.8
  }
  .orthoplus .top-panel__header {
    height: 70px;
    background-color: #fff;
  }
  .orthoplus .fa  {
    color: #404040
  }
  .orthoplus .fas  {
    color: #404040
  }
  .main-menu > li > a
  .global-menu {
    top: 70px;
    padding-bottom: 80px;
  }
  .orthoplus .global-menu__item {
    border-bottom: 0;
  }
  .orthoplus .dropdown-toggle.active {
    color: #fff !important;
}
  .orthoplus .dropdown {
    clear: both;
    position: relative;
    color: #fff
  }
  .orthoplus .dropdown-toggle {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0);
    border: 0;
    color: rgba(255, 255, 255, 0.9);
    display: flex;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    outline: 0;
    padding: 10px 5px 10px 15px;
    text-decoration: none;
    width: 100%;
  }
  .dropdown-toggle:hover {
    color: #fff !important;
    opacity: .8;
  }
  .orthoplus .dropdown.show {
    border-bottom: none;
    clear: both;
    position: relative;
  }
  .orthoplus .dropdown-menu {
    position: relative !important;
    padding: 10px;
    width: 110%;
    height: auto;
    left: -10px !important;
    background-color: #404040;
    transform: translate3d(0px, 0px, 0px) !important;
    clear: both;
    position: relative;
    box-shadow: 0 0 24px 0 rgba(0,0,0,0.3);
  }
  .orthoplus  a.dropdown-item {
    color: #fff;
    line-height: 38px;
    font-weight: 400;
    font-size: 14px;
  }
  .orthoplus  a.dropdown-item:hover, .orthoplus .global-menu__item a:hover {
   background-color: none !important;
   background: transparent;
   opacity: 0.8;
  }
  .orthoplus .top-panel__footer {
    background-image: linear-gradient(to right, #CF2F44, #8F191C);
     height: 88px;
     padding: 10px;
  }
  .orthoplus .top-panel__footer .profile {
    width: 310px;
  }
  .orthoplus .top-panel__footer .profile .image {
    width: 50px;
    margin-right: 10px;
    float: left;
  }
  .orthoplus .top-panel__footer .profile .user {
    width: 210px;
    float: left;
  }
  .orthoplus .top-panel__footer .profile .user a {
    color: #fff
  }
  .orthoplus .top-panel__footer .toggle {
   width: 30px;
  }
  .orthoplus .top-panel__btn-footer {
    background: none;
  }
  .orthoplus .ortho-plus-notification {
    line-height: 30px;
    padding: 10px 5px 10px 15px;
  }
  .orthoplus .notfications {
    padding-bottom: 20px;
    margin-top: 20px;
    border-bottom: 1px solid #fff;
  }
  .orthoplus .main-items {
    padding-top: 20px;
  }
  .orthoplus .ortho-plus-notification .row {
    margin: 0
  }
  .orthoplus .global-menu__item a:hover, .global-menu__item button:hover {
    background-color: transparent;
  }
  .orthoplus .ortho-plus-notification a {
    color: #fff
  }
  .orthoplus .ortho-plus-notification .img-after {
    width: 40px;
    float: left;
  }
  .orthoplus .ortho-plus-notification .text {
    /*width: 200px;*/
    float: left;
  }
  .orthoplus .ortho-plus-notification .img-after:after {
    content: "";
    display: inline-block;
    width: 5px;
    height: 5px;
    position: relative;
    top: -10px;
    right: -5px;
    background-size: contain;
    background-image: url(../../assets/icons/NEW.png);
  }
  .orthoplus .ortho-plus-notification:after {
    display: none
  }
}

@media (min-width: 1200px) {
.orthoplus.fix-menu-desktop .menu-desktop {
  background: #fff;
  top: -1px;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}
.orthoplus.fix-menu-desktop .menu-desktop .main-menu li a, .orthoplus.fix-menu-desktop .menu-desktop .main-menu li a:after {
  color: #000;
}
}

.orthoplus {
  &.fix-menu-desktop {
    .main-menu {
      margin-top: 0;
    }
  }
  .main-menu {
    margin-top: 15px;
  }
}
