.component {
  margin-bottom: 50px;

  @media screen and (min-width: @bpLG) {
    margin-bottom: 90px;

    .solutions & {
      margin-bottom: 50px;
    }
  }

  // Override for variations
  &.HP-001,
  &.SOL-002,
  &.ReCP-005 {
    margin-bottom: 35px;
  }

  &.OD-001 {
    margin-bottom: 60px;

    @media screen and (min-width: @bpLG) {
      margin-bottom: 10px;
    }
  }

  &.OD-002 {
    margin-bottom: 0;
  }

  &.with-quote {
    margin-bottom: 45px;
  }
}
.btn-primary {
  &:focus {
    box-shadow: none;
  }
}





@custom-radio-radius: 50%;



.custom-radio {
  .custom-control-indicator {
    border-radius: @custom-radio-radius;
    border-width: 4px;
  }

  .custom-control-input:checked ~ .custom-control-indicator {

  }

  // Dev
  .custom-control-input {
    &:disabled {
      &:checked {
        ~ .custom-control-indicator {
        }
      }
    }
  }

}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold;
}

.custom-control {
    cursor: pointer;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: 16px;
    min-height: 12px;
    padding-left: 24px;
    position: relative;
    margin-bottom: 0;
    font-size: 1.8rem;
    color: #000;
    line-height: 2rem;
    font-weight: bold;
}

input, button, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

input[type="radio"], input[type="checkbox"] {
    margin: 4px 0 0;
    margin-top: 1px \9;
    line-height: normal;
}

.custom-control-input {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

input[type="checkbox"], input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}

input[type="radio"], input[type="checkbox"] {
    margin: 4px 0 0;
    margin-top: 1px \9;
    line-height: normal;
}

.custom-control-indicator {
    position: absolute;
    background-color: #f2f2f2;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: block;
    height: 16px;
    left: 0;
    pointer-events: none;
    top: 1px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 16px;
    border-color: #666;
    border-style: solid;
    border-width: 2px;
    transition: all 0.3s ease;
    transform: translateZ(0);
}

.custom-radio .custom-control-indicator {
    border-radius: 50%;
    border-width: 2px;
}

.custom-control-input:checked ~ .custom-control-indicator {
    background: #CF2F44;
    border-color: #CF2F44;
    box-shadow: 0px 0px 5px 2px #CF2F45;
    color: #fff;
    &:after {
          display: block;
    clear: both;
    content: "";
    top: 3px;
    left: 3px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: white;
    position: absolute;
    }
}

.container {
    .component{
      &.article-hide {
        display: none;
        @media (min-width: @bpLG) {
          display: none;
        }
      }
    }
}


.container {
  .component {
      &.article-display {
        display: block;
        .article {
          display: block;
          @media (min-width: @bpLG) {
            display: flex;
          }
        }
      }
  }
}

.contact-modal {
  margin-top: 95px;
  @media (min-width: 1200px) {
    margin-top: 0;
  }
  h2, h5 {
    color: #fff;
  }
  .form-group {
    justify-content: flex-start;
    flex-direction: row;
    display: flex;
  }
  .shadow-custom {
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.15), 3px 28px 0px -9px rgba(255, 255, 255, 0.2);
  }
}


@dropdown-bg: linear-gradient(90deg,#CF2F44 0,#8F191C 100%);

.CONT-001 {
  padding: 20px;
  .nav {
    &.nav-tabs {
      border-bottom: 3px solid @color-brand-grey;
      .nav-item {
        margin-bottom: -3px;
        padding: 0 20px;
      }
      .nav-link {
        color: #000;
        font-weight: bold;
        &:hover {
          border-right: 0;
          border-left: 0;
          border-top: 0;
          border-bottom: 3px;
        }
        &.active {
          border-bottom: 3px solid #CF2F44;
          color: #000;
          border-right: 0;
          border-left: 0;
          border-top: 0;
        }
      }
    }
  }
  .country-title {
    color: @color-brand-grey;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .contact__country {
    color: @color-brand-grey;
    font-size: 1.6rem;
    font-weight: 500;
    margin: 30px;
  }

  .contact {
    padding: 20px 0;
  }

  .contact__times {
    color: @color-brand-grey;
    font-size: 1.2rem;

  }

  .contact__title {
    font-weight: bold;
    margin-top: 10px;
    font-size: 2rem;
  }

  .contact__number {
    margin-top: 10px;
    font-weight: bold;
    margin-bottom: 0;
    font-size: 1.6rem;
    padding-left: 25px;
    &:before {
      content: "\2713";
      color: #CF2F44;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1.2em;
      font-size: 1.6rem;
    }
  }

  .contact__email {
    margin-top: 10px;
    font-weight: bold;
    overflow-wrap: break-word;
    word-wrap: break-word;
    font-size: 1.4rem;
    padding-left: 25px;
    &:before {
      content: "\2713";
      color: #CF2F44;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1.2em;
      font-size: 1.6rem;
    }
  }

  .contact__address {
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: bold;
    margin-top: 20px;
  }

  .contact__mobile {
    display: none;
  }


  .message-box {
    padding: 20px;
  }

  .message-box__input-container {
    margin-bottom: 20px;
  }

  .message-box__title {
    font-size: 2rem;
    font-weight: bold;
  }

  .message-box__label {
    font-weight: bold;
    margin: 10px 0;
    font-size: 1.6rem;
    color: #000;
  }

  .message-box__input-label {
    font-weight: bold;
    font-size: 1.4rem;
  }

  @media (min-width: @bpMD) {



  }

  .nav-tabs-dropdown {
  display: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.nav-tabs-dropdown:before {
  content: "\f0d7";
  font-family: 'fontAwesome';
  position: absolute;
  right: 30px;
}

@media screen and (min-width: 1200px) {
  #nav-tabs-wrapper {
    display: flex!important;
  }
}

@media screen and (max-width: 1199px) {
    .nav-tabs-dropdown {
        display: flex;
        background: @dropdown-bg;
        color: #fff;
        &:hover {
          background: @dropdown-bg;
          color: #fff;
        }
    }
    #nav-tabs-wrapper {
        display: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        text-align: center;
         .nav-link {
          &.active {
            background: none;
            border: 0;
          }
        }
    }
   .nav-tabs-horizontal {
        min-height: 20px;
        padding: 19px;
        margin-bottom: 20px;
        background-color: #f5f5f5;
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
        box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
   }
    .nav-tabs-horizontal  > li {
        float: none;
    }
    .nav-tabs-horizontal  > li + li {
        margin-left: 2px;
    }
    .nav-tabs-horizontal > li,
    .nav-tabs-horizontal > li > a {
        background: transparent;
        width: 100%;
    }
    .nav-tabs-horizontal  > li > a {
        border-radius: 4px;
    }
    .nav-tabs-horizontal  > li.active > a,
    .nav-tabs-horizontal  > li.active > a:hover,
    .nav-tabs-horizontal  > li.active > a:focus {
        color: #ffffff;
        background-color: #428bca;
    }
}

@media screen and (max-width: 599px) {
  .form-row {
    flex-direction: column;
  }
  .contact__mobile {
    display: block;
    a {
      position: relative;
      padding-left: 35px;
      margin-right: 10px;
      i {
        margin-left: -15px;
      }
    }
  }
  .contact__email {
    display: none;
  }
  .contact__number {
    display: none;
  }
}

}

/*
.custom-form {

  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  max-width: 360px;
  margin: 40px auto 40px;
  background: #fff;
  padding: 40px;
  border-radius: 4px;
  .btn-primary {
    background-color: #8e44ad;
    border-color: #8e44ad;
  }
  .form-group {
    position: relative;
    padding-top: 16px;
    margin-bottom: 16px;
    .animated-label {
      position: absolute;
      top: 20px;
      left: 0;
      bottom: 0;
      z-index: 2;
      width: 100%;
      font-weight: 300;
      opacity: 0.5;
      cursor: text;
      transition: 0.2s ease all;
      margin: 0;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 45%;
        height: 2px;
        width: 10px;
        visibility: hidden;
        background-color:#8e44ad;
        transition: 0.2s ease all;
      }
    }
    &.not-empty {
      .animated-label {
        top: 0;
        font-size: 12px;
      }
    }
    .form-control {
      position: relative;
      z-index: 1;
      border-radius: 0;
      border-width: 0 0 1px;
      border-bottom-color: rgba(0,0,0,0.25);
      height: auto;
      padding: 3px 0 5px;
      &:focus {
        box-shadow: none;
        border-bottom-color: rgba(0,0,0,0.12);
        ~ .animated-label {
          top: 0;
          opacity: 1;
          color: #8e44ad;
          font-size: 12px;
          &:after {
            visibility: visible;
            width: 100%;
            left: 0;
          }
        }
      }
    }
  }
}



.custom-form {

  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  max-width: 360px;
  margin: 40px auto 40px;
  background: #fff;
  padding: 40px;
  border-radius: 4px;
  .btn-primary {
    background-color: #8e44ad;
    border-color: #8e44ad;
  }
  .form-group {
    position: relative;
    padding-top: 16px;
    margin-bottom: 16px;
    .animated-label {
      position: absolute;
      top: 20px;
      left: 0;
      bottom: 0;
      z-index: 2;
      width: 100%;
      font-weight: 300;
      opacity: 0.5;
      cursor: text;
      transition: 0.2s ease all;
      margin: 0;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 45%;
        height: 2px;
        width: 10px;
        visibility: hidden;
        background-color:#8e44ad;
        transition: 0.2s ease all;
      }
    }
    &.not-empty {
      .animated-label {
        top: 0;
        font-size: 12px;
      }
    }
    .form-control {
      position: relative;
      z-index: 1;
      border-radius: 0;
      border-width: 0 0 1px;
      border-bottom-color: rgba(0,0,0,0.25);
      height: auto;
      padding: 3px 0 5px;
      &:focus {
        box-shadow: none;
        border-bottom-color: rgba(0,0,0,0.12);
        ~ .animated-label {
          top: 0;
          opacity: 1;
          color: #8e44ad;
          font-size: 12px;
          &:after {
            visibility: visible;
            width: 100%;
            left: 0;
          }
        }
      }
    }
  }
}
 */


.btn-outline {
    &:disabled {
      background: transparent;
      color: #bcbdbc;
      box-shadow:  0 1px 1px rgba(0,0,0,.05);
      &:hover,
      &:active,
      &.active,
      &:focus {

          color: #bcbdbc;
      }
    }
    color: #fff;
    border: 1px solid #bcbdbc;
    &:hover,
    &:active,
    &.active,
    &:focus {

        color: #eee;
    }
}

.btn-outline {
    background: #bcbdbc;
}



 .CONT-001  {
  margin-bottom: 80px;
  // padding-right: 0;
    @media screen and (min-width: @bpXL) {
    margin-bottom: 0px;
  }
}
