.OD-002 {
  display: block;
  color: #fff;

  @media screen and (min-width: @bpMD) {
    background: #fff;
    color: #8F191C;
  }

  &.hero-toggle {
    .content {
      display: block;
    }

    h3 {
      .font-size(1.4);
      color: @color-brand-red;
      font-weight: bold;
      padding: 10px 0;
      width: 100%;
      text-align: center;
      background: #fff;
      @media (min-width: @bpMD) {
        text-align: left;
      }
    }

    .form-check {
      margin-bottom: 20px;

      .form-check-label {
        .font-size(1.8);
        color: #000;
      }
    }
  }

  .select-box {
    .font-size(1.6);
    position: relative;
    display: block;
    width: 100%;
    margin: 0 auto;
    color: #fff;

    &__current {
      position: relative;
      box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);
      cursor: pointer;
      outline: none;

      &:focus {
        & + .select-box__list {
          opacity: 1;
          animation-name: none;

          .select-box__option {
            cursor: pointer;
          }
        }

        .select-box__icon {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }

    &__icon {
      fill: #fff;
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      width: 20px;
      transition: 0.2s ease;
    }

    &__value {
      display: flex;
    }

    &__input {
      display: none;

      &.active + .select-box__input-text {
        display: block;
      }
      &:checked + .select-box__input-text {
        display: block;
      }
    }

    &__input-text {
      display: none;
      width: 100%;
      margin: 0;
      padding: 15px;
      background-color: #cf2f44;
      background-image: linear-gradient(90deg,#cf2f44,#8f191c);
    }

    &__list {
      background-color: #cf2f44;
      background-image: linear-gradient(90deg,#cf2f44,#8f191c);
      position: absolute;
      width: 100%;
      padding: 0;
      list-style: none;
      opacity: 0;
      z-index: 999;
      animation-name: HideList;
      animation-duration: 0.5s;
      animation-delay: 0.5s;
      animation-fill-mode: forwards;
      animation-timing-function: step-start;
      box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);
    }

    &__option {
      display: block;
      padding: 15px;
      background-color: #cf2f44;
      background-image: linear-gradient(90deg,#cf2f44,#8f191c);

      &:hover,
      &:focus {
        color: #fff;
        background-color: #cf2f44;
      }
    }
  }
}



@keyframes HideList {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(0);
  }
}
