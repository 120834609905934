.hero {
    background: transparent linear-gradient(180deg, #E3E7E42B 0%, #C7579A0D 23%, #D169780C 34%, #D46E6E0C 42%, #E38B390A 57%, #F2A90007 73%, #57616400 100%) 0% 0% no-repeat padding-box;
}
.section{
    padding: 60px 0;
    background-image: url('../images/BG@2x.png');
    background-repeat: no-repeat;
    /* background-size: 50% 500px; */
    background-position: right 0;
}
.techDocHeader{
    font: normal normal 40px Rubik;
    font-weight: bold;
}
  
.form  {
    height: 40px;
    border: 2px solid #E4E7E4;
    background-color: #fff;
}

.input{
    width: 75% !important;
    border: none;
    line-height: 32px;
}

.country {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 24px #0000001A;
    border-radius: 5px;
    float: right;
    margin-bottom: 25px;
    padding: 8px;
}
  
.moto {
    margin: 25px 0;
    max-width: 87%;
    font-size: 16px;
}
.moto strong {
    font-size: 18px;
}



.button {
    background: var(--indigo) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border: none;
    color: #fff;
    height: 40px;
    width: 90px;
    float: right;
}
.searchContainer {
    display: grid;
    grid-template-columns: 5fr 1fr;
    max-width: 58%;
    margin: 40px 0 60px 0;
    
}

@media screen and (max-width: 991px) {
    .country {
        float: left;
    }
    .searchContainer {
        display: 'grid';
        grid-template-columns: '7fr 1fr';
        max-width: 100%;
    }

    .section{
        background-position: center;
        background-size: cover;
    }
  }

  @media screen and (max-width: 768px) {

    .searchContainer {
        display: block;

    }
    .button {
        margin-top: 20px;
    }
  }
  