.SOL-007 {
  border-top: 5px solid #F4F4F4;
  padding-top: 60px;
  h2 {
    .font-size(4.6);
    line-height: 54px;
    margin-bottom: 9rem;
  }
  &.innovation-SQL{
    padding-top: 105px !important;
    h2{
      font-weight: bold;
    }
    .card-text {
      .font-size(1.8);
      font-weight: bold;
      line-height: 24px;
    }
  }
}

.button-well {
  margin: 50px auto;
}



.orthoplus {
  .SOL-007 {
    border-top: 0;
    h2 {
      text-align: left !important;
      margin-bottom: 1.5rem;
      font-size: 3.6rem;
      line-height: 4.4rem;
      @media screen and (max-width: @bpMD - 1px) {
        font-size: 2.4rem;
        line-height: 3.2rem;
        margin-bottom: 1.5rem !important;
      }
    }
  }
}

#POS025 {
  + #SOL007 {
    .SOL-007 {
      padding-top: 0;
      .tag, .date, .card-title {
        display: none;
      }
    }
  }
}

#SOL007 {
  + #SOL007ALT {
    .SOL-007 {
      padding-top: 0;
      padding-top: 30px;
      @media screen and (min-width: @bpMD) {
        position: relative;
        top: -50px;
      }
      @media screen and (min-width: @bpLG) {
        padding-top: 0;
      }
    }
  }
}

.SOL-007ALT {
  .background-dark-grey {
    padding-top: 20px;
    .card-text {
      color: @color-neutral-white;
    }
    .tag, .date, .card-title {
      display: none;
    }

  }
  ul.slick-dots {
    @media screen and (max-width: 767px) {
      bottom: -35px;
    }
  }
}
