.Re-003 {
  padding: 90px 0;
  &.Re-003--home {
    margin-top: 0 !important;
    padding: 0;
  }
  margin-top: 300px;


  @media (max-width: @bpMD) {
    margin-top: 0;
    padding: 30px 0;
  }

  .latest-content {
    margin: 0;
  }

  .article-header {
    h2 {
      .font-size(4.6);
      font-weight: bold;
      letter-spacing: -.3px;
      line-height: 58px;

      @media (max-width: @bpMD) {
        .font-size(3.4);
      }
    }

    > p {
      .font-size(1.8);
      line-height: 30px;

      &:last-of-type {
        margin-bottom: 60px;
      }
    }
  }

  &.variant {
    .latest-content {
      &:nth-of-type(odd) {
        .main {
          order: 1;
        }
      }
    }
  }

  .card-read-more{
    margin-top: 20px;
    a{
      text-decoration: none;
      margin-right: 10px;
      font-size: 14px;
      font-weight: bold;
    }
    .svg-icon-link{
      width: 24px;
      min-width: 24px;
      height: 25px;
      fill: #8f191c;
    }
  }
}
