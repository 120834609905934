.HP-001 {
  background-image: none;

  &.white {
    * {
      color: white !important;
      fill: #fff !important;
    }
  }

  &.white-color {
    background-repeat: no-repeat;

    * {
      color: white;
    }

    svg {
      fill: white;
    }

    h2 {
      padding-left: 15px;
      border-left: 4px solid white;
    }

    p {
      border: none !important;
    }

    .btn {
      background: transparent;
      border: 1px solid white;
    }
    .link-arrow-down {
      color: white;
    }
  }

  @media screen and (min-width: @bpMD) {
    background-size: cover;
    background-position: right center;
    width: 100%;
    display: block;
    height: 600px;
  }

  > span {
    display: block;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: 600px;

    @media (min-width: @bpMD) {
      background-image: none !important;
    }
  }


  .col {
    @media screen and (min-width: @bpMD) {
      max-width: 55%;
    }
  }

  .container {
    &,
    .row {
      height: 100%;
    }
  }

  h1 {
    .font-size(3.6);
    font-family: @font-family-secondary;
    line-height: 36px;
    margin: 20px 0;

    @media screen and (min-width: @bpMD) {
      .font-size(7.6);
      line-height: 76px;
      margin: 48px 0;
    }
  }

  p {
    .font-size(1.4);
    line-height: 22px;
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to bottom, @color-brand-red, #8f191c) 1 100%;
    border-right: 0;
    line-height: 33px;
    margin-bottom: 40px;
    padding-left: 20px;

    @media screen and (min-width: @bpMD) {
      .font-size(1.8);
      line-height: 30px;
    }
  }

  a {
    &.btn {
      background: #fff;
      margin-bottom: 20px;
      margin-right: 20px;
    }

    &.link-arrow-down {
      color: #000;
      font-weight: bold;
      @media screen and (max-width: @bpMD) {
        display: inline-block;
        transform: translateY(-10px);
      }
    }
  }

  &.dark-bg {
    color: #fff;

    p {
      border-image: linear-gradient(to bottom, #fff, #fff) 1 100%;
    }
  }

  &.overlay-bg{
    position: relative;
    background-size: cover;
    background-position: center;
    &:before{
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, rgba(0,0,0,0.5), rgba(0,0,0,0));
    }
  }
  &.variation-2 {
    h2 {
      border-left: 5px solid white;
      padding-left: 20px;
      max-width: 380px;
       @media screen and (min-width: @bpMD) {
        // display: inline-block;
        // transform: translateY(-10px);
      }

    }
    p {
      border-left: 0;
      padding-left: 0;
      max-width: 400px;
      b {
        font-weight: inherit;
      }
    }
    .btn {
      background-color: transparent;
      border: 1px solid #fff;
    }
    .link-arrow-down {
      font-weight: inherit;
    }
  }
}

