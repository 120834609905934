html {
  font-size: 62.5%;
}

body {
  .font-size(1.6);
  font-family: @font-family-primary;
}

p {
  .font-size(1.8);
  line-height: 30px;
}

h1,
.h1 {}

h2,
.h2 {
  .font-size(3.6);
  line-height: 54px;
}

h3,
.h3 {}

h4,
.h4 {
  .font-size(2.6);
  line-height: 33px;
}

h5,
.h5 {
  .font-size(2.5);
}

.lead {
  .font-size(2.6);
  line-height: 33px;
}

.post-tag {
  .font-size(1.4);
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  line-height: 18px;
  padding: 8px 7px;
}
// a href
a {
  .font-size(1.6);
  color: @color-brand-red;
  font-family: @font-family-primary;
  line-height: 20px;

  &:hover {
    color: darken(@color-brand-red, 10%);
    text-decoration: none;
  }

  &.link-arrow {
    color: #000;
    vertical-align: middle;

    &:after {
      content: '';
      background-image: url(../../assets/images/right-arrow-red.svg);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      display: inline-block;
      width: 24px;
      line-height: 20px;
      margin-left: 5px;
    }

    &-down {
      svg {
        fill: @color-brand-red;
        width: 24px;
        height: 24px;
      }
    }
  }
}

button {
  &.arrow-link {
    background-color: transparent;
    border: 0;
  }
}

// Titles

h2 {
    &.component-title {
        margin-top: 20px;
        margin-bottom: 20px;
        font-weight: bold;
        text-align: center;
        @media(min-width: @bpMD) {
          margin-top: 30px;
          margin-bottom: 30px;
          font-size: 2.4rem;
        }
    }
}

.h3-component-title {
  margin-bottom: 20px;

  @media(min-width: @bpMD) {
    margin-bottom: 40px;
  }

}

.h4-component-title {
  &.with-arrow {
    color: inherit;
    &:hover, &:focus, &:active {
      color: darken(@color-brand-red, 10%)
    }
  }
}
// Icons

.icon-right-title {
  margin-left: 5px;
  vertical-align: middle;
}

// Buttons
.btn,
button {
	cursor: pointer;
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus{
  box-shadow: none !important;
  border: 1px solid #CF2F44 !important;
  background-color: #CF2F44 !important;
}
.btn {
	border-radius: 0;
  .font-size(1.4);
  background: transparent;
  line-height: 22px;
  padding: 10px 20px;

	&.btn-primary {
    border: 1px solid @color-brand-red;
    color: @color-brand-red;

    &:hover {
     background: @color-brand-red;
     color: @color-neutral-white;
    }
  }

  &.btn-secondary {
    border: 1px solid #000;
    color: #000;

    &:hover {
      color: #fff;
    }
  }
}

// Arrow link
.arrow-link {
  background-image: url(../../assets/images/right-arrow-red.svg);
  color: #000;
  background-position: right center;
  background-size: 20px;
  background-repeat: no-repeat;
  padding-right: 30px;

  .dark-bg &,
  .dark &,
  &.dark {
    background-image: url(../../assets/images/right-arrow-white.svg);
    color: #FFF;
  }
}

.btn-primary {
  .dark-bg & {
    color: #FFF;
    border-color: #FFF;

    &:hover {
      background: transparent;
    }
  }
}

.btn-min-width {
  min-width: 160px;
}

// screen reader
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}


// resets
.a-reset {
  text-decoration: none;
  color: inherit;
  &:hover,
  &:focus,
  &:active {
    color:inherit;
    text-decoration: none;
  }
}

.arrow,
.icon {
  .dark & {
    fill: #fff;
  }
}

.dark-bg,
.dark {
  color: #fff;
}


.arrow {
  fill: #CF2F44;
  width: 24px;
  height: 14px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

// Slick
.slick-slider {
  .slick-dots {
    li {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: @color-brand-grey;
      &.slick-active {
        background-color: darken(@color-brand-red, 10%)
      }
    }
  }
}



//fixed columns
@media(min-width: 991px){
    .col-lg-3--fixed {
        position: fixed;
        +.col-lg-9 {
            margin-left: 25%;
        }
    }
}

.meta {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;

  .date,
  .tag,
  .cat {
    .font-size(1.4);
    line-height: 18px;
  }

  .tag,
  .cat {
    color: #8F191C;
    font-weight: bold;
  }

  .date {
    color: #BCBDBC
  }

}


.no-hero {
  padding-top: 120px;
}

.no-hero {
  &.orthoplus {
    padding-top: 115px;
  }
}

ul.slick-dots {
  bottom: 0px;

}

@media screen and (max-width: @bpMD - 1px) {
  .SOL-007 {
    ul.slick-dots {
      bottom: -20px;
    }
  }
}

.video-post {
  background-color: #000;
  border-bottom: 3px solid @color-brand-red;
}

.bg-none {
  background-color: transparent !important;
}

.color-premium {
  color: #00798C;
}

.color-red {
 color: @color-brand-red;
}

.Re-004 {
  .card {
    p {
      strong {
        text-decoration: none !important;
      }
    }
    .color-premium {
        text-transform: uppercase;
        position: relative;
        &:after {
          content: '';
          border-color: #00798C;
          width: 100%;
          position: absolute;
          left: 0;
          bottom: -4px;
          border-width: 0 0 2px;
          border-style: solid;
        }
      }
  }
}

// .text-fadeout {
//   position: relative;
//     bottom: 4em;
//     height: 4em;
//     background: -webkit-linear-gradient(
//         rgba(255, 255, 255, 0) 0%,
//         rgba(255, 255, 255, 1) 100%
//     );
// }

.fade-out {
  position: relative;
  max-height: 100px; // change the height
  overflow: hidden
}
.fade-out:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
   text-align: center;
  margin: 0;
  padding: 30px 0;
  background-image: linear-gradient(to bottom, transparent, white);
}

div#fade-container{
      max-width: 900px;
      margin: auto;
      position:relative;
      line-height: 1.3;
    }

    div#fade-content{
      max-height: 230px;
      overflow: hidden;
    }

    div#fade-content.full{
      max-height: none;
    }

    .fade-anchor {
      background-image: linear-gradient(to bottom, transparent, white);
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      cursor: pointer;
      display: block;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.7) 20%, white 50%);
      height: 130px;
      padding-top: 65px;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      text-align: center;
      -webkit-transition: background 150ms ease-in;
      transition: background 150ms ease-in;
    }

    .fade-anchor-text {
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 9pt 9pt 0 0;
      color: #000;
      display: inline-block;
      max-width: 100%;
      overflow: hidden;
      padding: 10px;
      text-decoration: underline;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

.color-grey {
  color: #8D8D8D;
}

main {
  > .row {
    &.justify-content-md-center {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.text-fadeout {
  position: relative;
  &:before {
    bottom: 0;
    content: '';
    display: block;
    height: 15rem;
    position: absolute;
    width: 100%;
    background: -webkit-linear-gradient(top,rgba(255,255,255,0) 0,rgba(255,255,255,0.7) 31%,white 100%);
    background: linear-gradient(to bottom,rgba(255,255,255,0) 0,rgba(255,255,255,0.7) 31%,white 100%);
  }
}

.row-inner-fix {
  margin-left: 15px;
  margin-right: 15px;
}


.stretched-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0,0,0,0);
  text-indent: -9999px;
  &:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0,0,0,0);
  }
}
@media screen and (max-width: 1199px) {
  .stretched-link--xl-max {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0,0,0,0);
  }
}

@media screen and (max-width: 767px) {
  .stretched-link--md-max {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0,0,0,0);
  }
}


.ReCP-002 {
  a {
    color: #fff;
  }
}


.modal {
  &.show {
    .modal-dialog {
      &.modal-center {
        display: flex;
        height: 100%;
        max-width: 800px;
        margin: 30px auto;
        .modal-content {
          align-self: center;
          .modal-body {
            position:relative;
            padding:0px;
            .close {
              position:absolute;
              right:-15px;
              top:-15px;
              z-index:999;
              font-size:2rem;
              font-weight: normal;
              color:#fff;
              opacity:1;
            }
          }
        }
      }
    }
  }
}

//jamies updates

.orthoplus .footer-list > li  {
  margin-bottom: 10px;
}
.orthoplus .footer-list > li.underline > a  {
  text-decoration: underline;
}
.orthoplus .footer-list > li > a, .orthoplus .footer-menus > li .title {
  color: #fff
}
.orthoplus .footer-list > li > a:hover {
  opacity: 0.6;
}
.orthoplus li.inline {
  display: inline-block;
    margin: 0 13px;
    list-style: none;
}
.orthoplus li.inline a, .orthoplus .footer__copyright {
  color: #BCBDBC;
  font-size: 1.2rem;
}
.orthoplus .footer__copyright {
  margin-right: 10px;
}
.orthoplus li.inline a:hover {
  opacity: 0.6;
}
.orthoplus .col-md-5ths--2x {
  width: 40%;
}
.orthoplus .col-md-5ths.last-2 {
  width: 19%;
}
@media (max-width: 1200px) {
  .orthoplus .col-md-5ths--2x {
    width: 50%;
  }
  .orthoplus .col-md-5ths.last-2 {
    width: 25%;
  }
}
@media (max-width: 768px) {
  .orthoplus li.inline {
    display: none;
  }
  .orthoplus .col-md-5ths--2x, .orthoplus .col-md-5ths.last-2 {
    width: 100%;
  }
}

.orthoplus .footer-menus > li h4.title .footer-drop-down:before {
  color: #fff;
}

a {
  &.link-unstyled {
    color: #000;
    &:hover {
      color: #000;
    }
  }
}

.tag-type {
  background: @op-color-dark-grey;
  padding: 4px 8px 2px 8px;
  color: white;
  line-height: 24px;
  font-size: 14px;
  text-transform: uppercase;
}

.credit-fee {
  color: @op-color-light-grey;
  line-height: 24px;
  font-size: 14px;
  text-transform: uppercase;
}

.video-length {
  font-size: 14px;
  line-height: 22px;
  color: #404040;
  margin-top: 5px;
}

.account-red-text {
  padding: 250px 0;
}

@media (max-width: 992px) {
  .account-red-text {

    padding:unset;
   }

}

.account-red-text p{
  color: white;
}

.account-red-text img {
  width: 220px;
  display: block;
  margin: 0 auto;
}


.account-red-text p:first-child {
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
  padding-top: 15px;
}


.account-red-text p:last-child {
  font-size: 20px;
  font-weight: normal;
  text-align: center;
  margin-top: 18px;
  padding-bottom: 15px;
}

.POS-051 {
  min-height: 210px;
}

.hero-container {
  color: #fff;
  padding: 20px;
  padding-bottom: 50px;
  .button-well {
    margin-bottom: 0;
  }
  h1 {
    margin-top: 40px;
    @media screen and (min-width: @bpLG) {
      font-size: 3.6rem;
      line-height: 4.4rem;
    }
  }
  .video-link {
    color: #fff;
  }
}

.background-secondary {
  background-image: none;
  background-color: @red1;
  background: linear-gradient(to right, darken(@red1, 5%), @red2);
  color: @white;
}

.POS-025 {
  position: relative;
  top: -40px;
}

.rightCaption {
  float: right;
}

.background-dark-grey {
  background-color: @op-color-dark-grey;
}


// jamies updates

.OD-001 .container .content p {
  max-width: 770px !important;
}
.HP-001 a.btn {
  background: transparent !important;
}
.HP-001 a.btn:hover {
  background: #CF2F44 !important
}

.orthoplus-beneath-video h4 {
  margin-bottom: 20px;
}
.orthoplus-beneath-video ul {
  margin: 0;
  padding: 0 0 0 15px
}
.orthoplus-beneath-video ul li {
  color: #8F191C;
  margin: 10px 0;
  font-weight: 500;
}
.orthoplus-beneath-video ul li span {
  color: black;
  font-size: 14px;
}
.orthoplus-beneath-video #POS007b h4 {
font-weight: bold
}
@media (max-width: 767px) {
  .orthoplus-beneath-video #POS007b {
    padding: 20px;
  }
}
.orthoplus .POS-015 {
  background: none !important;
}

@media (max-width: 479px) {
  .orthoplus .POS-015 .user-profile {
    display: block
  }
  .orthoplus .POS-015 h4 {
    font-size: 2.2rem;
  }
  .orthoplus .POS-015 .user-profile p {
    -webkit-border-image: -webkit-gradient(linear, left top, right top, from(#CF2F44), to(#8F191C)) 100% 1;
    -webkit-border-image: linear-gradient(to right, #CF2F44, #8F191C) 100% 1;
    -o-border-image: -o-linear-gradient(top, #CF2F44, #8F191C) 100% 1;
    border-image: -webkit-gradient(linear, right top, left top, from(#CF2F44), to(#8F191C)) 100% 1;
    border-image: linear-gradient(to right, #CF2F44, #8F191C) 100% 1;
    margin: 20px 0;
    padding: 20px 0 10px;
  }
  .orthoplus .POS-015 .user-profile img {
    width: 100%;
    height: 100%;
  }
}




.orthoplus-beneath-video {
  padding: 20px;
  background-color: #F8F8F8;
  @media screen and (min-width: @bpLG) {
    padding:  30px 45px;
  }
  .POS-007ALT {
    p {
      margin-top: 30px;
      margin-bottom: 30px;
      font-weight: bold;
    }
  }
}

.ie {
  .img-fluid {
    height: 100%;
    width: 100%;
  }
  @media screen and (min-width: @bpMD) {
    .request-call__container {
      > div {
        margin-top: 40px;
      }
    }
  }
  @media screen and (min-width: @bpLG) {
    .request-call__container {
      > div {
        margin-top: 50px;
      }
    }
  }
}
