@timeline-line: #D8D8D8;
@timeline-date: #CF2F44;

.AU-010 {

  .au-title {
    .font-size(3.6);
    margin-bottom: 60px;
    margin-top: 60px;
  }

  .au-border {
    border-top: 5px solid #F4F4F4;
    margin-top: 50px !important;
    padding-bottom: 50px !important;
    padding-top: 50px !important;
  }

  .small-text {
    .font-size(1.5);
  }

  .year {
    margin-top: 20px;

    @media(min-width: @bpMD) {
      margin-top: 30px;
    }
  }

   h2 {
    margin-top: 37px;
    margin-bottom: 20px;
    font-weight: bold;
    @media(min-width: @bpMD) {
      margin-top: 30px;
      margin-bottom: 30px;
     .font-size(3.6)
    }
  }
h4{
  .font-size(1.8)
}
  .sub-title {
    text-align: center;
    margin-bottom: 20px;
  }

  .timeline {
    position: relative;
    &::before {
        content: '';
        background: @timeline-line;
        width: 2px;
        height: 100%;
        position: absolute;
        left: 50px;
        transform: translateX(-50%);
    }
  }

  .timeline-item {
    width: 100%;
    margin-bottom: 70px;
    position: relative;
    &::after {
        content: '';
        display: block;
        clear: both;
    }
  }

  .timeline-content {
    position: relative;
    max-width: 100%;
    width: auto;
    margin-left: 70px;
    @media screen and (max-width: @bpMD - 1px) {
      max-width: 100%;
      width: auto;
      margin-left: 70px;
    }
  }

  .timeline-img {
    width: 14px;
    height: 14px;
    background: @timeline-line;
    border-radius: 50%;
    position: absolute;
    left: 28px;
    top: 50%;
    transform: translateY(-50%);
  }

.timeline-p-title {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #a9a9a9;
  text-transform: uppercase;
  font-weight: bold;
  @media(min-width: @bpLG) {
    font-size: inherit;
  }
}

.timeline-menu {
  li {
    margin-bottom: 10px;
    color: @color-brand-grey;
  }
}
  .timeline-menu__link {
    color: @color-brand-grey;
    position: relative;
    &:hover {
      color: @color-brand-red;
      text-decoration: none;
      @media(min-width: @bpLG) {
        &:before {
          width: 25px;
          height: 1px;
          background: #CF2F44;
          margin: 0 1rem;
          left: -50px;
          top: 5px;
          content: '';
          position: absolute;
        }
      }
    }
  }

  .timeline-content--date {
    .timeline-img {
      background: @timeline-date;
    }
  }

  .timeline-date {
    color: @timeline-date;
  }

  .timeline-menu {
    @media screen and (max-width: @bpLG - 1px) {
      display: flex;
      justify-content: space-between;
    }
  }

  .message-from {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    img {
      display: block;
      height: 150px;
      width: 235px;
      max-height: 250px;
      max-width: 250px;
      margin: 10px auto;
    }

  .timeline-tag {
    color: @timeline-date;
    font-size: 1rem;
    }
    .timeline-title {
      font-weight: bold;
      .font-size(2.6)
    }
    .timeline-text {
     .font-size(1.8);
      line-height: 24px;
    }
  }

  @media (min-width: @bpMD) {
    .clearfix {
      order: 2;
    }

    .message-from {
      text-align: left;
      flex-direction: row;
      .clearfix{
        width: 400px;
      }
      img {
        width: 100%;
        height: 250px;
        margin: 0;
        margin-left: 50px;
        max-width: 100%;
      }

    }

  }

}

.message-components {
  max-width: 400px;
  width: 100%;
}
.timeline {
  overflow: hidden;
}

@media (max-width: 991px) {

  .AU-001 {
    height: 420px;
    span{
      height: 420px;
    }

  }
  #HP004 .component > img{
    width: 100%;
  }
  .HP-004 .article-content{
    padding-left: 0;
  }
  .innovation-main-title{
    margin: 40px 0 !important;
  }
  .AU-006 .title {
    font-size: 18px;
    padding: 15px 20px;
  }
  .AU-006 .list-group li a{
    .font-size(1.4)!important;
  }
  #AU007 .innovation-title{
    margin: 30px 0;
  }
  .AU-006 .list-group{
    margin-bottom: 30px;
  }
  // #HP001{
  //   margin-top: 45px !important;
  // }
  // #HP001 h2{
  //   max-width: 100%;
  //   width: 100%;
  // }
  #HP001 .col{
    max-width: 100%;
  }
  .HP-001{
    height: 450px;
    span {
      height: 450px;
    }
  }
  .AU-003 .awards__image-container{
    height: 80px;
    margin-bottom: 10px;
  }
  .AU-003{
    padding-bottom: 15px;
  }
  .AU-003 .awards span{
    font-size: 10px;
  }
  .SOL-007.innovation-SQL{
    padding-top: 40px !important;
  }
  #SOL007 .SOL-007 h2{
    margin-bottom: 4rem;
  }

  .SOL-007.innovation-SQL .card-text{
    .font-size(1.2);
    line-height: 18px;
  }
  #SOL007 .SOL-007 .card.premium:not(.with-text):before{
    font-size:1rem
  }
  #SOL007 .SOL-007 .card{
    margin-bottom: 0;
  }
  #SOL007 .button-well{
    margin: 30px 0;
  }
  .AU-010 .timeline::before{
    left: 8px;
  }
  .AU-010 .timeline-img{
    left: -14px;
  }
  .AU-010 .timeline-content{
    margin-left: 30px;
  }
  .message-components{
    max-width: 290px;
  }
  .AU-010 .message-from .timeline-title{
    .font-size(2.0)
  }
  .AU-010 .message-from .timeline-text{
    .font-size(1.6)
  }
  .AU-010 .message-from img{
    margin-left: 5px;
  }
}


@media (max-width: 767px) {
  .AU-001{
    height: 450px;
  }
  .AU-001 span{
    height: 450px;
  }
  #AU001 p{
    line-height: 24px;
  }
  .AU-002 .innovation-title{
    .font-size(2.4) !important;
    margin: 20px 0;
  }
  .AU-001{
    margin-bottom: 0;
  }
  .innovation-text{
    .font-size(1.4);
    line-height: 22px;
  }
  #AU002 .AU-002 {
    margin-bottom: 30px;
  }
  #HP004 h2{
    .font-size(2.4);
    margin: 20px 0;
    line-height: 30px;
    padding-left: 15px;
  }
  #HP004 p span{
    .font-size(1.4);
    line-height: 22px;
    display: block;
  }
  .HP-004 .component .button-img img{
    width: 50%;
  }
  .innovation-main-title{
    .font-size(2.6) !important;
    margin: 0 0 40px 0 !important;
  }
  #AU007 .innovation-title{
    .font-size(2.4) !important;
    margin: 20px 0 20px;
  }
  .AU-006 button{
    margin-bottom: 15px;
  }
  #HP001 h2{
    .font-size(2.6);
    line-height: 35px;
  }
  .HP-001 span{
    padding: 48px 0;
  }
  .HP-001, .HP-001 span{
    height: 100%;
    min-height: 360px;
  }
  #HP001 p{
    .font-size(1.4);
    line-height: 22px;
  }
  .innovation-bg b{
    .font-size(1.2);
    margin-top: 20px;
    display: inline-block;

  }
  #AU003 .innovation-main-title{
    .font-size(2.6);
    line-height: 30px;
    margin-bottom: 30px !important;
  }
  .AU-003 .awards span{
    display: block;
    line-height: 16px;
  }
  .SOL-007 h2{
    .font-size(2.6);
    line-height: 30px;
  }
  .SOL-007.innovation-SQL .card-text{
    .font-size(1.8);
  }
  .SOL-007.innovation-SQL{
    margin-bottom: 40px !important;
  }
  .AU-010 .timeline-content{
    margin-left: 0;
  }
  .timeline-date{
    padding-left: 15px;
  }
  .AU-010 .timeline-item{
    margin-bottom: 30px;
  }
}

.responsibility-compliance {
  .awards {
    .awards__image-container {
      min-height: 145px;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      margin-bottom: 20px;
    }
    .awards__content {
      span {
        font-size: 1.2rem;
        color: @color-brand-grey;
      }
    }
  }
}
