@content-box-primary-bg: #EAEBEA;
@content-box-secondary-bg: linear-gradient(90deg,#CF2F44 0,#8F191C 100%);

.AU-006 {
  h2 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
    text-align: center;

    @media(min-width: @bpMD) {
      margin-top: 30px;
      margin-bottom: 30px;
      font-size: 2.4rem;
    }
  }

  @media(min-width: @bpMD) {
  .content-box__main-content {
      &.collapse:not(.show) {
          display: block;
      }
    }
  }

  .content-box {
    &.active {
     .fa-caret-down {
        transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
      }
    }
  }

  button {
    width: 100%;
    margin-bottom: 5px;
    border: 0;
    text-align: left;

  }

  .title {
    font-size: 26px;
    font-weight: bold;
    background-color: @content-box-primary-bg;
    padding: 35px 20px;
    &.title--red-background {
      background: @content-box-secondary-bg;
      color: @color-neutral-white;
    }
  }

  .list-group {
    border: 1px solid @color-brand-grey;
    padding: 0 10px;
    margin-bottom: 15px;
    @media(min-width: @bpMD) {
      margin-bottom: 60px;
    }
    li {
      border-color: @color-brand-grey;
      display: block;
      padding: 15px 0;
      a {
        display: block;
          .font-size(1.8) !important;

      }
    }
  }

}

.innovation-main-title {
  .font-size(4.6) !important;
  margin: 90px 0 56px !important;
}
