.message-container {
  border-top: 1px solid @op-color-extra-light-grey;
}

.message-container {
  &.unread {

    .message-row {
      background-color: @op-bg-grey;
      font-weight: bold;
      .message-icon-col {
        color: #CF2F44;
      }
      .message-time-date-col {

        @media screen and (min-width: @bpSM) {
          span {
            &:after {
              padding-left: 10px;
              content: '';
              display: inline-block;
              width: 10px;
              height: 10px;
              border-radius: 5px;
              background-color: #CF2F44;
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
}
.message-row {
  display: flex;
  margin-top: 3px;
  margin-bottom: 3px;
  padding: 10px 0;
  flex-direction: column;
  @media screen and (min-width: @bpSM) {
    flex-direction: row;
  }
}

.message-icon-col {
  display: none;
  @media screen and (min-width: @bpSM) {
    // min-width: 40px;
    // max-width: 40px;
    display: block;
    width: 7%;
    align-self: center;
    justify-content: center;
    i {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 20px;
    }
  }
}

.message-name-col {
  // max-width: 120px;
  // min-width: 120px;
  align-self: flex-start;
  width: 80%;
  @media screen and (min-width: @bpSM) {
  width: 18%;
  min-width: 120px;
  justify-content: center;
  align-items: center;
  align-self: center;
  }
  padding: 5px;
  p {
    margin-bottom: 0;
  }
}

.message-desc-col {
  align-self: flex-start;
  @media screen and (min-width: @bpSM) {
  width: 70%;
  align-self: center;
  justify-content: center;
  }
  padding: 5px;
  p {
    margin-bottom: 0;
  }
}

.message-time-date-col {
  // max-width: 120px;
  // min-width: 120px;
  width: 10%;
  min-width: 90px;
  display: flex;
  padding: 5px 10px;
  @media screen and (max-width: 575px) {
    position: absolute;
    right: 0;
    padding-right: 0;
    align-self: center;
    justify-content: center;
  }
  @media screen and (min-width: @bpSM) {
    justify-content: flex-start;
    align-items: center;
    align-self: center;
  }

}

.notifications-block {
  margin-bottom: 50px;
  @media screen and (min-width: @bpSM) {
    margin-bottom: 100px;
  }
}
