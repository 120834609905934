.orthoplus .POS-001 {
  background: #F8F8F8;
  padding: 20px;
  display: none;
  @media screen and (min-width: @bpXL) {
    display: block;
    nav {
      display: inline-block;
    }
    >span {
      float: right;
      font-size: 12px;
      line-height: 30px;
    }
  }
}

.orthoplus .POS-001 .breadcrumb a {
 text-decoration: none;
 color: #404040
}

.orthoplus {
  .POS-001 {
    .breadcrumb {
      a {
        &:after {
          display: none !important;
        }
      }
    }
  }
}

.orthoplus {
  .POS-001 {
    .breadcrumb {
      a {
        border: 0 !important;
        position: relative;
        padding-left: 5px;
        padding-right: 5px;
        margin-right: 5px;
        font-size: 1.8rem;
        margin-right: 20px;
        color: #212529;
        font-weight: bold;
        &:before {
          position: absolute;
          content: '';
          width: 0;
          bottom: -23px;
          left: 50%;
          transform: translateX(-50%);
          transition: 0.3s;
          height: 4px;
          background: linear-gradient(308.7deg, #8F191C 0%, #CF2F44 100%);
        }
        &:hover {
          &:before {
            width: 100%;
            background: linear-gradient(308.7deg, #8F191C 0%, #CF2F44 100%);
          }
        }
        &.active {
          color: #212529;
          &:before {
          background: linear-gradient(308.7deg, #8F191C 0%, #CF2F44 100%);
          width: 100%;
          }
        }
      }
      &:last-child {
        a {
          margin-right: 0;
        }
      }
    }
  }
}
 .POS-001 .breadcrumb a.active {
  color: #CF2F44;

}
.orthoplus .POS-001 .breadcrumb a:hover {
  color: #CF2F44;
  border-image: linear-gradient(308.7deg, #8F191C 0%, #CF2F44 100%);
  border-style: solid;
  border-width: 3px;
}
.orthoplus .POS-001 .breadcrumb a.active:after, .orthoplus .POS-001 .breadcrumb a:hover:after {

    display: block;
    position: relative;
    top: 20px;

}
.orthoplus .breadcrumb-item+.breadcrumb-item::before {
  display: none;
}

.orthoplus .POS-001 .breadcrumb {
margin-bottom: 0
}

.orthoplus .breadcrumb-item {
  padding: 0 20px 0 0;
}

.orthoplus .ReCP-001 h2 {
  font-size: 76px;
  font-size: 7.6rem;
  font-family: 'Calluna', sans-serif;
  line-height: 76px;
  margin-bottom: 40px;
  margin-top: 60px;
}
