.PP-007 {
  background-color: #f8f8f8;
  padding-top: 60px;
  padding-bottom: 60px;

  @media screen and (min-width: @bpMD) {
    margin-bottom: 90px;
  }

  h3 {
    .font-size(2.6);
    line-height: 34px;
    font-weight: bold;
  }

  p {
    .font-size(1.8);
    line-height: 30px;
  }

  .download-link {
      align-items: center;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      padding: 15px 0;
      border-bottom: 1px solid @border-grey;
  }

  .download-link__text {
      margin-bottom: 0;
  }

  svg {
    fill: @color-brand-red;
    height: 20px;
    width: 20px;
  }

  .PP-008 {
    margin-top: 60px;
  }
}
