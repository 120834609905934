.ReTD-013 {
  h4 {
    .font-size(2.6);
    line-height: 34px;
    margin-bottom: 30px;

    span {
      color: #8F191C;
    }
  }
}
