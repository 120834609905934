@settings-tab-bg: @op-color-extra-light-grey;

.settings-side-block {
  background-color: @op-bg-grey;
  padding: 20px;
  border-radius: 5px;
  span {
    font-weight: bold;
    font-size: 80%;
  }
}

.settings-side-block__title {
  color: @op-color-dark-red;
}

.settings-nav {
  .settings-nav-pill {
    margin-bottom: 2px;
    .settings-nav-pill__link {
      color: @op-color-white;
      padding: 20px;
      display: block;
      background-color: #404040;
      div {
        font-weight: bold;
      }
      span {
        font-size: 80%;
      }
      &.active {
        background-color: #CF2F44;
        background-image: linear-gradient(90deg, #CF2F44, #8F191C);
      }
    }
    &:first-child {
      a {
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
      }
    }
    &:last-child {
       margin-bottom: 0px;
      a {
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.tab-content-settings {
  height: 100%;
  .tab-pane {
    padding: 30px;
    background-color: @settings-tab-bg;
    border-radius: 2px;
    height: 100%;
    h2 {
      margin-top: 20px;
      margin-bottom: 15px;
      font-size: 2.4rem;
      font-weight: bold;
    }

    .form-row {
      margin-bottom: 10px;
      .form-control {
        border: 0;
        border-radius: 0;
      }
    }

    .reset-password {
      justify-content: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      margin-top: 10px;
    }



  }

}

.notification-settings {
  .notifications-text {
    margin-bottom: 0;
    font-weight: bold;
  }
  .notifications-example {
    position: relative;
    top: -2px;
    font-size: 90%;
  }
  .switch-container {
    margin-bottom: 20px;
    @media screen and (min-width: 577px) {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #404040;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #CF2F44;
  background-image: linear-gradient(90deg, #CF2F44, #8F191C);
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.form-my-profile {
  .form-group {
    @media screen and (min-width: 768px) {
      margin-bottom: 20px;
    }
  }
}
