.POS-004 {
  .post-back-to-link {
    margin-top: 20px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .post-back-to-link {
    a {
      color: #CF2F44;
      font-size: 2rem;
    }
  }
}

