.OD-001 {
  background-size: cover;
  background-position: center;
  width: 100%;
  display: block;

  @media (min-width: @bpMD) {
    height: 600px;
  }

  &.darkBG {
    color: #fff;

    .container {
      .content {
        p {
          border-image: linear-gradient(to bottom, #fff, #fff) 1 100%;
        }
      }
    }
  }

  .container {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    height: 100%;

    .content {
      display: flex;
      flex-flow: column;
      flex-grow: 1;
      justify-content: center;


      h1 {
        .font-size(7.6);
        font-family: @font-family-secondary;
        line-height: 76px;
        margin: 48px 0 48px;
      }

      p {
        .font-size(1.8);
        border-width: 4px;
        border-style: solid;
        border-image: linear-gradient(to bottom, @color-brand-red, #8f191c) 1 100%;
        border-right: 0;
        line-height: 33px;
        padding-left: 20px;
        max-width: 550px;
      }
    }
  }

  // This is positioned absolute only when in the hero block
  .OD-002 {
    @media (min-width: @bpMD) {
      display: flex;
      flex-flow: wrap;
      margin-top: -108px;
      padding: 20px;
      width: 70%;
      min-width: 640px;
    }
  }
}
